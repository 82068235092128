import React from "react";
import { Box, Typography } from "@material-ui/core";
import {
  frequentFlyerRegex as rewardsRegex,
  B2BTextField,
  NotificationBanner,
  BannerSeverity,
} from "halifax";
import { RouteComponentProps } from "react-router";
import { HertzLoyaltyFormConnectorProps } from "./container";
import "./styles.scss";
import clsx from "clsx";
import {
  HERTZ_REWARDS_TITLE,
  HERTZ_REWARDS_SUBTITLE,
  HERTZ_REWARDS_WARNING,
} from "./constants";
import { trackEvent } from "../../../../api/v1/analytics/trackEvent";
import {
  ADD_HERTZ_LOYALTY_REWARDS,
  CarsBookErrorEnum,
  ITravelerStepErrors,
  PaymentError,
} from "redmond";
import { ProductError } from "@b2bportal/purchase-api";

export interface IHertzLoyaltyFormProps
  extends RouteComponentProps,
    HertzLoyaltyFormConnectorProps {
  isMobile?: boolean;
  disabled?: boolean;
  showErrors?: ITravelerStepErrors;
  setShowErrors?: (showErrors: ITravelerStepErrors) => void;
}

export const HertzLoyaltyForm = ({
  hertzLoyaltyRewardsNumber,
  setHertzLoyaltyRewardsNumber,
  isMobile,
  disabled,
  showErrors,
  setShowErrors,
  priceQuoteErrors,
  confirmationDetailsErrors,
  selectedVehicle,
  useV1PurchaseFlow,
}: IHertzLoyaltyFormProps) => {
  React.useEffect(() => {
    if (
      [...priceQuoteErrors, ...confirmationDetailsErrors].some((error) => {
        return useV1PurchaseFlow
          ? (error as ProductError)?.value?.value?.msg ===
              CarsBookErrorEnum.InvalidLoyaltyNumber
          : (error as PaymentError)?.msg ===
              CarsBookErrorEnum.InvalidLoyaltyNumber;
      })
    ) {
      setShowErrors?.({ ...showErrors, loyaltyNumber: true });
    }
  }, [priceQuoteErrors, confirmationDetailsErrors]);

  const onRewardsBlur = (val: string) => {
    if (!val) {
      setShowErrors?.({ ...showErrors, loyaltyNumber: true });
    } else {
      rewardsRegex.test(val)
        ? setShowErrors?.({ ...showErrors, loyaltyNumber: false })
        : setShowErrors?.({ ...showErrors, loyaltyNumber: true });
    }
  };

  const onRewardsChanged = (val: string) => {
    setHertzLoyaltyRewardsNumber(val);
    rewardsRegex.test(val) &&
      setShowErrors?.({ ...showErrors, loyaltyNumber: false });
    trackEvent({
      eventName: ADD_HERTZ_LOYALTY_REWARDS,
      properties: {},
    });
  };
  return (
    <Box
      className={clsx("hertz-loyalty-rewards-container", { mobile: isMobile })}
    >
      <Box className="hertz-loyalty-rewards-description">
        <Typography variant="h3" className="title">
          {HERTZ_REWARDS_TITLE(selectedVehicle?.supplierRef)}
        </Typography>
        <Typography variant="body2" className="subtitle">
          {HERTZ_REWARDS_SUBTITLE(selectedVehicle?.supplierRef)}
        </Typography>
        <NotificationBanner
          className="hertz-rewards-price-banner"
          label={HERTZ_REWARDS_WARNING(selectedVehicle?.supplierRef)}
          severity={BannerSeverity.NOTICE}
        />
      </Box>
      <B2BTextField
        className="hertz-rewards-input-field"
        label="Rewards Number"
        value={hertzLoyaltyRewardsNumber}
        onChange={(value) => onRewardsChanged(value)}
        type="email"
        id="hertz-rewards-input-field"
        errorHelper={
          showErrors?.loyaltyNumber
            ? "Enter a valid rewards number."
            : undefined
        }
        onBlur={(value) => onRewardsBlur(value)}
        disabled={disabled}
      />
    </Box>
  );
};
