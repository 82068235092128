import axios from "axios";
import { config } from "../../config";
import { trackEngagementDataPath } from "../paths";
import { getOrSetCookie } from "@capone/common";
import { EngagementRequest, EngagementResponse } from "redmond";
import { generateUserId } from "halifax";

const END_POINT: string = trackEngagementDataPath;

export const trackEngagementEvent = (
  req: EngagementRequest
): Promise<EngagementResponse> =>
  new Promise(async (resolve, reject) => {
    try {
      const res = await axios.post(
        END_POINT,
        {
          event: {
            ...req.event,
            url: window.location.href,
            browser_session_id: getOrSetCookie(
              "browser_session_id",
              generateUserId()
            ),
          },
        },
        {
          baseURL: config.baseURL,
        }
      );

      const responseBody = res.data;
      resolve(responseBody);
    } catch (e) {
      console.error(e);
      reject(e);
    }
  });
