import { connect, ConnectedProps } from "react-redux";
import { withRouter } from "react-router";
import { AvailabilityErrorModal } from "./component";
import {
  getCarAvailabilityCallState,
  CarAvailabilityCallState,
} from "../../reducer";
import {
  fetchInitialCarsAvailability,
  resetCallStates,
} from "../../actions/actions";
import { IStoreState } from "../../../../reducers/types";

const mapStateToProps = (state: IStoreState) => {
  return {
    hasFailed:
      getCarAvailabilityCallState(state) === CarAvailabilityCallState.Failed,
  };
};

const mapDispatchToProps = {
  fetchInitialCarsAvailability,
  resetCallStates,
};

const connector = connect(mapStateToProps, mapDispatchToProps);

export type AvailabilityErrorModalConnectorProps = ConnectedProps<
  typeof connector
>;

export const ConnectedAvailabilityErrorModal = withRouter(
  connector(AvailabilityErrorModal)
);
