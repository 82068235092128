import React, { useMemo } from "react";
import { RouteComponentProps } from "react-router-dom";
import { Box } from "@material-ui/core";
import { ActionButton } from "halifax";

import { DriverAgeInput } from "../../../DesktopCarSearchControl/components/DriverAgeInput";
import {
  PickUpTimeSelector,
  DropOffTimeSelector,
} from "../../../DesktopCarSearchControl/components/TimeSelector";
import { transformToStringifiedAvailabilityQuery } from "../../../../../availability/utils/queryStringHelpers";
import { PATH_AVAILABILITY } from "../../../../../../utils/paths";
import { TimeAndAgePickerConnectorProps } from "./container";
import * as textConstants from "./constants";
import "./styles.scss";
import { calcMinimumDropOffTime } from "../../../DesktopCarSearchControl/components/TimeSelector/util";
import {
  AVAILABLE,
  CARS_OFF_HOUR_PICKUP,
  getExperimentVariant,
  useExperiments,
} from "../../../../../../context/experiments";

interface ITimeAndAgePicker
  extends TimeAndAgePickerConnectorProps,
    RouteComponentProps {}

export const TimeAndAgePicker = (props: ITimeAndAgePicker) => {
  const {
    history,
    readyToSearch,
    dropOffDate,
    dropOffTime,
    dropOffLocation,
    pickUpDate,
    pickUpTime,
    pickUpLocation,
    driverAge,
  } = props;

  const expState = useExperiments();

  const isCarsOffHourPickup = useMemo(
    () =>
      getExperimentVariant(expState.experiments, CARS_OFF_HOUR_PICKUP) ===
      AVAILABLE,
    [expState.experiments]
  );

  const minTime: string | undefined = useMemo(() => {
    if (pickUpDate && dropOffDate && pickUpTime) {
      return calcMinimumDropOffTime(pickUpDate, dropOffDate, pickUpTime);
    }
    return;
  }, [pickUpDate, dropOffDate, pickUpTime]);

  return (
    <Box className="time-age-picker-container redesign-v3">
      <Box className="time-picker-container">
        <PickUpTimeSelector increment={isCarsOffHourPickup ? 60 : 30} />

        <DropOffTimeSelector
          minTime={minTime}
          increment={isCarsOffHourPickup ? 60 : 30}
        />
      </Box>

      <DriverAgeInput className="driver-age-input" useModal />

      {readyToSearch && (
        <ActionButton
          className="cars-search-button"
          message={textConstants.SEARCH_RENTAL_CARS}
          onClick={() => {
            history.push(
              `${PATH_AVAILABILITY}${transformToStringifiedAvailabilityQuery(
                dropOffDate!,
                dropOffTime!,
                dropOffLocation!,
                pickUpDate!,
                pickUpTime!,
                pickUpLocation!,
                driverAge!
              )}`
            );
          }}
        />
      )}
    </Box>
  );
};
