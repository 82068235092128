import React, {
  createContext,
  FC,
  PropsWithChildren,
  useContext,
  useEffect,
  useState,
} from "react";
import { Experiment, ExperimentState, ExperimentVariant } from "redmond";
import { fetchActiveExperiments } from "../api/v1/experiments/fetchExperiments";

// Active Experiments

export const TREES_MODAL_EXPERIMENT = "c1-marketplace-trees";
export const TRAVEL_WALLET_OFFER_EXPERIMENT =
  "c1-marketplace-travel-wallet-offers";
export const CASH_VALUEPROP_EXPERIMENT = "c1-marketplace-cash-valueprop-merch";
export const TRAVEL_WALLET_CREDITS_EXPERIMENT =
  "c1-marketplace-travel-wallet-credits";
export const MOBILE_HOMESCREEN_REDESIGN_EXPERIMENT =
  "c1-marketplace-mobile-homescreen-redesign";
export const VCN_ENABLEMENT = "c1-marketplace-vcn-enablement";
export const CREDIT_OFFER_STACKING_V1 =
  "c1-marketplace-credit-offer-stacking-v1";
export const TRAVEL_CREDIT_HISTORY_EXPERIMENT =
  "c1-marketplace-credit-transaction-history";
export const CARS_OFF_HOUR_PICKUP = "c1-marketplace-off-hour-pickup-cars";
export const RECENTLY_VIEWED_V2_CARS = "c1-marketplace-recently-viewed-v2-cars";
export const ANNUAL_TRAVEL_CREDITS = "c1-marketplace-annual-travel-credits";
export const PRICE_MATCH_CREDITS_EXPERIMENT =
  "c1-marketplace-price-match-credits";
export const TRAVEL_SALE = "c1-marketplace-travel-sale";
export const CARS_CX_V1 = "c1-marketplace-cars-cx-v1";
export const CARS_V1_PURCHASE_FLOW = "c1-marketplace-cars-purchase-v2";
export const GLOBAL_MOBILE_NAV_EXPERIMENT = "c1-marketplace-global-mobile-nav";

// Default variants
export const CONTROL = "control";
export const AVAILABLE = "available";
export const DEFAULT_VARIANTS = [CONTROL, AVAILABLE] as const;
export type DefaultVariantType = typeof CONTROL | typeof AVAILABLE;

// Variants for c1-marketplace-cash-valueprop-merch
export const CASH_VALUEPROP_A = "cash-valueprop-a";
export const CASH_VALUEPROP_B = "cash-valueprop-b";
export const CASH_VALUEPROP_C = "cash-valueprop-c";
export const CASH_VALUEPROP_VARIANTS = [
  CONTROL,
  CASH_VALUEPROP_A,
  CASH_VALUEPROP_B,
  CASH_VALUEPROP_C,
] as const;
export type CashValuePropVariantType =
  | typeof CONTROL
  | typeof CASH_VALUEPROP_A
  | typeof CASH_VALUEPROP_B
  | typeof CASH_VALUEPROP_C;
// Variants for c1-marketplace-mobile-homescreen-redesign
export const MOBILE_HOMESCREEN_REDESIGN_V1 = "variant-1";
export const MOBILE_HOMESCREEN_REDESIGN_V2 = "variant-2";
export const MOBILE_HOMESCREEN_REDESIGN_V3 = "variant-3";
export const MOBILE_HOMESCREEN_REDESIGN_VARIANTS = [
  CONTROL,
  MOBILE_HOMESCREEN_REDESIGN_V1,
  MOBILE_HOMESCREEN_REDESIGN_V2,
  MOBILE_HOMESCREEN_REDESIGN_V3,
] as const;

export type MobileHomescreenRedesignPropVariantType =
  | typeof CONTROL
  | typeof MOBILE_HOMESCREEN_REDESIGN_V1
  | typeof MOBILE_HOMESCREEN_REDESIGN_V2
  | typeof MOBILE_HOMESCREEN_REDESIGN_V3;

// Variants for c1-marketplace-travel-sale
export const TRAVEL_SALE_LEAD_UP = "lead-up";
export const TRAVEL_SALE_ACTIVE = "active";
export const TRAVEL_SALE_VARIANTS = [
  CONTROL,
  TRAVEL_SALE_LEAD_UP,
  TRAVEL_SALE_ACTIVE,
] as const;
export type TravelSalePropVariantType =
  | typeof CONTROL
  | typeof TRAVEL_SALE_LEAD_UP
  | typeof TRAVEL_SALE_ACTIVE;

// Variants for c1-marketplace-cars-cx-v1
export const CARS_CX_V1_VARIANTS = DEFAULT_VARIANTS;
export type CarsCXV1VariantType = typeof CONTROL | typeof AVAILABLE;

const defaultInitializer = (): ExperimentState => {
  return {
    experiments: [],
    trackingProperties: undefined,
  };
};

export const ExperimentsContext = createContext<ExperimentState | undefined>(
  undefined
);

// readonly (together with const restriction below) ensures typeof supportedVariants[number]
// can be recognized by transpiler as an array of constants
// [string, ...string[]] ensures array is not empty.
export function getExperimentVariantCustomVariants<
  T extends readonly [string, ...string[]]
>(
  experiments: Array<Experiment>,
  experimentId: string,
  // List of supported variants. If the variant retreived is not recognized by the code, the code will fall back to the first variant.
  // Note the input list needs to be declared as const e.g. const DEFAULT_VARIANTS = [CONTROL, AVAILABLE] as const;
  supportedVariants: T
): (typeof supportedVariants)[number] {
  const experiment = experiments?.find((exp) => exp.id === experimentId);

  if (experiment) {
    if (supportedVariants.includes(experiment.variant)) {
      return experiment.variant;
    } else {
      return supportedVariants[0];
    }
  } else {
    return supportedVariants[0];
  }
}

export function getExperimentVariant(
  experiments: Array<Experiment>,
  experimentId: string
): DefaultVariantType {
  return getExperimentVariantCustomVariants(
    experiments,
    experimentId,
    DEFAULT_VARIANTS
  );
}

export function useExperiments(): ExperimentState {
  const ctx = useContext(ExperimentsContext);
  if (!ctx) throw new Error(`must be used within a ExperimentsProvider`);
  return ctx;
}

export function useExperiment(
  experimentId: string,
  target: string = ExperimentVariant.AVAILABLE
) {
  const { experiments } = useExperiments();
  if (!experiments) throw new Error("No experiments found in Context");
  const expVariant = getExperimentVariant(experiments, experimentId);

  return expVariant === target;
}

export function addTrackingProperties(
  trackingProperties: Map<string, string> | undefined,
  properties?: any
): unknown {
  if (!properties) {
    properties = {};
  }
  if (trackingProperties) {
    properties["experiments"] = Object.keys(trackingProperties).map(
      (ex) => `${ex}_${trackingProperties![ex]}`
    );
  }
  return properties;
}

type ExperimentsProviderProps = {
  initState?: ExperimentState;
};

const ExperimentsProvider: FC<ExperimentsProviderProps & PropsWithChildren> = ({
  initState = defaultInitializer(),
  children,
}) => {
  // TODO: use tracking reducers
  const [state, setState] = useState(initState);

  useEffect(() => {
    if (!initState?.experiments.length) {
      const fetchExperiments = async () => {
        await fetchActiveExperiments().then((result) => {
          setState(result as ExperimentState);
        });
      };
      fetchExperiments();
    }
  }, []);

  return (
    <ExperimentsContext.Provider value={state}>
      {children}
    </ExperimentsContext.Provider>
  );
};

export default ExperimentsProvider;
