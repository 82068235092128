import axios from "axios";
import { config } from "../../config";

import {
  CarShopRequest,
  CarShopResponse,
  VIEWED_CAR_SHOP_LOADING,
} from "redmond";
import { carExtraInfo } from "../paths";

import dayjs from "dayjs";
import { trackEvent } from "../analytics/trackEvent";

export const fetchCarExtraInfo = (
  body: CarShopRequest
): Promise<CarShopResponse> =>
  new Promise(async (resolve, reject) => {
    try {
      const startTime = dayjs();
      const res = await axios.post(carExtraInfo, body, {
        baseURL: config.baseURL,
      });
      trackEvent({
        eventName: VIEWED_CAR_SHOP_LOADING,
        properties: {
          runtime: dayjs().diff(startTime, "seconds", true),
        },
      });
      resolve(res.data);
    } catch (e) {
      reject(e);
    }
  });
