import { connect, ConnectedProps } from "react-redux";
import { withRouter } from "react-router";
import { IStoreState } from "../../../../reducers/types";

import { CarTravelWalletDrawer as TravelWalletDrawer } from "./component";

import {
  getActiveTravelWalletOffers,
  getCreditBreakdown,
  getTravelWalletCredit,
  getTravelWalletOffers,
} from "../../reducer";
import { getRewardsAccounts } from "../../../rewards/reducer";

const mapStateToProps = (state: IStoreState) => {
  return {
    allOffers: getTravelWalletOffers(state),
    activeOffers: getActiveTravelWalletOffers(state),
    rewardsAccounts: getRewardsAccounts(state),
    travelCredit: getTravelWalletCredit(state),
    creditBreakdown: getCreditBreakdown(state),
  };
};

export const mapDispatchToProps = {};

const connector = connect(mapStateToProps, mapDispatchToProps);

export type TravelWalletDrawerConnectorProps = ConnectedProps<typeof connector>;

export const ConnectedTravelWalletDrawer = withRouter(
  connector(TravelWalletDrawer)
);
