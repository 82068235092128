import React, { useEffect } from "react";
import { GenericInfoPopup, Icon, IconName, useDeviceTypes } from "halifax";
import type { BookingSubmitApprovalModalConnectorProps } from "./container";
import * as textConstants from "./textConstants";
import { useHistory } from "react-router-dom";

import "./styles.scss";
import { trackEvent } from "../../../../../api/v1/analytics/trackEvent";
import {
  MODAL_ALERT,
  ModalAlertProperties,
  ModalCategoryType,
  ModalScreens,
} from "redmond";

export interface IBookingSubmitApprovalModalProps
  extends BookingSubmitApprovalModalConnectorProps {}

export const BookingSubmitApprovalModal = ({
  isOpen,
}: IBookingSubmitApprovalModalProps) => {
  const { matchesMobile } = useDeviceTypes();
  const history = useHistory();
  const goToTrips = () => {
    history.push("/trips/?tripsFilter=Trip%20Requests");
  };

  useEffect(() => {
    if (isOpen) {
      trackEvent({
        eventName: MODAL_ALERT,
        properties: {
          type: "request_submitted",
          screen: ModalScreens.CARS_CHECKOUT,
          category: ModalCategoryType.FEATURE,
          primary_button: "Done",
          secondary_button: "",
          modal_title: textConstants.title,
          agent_title: textConstants.title,
          modal_subtitle: textConstants.subtitle,
          agent_subtitle: textConstants.subtitle,
          funnel: "cars",
          step: "checkout",
        } satisfies ModalAlertProperties,
      });
    }
  }, [isOpen]);

  return (
    <GenericInfoPopup
      className="booking-submit-approval-modal"
      isMobile={matchesMobile}
      onClose={goToTrips}
      open={isOpen}
      image={
        <Icon
          className="request-success-icon"
          name={IconName.RequestSubmittedGreenIcon}
        />
      }
      subtitle={textConstants.subtitle}
      title={textConstants.title}
      buttons={[
        {
          buttonText: "Done",
          onClick: goToTrips,
        },
      ]}
    />
  );
};
