import React from "react";
import { Box } from "@material-ui/core";
import { NoResults, ActionButton, useDeviceTypes } from "halifax";
import { RouterProps } from "react-router";
import clsx from "clsx";

import { AvailabilityNoResultsConnectorProps } from "./container";
import * as textConstants from "./textConstants";
import "./styles.scss";

export interface IAvailabilityNoResultsProps
  extends AvailabilityNoResultsConnectorProps,
    RouterProps {}

export const AvailabilityNoResults = (props: IAvailabilityNoResultsProps) => {
  const {
    history,
    hasChangedFilter,
    resetFilters,
    fetchInitialCarsAvailability,
  } = props;
  const { matchesMobile } = useDeviceTypes();

  return (
    <Box
      className={clsx("availability-no-results-root", {
        mobile: matchesMobile,
      })}
    >
      <Box className={"availability-no-results-container"}>
        <NoResults
          className="availability-no-results"
          title={textConstants.NO_CARS_TITLE_TEXT}
          subtitle={textConstants.NO_CARS_SUBTITLE_TEXT}
        />
        <Box className="availability-no-results-buttons">
          <ActionButton
            className="search-again-button"
            defaultStyle="h4r-secondary"
            message={textConstants.SEARCH_AGAIN_BUTTON_TEXT}
            onClick={() => fetchInitialCarsAvailability(history)}
          />
          {hasChangedFilter && (
            <ActionButton
              className="reset-button"
              defaultStyle="h4r-secondary"
              message={textConstants.RESET_BUTTON_TEXT}
              onClick={resetFilters}
            />
          )}
        </Box>
      </Box>
    </Box>
  );
};
