import { CorpUsersListPayload } from "../../api/v1/user/fetchCorpUsersList";
import { Gender, ICorpPerson, IPerson } from "redmond";
import { generateUserId } from "halifax";
import dayjs from "dayjs";

const mapExistingTravelers = (
  travelers: IPerson[],
  usersListPayload: CorpUsersListPayload[],
  hiddenTravelerIds: string[],
  loggedInUserId: string
): ICorpPerson[] => {
  return travelers.map((traveler) => {
    const deUser = usersListPayload.find(
      (userResponse) => userResponse.defaultTravelerId === traveler.id
    );

    const isPrivateProfile = hiddenTravelerIds.includes(traveler.id);

    return {
      ...traveler,
      isGhostUser: !deUser,
      isMissingInfo: false,
      isPrivateProfile: isPrivateProfile,
      isDefaultProfile: deUser && deUser.id === loggedInUserId,
    };
  });
};

const createSyntheticTravelers = (
  travelers: IPerson[],
  usersListPayload: CorpUsersListPayload[],
  loggedInUserId: string
): ICorpPerson[] => {
  const existingTravelerIds = new Set(travelers.map((traveler) => traveler.id));
  const syntheticTravelers = usersListPayload
    .filter((deUser) => !existingTravelerIds.has(deUser.defaultTravelerId))
    .map((missingInfoUser) => {
      return {
        id: generateUserId(),
        givenName: missingInfoUser.firstName,
        surname: missingInfoUser.lastName,
        gender: Gender.M,
        dateOfBirth: "1870-01-01",
        assistance: [],
        createdAt: dayjs().toISOString(),
        frequentFlyer: {},
        hotelLoyalty: {},
        isGhostUser: false,
        isMissingInfo: true,
        linkedDEUserId: missingInfoUser.id,
        isDefaultProfile: missingInfoUser.id === loggedInUserId,
      };
    });

  return syntheticTravelers;
};

export const buildCorpTravelers = (
  travelers: IPerson[],
  usersListPayload: CorpUsersListPayload[],
  hiddenTravelerIds: string[],
  loggedInUserId: string
): ICorpPerson[] => {
  const existingTravelers = mapExistingTravelers(
    travelers,
    usersListPayload,
    hiddenTravelerIds,
    loggedInUserId
  );
  const syntheticTravelers = createSyntheticTravelers(
    travelers,
    usersListPayload,
    loggedInUserId
  );
  const corpUsers = [...existingTravelers, ...syntheticTravelers];
  corpUsers.sort((userA, userB) => {
    if (userA.givenName < userB.givenName) return -1;
    if (userA.givenName > userB.givenName) return 1;
    if (userA.surname < userB.surname) return -1;
    if (userA.surname > userB.surname) return 1;

    return 0;
  });
  return corpUsers;
};
