import React, { useState } from "react";
import { Box, Typography, Link } from "@material-ui/core";
import clsx from "clsx";

import "./styles.scss";

const MAX_CHAR_COUNT = 115;

interface IPolicyDetails {
  title?: string;
  text?: string;
  displayText?: string;
}

interface ICarPolicyCardProps {
  title: string;
  description?: string;
  details?: IPolicyDetails[];
  isMobile?: boolean;
}

export const CarPolicyCard = (props: ICarPolicyCardProps) => {
  const { title, description, details, isMobile } = props;

  const [isHidden, setIsHidden] = useState<boolean>(true);

  if (!description && !details) return null;

  const displayText = details
    ? details?.reduce((detail, policy) => `${detail} ${policy.text}`, "")
    : description || "";

  return (
    <Box className={clsx("car-policy-card", { mobile: isMobile })}>
      <Typography className="car-policy-title">{title}</Typography>
      {details ? (
        details.map((detail, index) => (
          <React.Fragment key={index}>
            <Typography
              className={clsx("car-policy-text", isHidden && "hidden")}
              variant="subtitle1"
              dangerouslySetInnerHTML={{
                __html: detail?.title || "",
              }}
            />
            <Typography
              className={clsx("car-policy-text", isHidden && "hidden")}
              variant="body1"
              dangerouslySetInnerHTML={{
                __html: isHidden
                  ? detail.text && detail.text.length > MAX_CHAR_COUNT
                    ? `${detail.text?.slice(0, MAX_CHAR_COUNT)}...`
                    : ""
                  : detail.text || "",
              }}
            />
          </React.Fragment>
        ))
      ) : (
        <Typography className="car-policy-text">{displayText}</Typography>
      )}
      {displayText.length > MAX_CHAR_COUNT && (
        <Link
          className="hide-text-button"
          onClick={() => setIsHidden(!isHidden)}
          aria-expanded={!isHidden}
          role="button"
          aria-label="Hide or show more policy text"
          tabIndex={0}
        >
          {isHidden ? "View more" : "View less"}
        </Link>
      )}
    </Box>
  );
};
