import { put, select } from "redux-saga/effects";
import { actions } from "../../actions";
import { ConnectionResultEnum, InvalidEnum, TRIP_REQUESTED_CAR } from "redmond";
import { getCompleteBuyCarProperties, getSession } from "../../reducer";
import { IStoreState } from "../../../../reducers/types";
import submitForApproval from "../../../../api/v1/book/book-flow/submitForApproval";
import { trackEvent } from "../../../../api/v1/analytics/trackEvent";

export function* submitForApprovalSaga({
  payload,
}: actions.ISetSubmitForApproval) {
  try {
    const state: IStoreState = yield select();
    const session = getSession(state);
    if (!session) {
      throw Error("Session is undefined");
    }
    const completeBuyCarProperties = getCompleteBuyCarProperties(state);

    yield submitForApproval(payload);

    trackEvent({
      eventName: TRIP_REQUESTED_CAR,
      properties: {
        ...completeBuyCarProperties.properties,
        success: true,
      },
      encryptedProperties: [...completeBuyCarProperties.encryptedProperties],
    });

    yield put(actions.setSubmitForApprovalSuccess());
  } catch (e) {
    const state: IStoreState = yield select();
    const completeBuyCarProperties = getCompleteBuyCarProperties(state);

    trackEvent({
      eventName: TRIP_REQUESTED_CAR,
      properties: {
        ...completeBuyCarProperties.properties,
        success: false,
      },
      encryptedProperties: [...completeBuyCarProperties.encryptedProperties],
    });

    yield put(
      actions.setSubmitForApprovalFailure({
        Invalid: InvalidEnum.Missing,
        ConnectionResult: ConnectionResultEnum.Invalid,
      })
    );
  }
}
