import {
  CarsAutocompleteResponse,
  CarsAutocompleteResult,
  CarsTripCategory,
  DateTime,
} from "redmond";
import * as actionTypes from "./constants";
import { MobileCarSearchStep } from "../reducer";

export interface IFetchLocationResults {
  type:
    | actionTypes.FETCH_DROP_OFF_LOCATION_RESULTS
    | actionTypes.FETCH_PICK_UP_LOCATION_RESULTS;
  queryString: string;
}

export const fetchDropOffLocationResults = (
  queryString: string
): IFetchLocationResults => ({
  type: actionTypes.FETCH_DROP_OFF_LOCATION_RESULTS,
  queryString,
});

export const fetchPickUpLocationResults = (
  queryString: string
): IFetchLocationResults => ({
  type: actionTypes.FETCH_PICK_UP_LOCATION_RESULTS,
  queryString,
});

export interface ISetLocationResults {
  type:
    | actionTypes.SET_DROP_OFF_LOCATION_RESULTS
    | actionTypes.SET_PICK_UP_LOCATION_RESULTS;
  results: CarsAutocompleteResponse[];
}

export const setDropOffLocationResults = (
  results: CarsAutocompleteResponse[]
): ISetLocationResults => ({
  type: actionTypes.SET_DROP_OFF_LOCATION_RESULTS,
  results,
});

export const setPickUpLocationResults = (
  results: CarsAutocompleteResponse[]
): ISetLocationResults => ({
  type: actionTypes.SET_PICK_UP_LOCATION_RESULTS,
  results,
});

export interface ISetLocation {
  type: actionTypes.SET_DROP_OFF_LOCATION | actionTypes.SET_PICK_UP_LOCATION;
  location: CarsAutocompleteResult | null;
}

export const setPickUpLocation = (
  location: CarsAutocompleteResult | null
): ISetLocation => ({
  type: actionTypes.SET_PICK_UP_LOCATION,
  location,
});

export const setDropOffLocation = (
  location: CarsAutocompleteResult | null
): ISetLocation => ({
  type: actionTypes.SET_DROP_OFF_LOCATION,
  location,
});

export interface ISetDate {
  type: actionTypes.SET_DROP_OFF_DATE | actionTypes.SET_PICK_UP_DATE;
  date: Date | null;
}

export const setDropOffDate = (date: Date | null): ISetDate => ({
  type: actionTypes.SET_DROP_OFF_DATE,
  date,
});

export const setPickUpDate = (date: Date | null): ISetDate => ({
  type: actionTypes.SET_PICK_UP_DATE,
  date,
});

export interface ISetTime {
  type: actionTypes.SET_DROP_OFF_TIME | actionTypes.SET_PICK_UP_TIME;
  time: DateTime | null;
}

export const setDropOffTime = (time: DateTime | null): ISetTime => ({
  type: actionTypes.SET_DROP_OFF_TIME,
  time,
});

export const setPickUpTime = (time: DateTime | null): ISetTime => ({
  type: actionTypes.SET_PICK_UP_TIME,
  time,
});

export interface ISetDriverAge {
  type: actionTypes.SET_DRIVER_AGE;
  driverAge: number | null;
}

export const setDriverAge = (driverAge: number | null): ISetDriverAge => ({
  type: actionTypes.SET_DRIVER_AGE,
  driverAge,
});

export interface ISetOpenDatesModal {
  type: actionTypes.SET_OPEN_DATES_MODAL;
  openDatesModal: boolean;
}

export const setOpenDatesModal = (
  openDatesModal: boolean
): ISetOpenDatesModal => ({
  type: actionTypes.SET_OPEN_DATES_MODAL,
  openDatesModal,
});

export interface ISetMobileSearchMobileStep {
  type: actionTypes.SET_MOBILE_SEARCH_PROGRESS;
  mobileCarSearchStep: MobileCarSearchStep;
}

export const setMobileCarSearchStep = (
  mobileCarSearchStep: MobileCarSearchStep
): ISetMobileSearchMobileStep => ({
  type: actionTypes.SET_MOBILE_SEARCH_PROGRESS,
  mobileCarSearchStep,
});

export interface ISetTripCategory {
  type: actionTypes.SET_TRIP_CATEGORY;
  tripCategory: CarsTripCategory;
}

export const setTripCategory = (
  tripCategory: CarsTripCategory
): ISetTripCategory => ({
  type: actionTypes.SET_TRIP_CATEGORY,
  tripCategory,
});

export interface ISetPickUpAutocompleteError {
  type: actionTypes.SET_PICK_UP_AUTOCOMPLETE_ERROR;
  error: boolean;
}

export const setPickUpAutocompleteError = (
  error: boolean
): ISetPickUpAutocompleteError => ({
  type: actionTypes.SET_PICK_UP_AUTOCOMPLETE_ERROR,
  error,
});

export interface ISetDropOffAutocompleteError {
  type: actionTypes.SET_DROP_OFF_AUTOCOMPLETE_ERROR;
  error: boolean;
}

export const setDropOffAutocompleteError = (
  error: boolean
): ISetDropOffAutocompleteError => ({
  type: actionTypes.SET_DROP_OFF_AUTOCOMPLETE_ERROR,
  error,
});

export type CarSearchActions =
  | IFetchLocationResults
  | ISetLocation
  | ISetLocationResults
  | ISetLocation
  | ISetTime
  | ISetDate
  | ISetDriverAge
  | ISetOpenDatesModal
  | ISetMobileSearchMobileStep
  | ISetTripCategory
  | ISetPickUpAutocompleteError
  | ISetDropOffAutocompleteError;
