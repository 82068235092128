import { connect, ConnectedProps } from "react-redux";
import { withRouter } from "react-router";
import { IStoreState } from "../../reducers/types";
import { CarsShop } from "./component";
import { actions } from "./actions";
import { getRewardsAccounts } from "../rewards/reducer";
import { actions as rewardsActions } from "../rewards/actions";
import { getViewedCarDetailsProperties } from "./reducer/selectors";

const mapStateToProps = (state: IStoreState) => {
  return {
    rewardsAccounts: getRewardsAccounts(state),
    viewedCarFromListProperties: getViewedCarDetailsProperties(state),
  };
};

export const mapDispatchToProps = {
  fetchCarExtraInfo: actions.fetchCarExtraInfo,
  setSelectedAccount: rewardsActions.setSelectedAccountReferenceId,
};

const connector = connect(mapStateToProps, mapDispatchToProps);
export type CarsShopConnectorProps = ConnectedProps<typeof connector>;

export const ConnectedCarsShop = withRouter(connector(CarsShop));
