import axios from "axios";
import { config } from "../../../config";

import { carBookApiPrefix } from "../../paths";
import { CarPriceQuoteRequest, ConnectionResult } from "redmond";

const END_POINT: string = `${carBookApiPrefix}/priceQuote/schedule`;

export const schedulePriceQuote = (
  req: CarPriceQuoteRequest
): Promise<ConnectionResult> =>
  new Promise(async (resolve, reject) => {
    try {
      const res = await axios.post(END_POINT, req, {
        baseURL: config.baseURL,
      });

      const responseBody: ConnectionResult = res.data;
      resolve(responseBody);
    } catch (e) {
      reject(e);
    }
  });
