import { connect, ConnectedProps } from "react-redux";
import { IStoreState } from "../../../../reducers/types";
import { PriceBreakdownDropdown } from "./component";
import {
  getSelectedDriverAge,
  getPollPriceQuoteCallState,
  getCarPricingLineItems,
  getCarRewardsAndTotalLineItems,
  getCarType,
  getCarTripTotalInPrices,
} from "../../reducer";
import { getCarShopSelectedVehicle } from "../../../shop/reducer";

const mapStateToProps = (state: IStoreState) => {
  return {
    carType: getCarType(state),
    pollPriceQuoteCallState: getPollPriceQuoteCallState(state),
    pricingLineItems: getCarPricingLineItems(state),
    rewardsAndTotalLineItems: getCarRewardsAndTotalLineItems(state),
    tripTotal: getCarTripTotalInPrices(state),
    selectedVehicle: getCarShopSelectedVehicle(state),
    driverAge: getSelectedDriverAge(state),
  };
};

const mapDispatchToProps = {};

const connector = connect(mapStateToProps, mapDispatchToProps);

export type PriceBreakdownDropdownConnectorProps = ConnectedProps<
  typeof connector
>;

export const ConnectedPriceBreakdownDropdown = connector(
  PriceBreakdownDropdown
);
