import axios from "axios";
import { config } from "../../../config";

import { carBookApiPrefix } from "../../paths";
import { PaymentFinalizeResult, PaymentSessionToken } from "redmond";

const END_POINT: string = `${carBookApiPrefix}/purchase/pollFinalized`;

export const pollFinalized = (
  req: PaymentSessionToken
): Promise<PaymentFinalizeResult> =>
  new Promise(async (resolve, reject) => {
    try {
      const res = await axios.post(END_POINT, req, {
        baseURL: config.baseURL,
      });

      const responseBody = res.data;
      resolve(responseBody);
    } catch (e) {
      reject(e);
    }
  });
