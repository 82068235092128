import React from "react";
import { Box, Button, Chip } from "@material-ui/core";

import { AvailabilityFilterTagsProps, FilterKey } from "./container";
import * as textConstants from "./textConstants";
import "./styles.scss";

export interface IAvailabilityFilterTagsProps
  extends AvailabilityFilterTagsProps {}

export const AvailabilityFilterTags = (props: IAvailabilityFilterTagsProps) => {
  const appliedFilters = Object.keys(props.filters).filter(
    (key) => props.filters[key]
  );

  const handleDelete = (key: string) => {
    props.resets.reset[key]();
  };

  if (appliedFilters.length === 0) return null;

  return (
    <Box
      className={"availability-filter-tags"}
      role="region"
      aria-label="Applied Filters"
    >
      {appliedFilters.map((filter) => (
        <Box>
          <Chip
            className={"availability-filter-chip"}
            key={filter}
            label={mapFilterKeyToText[filter as FilterKey]}
            aria-label={`Remove ${mapFilterKeyToText[filter as FilterKey]}`}
            onDelete={() => handleDelete(filter)}
            color="secondary"
          />
          <span className="remove-label">Remove</span>
        </Box>
      ))}
      {appliedFilters.length > 0 && (
        <Button onClick={() => props.resets.resetAll()}>
          {textConstants.RESET_TEXT}
        </Button>
      )}
    </Box>
  );
};

const mapFilterKeyToText: { [key in FilterKey]: string } = {
  CarType: textConstants.CAR_TYPE_TEXT,
  PriceRange: textConstants.PRICE_PER_DAY_TEXT,
  CarCompany: textConstants.RENTAL_CAR_COMPANY_TEXT,
  Specification: textConstants.SPECIFICATIONS_TEXT,
  Cancellation: textConstants.CANCELLATION_POLICY_TEXT,
  Passengers: textConstants.PASSENGERS,
  PickUpType: textConstants.PICKUP_TYPE_TEXT,
  Bags: textConstants.BAGS_TEXT,
  IsInPolicy: textConstants.IN_POLICY_TEXT,
};
