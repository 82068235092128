export const FETCH_DROP_OFF_LOCATION_RESULTS =
  "carSearch/FETCH_DROP_OFF_LOCATION_RESULTS";
export type FETCH_DROP_OFF_LOCATION_RESULTS =
  typeof FETCH_DROP_OFF_LOCATION_RESULTS;

export const FETCH_PICK_UP_LOCATION_RESULTS =
  "carSearch/FETCH_PICK_UP_LOCATION_RESULTS";
export type FETCH_PICK_UP_LOCATION_RESULTS =
  typeof FETCH_PICK_UP_LOCATION_RESULTS;

export const SET_DROP_OFF_LOCATION = "carSearch/SET_DROP_OFF_LOCATION";
export type SET_DROP_OFF_LOCATION = typeof SET_DROP_OFF_LOCATION;

export const SET_DROP_OFF_LOCATION_RESULTS =
  "carSearch/SET_DROP_OFF_LOCATION_RESULTS";
export type SET_DROP_OFF_LOCATION_RESULTS =
  typeof SET_DROP_OFF_LOCATION_RESULTS;

export const SET_PICK_UP_LOCATION = "carSearch/SET_PICK_UP_LOCATION";
export type SET_PICK_UP_LOCATION = typeof SET_PICK_UP_LOCATION;

export const SET_PICK_UP_LOCATION_RESULTS =
  "carSearch/SET_PICK_UP_LOCATION_RESULTS";
export type SET_PICK_UP_LOCATION_RESULTS = typeof SET_PICK_UP_LOCATION_RESULTS;

export const SET_PICK_UP_DATE = "carSearch/SET_PICK_UP_DATE";
export type SET_PICK_UP_DATE = typeof SET_PICK_UP_DATE;

export const SET_DROP_OFF_DATE = "carSearch/SET_DROP_OFF_DATE";
export type SET_DROP_OFF_DATE = typeof SET_DROP_OFF_DATE;

export const SET_PICK_UP_TIME = "carSearch/SET_PICK_UP_TIME";
export type SET_PICK_UP_TIME = typeof SET_PICK_UP_TIME;

export const SET_DROP_OFF_TIME = "carSearch/SET_DROP_OFF_TIME";
export type SET_DROP_OFF_TIME = typeof SET_DROP_OFF_TIME;

export const SET_DRIVER_AGE = "carSearch/SET_DRIVER_AGE";
export type SET_DRIVER_AGE = typeof SET_DRIVER_AGE;

export const SET_OPEN_DATES_MODAL = "carSearch/SET_OPEN_DATES_MODAL";
export type SET_OPEN_DATES_MODAL = typeof SET_OPEN_DATES_MODAL;

export const SET_MOBILE_SEARCH_PROGRESS =
  "carSearch/SET_MOBILE_SEARCH_PROGRESS";
export type SET_MOBILE_SEARCH_PROGRESS = typeof SET_MOBILE_SEARCH_PROGRESS;

export const SET_TRIP_CATEGORY = "carSearch/SET_TRIP_CATEGORY";
export type SET_TRIP_CATEGORY = typeof SET_TRIP_CATEGORY;

export const SET_PICK_UP_AUTOCOMPLETE_ERROR =
  "carSearch/SET_PICK_UP_AUTOCOMPLETE_ERROR";
export type SET_PICK_UP_AUTOCOMPLETE_ERROR =
  typeof SET_PICK_UP_AUTOCOMPLETE_ERROR;

export const SET_DROP_OFF_AUTOCOMPLETE_ERROR =
  "carSearch/SET_DROP_OFF_AUTOCOMPLETE_ERROR";
export type SET_DROP_OFF_AUTOCOMPLETE_ERROR =
  typeof SET_DROP_OFF_AUTOCOMPLETE_ERROR;
