import React, { useRef, useState } from "react";
import {
  Box,
  Switch,
  TextField,
  Typography,
  withStyles,
} from "@material-ui/core";
import {
  MobileAutocompleteOptions,
  removeStyleSheetByMetaName,
  Icon,
  IconName,
  InfoIcon,
  MobilePopoverCard,
  CloseButtonIcon,
  ActionLink,
  ActionButton,
  RecentlySearchedList,
} from "halifax";
import {
  CarsAutocompleteResult,
  CarsTripCategory,
  RecentCarSearch,
} from "redmond";
import clsx from "clsx";

import { MobileLocationSearchModalConnectorProps } from "./container";
import * as textConstants from "./textConstants";
import { colors } from "../../../../../../utils/colors";
import "./styles.scss";
import { PATH_HOME } from "../../../../../../utils/paths";
import H from "history";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimesCircle } from "@fortawesome/free-solid-svg-icons";

export interface IMobileLocationSearchModalProps
  extends MobileLocationSearchModalConnectorProps {
  onContinue?: () => void;
  history: H.History;
  openModal: boolean;
  setOpenModal: (val: boolean) => void;
  focusDropOff?: boolean;
  searchOnContinue?: boolean;
  setLocalPickUpLocation?: (arg: CarsAutocompleteResult | null) => void;
  setLocalDropOffLocation?: (arg: CarsAutocompleteResult | null) => void;
  headerText?: string;
  recentSearches?: RecentCarSearch[];
  onRecentSearchClick?: (search: RecentCarSearch) => void;
  onSelectLocation?: (value: any) => void;
}

// TODO: refactor DropOffOptionSwitch so that it's stored in its own .tsx file;
const StyledSwitch = withStyles({
  switchBase: {
    // PrivateSwitchBase - default padding size
    padding: "9px",
    "&$checked + $track": {
      backgroundColor: colors["blue-9"],
    },
  },
  track: {
    borderRadius: "16px",
    opacity: 1,
    backgroundColor: colors["grey-8"],
  },
  thumb: {},
})(Switch);

export const MobileLocationSearchModal = (
  props: IMobileLocationSearchModalProps
) => {
  const {
    pickUpLocation,
    dropOffLocation,
    loading,
    tripCategory,
    pickUpLocationResults,
    dropOffLocationResults,
    fetchDropOffLocationResults,
    fetchPickUpLocationResults,
    setDropOffLocation,
    setPickUpLocation,
    setTripCategory,
    hasPickUpAutocompleteError,
    hasDropOffAutocompleteError,
    setPickUpAutocompleteError,
    setDropOffAutocompleteError,
    history,
    openModal,
    setOpenModal,
    focusDropOff,
    searchOnContinue = false,
    onContinue,
    setLocalPickUpLocation,
    setLocalDropOffLocation,
    headerText,
    recentSearches,
    onRecentSearchClick,
    onSelectLocation,
  } = props;
  const [pickUpFocused, setPickUpFocused] = useState<boolean>(false);
  const [pickUpSearchString, setPickUpSearchString] = useState<string>("");
  const [dropOffSearchString, setDropOffSearchString] = useState<string>("");
  const [dropOffFocused, setDropOffFocused] = useState<boolean>(false);

  // note on searchOnContinue prop: if searchOnContinue is true, the redux state should not be set, only set locally with setLocalPickUpLocation/setLocalDropOffLocation props.
  // this will allow users to open edit location modal (from availability) and close out of it without doing a new search if they change their mind after updating/clearing the locations.
  // the redux states will update when user clicks continue button since it will trigger a new search with the new locations.
  const onOriginSearch = (
    event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>
  ) => {
    hasPickUpAutocompleteError && setPickUpAutocompleteError(false);
    !searchOnContinue && setPickUpLocation(null);
    setPickUpSearchString(event.target.value);
    fetchPickUpLocationResults(event.target.value);
  };

  const onDestinationSearch = (
    event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>
  ) => {
    hasDropOffAutocompleteError && setDropOffAutocompleteError(false);
    !searchOnContinue && setDropOffLocation(null);
    setDropOffSearchString(event.target.value);
    fetchDropOffLocationResults(event.target.value);
  };

  const onPickUpSelected = (value: any) => {
    setPickUpFocused(false);
    setPickUpSearchString(value.label);
    !searchOnContinue && setPickUpLocation(value);
    setLocalPickUpLocation && setLocalPickUpLocation(value);
    onSelectLocation?.(value);
    if (tripCategory === CarsTripCategory.SAME_AS_DROP_OFF) {
      setDropOffSearchString(value.label);
      setDropOffLocation(value);
    }
    if (dropOffLocation || tripCategory === CarsTripCategory.SAME_AS_DROP_OFF) {
      !searchOnContinue && handleClose();
    }
  };

  const element = useRef<HTMLInputElement | null>(null);

  const onDropOffSelected = (value: any) => {
    setDropOffFocused(false);
    setDropOffSearchString(value.label);
    !searchOnContinue && setDropOffLocation(value);
    setLocalDropOffLocation && setLocalDropOffLocation(value);
    onSelectLocation?.(value);
    if (pickUpLocation) {
      !searchOnContinue && handleClose();
    }
    if (!pickUpLocation) {
      setPickUpFocused(true);
      if (element.current) {
        element.current.focus();
      }
    }
  };

  const handleTripCategoryChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    if (event.target.checked) {
      // SAME_AS_DROP_OFF
      setTripCategory(CarsTripCategory.SAME_AS_DROP_OFF);
      if (pickUpLocation) {
        setDropOffLocation(pickUpLocation);
      }
    } else {
      // DIFFERENT_DROP_OFF
      setTripCategory(CarsTripCategory.DIFFERENT_DROP_OFF);
    }
  };

  React.useEffect(() => {
    removeStyleSheetByMetaName("PrivateSwitchBase");
  }, []);
  React.useEffect(() => {
    pickUpLocation && setPickUpSearchString(pickUpLocation?.label);
  }, [pickUpLocation]);
  React.useEffect(() => {
    dropOffLocation && setDropOffSearchString(dropOffLocation?.label);
  }, [dropOffLocation]);

  const handleClose = () => {
    if (searchOnContinue) {
      if (pickUpLocation) {
        setPickUpSearchString(pickUpLocation?.label);
        setLocalPickUpLocation && setLocalPickUpLocation(pickUpLocation);
      }
      if (dropOffLocation) {
        setDropOffSearchString(dropOffLocation?.label);
        setLocalDropOffLocation && setLocalDropOffLocation(dropOffLocation);
      }
    }
    setOpenModal(false);
  };

  const onClearPickUpField = () => {
    searchOnContinue
      ? setLocalPickUpLocation && setLocalPickUpLocation(null)
      : setPickUpLocation(null);
    setPickUpSearchString("");
    fetchPickUpLocationResults("");
  };

  const onClearDropOffField = () => {
    searchOnContinue
      ? setLocalDropOffLocation && setLocalDropOffLocation(null)
      : setDropOffLocation(null);
    setDropOffSearchString("");
    fetchDropOffLocationResults("");
  };

  const MobileSearchPopoverHeader = () => {
    return (
      <Box className="modal-header-container">
        <Typography className="header-title">
          {headerText || textConstants.RENTAL_CARS}
        </Typography>
        <ActionLink
          className="modal-close-button"
          onClick={handleClose}
          content={<CloseButtonIcon className="close-button-icon" />}
          label="Close"
          showTappableArea
        />
      </Box>
    );
  };

  const handleContinue = () => {
    onContinue && onContinue();
    handleClose();
  };

  return (
    <MobilePopoverCard
      open={openModal}
      className={"mobile-cars-search-location-popup"}
      onClose={handleClose}
      headerElement={<MobileSearchPopoverHeader />}
    >
      <Box className="mobile-cars-search-location-modal-content">
        <Box className="mobile-cars-search-input-and-toggle-container">
          <Box className="mobile-cars-search-location-input-field pickup">
            <Icon name={IconName.B2BMapPin} />
            <TextField
              className={clsx("location-auto-complete", "mobile")}
              onChange={(value) => onOriginSearch(value)}
              value={pickUpSearchString}
              label={textConstants.PICK_UP_LOCATION}
              InputProps={{
                disableUnderline: true,
              }}
              autoFocus={focusDropOff ? false : true}
              onFocus={() => {
                setPickUpFocused(true);
                setDropOffFocused(false);
              }}
            />
            {/* Note: Instead of conditionally rendering this icon component, a className of 'hidden' is conditionally used so it can take the style "visibility:hidden"
            This style will allocate space for the icon even when it's not shown - this prevents the sibling elements from changing when it's shown or hidden */}
            <FontAwesomeIcon
              className={clsx("icon", { hidden: !pickUpSearchString })}
              icon={faTimesCircle}
              onClick={onClearPickUpField}
            />
          </Box>
          {tripCategory === CarsTripCategory.DIFFERENT_DROP_OFF ? (
            <Box className="mobile-cars-search-location-input-field dropoff">
              <Icon name={IconName.B2BMapPin} />
              <TextField
                className={clsx("location-auto-complete", "mobile")}
                label={textConstants.DROP_OFF_LOCATION}
                InputProps={{
                  disableUnderline: true,
                }}
                onFocus={() => {
                  setDropOffFocused(true);
                  setPickUpFocused(false);
                }}
                onChange={(value) => onDestinationSearch(value)}
                value={dropOffSearchString}
                autoFocus={focusDropOff}
              />
              <FontAwesomeIcon
                className={clsx("icon", { hidden: !dropOffSearchString })}
                icon={faTimesCircle}
                onClick={onClearDropOffField}
              />
            </Box>
          ) : null}
          <Box className="trip-category-switch-container">
            <Typography className="trip-category-label">
              {textConstants.TRIP_CATEGORY_LABEL}
            </Typography>
            <StyledSwitch
              checked={tripCategory === CarsTripCategory.SAME_AS_DROP_OFF}
              onChange={handleTripCategoryChange}
              focusVisibleClassName={"trip-category-switch-focus-visible"}
              disableRipple
              className={"cars-trip-category-switch"}
              classes={{
                thumb: "trip-category-switch-thumb",
                track:
                  tripCategory === CarsTripCategory.SAME_AS_DROP_OFF
                    ? "trip-category-switch-track checked"
                    : "trip-category-switch-track",
                switchBase: "trip-category-switch-base",
              }}
            />
          </Box>
          {(hasPickUpAutocompleteError || hasDropOffAutocompleteError) && (
            <Box className="autocomplete-error-container">
              <InfoIcon />
              <span className="autocomplete-error-text">
                Error fetching results.{" "}
              </span>
              <span
                onClick={() => {
                  history.push(PATH_HOME);
                  hasPickUpAutocompleteError &&
                    setPickUpAutocompleteError(false);
                  hasDropOffAutocompleteError &&
                    setDropOffAutocompleteError(false);
                }}
                className="try-again-text"
              >
                Try again.
              </span>
            </Box>
          )}
        </Box>

        {pickUpFocused && (
          <MobileAutocompleteOptions
            value={null}
            valueCategories={pickUpLocationResults}
            setValue={(value: any) => onPickUpSelected(value)}
            loading={loading}
            loadingText={"Loading"}
            hideSidePadding
          />
        )}
        {!loading &&
          pickUpSearchString.length === 0 &&
          (pickUpLocationResults.length === 0 ||
            pickUpLocationResults[0]?.results.length === 0) &&
          !!recentSearches &&
          recentSearches.length > 0 &&
          !!onRecentSearchClick && (
            <div
              className={clsx("mobile-recently-searched-cars-container", {
                "has-results": pickUpLocationResults.length > 0,
              })}
            >
              <RecentlySearchedList
                recentSearches={recentSearches}
                onRecentSearchClick={(recentSearch) =>
                  onRecentSearchClick(recentSearch as RecentCarSearch)
                }
              />
            </div>
          )}

        {tripCategory === CarsTripCategory.DIFFERENT_DROP_OFF &&
          dropOffFocused && (
            <MobileAutocompleteOptions
              value={null}
              valueCategories={dropOffLocationResults}
              setValue={(value: any) => onDropOffSelected(value)}
              loading={loading}
              loadingText={"Loading"}
              hideSidePadding
            />
          )}
        {searchOnContinue && onContinue && (
          <ActionButton
            className={clsx("mobile-autocomplete-continue-button", "b2b")}
            message={textConstants.CONTINUE}
            onClick={handleContinue}
          />
        )}
      </Box>
    </MobilePopoverCard>
  );
};
