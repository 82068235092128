import { TravelWalletCredit, TravelWalletOffer } from "redmond";
import { CurrencyFormatters } from "halifax";

export const getTravelOfferBannerText = (
  offer: TravelWalletOffer,
  bannerType: "availability" | "shop"
) => {
  switch (bannerType) {
    case "availability":
      return offer.availabilityPageBanner;
    case "shop":
      return offer.shopPageBanner;
    default:
      return undefined;
  }
};

export const getTravelCreditBannerText = (credit: TravelWalletCredit) => {
  return `You have <strong>${CurrencyFormatters.add(
    credit.amount?.currency,
    undefined,
    "en-US"
  ).format(
    Math.abs(credit.amount?.amount || 0)
  )}</strong> in travel credits available. Credits applied at checkout.`;
};

export const SEARCH_CARS_CTA_COPY = "Search Cars";

export const VIEW_OFFER_DETAILS_CTA_COPY = "View Offer Details";

export const VIEW_DETAILS_CTA_COPY = "View details";

export const CREDITS_MODAL_HEADER_TEXT = (name?: string) =>
  `${name ? `${name}'s ` : ""}<strong>travel credit</strong> summary`;
