import { put, call, select } from "redux-saga/effects";
import { actions } from "../../actions";
import { schedulePriceQuote } from "../../../../api/v1/book/book-flow/schedulePriceQuote";
import {
  ConnectionResultEnum,
  InvalidEnum,
  ConnectionResult,
  Valid,
  PaymentSessionToken,
} from "redmond";
import { IStoreState } from "../../../../reducers/types";
import {
  getSession,
  getUseV1PurchaseFlow,
  priceQuoteParamsSelector,
} from "../../reducer";
import { ISchedulePriceQuote } from "../../actions/actions";
import { closeSession } from "../../../../api/v1/book/session/closeSession";
import { schedulePriceQuoteV1 } from "../../../../api/v2/book/book-flow/schedulePriceQuote";
import { closeSessionV1 } from "../../../../api/v2/book/session/closeSession";

// Wrapper function that first opens session, and then calls schedule price quote.
export function* schedulePriceQuoteSaga({
  agentFee,
  pollQuoteOnly,
}: ISchedulePriceQuote) {
  yield call(callSchedulePriceQuote, agentFee, pollQuoteOnly);
}

function* callSchedulePriceQuote(agentFee?: number, pollQuoteOnly?: boolean) {
  try {
    const state: IStoreState = yield select();
    const session = getSession(state);
    const version = getUseV1PurchaseFlow(state) ? "v1" : "v0";

    if (session) {
      if (version === "v1") {
        yield closeSessionV1({ token: session });
      } else {
        yield closeSession(session);
      }
      yield put(actions.clearSession());
    }

    const params = priceQuoteParamsSelector(state);
    if (!params) {
      throw new Error("Invalid Request.");
    }

    if (version === "v0") {
      const result: ConnectionResult = yield schedulePriceQuote(params);

      if (result) {
        if ("Invalid" in result) {
          yield put(actions.setSchedulePriceQuoteCallStateFailed(result));
        } else {
          yield put(actions.setSchedulePriceQuoteCallStateSuccess());
          yield put(actions.setSession((result as Valid).token));
          yield put(actions.pollPriceQuote(agentFee, pollQuoteOnly));
        }
      } else {
        yield put(
          actions.setSchedulePriceQuoteCallStateFailed({
            Invalid: InvalidEnum.Missing,
            ConnectionResult: ConnectionResultEnum.Invalid,
          })
        );
      }
    } else {
      const result: String = yield schedulePriceQuoteV1({
        req: params.groundQuoteRequest,
      });
      yield put(actions.setSchedulePriceQuoteCallStateSuccess());
      yield put(actions.setSession({ value: result } as PaymentSessionToken));
      yield put(actions.pollPriceQuote(agentFee, pollQuoteOnly));
    }
  } catch (e) {
    yield put(
      actions.setSchedulePriceQuoteCallStateFailed({
        Invalid: InvalidEnum.Missing,
        ConnectionResult: ConnectionResultEnum.Invalid,
      })
    );
  }
}
