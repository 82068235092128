import { connect, ConnectedProps } from "react-redux";
import { withRouter } from "react-router";

import { AvailabilityFilters } from "./component";
import {
  getCarAvailabilityCarTypeFilter,
  getNonEmptyCarAvailabilityPriceRangeFilter,
  getCarAvailabilityMinMaxPriceRange,
  getCurrencySymbol,
  getCarAvailabilityCarCompaniesFilter,
  getCarAvailabilityCarCompaniesList,
  getCarAvailabilitySpecificationsFilter,
  getCarAvailabilityCancellationPolicyFilter,
  getAppliedFiltersCount,
  getCarAvailabilityMaxTotalPrice,
  getCarAvailabilityTotalMinMaxPriceRange,
  getCarAvailabilityPassengersFilter,
  getCarAvailabilityPickUpFilter,
  getCarAvailabilityBagsFilter,
  getCarAvailabilityPolicyFilter,
} from "../../reducer";
import {
  setCarTypeFilter,
  setPriceRangeFilter,
  setCarCompaniesFilter,
  setSpecificationsFilter,
  setCancellationPolicyFilter,
  setMaxTotalPriceFilter,
  setPassengersFilter,
  setPickupFilter,
  setBagsFilter,
  setCarPolicyFilter,
} from "../../actions/actions";
import { IStoreState } from "../../../../reducers/types";

const mapStateToProps = (state: IStoreState) => {
  return {
    carTypes: getCarAvailabilityCarTypeFilter(state),
    priceRange: getNonEmptyCarAvailabilityPriceRangeFilter(state),
    minMaxPriceRange: getCarAvailabilityMinMaxPriceRange(state),
    currencySymbol: getCurrencySymbol(state),
    carCompanies: getCarAvailabilityCarCompaniesFilter(state),
    carCompaniesList: getCarAvailabilityCarCompaniesList(state),
    specifications: getCarAvailabilitySpecificationsFilter(state),
    cancellation: getCarAvailabilityCancellationPolicyFilter(state),
    appliedFiltersCount: getAppliedFiltersCount(state),
    maxTotalPrice: getCarAvailabilityMaxTotalPrice(state),
    totalMinMaxPriceRange: getCarAvailabilityTotalMinMaxPriceRange(state),
    passengers: getCarAvailabilityPassengersFilter(state),
    pickUps: getCarAvailabilityPickUpFilter(state),
    bags: getCarAvailabilityBagsFilter(state),
    isInPolicy: getCarAvailabilityPolicyFilter(state),
  };
};

const mapDispatchToProps = {
  setCarTypeFilter,
  setPriceRangeFilter,
  setCarCompaniesFilter,
  setSpecificationsFilter,
  setCancellationPolicyFilter,
  setMaxTotalPriceFilter,
  setPassengersFilter,
  setPickupFilter,
  setBagsFilter,
  setCarPolicyFilter,
};

const connector = connect(mapStateToProps, mapDispatchToProps);

export type AvailabilityFiltersConnectorProps = ConnectedProps<
  typeof connector
>;

export const ConnectedAvailabilityFilters = withRouter(
  connector(AvailabilityFilters)
);
