import { connect, ConnectedProps } from "react-redux";

import { setPickUpDate, setDropOffDate } from "../../actions/actions";
import {
  getPickUpDate,
  getDropOffDate,
  getTripCategory,
  getPickUpLocation,
  getDropOffLocation,
  getDriverAge,
  getDropOffTime,
  getPickUpTime,
} from "../../reducer";
import { IStoreState } from "../../../../reducers/types";
import { MobileCarSearchControlV2 } from "./component";
import { withRouter } from "react-router";

const mapDispatchToProps = {
  setPickUpDate,
  setDropOffDate,
};
const mapStateToProps = (state: IStoreState) => {
  const pickUpDate = getPickUpDate(state);
  const dropOffDate = getDropOffDate(state);
  const tripCategory = getTripCategory(state);
  const pickUpLocation = getPickUpLocation(state);
  const dropOffLocation = getDropOffLocation(state);
  const dropOffTime = getDropOffTime(state);
  const pickUpTime = getPickUpTime(state);
  const driverAge = getDriverAge(state);
  return {
    pickUpDate,
    dropOffDate,
    tripCategory,
    pickUpLocation,
    dropOffLocation,
    dropOffTime,
    pickUpTime,
    driverAge,
  };
};

const connector = connect(mapStateToProps, mapDispatchToProps);

export type MobileCarSearchControlV2ConnectorProps = ConnectedProps<
  typeof connector
>;

export const ConnectedMobileCarSearchControlV2 = withRouter(
  connector(MobileCarSearchControlV2)
);
