import React from "react";
import { CloseButtonIcon, Header, ActionLink } from "halifax";
import { Box, Typography } from "@material-ui/core";
import { RouteComponentProps } from "react-router";
import { faChevronLeft } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import clsx from "clsx";
import dayjs from "dayjs";

import { MobileCarSearchConnectorProps } from "./container";
import "./styles.scss";
import {
  LocationSearch,
  MobileCalendarPicker,
  TimeAndAgePicker,
} from "./components";
import * as textConstants from "./textConstants";
import { PATH_HOME } from "../../../../utils/paths";
import { MobileCarSearchStep } from "../../reducer";
import { RecentCarSearch } from "redmond";

export interface IMobileCarSearchControlProps
  extends MobileCarSearchConnectorProps,
    RouteComponentProps {
  recentSearches?: RecentCarSearch[];
  onRecentSearchClick?: (search: RecentCarSearch) => void;
  onSelectLocation?: (value: any) => void;
  onSelectDates?: (pickUp: Date | null, dropOff: Date | null) => void;
}

export const MobileCarSearchControl = (props: IMobileCarSearchControlProps) => {
  const {
    currentStep,
    pickUpDate,
    dropOffDate,
    setCurrentStep,
    history,
    hasPickUpAutocompleteError,
    hasDropOffAutocompleteError,
    setPickUpAutocompleteError,
    setDropOffAutocompleteError,
    recentSearches,
    onRecentSearchClick,
    onSelectLocation,
    onSelectDates,
  } = props;

  const focusedMonthIndex = pickUpDate
    ? dayjs(pickUpDate).diff(dayjs(), "month")
    : 0;

  const handleGoBack = () => {
    setCurrentStep(
      currentStep > MobileCarSearchStep.LocationSearch
        ? currentStep - 1
        : currentStep
    );
  };

  React.useEffect(() => {
    window.scrollTo(0, 0);
  }, [currentStep]);

  const resetAutocompleteErrors = () => {
    hasPickUpAutocompleteError && setPickUpAutocompleteError(false);
    hasDropOffAutocompleteError && setDropOffAutocompleteError(false);
  };
  return (
    <Box
      className={clsx({
        "date-range-picker": currentStep === MobileCarSearchStep.CalendarPicker,
      })}
    >
      <Header
        center={<HeaderCenterSection currentStep={currentStep} />}
        left={
          <ActionLink
            className={clsx("mobile-car-search-header-go-back", {
              hidden: currentStep === MobileCarSearchStep.LocationSearch,
            })}
            onClick={handleGoBack}
            content={<FontAwesomeIcon icon={faChevronLeft} />}
          />
        }
        right={
          <ActionLink
            className="mobile-car-search-header-close"
            onClick={() => {
              setCurrentStep(MobileCarSearchStep.LocationSearch);
              history.push(PATH_HOME);
              resetAutocompleteErrors();
            }}
            content={<CloseButtonIcon />}
            label="Close"
          />
        }
        isMobile={true}
        fullWidth={true}
      />
      {currentStep === MobileCarSearchStep.LocationSearch && (
        <Box className="location-search-container">
          <LocationSearch
            history={history}
            recentSearches={recentSearches}
            onRecentSearchClick={onRecentSearchClick}
            onSelectLocation={onSelectLocation}
          />
        </Box>
      )}
      {currentStep === MobileCarSearchStep.CalendarPicker && (
        <MobileCalendarPicker
          focusedMonthIndex={focusedMonthIndex}
          dropOffDate={dropOffDate}
          pickUpDate={pickUpDate}
          onSelectDates={onSelectDates}
        />
      )}
      {currentStep === MobileCarSearchStep.TimePickerAndDriverAge && (
        <TimeAndAgePicker />
      )}
    </Box>
  );
};

interface IHeaderCenterSectionProps {
  currentStep: MobileCarSearchStep;
}

const HeaderCenterSection = (props: IHeaderCenterSectionProps) => {
  const { currentStep } = props;
  return (
    <Box className="header-center-section">
      <Typography className="header-section-label">
        {currentStep === MobileCarSearchStep.LocationSearch &&
          textConstants.CAR_RENTALS}
        {currentStep === MobileCarSearchStep.CalendarPicker &&
          textConstants.CHOOSE_YOUR_DATES}
        {currentStep === MobileCarSearchStep.TimePickerAndDriverAge &&
          textConstants.SELECT_TIME_AND_DRIVER_AGE}
      </Typography>
    </Box>
  );
};
