import { connect, ConnectedProps } from "react-redux";
import { withRouter } from "react-router";
import { BookingSuccessModal } from "./component";
import {
  getConfirmationDetails,
  getContactEmail,
  getEarnedString,
  getNumDays,
} from "../../reducer";
import { IStoreState } from "../../../../reducers/types";
import { actions } from "../../actions";
import { getCarShopSelectedVehicle } from "../../../shop/reducer";

const mapStateToProps = (state: IStoreState) => ({
  confirmation: getConfirmationDetails(state),
  selectedVehicle: getCarShopSelectedVehicle(state),
  earnString: getEarnedString(state),
  days: getNumDays(state),
  email: getContactEmail(state),
});

const mapDispatchToProps = {
  resetBookState: actions.redoSearch,
};

const connector = connect(mapStateToProps, mapDispatchToProps);

export type BookingSuccessModalConnectorProps = ConnectedProps<
  typeof connector
>;

export const ConnectedBookingSuccessModal = withRouter(
  connector(BookingSuccessModal)
);
