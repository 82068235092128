export const SPLIT_PAY_APPLY_TEXT = "Apply";
export const SPLIT_PAY_EDIT_TEXT = "Edit amount";
export const SPLIT_PAY_SAVE_TEXT = "Save";
export const SPLIT_PAY_CANCEL_TEXT = "Cancel";
export const SPLIT_PAY_DO_NOT_APPLY_TEXT = "Do not apply my rewards";
export const SPLIT_PAY_REWARDS_APPLY_TEXT = (
  cardName: string,
  showColon: boolean
) => `Apply ${cardName} Rewards${showColon ? ":" : ""}`;
export const SPLIT_PAY_CUSTOM_AMOUNT_TOO_HIGH =
  "Invalid amount. Enter an amount that is lower then your available balance.";
export const SPLIT_PAY_CUSTOM_AMOUNT_GENERIC_ERROR =
  "Invalid rewards balance. Please enter a valid amount.";
export const SPLIT_PAY_SAVE_NOTIFICATION =
  "To apply this amount, Save before you continue.";
export const SPLIT_PAY_COVERS_TOTAL_COST_NOTIFICATION =
  "Your Rewards covers the total cost of your booking.";
export const ALL_ACCOUNT_DO_NOT_MEET_MINIMUM_REQUIREMENT =
  "Your rewards account does not meet the minimum redemption amount. To continue with this booking, please use your Capital One Credit Card to complete this transaction.";
export const ONE_ACCOUNT_DO_NOT_MEET_MINIMUM_REQUIREMENT =
  "One of your rewards accounts does not meet the minimum redemption amount.";

export const TRAVEL_CREDIT_OFFER_AND_REWARD_SPLIT_PAY_NOTI =
  "Your travel credits, travel offer and rewards cover the total cost of your booking.";
export const getSplitPayCoverageNotification = (
  hasOfferToApply: boolean,
  hasCreditsToApply: boolean
) => {
  if (hasOfferToApply && hasCreditsToApply) {
    return "Your travel credits, travel offer and rewards cover the total cost of your booking.";
  } else if (hasOfferToApply) {
    return "Your travel offer and rewards cover the total cost of your booking.";
  } else if (hasCreditsToApply) {
    return "Your travel credits and rewards cover the total cost of your booking.";
  } else {
    return SPLIT_PAY_COVERS_TOTAL_COST_NOTIFICATION;
  }
};
