/**
 * Util to Calculate help calculate the soonest a car can be dropped off same day, 1 hour min
 * @param pickUpDate
 * @param dropOffDate
 * @param pickUpTime
 */
export const calcMinimumDropOffTime = (
  pickUpDate: Date,
  dropOffDate: Date,
  pickUpTime: string
) => {
  if (pickUpDate.getTime() === dropOffDate.getTime()) {
    const [h, m, a] = pickUpTime.split(/:|\s/);
    let hour = Number(h) + 1;
    let min = m;
    let meridiem = a;

    if (hour > 11 && a === "PM") {
      if (hour > 12) {
        hour = hour - 12;
      } else {
        // MAX time for same day
        hour = 11;
        min = "30";
      }
    } else if (hour > 11 && a === "AM") {
      meridiem = hour > 12 ? "AM" : "PM";
      hour = hour === 12 ? 12 : 1;
    }
    return `${hour}:${min} ${meridiem}`;
  }
  return;
};
