import axios from "axios";
import { userUpdateDefaultTravelerPath } from "../../paths";
import { config } from "../../config";

export const updateUserDefaultTraveler = (
  body: IUserDefaultTravelerPayload
): Promise<IUserDefaultTravelerResponse> =>
  new Promise(async (resolve, reject) => {
    try {
      const res = await axios.put(userUpdateDefaultTravelerPath, body, {
        baseURL: config.baseURL,
      });

      const responseBody: IUserDefaultTravelerResponse = res.data;
      resolve(responseBody);
    } catch (e) {
      reject(e);
    }
  });

export interface IUserDefaultTravelerPayload {
  travelerId: string;
  userId?: string;
}

export interface IUserDefaultTravelerResponse {}

export default updateUserDefaultTraveler;
