import axios from "axios";
import { config } from "../../../config";

import { WalletOffersResponse, OffersForProductsRequest } from "redmond";
import { travelWalletApiPrefix } from "../../paths";

const END_POINT: string = `${travelWalletApiPrefix}/offers`;

export const fetchTravelWalletOffers = (
  body: OffersForProductsRequest
): Promise<WalletOffersResponse> =>
  new Promise(async (resolve, reject) => {
    try {
      const res = await axios.put(END_POINT, body, {
        baseURL: config.baseURL,
      });
      resolve(res.data);
    } catch (e) {
      reject(e);
    }
  });
