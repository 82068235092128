import React from "react";

export const FETCHING_AVAILABILITY_TEXT = "for cars";
export const FETCHING_AVAILABILITY_SECONDARY_TEXT =
  "If you find a better price on another site within 24 hours of booking, we'll refund you the difference.";

export const EARN_ENHANCEMENT_SUBTITLE = (
  earnRate: string | number,
  productDisplayName: string,
  currency: string
) => {
  const multiplier = currency.includes("cash") ? "%" : "X";
  return (
    <>
      <span className="font-bold">
        Earn {earnRate}
        {multiplier} {currency}
      </span>{" "}
      on cars with your {productDisplayName} account.
    </>
  );
};

export const PARADISE_SUBTITLE =
  "Book with flexibility and start your next journey here.";
