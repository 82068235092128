import { put } from "redux-saga/effects";
import { actions } from "../../actions";
import { fetchPaymentMethod } from "../../../../api/v1/book/payment-methods/fetchPaymentMethod";
import { GetPaymentMethodSuccess, GetPaymentMethodResponse } from "redmond";

export function* fetchPaymentMethodSaga({
  paymentId,
}: actions.IFetchPaymentMethod) {
  try {
    const response: GetPaymentMethodResponse = yield fetchPaymentMethod({
      paymentId,
    });

    if ((response as GetPaymentMethodSuccess).value) {
      yield put(
        actions.setPaymentMethod({
          paymentMethod: (response as GetPaymentMethodSuccess).value,
        })
      );
    } else {
      yield put(actions.setPaymentMethodCallStateFailed());
    }
  } catch (e) {
    yield put(actions.setPaymentMethodCallStateFailed());
  }
}
