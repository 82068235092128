export const apiVersionPrefix: string = "/api/v0";

export const locationAutocompleteApiPrefix: string = `${apiVersionPrefix}/autocomplete/ground`;
export const carsAvailabilityApiPrefix: string = `${apiVersionPrefix}/ground/availability`;
export const rewardsApiPrefix: string = `${apiVersionPrefix}/rewards`;
export const carExtraInfo: string = `${apiVersionPrefix}/ground/shop`;
export const fetchUserInfoPath: string = `${apiVersionPrefix}/userInfo`;
export const customerDetailsApiPath: string = `${apiVersionPrefix}/customer/details`;
export const passengersApiPrefix: string = `${apiVersionPrefix}/passengers`;
export const paymentMethodsApiPrefix: string = `${apiVersionPrefix}/paymentMethods`;
export const carBookApiPrefix: string = `${apiVersionPrefix}/payment`;
export const analyticsApiPrefix: string = `${apiVersionPrefix}/tracking`;

export const analyticsEventApi: string = `${analyticsApiPrefix}/event`;
export const experimentsApiPrefix: string = `${apiVersionPrefix}/experiments`;

export const userApiPrefix: string = `${apiVersionPrefix}/user`;
export const travelWalletApiPrefix: string = `${userApiPrefix}/wallet`;

// Corporate Travel
export const pendingRequestApiPrefix = `${apiVersionPrefix}/pendingRequests/ground`;
export const fetchPolicies = `${apiVersionPrefix}/policies/details`;

// Engagement data stream
export const engagementDataApiPrefix: string = `${apiVersionPrefix}/engagement`;
export const trackEngagementDataPath: string = `${engagementDataApiPrefix}/event`;
