import { connect, ConnectedProps } from "react-redux";
import { DesktopCalendarPicker } from "./component";
import { IStoreState } from "../../../../../../reducers/types";
import { getDropOffDate, getPickUpDate } from "../../../../reducer";
import { actions } from "../../../../actions";

const mapDispatchToProps = {
  setFromDate: actions.setPickUpDate,
  setUntilDate: actions.setDropOffDate,
  setOpenDatesModal: actions.setOpenDatesModal,
};

const mapStateToProps = (state: IStoreState) => {
  return {
    untilDate: getDropOffDate(state),
    fromDate: getPickUpDate(state),
  };
};

export const connector = connect(mapStateToProps, mapDispatchToProps);

export type ConnectedDesktopCalendarPickerProps = ConnectedProps<
  typeof connector
>;

export const ConnectedDesktopCalendarPicker = connector(DesktopCalendarPicker);
