import { connect, ConnectedProps } from "react-redux";
import { PickerType } from "halifax";

import { MonthAndDatePicker } from "./component";

const mapStateToProps = () => {
  return {
    pickerType: PickerType.RANGE,
  };
};

const mapDispatchToProps = {};

const connector = connect(mapStateToProps, mapDispatchToProps);

export type MonthAndDatePickerConnectorProps = ConnectedProps<typeof connector>;

export const ConnectedMonthAndDatePicker = connector(MonthAndDatePicker);
