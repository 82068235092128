import { connect, ConnectedProps } from "react-redux";
import { IStoreState } from "../../../../../../reducers/types";
import { getDriverAge } from "../../../../reducer";
import { setDriverAge } from "../../../../actions/actions";
import { DriverAgeInput } from "./component";

export const mapStateToProps = (state: IStoreState) => ({
  driverAge: getDriverAge(state),
});

export const mapDispatchToProps = {
  setDriverAge,
};

const connector = connect(mapStateToProps, mapDispatchToProps);

export type DriverAgeInputConnectorProps = ConnectedProps<typeof connector>;

export const ConnectedDriverAgeInput = connect(
  mapStateToProps,
  mapDispatchToProps
)(DriverAgeInput);
