import { connect, ConnectedProps } from "react-redux";
import { withRouter } from "react-router";
import { BookingErrorModal } from "./component";
import {
  getErrorTitles,
  getHasError,
  getIsBookingValid,
  getModalType,
  getPollPriceQuoteCallState,
  getSelectedPaymentMethodId,
  getSession,
  getUserSelectedTravelerId,
} from "../../reducer";
import { IStoreState } from "../../../../reducers/types";
import {
  redoSearch,
  scheduleBook,
  resetBookErrors,
  acknowledgePriceDifference,
} from "../../actions/actions";
import { getCarShopSelectedVehicle } from "../../../shop/reducer/selectors";
import { getCarShopQueryParams } from "../../../availability/reducer";
import { CallState } from "redmond";

const mapStateToProps = (state: IStoreState) => ({
  hasError: getHasError(state),
  errorTitles: getErrorTitles(state),
  paymentMethodId: getSelectedPaymentMethodId(state),
  currentSession: getSession(state),
  selectedTravelerId: getUserSelectedTravelerId(state),
  selectedVehicle: getCarShopSelectedVehicle(state),
  isBookingValid: getIsBookingValid(state),
  priceQuoteSuccessful: getPollPriceQuoteCallState(state) === CallState.Success,
  carQueryParams: getCarShopQueryParams(state),
  modalType: getModalType(state),
});

const mapDispatchToProps = {
  redoSearch,
  scheduleBook,
  acknowledgePriceDifference,
  resetBookErrors,
};

const connector = connect(mapStateToProps, mapDispatchToProps);

export type BookingErrorModalConnectorProps = ConnectedProps<typeof connector>;

export const ConnectedBookingErrorModal = withRouter(
  connector(BookingErrorModal)
);
