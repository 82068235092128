export const PICK_UP_LOCATION = "Pick-up location";
export const DROP_OFF_LOCATION = "Drop-off location";
export const CHOOSE_DATES = "Choose Dates";
export const SEARCH_CARS = "Search Cars";
export const PICK_UP = "Pick-up";
export const DROP_OFF = "Drop-off";
export const PICK_UP_TIME = "Pick-up time";
export const DROP_OFF_TIME = "Drop-off time";
export const MISSING_INFO_SEARCH_ERROR =
  "To continue, please complete all required fields.";
