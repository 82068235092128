import { connect, ConnectedProps } from "react-redux";
import { IStoreState } from "../../../../../../reducers/types";
import { actions } from "../../../../actions";
import {
  getDropOffLocation,
  getPickUpLocation,
  getDropOffLocationResultsLoading,
  getPickUpLocationResultsLoading,
  getPickUpLocationResults,
  getDropOffLocationResults,
  getTripCategory,
  getHasPickUpAutocompleteError,
  getHasDropOffAutocompleteError,
} from "../../../../reducer";
import { MobileLocationSearchModal } from "./component";

const mapDispatchToProps = {
  setTripCategory: actions.setTripCategory,
  setMobileCarSearchStep: actions.setMobileCarSearchStep,
  setPickUpLocation: actions.setPickUpLocation,
  setDropOffLocation: actions.setDropOffLocation,
  fetchPickUpLocationResults: actions.fetchPickUpLocationResults,
  fetchDropOffLocationResults: actions.fetchDropOffLocationResults,
  setPickUpAutocompleteError: actions.setPickUpAutocompleteError,
  setDropOffAutocompleteError: actions.setDropOffAutocompleteError,
};
const mapStateToProps = (state: IStoreState) => {
  const pickUpLocation = getPickUpLocation(state);
  const dropOffLocation = getDropOffLocation(state);

  return {
    pickUpLocation,
    dropOffLocation,
    tripCategory: getTripCategory(state),
    pickUpLocationResults: getPickUpLocationResults(state),
    dropOffLocationResults: getDropOffLocationResults(state),
    loading:
      !!getPickUpLocationResultsLoading(state) ||
      !!getDropOffLocationResultsLoading(state),
    hasPickUpAutocompleteError: getHasPickUpAutocompleteError(state),
    hasDropOffAutocompleteError: getHasDropOffAutocompleteError(state),
  };
};

const connector = connect(mapStateToProps, mapDispatchToProps);

export type MobileLocationSearchModalConnectorProps = ConnectedProps<
  typeof connector
>;

export const ConnectedMobileLocationSearchModal = connector(
  MobileLocationSearchModal
);
