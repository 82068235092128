import { useEffect } from "react";
import { useLocation } from "react-router-dom";
import corporateConfig from "../../utils/capone-corporate/config";
import { isCorpTenant } from "@capone/common";

// note: the implementation is taken from https://drive.google.com/file/d/1xbsZZ0rt48nJNb7BlxpwcBKX4wUB6_Qw/view?ts=612f9085
const injectCempDigital = () => {
  (window as any).activateCempDigital = (function (wdw: any) {
    var CEMP_DIGITAL_PROPERTY_URL =
      "https://resources.digital-cloud.medallia.com/wdcus/160752/onsite/embed.js";
    var CEMP_DIGITAL_FORMS_TO_PRELOAD: (string | number)[] = [];

    if (isCorpTenant(corporateConfig.TENANT)) {
      CEMP_DIGITAL_PROPERTY_URL =
        corporateConfig.cempDigitalPropertyUrl || CEMP_DIGITAL_PROPERTY_URL;
      CEMP_DIGITAL_FORMS_TO_PRELOAD =
        corporateConfig.cempDigitalFormsToPreload ||
        CEMP_DIGITAL_FORMS_TO_PRELOAD;
    }

    var CEMP_DIGITAL_STATIC_PARAMS = {
      surveyProgram: "CEMP Digital",
      appType: "Web",
      lob: "OTHER",
    };
    var CEMP_DIGITAL_DYNAMIC_PARAMS: string[] = [];

    return function () {
      return new Promise(function (resolve) {
        wdw.cempDataLayer = wdw.cempDataLayer || {};
        Object.keys(CEMP_DIGITAL_STATIC_PARAMS).forEach(function (param) {
          wdw.cempDataLayer[param] = CEMP_DIGITAL_STATIC_PARAMS[param];
        });
        CEMP_DIGITAL_DYNAMIC_PARAMS.forEach(function (param) {
          wdw.cempDataLayer[param] =
            wdw.cempDataLayer[param] === undefined
              ? null
              : wdw.cempDataLayer[param];
        });
        if (wdw.cempDigital == null) {
          const cempDigitalLoader = document.createElement("script");
          cempDigitalLoader.async = true;
          document.body.appendChild(cempDigitalLoader);
          cempDigitalLoader.src = CEMP_DIGITAL_PROPERTY_URL;
        }
        var initializeCempDigital = function () {
          wdw.cempDigital = wdw.KAMPYLE_ONSITE_SDK;
          CEMP_DIGITAL_FORMS_TO_PRELOAD.forEach(function (surveyId) {
            wdw.cempDigital.loadForm(surveyId);
          });
          var cempDigitalInitialized = document.createEvent("Event");
          cempDigitalInitialized.initEvent(
            "cempDigitalInitialized",
            true,
            true
          );
          window.dispatchEvent(cempDigitalInitialized);
          resolve(true);
        };

        wdw.addEventListener("neb_OnsiteLoaded", initializeCempDigital, false);
      });
    };
  })(window);

  var cempDigitalInjected = document.createEvent("Event");
  cempDigitalInjected.initEvent("cempDigitalInjected", true, true);
  window.dispatchEvent(cempDigitalInjected);
};

export const activateCempDigital = () => {
  var wdw = window as any;
  if (cempDigitalIsEnabled() == true) {
    initializeCempDataLayer(wdw);
    initializeCempDigital(wdw);
  }
};

export const cempDigitalIsEnabled = () => {
  return true; // Add feature flag logic here
};

export const initializeCempDataLayer = (wdw: any) => {
  wdw.cempDataLayer = wdw.cempDataLayer || {};
  wdw.cempDataLayer.country = "USA"; // Add more CEMP Data Layer assignment as appropriate
  wdw.cempDataLayer.responseUrl = window.location.href;
  // corporate travel cemp survey pulls from responseURL instead
  wdw.cempDataLayer.responseURL = window.location.href;
};

export const initializeCempDigital = (wdw: any) => {
  function startCempDigital() {
    if (
      wdw.activateCempDigital != null &&
      typeof wdw.activateCempDigital === "function"
    ) {
      wdw.activateCempDigital();
      return true;
    }
    return false;
  }
  if (startCempDigital() == false) {
    window.addEventListener("cempDigitalInjected", startCempDigital, false);
  }
};

const onUrlChange = (location: string) => {
  const wdw = window as any;
  if (typeof wdw.cempDigital?.updatePageView === "function") {
    wdw.cempDataLayer.responseUrl = location;
    // corporate travel cemp survey pulls from responseURL instead
    wdw.cempDataLayer.responseURL = location;
    wdw.cempDigital.updatePageView();
  }
};

export const useMedalliaFeedback = () => {
  useEffect(() => {
    const loadMedalliaFeedback = async () => {
      await injectCempDigital();
      activateCempDigital();
    };
    loadMedalliaFeedback();
  }, []);

  const location = useLocation();

  useEffect(() => {
    onUrlChange(location.pathname);
  }, [location]);
};
