import { takeLeading } from "redux-saga/effects";

import { actionTypes } from "../actions";
import { fetchRewardsAccountsSaga } from "./accounts";

export function* watchFetchRewardsAccounts() {
  yield takeLeading(
    actionTypes.FETCH_REWARDS_ACCOUNTS,
    fetchRewardsAccountsSaga
  );
}
