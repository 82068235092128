import { getConfigTenant } from "@capone/common";

const urlParams = new URLSearchParams(window.location.search);
const isAgentQuery = urlParams.get("agent") === "true";
const isAgentURL = window.location.href.includes("agent");
const isAgentPortal = isAgentURL || isAgentQuery;

const adminPortalPath = window.__portal_env__
  ? window.__portal_env__.ADMIN_PORTAL_PATH
  : process.env?.ADMIN_PORTAL_PATH || "";

const agentPortalUrl = window.__portal_env__
  ? window.__portal_env__.AGENT_PORTAL_URL
  : process.env?.AGENT_PORTAL_URL || "";

const agentAdminPortalUrl = agentPortalUrl + adminPortalPath;

const adminPortalUrl = window.__portal_env__
  ? window.__portal_env__.ADMIN_PORTAL_URL
  : process.env?.ADMIN_PORTAL_URL;

const adminPortalUrlWithSource = `${adminPortalUrl}?source=booking_portal`;

const config = {
  ptFlightsModuleUrl: window.__portal_env__
    ? `${window.location.origin}${window.__portal_env__.REACT_APP_MODULES_FLIGHTS_MODULE_URL}`
    : process.env?.REACT_APP_MODULES_FLIGHTS_MODULE_URL,
  ptHotelsModuleUrl: window.__portal_env__
    ? `${window.location.origin}${window.__portal_env__.REACT_APP_MODULES_HOTELS_MODULE_URL}`
    : process.env?.REACT_APP_MODULES_HOTELS_MODULE_URL,
  ptTripsModuleUrl: window.__portal_env__
    ? `${window.location.origin}${window.__portal_env__.REACT_APP_MODULES_TRIPS_MODULE_URL}`
    : process.env?.REACT_APP_MODULES_TRIPS_MODULE_URL,
  ptTermsModuleUrl: window.__portal_env__
    ? `${window.location.origin}${window.__portal_env__.REACT_APP_MODULES_TERMS_MODULE_URL}`
    : process.env?.REACT_APP_MODULES_TERMS_MODULE_URL,
  ptCarsModuleUrl: window.__portal_env__
    ? `${window.location.origin}${window.__portal_env__.REACT_APP_MODULES_CARS_MODULE_URL}`
    : process.env?.REACT_APP_MODULES_CARS_MODULE_URL,
  ptLuxuryHotelsModuleUrl: window.__portal_env__
    ? `${window.location.origin}${window.__portal_env__.REACT_APP_MODULES_LUXURY_HOTELS_MODULE_URL}`
    : process.env?.REACT_APP_MODULES_LUXURY_HOTELS_MODULE_URL,
  ptTravelWalletModuleUrl: window.__portal_env__
    ? `${window.location.origin}${window.__portal_env__.REACT_APP_MODULES_TRAVEL_WALLET_MODULE_URL}`
    : process.env?.REACT_APP_MODULES_TRAVEL_WALLET_MODULE_URL,
  googleMapsApiKey: window.__portal_env__
    ? window.__portal_env__.REACT_APP_GOOGLE_MAPS_API_KEY
    : process.env?.REACT_APP_GOOGLE_MAPS_API_KEY,
  ptAuthModuleUrl: window.__portal_env__
    ? `${window.location.origin}${
        isAgentPortal
          ? window.__portal_env__.REACT_APP_MODULES_AGENT_AUTH_MODULE_URL
          : window.__portal_env__.REACT_APP_MODULES_AUTH_MODULE_URL
      }`
    : isAgentPortal
    ? process.env?.REACT_APP_MODULES_AGENT_AUTH_MODULE_URL
    : process.env?.REACT_APP_MODULES_AUTH_MODULE_URL,
  ptIsAgentPortal: isAgentPortal,
  ENV: window.__portal_env__ ? window.__portal_env__.ENV : "",
  REACT_APP_VERSION: window.__portal_env__
    ? window.__portal_env__.REACT_APP_VERSION
    : "",
  isMaintenanceModeEnabled: window.__portal_env__
    ? window.__portal_env__.REACT_APP_MAINTENANCE_MODE_ENABLED
    : "false",
  isTimedMaintenanceModeEnabled: window.__portal_env__
    ? window.__portal_env__.REACT_APP_TIMED_MAINTENANCE_MODE_ENABLED
    : "false",
  MAINTENANCE_START_TIME: window.__portal_env__
    ?.REACT_APP_TIMED_MAINTENANCE_MODE_START_TIME
    ? parseInt(
        window.__portal_env__.REACT_APP_TIMED_MAINTENANCE_MODE_START_TIME
      )
    : undefined,
  MAINTENANCE_END_TIME: window.__portal_env__
    ?.REACT_APP_TIMED_MAINTENANCE_MODE_END_TIME
    ? parseInt(window.__portal_env__.REACT_APP_TIMED_MAINTENANCE_MODE_END_TIME)
    : undefined,
  MAINTENANCE_TITLE: window.__portal_env__
    ? window.__portal_env__.REACT_APP_MAINTENANCE_TITLE
    : "",
  MAINTENANCE_SUBTITLE: window.__portal_env__
    ? window.__portal_env__.REACT_APP_MAINTENANCE_SUBTITLE
    : "",
  CASH_CARD_MAINTENANCE_ICON: window.__portal_env__
    ? window.__portal_env__.CASH_CARD_MAINTENANCE_ICON
    : "false",
  consumerPortalUrl: window.__portal_env__
    ? window.__portal_env__.CONSUMER_PORTAL_URL
    : process.env?.CONSUMER_PORTAL_URL,
  adminPortalUrl: isAgentPortal ? agentAdminPortalUrl : adminPortalUrl,
  adminPortalUrlWithSource,
  cempDigitalPropertyUrl: window.__portal_env__
    ? window.__portal_env__.CEMP_DIGITAL_PROPERTY_URL
    : process.env?.CEMP_DIGITAL_PROPERTY_URL,
  cempDigitalFormsToPreload: window.__portal_env__
    ? window.__portal_env__.CEMP_DIGITAL_FORMS_TO_PRELOAD
    : [parseInt(process.env?.CEMP_DIGITAL_FORMS_TO_PRELOAD ?? "0")],
  TENANT: getConfigTenant(),
};

export default config;
