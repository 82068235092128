import {
  getAgentErrorSubtitle,
  getAgentErrorTitle,
  getRewardsString,
} from "halifax";
import { ErrorTitles, CarsBookErrorEnum, RewardsPrice } from "redmond";

const GENERIC_ERROR_TITLE = "Uh oh! Something went wrong";
const GENERIC_ERROR_SUBTITLE = "Please search again";

// CHECKOUT COMPLETION TEXT
export const ADD_TRAVELERS = "Add Driver";
export const DRIVER_INFORMATION = "Driver Information";
export const CONTACT = "Contact Information";
export const REWARDS_AND_PAYMENT = (canRedeemRewards: boolean) =>
  canRedeemRewards ? "Rewards & Payment" : "Payment";

// BUTTON_TEXT
export const CONTINUE = "Continue";
export const TRY_AGAIN = "Try Again";
export const SEARCH_AGAIN = "Search Again";
export const CHOOSE_ANOTHER_CAR = "Choose Another Car";
export const CHOOSE_ANOTHER_RATE = "Choose Another Rate";
export const CONFIRM_AND_BOOK = "Confirm and Book";
export const UPDATE_PAYMENT_INFO = "Update Payment";
export const EDIT_PAYMENT_INFO = "Edit Payment Information";
export const UPDATE_DRIVER = "Update Driver Details";
export const EDIT_REWARDS_NUMBER = "Edit Rewards Number";
export const EDIT_DRIVER = "Edit Driver";

export const ERROR_ICON = "error";
export const UNABLED_ICON = "unabled";
export const PRICE_DECREASE_ICON = "decreased";
export const PRICE_INCREASE_ICON = "increased";

// Loyalty program names
export const HERTZ_GOLD_PLUS = "Hertz Gold Plus Rewards®";
export const DOLLAR_EXPRESS = "Dollar Express Rewards";
export const THRIFTY_BLUE_CHIP = "Thrifty Blue Chip Rewards";

export const REWARDS_PROGRAM_NAME = (supplierRef?: string) => {
  switch (supplierRef) {
    case "ZE":
    default:
      return HERTZ_GOLD_PLUS;
    case "ZT":
      return THRIFTY_BLUE_CHIP;
    case "ZR":
      return DOLLAR_EXPRESS;
  }
};

export const FRAUD_TITLES: ErrorTitles = {
  icon: UNABLED_ICON,
  title: GENERIC_ERROR_TITLE,
  subtitle: `There is an issue with your account. Please contact Capital One Support.`,
  primaryButtonText: SEARCH_AGAIN,
};

export const GENERIC_ERROR_TITLES: ErrorTitles = {
  icon: ERROR_ICON,
  title: GENERIC_ERROR_TITLE,
  subtitle: GENERIC_ERROR_SUBTITLE,
  primaryButtonText: SEARCH_AGAIN,
  secondaryButtonText: CHOOSE_ANOTHER_CAR,
};

export const PAYMENT_METHOD_ERROR_TITLES = (error: string): ErrorTitles => {
  switch (error) {
    case "InvalidCVV":
      return {
        agentSubtitle: getAgentErrorSubtitle(error),
        agentTitle: getAgentErrorTitle(error),
        icon: ERROR_ICON,
        title: "CVV Error",
        subtitle:
          "Ensure your CVV / Security Code is entered correctly and try again.",
        primaryButtonText: EDIT_PAYMENT_INFO,
      };
    case "InvalidAddress":
      return {
        agentSubtitle: getAgentErrorSubtitle(error),
        agentTitle: getAgentErrorTitle(error),
        icon: ERROR_ICON,
        title: "Address Verification Error",
        subtitle:
          "Ensure your Zip Code and Country is entered correctly and try again.",
        primaryButtonText: EDIT_PAYMENT_INFO,
      };
    default:
    case "GenericError":
      return {
        agentSubtitle: getAgentErrorSubtitle(error),
        agentTitle: getAgentErrorTitle(error),
        icon: ERROR_ICON,
        title: "Error Adding Payment Method",
        subtitle:
          "We were unable to add your payment method. Please ensure all of your information is correct and try again.",
        primaryButtonText: EDIT_PAYMENT_INFO,
      };
  }
};

export const INACTIVITY_TITLES: ErrorTitles = {
  icon: ERROR_ICON,
  title: "Oh no. Your booking has timed out.",
  subtitle:
    "It looks like you've been away for a little while. Please re-select your details and try again.",
  primaryButtonText: CONTINUE,
};

export const PAYMENT_ERROR_TITLES: ErrorTitles = {
  icon: ERROR_ICON,
  title: "Oh no - we couldn't confirm your booking",
  subtitle: "Please try again or select a new payment method",
  secondaryButtonText: "Edit payment method",
  primaryButtonText: TRY_AGAIN,
};

export const LACK_OF_INVENTORY_TITLES: ErrorTitles = {
  icon: UNABLED_ICON,
  title: "Oh no - this price is no longer available",
  subtitle:
    "Prices can change frequently: please try again in a few hours or try another car.",
  secondaryButtonText: CHOOSE_ANOTHER_CAR,
  primaryButtonText: TRY_AGAIN,
};

export const RATE_NOT_AVAILABLE_TITLES: ErrorTitles = {
  icon: UNABLED_ICON,
  title: "Oh no - this rate is no longer available",
  subtitle: "Please select another rate to continue your stay",
  secondaryButtonText: CHOOSE_ANOTHER_CAR,
  primaryButtonText: CHOOSE_ANOTHER_RATE,
};

export const QUOTE_ERROR_TITLES = (
  subtitle: string = "",
  supplierRef?: string
): ErrorTitles => {
  switch (subtitle) {
    case CarsBookErrorEnum.InvalidLoyaltyNumber:
    case CarsBookErrorEnum.DollarExpressNotFound:
    case CarsBookErrorEnum.BlueChipNumberNotFound:
      return {
        icon: UNABLED_ICON,
        title: `We were unable to process your booking due to an invalid ${REWARDS_PROGRAM_NAME(
          supplierRef
        )} number.`,
        subtitle: `Please ensure the number you have entered is correct, we cannot process your booking with an incorrect rewards number. If you are unsure, remove the rewards number and try booking again. You can add your ${REWARDS_PROGRAM_NAME(
          supplierRef
        )} number by contacting customer support.`,
        primaryButtonText: EDIT_REWARDS_NUMBER,
      };
    case CarsBookErrorEnum.LoyaltyNameMismatch:
      return {
        icon: UNABLED_ICON,
        title: `The name of the driver does not match the name on your ${REWARDS_PROGRAM_NAME(
          supplierRef
        )} account`,
        subtitle: `To use your ${REWARDS_PROGRAM_NAME(
          supplierRef
        )} account, ensure the driver name matches the account. Please edit the driver name or add the correct driver.`,
        primaryButtonText: EDIT_DRIVER,
      };
    default:
      return {
        subtitle,
        icon: ERROR_ICON,
        title: GENERIC_ERROR_TITLE,
        secondaryButtonText: TRY_AGAIN,
        primaryButtonText: UPDATE_DRIVER,
      };
  }
};

export const UNDER_AGE_RATE_TITLES = (minAge?: number): ErrorTitles => {
  return {
    icon: UNABLED_ICON,
    title: `Oh no - this rate isn't available for drivers under the age of ${
      minAge ?? 25
    }`,
    subtitle: "Please select another driver or update you search criteria",
    secondaryButtonText: TRY_AGAIN,
    primaryButtonText: UPDATE_DRIVER,
  };
};

export const USER_CARD_ERROR_TITLES: ErrorTitles = {
  icon: UNABLED_ICON,
  title: "We were unable to process your payment method.",
  subtitle:
    "Choose another payment method, or delete your original payment method and add it again. If the problem continues, please contact Customer Support at 844-422-6922 for further assistance.",
  primaryButtonText: CONTINUE,
};

export const REDEMPTION_FAILED_TITLES: ErrorTitles = {
  icon: UNABLED_ICON,
  title: "Rewards Redemption Failure",
  subtitle:
    "We were unable to redeem your rewards at this time. Please try again, and if the issue continues to persist, please try again later.",
  primaryButtonText: TRY_AGAIN,
};

export const DRIVER_AGE_NOT_MET = (minimumAge: number): ErrorTitles => ({
  icon: UNABLED_ICON,
  title: "Oh no - the car did not accept your booking",
  subtitle: `The car requires the reservation holder to be at least ${minimumAge} years old. Please search for a different car.`,
  primaryButtonText: CHOOSE_ANOTHER_CAR,
});

export const MISSING_LICENSE_TITLES: ErrorTitles = {
  icon: ERROR_ICON,
  title: "Missing Driver License Details",
  subtitle:
    "It looks like you haven't added a driver's license to your traveler. Please edit your traveler details",
  primaryButtonText: UPDATE_DRIVER,
};

// v1 purchase endponit error msg:
export const ADVANCE_LEAD_TIME_TITLE: ErrorTitles = {
  icon: UNABLED_ICON,
  title:
    "Your selected pickup date and time does not meet the minimum advance booking requirement.",
  subtitle: "Please choose a later pickup date and time.",
  primaryButtonText: SEARCH_AGAIN,
  secondaryButtonText: CHOOSE_ANOTHER_CAR,
};
export const AGE_REQUIREMENT_TITILE: ErrorTitles = {
  icon: UNABLED_ICON,
  title:
    "The selected driver's age does not meet the requirements for car rental.",
  subtitle: "Please choose another driver or select a different vehicle.",
  secondaryButtonText: CHOOSE_ANOTHER_CAR,
  primaryButtonText: UPDATE_DRIVER,
};
export const RATE_UNAVAILABLE_TITLES: ErrorTitles = {
  icon: UNABLED_ICON,
  title: "The selected vehicle is no longer available.",
  subtitle: "Please choose a different vehicle.",
  primaryButtonText: SEARCH_AGAIN,
  secondaryButtonText: CHOOSE_ANOTHER_CAR,
};
export const DUPLICATE_BOOKING_TITLES: ErrorTitles = {
  icon: UNABLED_ICON,
  title: "A booking with similar details already exists.",
  subtitle:
    "Please check your existing bookings or modify your current search.",
  primaryButtonText: SEARCH_AGAIN,
  secondaryButtonText: CHOOSE_ANOTHER_CAR,
};
export const OVERLAPPING_RESERVATION_TITLES: ErrorTitles = {
  icon: UNABLED_ICON,
  title:
    "You already have a reservation that overlaps with these dates and times.",
  subtitle: "Please adjust your search or check your existing reservations.",
  primaryButtonText: SEARCH_AGAIN,
  secondaryButtonText: CHOOSE_ANOTHER_CAR,
};
export const PRICE_CHANGE_TITLES: ErrorTitles = {
  icon: UNABLED_ICON,
  title:
    "We detected a price increase for your booking since confirmation, prompting a cancellation to avoid additional charges.",
  subtitle: "Please select a new vehicle or contact support for assistance.",
  primaryButtonText: SEARCH_AGAIN,
  secondaryButtonText: CHOOSE_ANOTHER_CAR,
};

// Pricing

export const BASE_FARE = "Base Fare";
export const DAY_TOTAL = (days: number | null) => {
  const dayText = days && days <= 1 ? "day" : "days";
  return `Car x ${days} ${dayText}`;
};
export const TOTAL_INC_TAXES_FEES = "Total including taxes and fees";

export const TAXES_AND_FEES = "Taxes & Fees";
export const DUE_TODAY = "Due Today";
export const DUE_AT_PICKUP = "Due at Pick-Up";
export const ADDITIONAL_FEES = "Additional Fee";
export const TOTAL = "Total";
export const REWARDS = "Rewards applied:";
export const REWARDS_BALANCE_REMAINING = "Rewards balance remaining:";
export const AMOUNT_DUE = "Amount Due:";
export const TRAVEL_OFFER_APPLIED = "Travel offer applied:";
export const TRAVEL_CREDITS_APPLIED = "Travel credits applied:";
export const TOTAL_TRAVEL_CREDITS_APPLIED = "Total travel credits applied:";

export const EARN_STRING = (amount?: number, currency?: string) =>
  `You’ll earn <strong>${amount?.toLocaleString("en-US")} ${
    currency || "Miles"
  }</strong> on this trip!`;

export const EARNED_STRING = (rewards: RewardsPrice) =>
  `You've earned <strong>${getRewardsString(rewards)}</strong> on this trip!`;

export const getPriceDifferenceTitle = (isIncrease: boolean, amount: string) =>
  `${!isIncrease ? `You're in luck! ` : ``}The price has ${
    isIncrease ? "increased" : "decreased"
  } by $${amount}.`;
export const PRICE_DECREASE_SUBTITLE =
  "Sometimes car rental companies change the prices unexpectedly. You can review the new trip total on the final screen before checkout.";
export const PRICE_INCREASE_SUBTITLE =
  "Sometimes rental car companies change the prices unexpectedly. Sorry about that! You can review the new trip total on the final screen before checkout.";
export const HERTZ_LOYALTY_INCREASE_SUBTITLE =
  "<span>Sometimes rental car companies change the prices unexpectedly. You can review the new trip total on the final screen before checkout.</span><br><br><span>This price increase may be due to pre-accepted clauses in your loyalty profile.</span>";
export const PRICE_INCREASE_OFFER_COVERAGE_TITLE =
  "We’ve detected a price increase. Your Travel Offer no longer covers the total cost of your booking.";
export const PRICE_INCREASE_CREDITS_COVERAGE_TITLE =
  "We’ve detected a price increase. Your travel credits no longer cover the total cost of your booking.";
export const PRICE_INCREASE_WALLET_COVERAGE_SUBTITLE =
  "Please add or select a payment method to complete the booking.";
