import { takeLatest } from "redux-saga/effects";
import { actionTypes } from "../actions";
import { fetchCarsAvailabilitySaga } from "./fetchCarsAvailabilitySaga";
import { listPaymentMethodsSaga } from "./listPaymentMethodsSaga";

export function* watchFetchCarsAvailability() {
  yield takeLatest(
    actionTypes.FETCH_CAR_AVAILABILITY,
    fetchCarsAvailabilitySaga
  );
}

export function* watchListCarsAvailabilityPaymentMethods() {
  yield takeLatest(actionTypes.LIST_PAYMENT_METHODS, listPaymentMethodsSaga);
}
