import { connect, ConnectedProps } from "react-redux";

import { IStoreState } from "../../../../../../reducers/types";
import {
  setPickUpDate,
  setDropOffDate,
  setMobileCarSearchStep,
} from "../../../../actions/actions";
import { getPickUpDate, getDropOffDate } from "../../../../reducer";
import { MobileCalendarPicker } from "./component";

const mapDispatchToProps = {
  setPickUpDate,
  setDropOffDate,
  setMobileCarSearchStep,
};
const mapStateToProps = (state: IStoreState) => {
  const pickUpDate = getPickUpDate(state);
  const dropOffDate = getDropOffDate(state);

  const readyToContinue = pickUpDate && dropOffDate;

  return {
    readyToContinue,
  };
};

const connector = connect(mapStateToProps, mapDispatchToProps);

export type MobileCalendarPickerConnectorProps = ConnectedProps<
  typeof connector
>;

export const ConnectedMobileCalendarPicker = connector(MobileCalendarPicker);
