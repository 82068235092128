import { getConfigTenant } from "@capone/common";

// Use process.env when running Pawtucket modules on their own, otherwise use runtime config from McLean
export const BASE_URL = window.__mclean_env__
  ? window.__mclean_env__.REACT_APP_TYSONS_URL
  : "";

export const GOOGLE_MAPS_API_KEY = window.__mclean_env__
  ? window.__mclean_env__.REACT_APP_GOOGLE_MAPS_API_KEY
  : process.env.REACT_APP_GOOGLE_MAPS_API_KEY;

export const SPREEDLY_ENVIRONMENT_KEY = window.__mclean_env__
  ? window.__mclean_env__.REACT_APP_SPREEDLY_ENV_KEY
  : process.env.REACT_APP_SPREEDLY_ENV_KEY;

const TENANT = getConfigTenant();

export const config = {
  // TODO implement auth when BE complete
  apiKey: "test",
  baseURL: BASE_URL!,
  googleMapsApiKey: GOOGLE_MAPS_API_KEY!,
  spreedlyEnvironmentKey: SPREEDLY_ENVIRONMENT_KEY!,
  TENANT,
};
