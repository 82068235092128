import React from "react";
import { Box } from "@material-ui/core";
import { useDeviceTypes } from "halifax";
import clsx from "clsx";

import {
  DesktopAvailabilityFilters,
  MobileAvailabilityFilters,
} from "./components";
import { AvailabilityFiltersConnectorProps } from "./container";
import { RouteComponentProps } from "react-router-dom";
import {
  AVAILABLE,
  CARS_CX_V1,
  GLOBAL_MOBILE_NAV_EXPERIMENT,
  getExperimentVariant,
  useExperiments,
} from "../../../../context/experiments";

export interface IAvailabilityFiltersProps
  extends AvailabilityFiltersConnectorProps,
    RouteComponentProps {
  modalOpen: boolean;
  setModalOpen: (open: boolean) => void;
}

export const AvailabilityFilters = (props: IAvailabilityFiltersProps) => {
  const { matchesMobile } = useDeviceTypes();

  const expState = useExperiments();

  const carsCXV1Experiment = getExperimentVariant(
    expState.experiments,
    CARS_CX_V1
  );
  const isCarsCXV1Experiment = React.useMemo(
    () => carsCXV1Experiment === AVAILABLE,
    [carsCXV1Experiment]
  );

  const isGlobalMobileNavExperiment =
    getExperimentVariant(expState.experiments, GLOBAL_MOBILE_NAV_EXPERIMENT) ===
    AVAILABLE;

  return (
    <Box
      className={clsx("availability-filter-root", { mobile: matchesMobile })}
    >
      <Box className="availability-filter-container">
        {!matchesMobile && (
          <DesktopAvailabilityFilters
            {...props}
            isCarsCXV1Experiment={isCarsCXV1Experiment}
          />
        )}
        {matchesMobile && (
          <MobileAvailabilityFilters
            {...props}
            isCarsCXV1Experiment={isCarsCXV1Experiment}
            isGlobalMobileNavExperiment={isGlobalMobileNavExperiment}
          />
        )}
      </Box>
    </Box>
  );
};
