import {
  VehicleAvailability,
  ResultsContext,
  CarsAvailabilityRequestEnum,
  VehicleKindEnum,
  IPriceRange,
  CarsAutocompleteResult,
  DateTime,
  TravelWalletOffer,
  CarEntryTypeEnum,
  PickUpType,
  CarsAvailabilityRequest,
  Payment,
  CallState,
} from "redmond";

import { actions, actionTypes } from "../actions";
import { CarFilterTypeV5 } from "./utils/carAvailabilityHelperFunctions";

export enum GeneralCarType {
  Unknown = "Unknown",
  Small = "Small",
  Medium = "Medium",
  Large = "Large",
}

export const vehicleKindToGeneralCarType: {
  [vehicleKind: string]: GeneralCarType;
} = {
  [VehicleKindEnum.Truck]: GeneralCarType.Large,
  [VehicleKindEnum.Standard]: GeneralCarType.Medium,
  [VehicleKindEnum.Economy]: GeneralCarType.Small,
  [VehicleKindEnum.Compact]: GeneralCarType.Small,
  [VehicleKindEnum.Small]: GeneralCarType.Small,
  [VehicleKindEnum.SUV]: GeneralCarType.Large,
  [VehicleKindEnum.Mini]: GeneralCarType.Small,
  [VehicleKindEnum.Intermediate]: GeneralCarType.Medium,
  [VehicleKindEnum.Van]: GeneralCarType.Large,
  [VehicleKindEnum.Luxury]: GeneralCarType.Medium,
  [VehicleKindEnum.FullSize]: GeneralCarType.Medium,
  [VehicleKindEnum.Mystery]: GeneralCarType.Unknown,
};

export interface ICarCompaniesFilter {
  [code: string]: boolean;
}

export enum Specification {
  AirConditioning = "AirConditioning",
  AutomaticTransmission = "AutomaticTransmission",
  UnlimitedMileage = "UnlimitedMileage",
}

export type ISpecifications = {
  [key in Specification]: boolean;
};

export enum CancellationPolicy {
  FreeCancellation = "FreeCancellation",
}

export type ICancellationPolicy = {
  [key in CancellationPolicy]: boolean;
};

export enum PassengerFilter {
  UpToFive = "UpToFive",
  SixOrMore = "SixOrMore",
}

export interface ICarFilterState {
  carTypes: GeneralCarType[] | CarFilterTypeV5[];
  pricePerDay: IPriceRange | null;
  maxTotalPrice: number;
  carCompanies: ICarCompaniesFilter;
  specifications: ISpecifications;
  cancellation: ICancellationPolicy;
  passengers: PassengerFilter[];
  pickUp: PickUpType[];
  bags: number[];
  isInPolicy: boolean;
}

export const PLACEHOLDER_PRICE_RANGE = {
  min: 10,
  max: 1000,
};

export const PLACEHOLDER_CAR_COMPANIES: { key: string; name: string }[] = [
  { key: "AC", name: "Ace" },
  { key: "BGT", name: "Budget" },
  { key: "PLS", name: "Payless" },
];

export const initialFilterState: ICarFilterState = {
  carTypes: [],
  pricePerDay: null,
  maxTotalPrice: 0,
  carCompanies: {},
  specifications: {
    [Specification.AirConditioning]: false,
    [Specification.AutomaticTransmission]: false,
    [Specification.UnlimitedMileage]: false,
  },
  cancellation: {
    [CancellationPolicy.FreeCancellation]: false,
  },
  passengers: [],
  pickUp: [],
  bags: [],
  isInPolicy: false,
};

export interface ICarAvailabilitySearchedState {
  dropOffDateSearched: Date | null;
  dropOffTimeSearched: DateTime | null;
  dropOffLocationSearched: CarsAutocompleteResult | null;
  pickUpDateSearched: Date | null;
  pickUpTimeSearched: DateTime | null;
  pickUpLocationSearched: CarsAutocompleteResult | null;
}

const initialSearchedState = {
  dropOffDateSearched: null,
  dropOffTimeSearched: null,
  dropOffLocationSearched: null,
  pickUpDateSearched: null,
  pickUpTimeSearched: null,
  pickUpLocationSearched: null,
};

export interface ICarAvailabilityState
  extends ICarFilterState,
    ICarAvailabilitySearchedState {
  vehicles: VehicleAvailability[];
  context: ResultsContext;
  // if defined, this Results is not the entirety of the query, and the client should do a follow up query to get the rest
  nextPageToken?: string;
  carAvailabilityCallState: CarAvailabilityCallState;
  // The datesPicker popup needs to be controllable through different components
  openDatesModal: boolean;
  bestOfferOverall?: TravelWalletOffer;
  carAvailabilityEntryPoint?: CarEntryTypeEnum;
  isCarsCXV1Experiment?: boolean;
  carAvailabilityRequest: CarsAvailabilityRequest;
  pickUpLocationCountry?: string;
  paymentMethods: Payment[];
  listPaymentMethodCallState: CallState;
}

export enum CarAvailabilityCallState {
  NotCalled = "NotCalled",
  InitialSearchCallInProcess = "InitialSearchCallInProcess",
  FollowUpSearchCallInProcess = "FollowUpSearchCallInProcess",
  InitialSearchCallSuccess = "InitialSearchCallSuccess",
  FollowUpSearchCallSuccess = "FollowUpSearchCallSuccess",
  // when a FollowUpSearch does not have nextPageToken defined, it is Complete
  Complete = "Complete",
  Failed = "Failed",
}

const initialState: ICarAvailabilityState = {
  vehicles: [],
  context: {
    vendors: {},
    suppliers: {},
    opaqueShopRequestContext: "",
  },
  nextPageToken: undefined,
  carAvailabilityCallState: CarAvailabilityCallState.NotCalled,
  openDatesModal: false,
  bestOfferOverall: undefined,
  carAvailabilityEntryPoint: undefined,
  isCarsCXV1Experiment: false,
  carAvailabilityRequest: {} as CarsAvailabilityRequest,
  pickUpLocationCountry: undefined,
  paymentMethods: [],
  listPaymentMethodCallState: CallState.NotCalled,
  ...initialFilterState,
  ...initialSearchedState,
};

export function reducer(
  state: ICarAvailabilityState = initialState,
  action: actions.CarAvailabilityActions
): ICarAvailabilityState {
  switch (action.type) {
    case actionTypes.FETCH_CAR_AVAILABILITY:
      return {
        ...state,
        carAvailabilityCallState:
          action.requestType === CarsAvailabilityRequestEnum.InitialSearch
            ? CarAvailabilityCallState.InitialSearchCallInProcess
            : state.nextPageToken
            ? CarAvailabilityCallState.FollowUpSearchCallInProcess
            : state.carAvailabilityCallState,
        vehicles:
          action.requestType === CarsAvailabilityRequestEnum.InitialSearch
            ? initialState.vehicles
            : state.vehicles,
        context:
          action.requestType === CarsAvailabilityRequestEnum.InitialSearch
            ? initialState.context
            : state.context,
        pickUpLocationCountry:
          action.requestType === CarsAvailabilityRequestEnum.InitialSearch
            ? initialState.pickUpLocationCountry
            : state.pickUpLocationCountry,
      };

    case actionTypes.SET_CAR_AVAILABILITY_RESULTS:
      const isInitialRequest =
        state.carAvailabilityCallState ===
        CarAvailabilityCallState.InitialSearchCallInProcess;
      const hasCompletedRequest = !action.payload.nextPageToken;

      if (isInitialRequest) {
        return {
          ...state,
          vehicles: action.payload.vehicles,
          context: action.payload.context,
          nextPageToken: action.payload.nextPageToken,
          carAvailabilityCallState: hasCompletedRequest
            ? CarAvailabilityCallState.Complete
            : CarAvailabilityCallState.InitialSearchCallSuccess,
          bestOfferOverall: action.payload.bestOfferOverall,
          pickUpLocationCountry: action.payload.pickUpLocationCountry,
        };
      }

      return {
        ...state,
        // each FollowUpSearch fetches more cars based on the specified page size;
        // the new cars result continues from where the previous call ends.
        vehicles: [...state.vehicles, ...action.payload.vehicles],
        context: {
          vendors: {
            ...state.context.vendors,
            ...action.payload.context.vendors,
          },
          suppliers: {
            ...state.context.suppliers,
            ...action.payload.context.suppliers,
          },
          opaqueShopRequestContext:
            action.payload.context.opaqueShopRequestContext,
        },
        nextPageToken: action.payload.nextPageToken,
        carAvailabilityCallState: hasCompletedRequest
          ? CarAvailabilityCallState.Complete
          : CarAvailabilityCallState.FollowUpSearchCallSuccess,
      };

    case actionTypes.SET_CAR_AVAILABILITY_CALL_STATE_FAILED:
      return {
        ...state,
        carAvailabilityCallState: CarAvailabilityCallState.Failed,
      };

    case actionTypes.SET_CAR_TYPE_FILTER:
      return {
        ...state,
        carTypes: action.carTypes,
      };
    case actionTypes.SET_PASSENGERS_FILTER:
      return {
        ...state,
        passengers: action.passengersSelected,
      };
    case actionTypes.SET_PRICE_RANGE_FILTER:
      return {
        ...state,
        pricePerDay: action.priceRange,
      };

    case actionTypes.SET_MAX_TOTAL_PRICE_FILTER:
      return { ...state, maxTotalPrice: action.maxTotalPrice };

    case actionTypes.SET_CAR_COMPANIES_FILTER:
      return {
        ...state,
        carCompanies: action.carCompanies,
      };

    case actionTypes.SET_SPECIFICATIONS_FILTER:
      return {
        ...state,
        specifications: action.specifications,
      };

    case actionTypes.SET_CANCELLATION_POLICY_FILTER:
      return {
        ...state,
        cancellation: action.cancellation,
      };

    case actionTypes.RESET_CAR_AVAILABILITY_FILTERS:
      return {
        ...state,
        ...initialFilterState,
      };

    case actionTypes.RESET_CAR_AVAILABILITY_CALL_STATES:
      return {
        ...state,
        carAvailabilityCallState: initialState.carAvailabilityCallState,
      };

    case actionTypes.SET_PICK_UP_LOCATION_SEARCHED:
      const { location: pickUpLocationSearched } = action;
      return {
        ...state,
        pickUpLocationSearched,
      };

    case actionTypes.SET_PICK_UP_DATE_SEARCHED:
      const { date: pickUpDateSearched } = action;
      return {
        ...state,
        pickUpDateSearched,
      };

    case actionTypes.SET_PICK_UP_TIME_SEARCHED:
      const { time: pickUpTimeSearched } = action;
      return {
        ...state,
        pickUpTimeSearched,
      };

    case actionTypes.SET_DROP_OFF_LOCATION_SEARCHED:
      const { location: dropOffLocationSearched } = action;
      return {
        ...state,
        dropOffLocationSearched,
      };

    case actionTypes.SET_DROP_OFF_DATE_SEARCHED:
      const { date: dropOffDateSearched } = action;
      return {
        ...state,
        dropOffDateSearched,
      };

    case actionTypes.SET_DROP_OFF_TIME_SEARCHED:
      const { time: dropOffTimeSearched } = action;
      return {
        ...state,
        dropOffTimeSearched,
      };

    case actionTypes.SET_OPEN_DATES_MODAL:
      return {
        ...state,
        openDatesModal: action.openDatesModal,
      };
    case actionTypes.SET_CAR_AVAILABILITY_ENTRY_POINT:
      return {
        ...state,
        carAvailabilityEntryPoint: action.entryPoint,
      };

    case actionTypes.SET_IS_CARS_CX_V1_EXPERIMENTS:
      return {
        ...state,
        isCarsCXV1Experiment: action.isEnabled,
      };

    case actionTypes.SET_PICKUP_FILTER:
      return {
        ...state,
        pickUp: action.pickUpTypes,
      };
    case actionTypes.SET_BAGS_FILTER:
      return {
        ...state,
        bags: action.bags,
      };

    case actionTypes.SET_POLICY_FILTER:
      return {
        ...state,
        isInPolicy: action.isInPolicy,
      };

    case actionTypes.SET_CAR_AVAILABILITY_REQUEST:
      return {
        ...state,
        carAvailabilityRequest: action.payload,
      };

    case actionTypes.LIST_PAYMENT_METHODS:
      return {
        ...state,
        listPaymentMethodCallState: CallState.InProcess,
      };

    case actionTypes.SET_PAYMENT_METHODS:
      return {
        ...state,
        paymentMethods: action.paymentMethods,
        listPaymentMethodCallState: CallState.Success,
      };

    case actionTypes.SET_PAYMENT_METHODS_CALL_STATE_FAILED:
      return {
        ...state,
        listPaymentMethodCallState: CallState.Failed,
      };

    default:
      return state;
  }
}

export * from "./selectors";
export { CarFilterTypeV5 };
