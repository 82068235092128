import React from "react";
import {
  Box,
  Checkbox,
  FormGroup,
  FormControl,
  FormControlLabel,
  Typography,
} from "@material-ui/core";
import { SwitchButton, useDeviceTypes } from "halifax";
import clsx from "clsx";

import { ICancellationPolicy, CancellationPolicy } from "../../../../reducer";
import {
  VIEW_CARS_WITH_FREE_CANCEL,
  getCancellationPolicyTitle,
} from "./textConstants";
import "./styles.scss";
import { randomIdWithName } from "../../../../utils/randomIdWithName";

export interface ICancellationPolicySelectionProps {
  className?: string;
  cancellation: ICancellationPolicy;
  setCancellationPolicy: (cancellation: ICancellationPolicy) => void;
  isCarsCXV1Experiment?: boolean;
}

export const CancellationPolicySelection = (
  props: ICancellationPolicySelectionProps
) => {
  const {
    className,
    cancellation,
    setCancellationPolicy,
    isCarsCXV1Experiment,
  } = props;
  const { matchesMobile } = useDeviceTypes();
  const isChecked = (option: string): boolean => {
    return cancellation[option];
  };

  const handleChange = (option: string) => {
    let newSelected = { ...cancellation };
    newSelected[option] = !newSelected[option];
    setCancellationPolicy(newSelected);
  };

  const renderOptions = (options: CancellationPolicy[]) => {
    return options.map((option) => {
      const title = getCancellationPolicyTitle(option);
      const checked = isChecked(option);
      const id = randomIdWithName(option);
      return (
        <FormControlLabel
          key={option}
          className="cancellation-selection-form-control-label"
          onKeyDown={(e) => {
            if (e && e.key === "Enter") {
              handleChange(option);
            }
          }}
          control={
            <Checkbox
              inputProps={{ "aria-labelledby": id }}
              checked={checked}
              onChange={() => handleChange(option)}
              value={option}
              className="cancellation-selection-checkbox"
            />
          }
          labelPlacement="start"
          label={
            <Box className="option-label-wrapper">
              <Typography id={id} variant="body2" className="option-label">
                {title}
              </Typography>
            </Box>
          }
        />
      );
    });
  };

  return (
    <Box
      className={clsx(
        "cancellation-policy-selection-root",
        { mobile: matchesMobile, "cars-cx-v1": isCarsCXV1Experiment },
        className
      )}
    >
      <Box className="cancellation-policy-selection-container">
        {isCarsCXV1Experiment ? (
          <SwitchButton
            content={VIEW_CARS_WITH_FREE_CANCEL}
            checked={cancellation[CancellationPolicy.FreeCancellation] === true}
            onClick={() => {
              let newSelected = { ...cancellation };
              newSelected[CancellationPolicy.FreeCancellation] =
                !newSelected[CancellationPolicy.FreeCancellation];
              setCancellationPolicy(newSelected);
            }}
            switchAtFront
          />
        ) : (
          <FormControl
            className="cancellation-selection-form-control"
            component="fieldset"
          >
            <FormGroup>
              {renderOptions([CancellationPolicy.FreeCancellation])}
            </FormGroup>
          </FormControl>
        )}
      </Box>
    </Box>
  );
};
