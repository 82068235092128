import { connect, ConnectedProps } from "react-redux";
import { CarsSearch } from "./component";
import { IStoreState } from "../../reducers/types";
import { fetchRewardsAccounts } from "../rewards/actions/actions";
import {
  getFetchRewardsAccountsCallState,
  getRewardsAccounts,
  getRewardsAccountWithLargestEarnForBanner,
} from "../rewards/reducer";
import { getMobileSearchStep } from "./reducer";
import { resetCallStates } from "../availability/actions/actions";
import { setMobileCarSearchStep } from "./actions/actions";
import { listPaymentMethods } from "../book/actions/actions";

const mapStateToProps = (state: IStoreState) => ({
  rewardsAccounts: getRewardsAccounts(state),
  largestValueAccount: getRewardsAccountWithLargestEarnForBanner(state),
  fetchRewardsAccountsCallState: getFetchRewardsAccountsCallState(state),
  currentStep: getMobileSearchStep(state),
});

const mapDispatchToProps = {
  fetchRewardsAccounts,
  resetCallStates,
  setMobileCarSearchStep,
  listPaymentMethods,
};

const connector = connect(mapStateToProps, mapDispatchToProps);

export type CarsSearchConnectorProps = ConnectedProps<typeof connector>;

export const ConnectedCarsSearch = connector(CarsSearch);
