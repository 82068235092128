import React from "react";

export const CAR_TYPE_TEXT = "Car type";
export const PRICE_RANGE_TEXT = "Price Range";
export const PRICE_PER_DAY_TEXT = "Price per day";
export const RENTAL_CAR_COMPANY_TEXT = "Rental car company";
export const SPECIFICATIONS_TEXT = "Specifications";
export const CANCELLATION_POLICY_TEXT = "Cancellation policy";
export const PICKUP_TYPE_TEXT = "Pick-up Location";
export const BAGS_TEXT = "Number of bags";
export const FILTERS_TEXT = "Filters";
export const IN_POLICY_TEXT = "Policy Status";

export const APPLY_FILTERS_TEXT = (filtersToApplyCount: number) =>
  `Apply Filters${filtersToApplyCount > 0 ? ` (${filtersToApplyCount})` : ""}`;

const withAppliedLabel = (filterLabel: string) => (appliedLabel?: string) =>
  (
    <>
      {filterLabel}
      {appliedLabel ? (
        <>
          : <span className="applied-label">{appliedLabel}</span>
        </>
      ) : (
        ""
      )}
    </>
  );

export const CAR_TYPE_DROPDOWN_LABEL = withAppliedLabel("Car type");
export const PRICE_DROPDOWN_LABEL = withAppliedLabel("Max price");
export const RENTAL_COMPANY_DROPDOWN_LABEL = withAppliedLabel("Rental company");
export const CANCELLATION_DROPDOWN_LABEL = withAppliedLabel("Cancellation");
export const SPECIFICATIONS_DROPDOWN_LABEL = withAppliedLabel("Specifications");
export const PASSENGERS_DROPDOWN_LABEL = withAppliedLabel("Passengers");
export const PICKUP_TYPE_DROPDOWN_LABEL = withAppliedLabel("Pick-up Location");
export const BAGS_DROPDOWN_LABEL = withAppliedLabel("Bags");
export const POLICY_DROPDOWN_LABEL = withAppliedLabel(IN_POLICY_TEXT);

export const RENTAL_COMPANY_FILTER_TITLE = "Rental company";
export const PRICE_FILTER_TITLE = "Max price";
export const PRICE_FILTER_SUBTITLE = "Total including taxes and fees";

export const RESET_CTA_TEXT = "Clear all filters";

export const PASSENGERS = "Passengers";
