import { connect, ConnectedProps } from "react-redux";
import { isCorpTenant } from "@capone/common";
import { withRouter } from "react-router";
import { getCarShopQueryParams } from "./../../../availability/reducer/selectors/index";
import { IStoreState } from "../../../../reducers/types";
import {
  getDropOffDate,
  getDropOffTime,
  getDropOffLocation,
  getPickUpDate,
  getPickUpTime,
  getPickUpLocation,
  getDriverAge,
} from "../../../search/reducer";
import {
  getCarShopCallError,
  getCarShopCallState,
  CarExtraInfoCallError,
  CarExtraInfoCallState,
  getCarShopSelectedVehicle,
  getExtraInfo,
} from "../../reducer";
import { CarShopDetails } from "./component";
import { redoSearch } from "../../../book/actions/actions";
import {
  getRewardsAccountWithLargestEarnForBanner,
  getUserIsPrimaryCardHolder,
} from "../../../rewards/reducer";
import { getTravelWalletCredit } from "../../../travel-wallet/reducer";
import { config } from "../../../../api/config";

const mapStateToProps = (state: IStoreState) => {
  return {
    dropOffDate: getDropOffDate(state),
    dropOffTime: getDropOffTime(state),
    dropOffLocation: getDropOffLocation(state),
    pickUpDate: getPickUpDate(state),
    pickUpTime: getPickUpTime(state),
    pickUpLocation: getPickUpLocation(state),
    driverAge: getDriverAge(state),
    carShopError:
      getCarShopCallError(state) === CarExtraInfoCallError.NoAvailability,
    carShopCallInProgress:
      getCarShopCallState(state) === CarExtraInfoCallState.InProcess,
    selectedVehicle: getCarShopSelectedVehicle(state),
    carShopQueryParams: getCarShopQueryParams(state),
    extraInfo: getExtraInfo(state),
    largestValueAccount: getRewardsAccountWithLargestEarnForBanner(state),
    credit: getTravelWalletCredit(state),
    canEarnRewards:
      !isCorpTenant(config.TENANT) || getUserIsPrimaryCardHolder(state),
  };
};

export const mapDispatchToProps = {
  resetBookState: redoSearch,
};

const connector = connect(mapStateToProps, mapDispatchToProps);

export type CarShopDetailsConnectorProps = ConnectedProps<typeof connector>;

export const ConnectedCarShopDetails = withRouter(connector(CarShopDetails));
