import {
  CarEntryTypeEnum,
  CarsAutocompleteResult,
  IdEnum,
  IIdCar,
  IIdFlight,
  GroundPlace as Place,
} from "redmond";
import dayjs from "dayjs";

export interface ICarAvailabilityParsedQuery {
  dropOffDate: Date | null;
  dropOffTime: string;
  dropOffLocation: string;
  pickUpDate: Date | null;
  pickUpTime: string;
  pickUpLocation: string;
  driverAge: number;
  entryPoint?: CarEntryTypeEnum;
}

export const transformToStringifiedAvailabilityQuery = (
  dropOffDate: Date | string | null,
  dropOffTime: string | null,
  dropOffLocation: CarsAutocompleteResult | string | null,
  pickUpDate: Date | string | null,
  pickUpTime: string | null,
  pickUpLocation: CarsAutocompleteResult | string | null,
  driverAge: number | null,
  entryPoint?: CarEntryTypeEnum
): string => {
  if (
    pickUpDate === null ||
    dropOffDate === null ||
    pickUpLocation === null ||
    dropOffLocation === null ||
    pickUpTime === null ||
    dropOffTime === null ||
    driverAge === null
  ) {
    return `?pickUpLocation=${pickUpLocation}`;
  }
  const formatDropOffDate =
    typeof dropOffDate !== "string"
      ? dayjs(dropOffDate).format("YYYY-MM-DD")
      : dropOffDate;
  const formatPickUpDate =
    typeof pickUpDate !== "string"
      ? dayjs(pickUpDate).format("YYYY-MM-DD")
      : pickUpDate;
  const formatDropOffLocation =
    typeof dropOffLocation !== "string"
      ? dropOffLocation.id.Id === IdEnum.Flight
        ? (dropOffLocation.id as IIdFlight).code.code
        : ((dropOffLocation.id as IIdCar).groundSelection as Place).searchTerm
      : dropOffLocation;
  const formatPickUpLocation =
    typeof pickUpLocation !== "string"
      ? pickUpLocation.id.Id === IdEnum.Flight
        ? (pickUpLocation.id as IIdFlight).code.code
        : ((pickUpLocation.id as IIdCar).groundSelection as Place).searchTerm
      : pickUpLocation;

  let string =
    `?dropOffDate=${formatDropOffDate}` +
    `&dropOffTime=${dropOffTime}` +
    `&dropOffLocation=${formatDropOffLocation}` +
    `&pickUpDate=${formatPickUpDate}` +
    `&pickUpTime=${pickUpTime}` +
    `&pickUpLocation=${formatPickUpLocation}` +
    `&driverAge=${driverAge}`;
  if (entryPoint) {
    string += `&entryPoint=${entryPoint}`;
  }
  return string;
};

export interface ICarShopQueryParsedQuery extends ICarAvailabilityParsedQuery {
  vehicleId: string;
}

export interface ICarShopQueryParams {
  vehicleId: string | null;
  dropOffDate: Date | null;
  dropOffTime: string | null;
  dropOffLocation: CarsAutocompleteResult | null;
  pickUpDate: Date | null;
  pickUpTime: string | null;
  pickUpLocation: CarsAutocompleteResult | null;
  driverAge: number | null;
}

export const transformToStringifiedShopQuery = ({
  vehicleId,
  dropOffDate,
  dropOffTime,
  dropOffLocation,
  pickUpDate,
  pickUpTime,
  pickUpLocation,
  driverAge,
}: ICarShopQueryParams): string => {
  if (dropOffDate === null || pickUpDate === null) {
    return `?vehicleId=${vehicleId}`;
  }

  const formatDropOffDate = dayjs(dropOffDate).format("YYYY-MM-DD");
  const formatPickUpDate = dayjs(pickUpDate).format("YYYY-MM-DD");
  const formatDropOffLocation = dropOffLocation?.label;
  const formatPickUpLocation = pickUpLocation?.label;

  return (
    `?vehicleId=${vehicleId}` +
    `&dropOffDate=${formatDropOffDate}` +
    `&pickUpDate=${formatPickUpDate}` +
    `&dropOffTime=${dropOffTime}` +
    `&pickUpTime=${pickUpTime}` +
    `&dropOffLocation=${formatDropOffLocation}` +
    `&pickUpLocation=${formatPickUpLocation}` +
    `&driverAge=${driverAge}`
  );
};
