import React from "react";
import { DriverAgeInput as AgeInput } from "halifax";
import clsx from "clsx";

import { DriverAgeInputConnectorProps } from "./container";
import * as constants from "./constants";
import "./styles.scss";

export interface IDriverAgeInputProps extends DriverAgeInputConnectorProps {
  className?: string;
  isMobile?: boolean;
  hasMissingSearchInfoError?: boolean;
  useModal?: boolean;
}

export const DriverAgeInput = ({
  className,
  driverAge,
  setDriverAge,
  isMobile,
  hasMissingSearchInfoError,
  useModal,
}: IDriverAgeInputProps) => {
  return (
    <AgeInput
      isMobile={isMobile!}
      className={clsx("b2b", className)}
      label={constants.DRIVER_AGE}
      defaultValue={driverAge!}
      onChange={(event: { target: { value: string } }) => {
        setDriverAge(parseInt(event.target.value, 10));
      }}
      error={
        typeof driverAge === "number" &&
        (driverAge === 0 || driverAge < 18 || driverAge > 99)
      }
      hasMissingSearchInfoError={hasMissingSearchInfoError}
      useModal={useModal}
    />
  );
};
