import { ActionButton } from "halifax";
import React from "react";
import { MobileDatesSelectionConnectorProps } from "./container";
import { DATES_TEXT } from "./textConstants";
import { RouterProps } from "react-router";
import { Typography } from "@material-ui/core";
import { Icon, IconName } from "halifax";
import { MobileCalendarPickerModal } from "../../../search/components/MobileCarSearchControlV2/components/MobileCalendarPickerModal";
import "./styles.scss";
import * as textConstants from "./textConstants";
export interface IMobileDatesSelection
  extends MobileDatesSelectionConnectorProps,
    RouterProps {}

export const MobileDatesSelection = (props: IMobileDatesSelection) => {
  const { pickUpDate, dropOffDate } = props;
  const [openDatesModal, setOpenDatesModal] = React.useState(false);

  if (!pickUpDate || !dropOffDate) return null;

  return (
    <>
      <ActionButton
        className="car-availability-edit-date-button-wrapper"
        buttonClassName="car-availability-edit-date-button"
        defaultStyle="h4r-secondary"
        onClick={() => setOpenDatesModal(true)}
        message={
          <Typography variant="button" className="edit-date-button-text">
            {DATES_TEXT(pickUpDate, dropOffDate)}
            <Icon className="edit-icon" name={IconName.B2BEditPencil} />
          </Typography>
        }
      />
      <MobileCalendarPickerModal
        openModal={openDatesModal}
        setOpenModal={setOpenDatesModal}
        searchOnContinue
        headerText={textConstants.CHANGE_DATES}
      />
    </>
  );
};
