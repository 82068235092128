import React from "react";
import { Box, Typography } from "@material-ui/core";
import {
  ActionButton,
  ActionLink,
  CloseButtonIcon,
  Icon,
  IconName,
  MobilePopoverCard,
} from "halifax";

import {
  MonthAndDatePicker,
  MonthAndDatePickerType,
} from "../../../DesktopCarSearchControl/components/MonthAndDatePicker";
import { MobileCalendarPickerModalConnectorProps } from "./container";
import "./styles.scss";
import dayjs from "dayjs";
import * as textConstants from "./textConstants";
import clsx from "clsx";
import { PATH_AVAILABILITY } from "../../../../../../utils/paths";
import { RouteComponentProps } from "react-router";
import { transformToStringifiedAvailabilityQuery } from "../../../../../availability/utils/queryStringHelpers";

interface IMobileCalendarPickerModalProps
  extends MobileCalendarPickerModalConnectorProps,
    RouteComponentProps {
  focusedMonthIndex?: number;
  openModal: boolean;
  setOpenModal: (val: boolean) => void;
  searchOnContinue?: boolean;
  headerText?: string;
  onSelectDates?: (pickUp: Date | null, dropOff: Date | null) => void;
}

export const MobileCalendarPickerModal = (
  props: IMobileCalendarPickerModalProps
) => {
  const {
    focusedMonthIndex,
    setPickUpDate,
    setDropOffDate,
    openModal,
    setOpenModal,
    pickUpLocation,
    dropOffLocation,
    pickUpTime,
    dropOffTime,
    driverAge,
    pickUpDate,
    dropOffDate,
    searchOnContinue = false,
    history,
    headerText,
    onSelectDates,
  } = props;

  const [localPickUpDate, setLocalPickUpDate] = React.useState<Date | null>(
    null
  );
  const [localDropOffDate, setLocalDropOffDate] = React.useState<Date | null>(
    null
  );

  React.useEffect(() => {
    setLocalPickUpDate(pickUpDate);
  }, [pickUpDate]);

  React.useEffect(() => {
    setLocalDropOffDate(dropOffDate);
  }, [dropOffDate]);

  const isReadyToSearch =
    (searchOnContinue ? !!localDropOffDate : !!dropOffDate) &&
    !!dropOffTime &&
    !!dropOffLocation &&
    (searchOnContinue ? !!localPickUpDate : !!pickUpDate) &&
    !!pickUpTime &&
    !!pickUpLocation &&
    !!driverAge;
  const MobileSearchPopoverHeader = () => {
    return (
      <Box className="modal-header-container">
        <Typography className="header-title">
          {headerText || textConstants.CHOOSE_DATES}
        </Typography>
        <ActionLink
          className="modal-close-button"
          onClick={handleClose}
          content={<CloseButtonIcon className="close-button-icon" />}
          label="Close"
          showTappableArea
        />
      </Box>
    );
  };

  const DatePickerModalHeader = ({
    label,
    value,
  }: {
    label: string;
    value?: string;
  }) => {
    return (
      <Box className="selected-date-wrapper">
        <Icon name={IconName.Calendar} />
        <Box className="selected-date-content">
          <Typography className="content-top-label">
            {value ? label : ""}
          </Typography>
          <Typography
            className={clsx("content-main-content", { label: !value })}
          >
            {value || label}
          </Typography>
        </Box>
      </Box>
    );
  };
  const handleContinue = () => {
    setPickUpDate(localPickUpDate);
    setDropOffDate(localDropOffDate);
    setOpenModal(false);
    onSelectDates?.(localPickUpDate, localDropOffDate);

    searchOnContinue &&
      isReadyToSearch &&
      history.push(
        `${PATH_AVAILABILITY}${transformToStringifiedAvailabilityQuery(
          localDropOffDate!,
          dropOffTime!,
          dropOffLocation!,
          localPickUpDate!,
          pickUpTime!,
          pickUpLocation!,
          driverAge!
        )}`
      );
  };

  const handleClose = () => {
    !searchOnContinue && setPickUpDate(localPickUpDate);
    !searchOnContinue && setDropOffDate(localDropOffDate);
    searchOnContinue && setLocalPickUpDate(pickUpDate);
    searchOnContinue && setLocalDropOffDate(dropOffDate);

    setOpenModal(false);
  };

  return (
    <MobilePopoverCard
      open={openModal}
      className={"mobile-cars-search-calendar-popup"}
      onClose={handleClose}
      headerElement={<MobileSearchPopoverHeader />}
    >
      <Box className={"mobile-calendar-picker-root v2"}>
        <MonthAndDatePicker
          className="b2b"
          viewType={MonthAndDatePickerType.Column}
          focusedMonthIndex={focusedMonthIndex}
          setFromDate={setLocalPickUpDate}
          setUntilDate={setLocalDropOffDate}
          from={localPickUpDate}
          until={localDropOffDate}
          header={
            <Box className="cars-mobile-calendar-header">
              <DatePickerModalHeader
                label={textConstants.PICK_UP}
                value={
                  localPickUpDate
                    ? dayjs(localPickUpDate).format("ddd D, MMM")
                    : undefined
                }
              />
              <DatePickerModalHeader
                label={textConstants.DROP_OFF}
                value={
                  localDropOffDate
                    ? dayjs(localDropOffDate).format("ddd D, MMM")
                    : undefined
                }
              />
            </Box>
          }
        />
        {localPickUpDate && localDropOffDate && (
          <ActionButton
            className="mobile-calendar-picker-continue-button b2b"
            message={textConstants.CONTINUE}
            onClick={handleContinue}
          />
        )}
      </Box>
    </MobilePopoverCard>
  );
};
