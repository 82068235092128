import { connect, ConnectedProps } from "react-redux";
import { CarBookMobileButton } from "./component";
import { IStoreState } from "../../../../reducers/types";
import { getUserSelectedTravelerId } from "../../reducer";

const mapStateToProps = (state: IStoreState) => {
  return {
    selectedTravelerId: getUserSelectedTravelerId(state),
  };
};

const mapDispatchToProps = {};

const connector = connect(mapStateToProps, mapDispatchToProps);

export type CarBookMobileButtonConnectorProps = ConnectedProps<
  typeof connector
>;

export const ConnectedCarBookMobileButton = connector(CarBookMobileButton);
