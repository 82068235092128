import React from "react";
import { Box } from "@material-ui/core";
import { ActionButton } from "halifax";

import {
  MonthAndDatePicker,
  MonthAndDatePickerType,
} from "../../../DesktopCarSearchControl/components/MonthAndDatePicker";
import { MobileCalendarPickerConnectorProps } from "./container";
import * as textConstants from "./textConstants";
import { MobileCarSearchStep } from "../../../../reducer";
import "./styles.scss";
import clsx from "clsx";

interface IMobileCalendarPickerProps
  extends MobileCalendarPickerConnectorProps {
  pickUpDate: Date | null;
  dropOffDate: Date | null;
  focusedMonthIndex: number;
  onComplete?: () => void;
  hideTimePicker?: boolean;
  onSelectDates?: (pickUp: Date | null, dropOff: Date | null) => void;
}

export const MobileCalendarPicker = (props: IMobileCalendarPickerProps) => {
  const {
    readyToContinue,
    focusedMonthIndex,
    setPickUpDate,
    setDropOffDate,
    pickUpDate,
    dropOffDate,
    setMobileCarSearchStep,
    hideTimePicker,
    onSelectDates,
  } = props;

  return (
    <Box className="mobile-calendar-picker-root">
      <MonthAndDatePicker
        className="b2b"
        viewType={MonthAndDatePickerType.Column}
        focusedMonthIndex={focusedMonthIndex}
        setFromDate={setPickUpDate}
        setUntilDate={setDropOffDate}
        from={pickUpDate}
        until={dropOffDate}
      />
      {!hideTimePicker && readyToContinue && (
        <ActionButton
          className={clsx("mobile-calendar-picker-search-button", "b2b")}
          message={textConstants.CHOOSE_DATES}
          onClick={() => {
            setMobileCarSearchStep(MobileCarSearchStep.TimePickerAndDriverAge);
            onSelectDates?.(pickUpDate, dropOffDate);
          }}
        />
      )}
    </Box>
  );
};
