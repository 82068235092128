import { connect, ConnectedProps } from "react-redux";
import { isCorpTenant } from "@capone/common";
import { withRouter } from "react-router";
import { IStoreState } from "../../../../reducers/types";
import { CarExtraInfoCallState } from "../../reducer";
import {
  getCarShopCallState,
  getCarShopSelectedVehicle,
  getExtraInfo,
} from "../../reducer/selectors";
import { MobileShop } from "./component";
import { redoSearch } from "../../../book/actions/actions";
import { getCarShopQueryParams } from "./../../../availability/reducer/selectors/index";
import {
  getRewardsAccountWithLargestEarnForBanner,
  getUserIsPrimaryCardHolder,
} from "../../../rewards/reducer";
import { getTravelWalletCredit } from "../../../travel-wallet/reducer";
import { config } from "../../../../api/config";

const mapStateToProps = (state: IStoreState) => {
  return {
    selectedVehicle: getCarShopSelectedVehicle(state),
    carShopCallInProgress:
      getCarShopCallState(state) === CarExtraInfoCallState.InProcess,
    carShopError: getCarShopCallState(state) === CarExtraInfoCallState.Failed,
    extraInfo: getExtraInfo(state),
    carShopQueryParams: getCarShopQueryParams(state),
    largestValueAccount: getRewardsAccountWithLargestEarnForBanner(state),
    credit: getTravelWalletCredit(state),
    canEarnRewards:
      !isCorpTenant(config.TENANT) || getUserIsPrimaryCardHolder(state),
  };
};

export const mapDispatchToProps = {
  resetBookState: redoSearch,
};

const connector = connect(mapStateToProps, mapDispatchToProps);

export type MobileShopConnectorProps = ConnectedProps<typeof connector>;

export const ConnectedMobileShop = withRouter(connector(MobileShop));
