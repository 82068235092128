/* eslint-disable import/prefer-default-export */
import React from "react";
import {Checkbox, FormControlLabel, Box, Typography} from "@material-ui/core";

import "./styles.scss";
import clsx from "clsx";

export type Props = {
  isInPolicy: boolean;
  setIsInPolicy: (newValue: boolean) => void;
  title?: string;
  labelPlacement?: "start" | "end";
};

export const PolicyStatusSelection = ({
  isInPolicy,
  setIsInPolicy,
  title,
  labelPlacement,
}: Props) => (
  <Box
    className={clsx("cars-policy-status-selection-root", {
      "cars-policy-status-selection-root-expanded": labelPlacement === "start",
    })}
  >
    {title && <Box className="header-container">{title}</Box>}
    <FormControlLabel
      labelPlacement={labelPlacement}
      control={
        <Checkbox
          className="checkbox"
          checked={isInPolicy}
          onChange={(e) => setIsInPolicy(e.target.checked)}
        />
      }
      label={
          <Box className="option-label-wrapper">
              <Typography variant="body2" className="option-label">
                  In policy only
              </Typography>
          </Box>
      }
    />
  </Box>
);

PolicyStatusSelection.defaultProps = {
  title: "",
  labelPlacement: "start",
};
