import { CurrencyFormatters } from "halifax";
import { TravelWalletCredit, TravelWalletOffer } from "redmond";

export const CREDIT_MODAL_HEADER = (name: string) =>
  `${name}'s <strong>travel credit</strong> Summary`;

export const VIEW_OFFER_DETAILS_CTA_COPY = "View Offer Details";
export const CREDITS_CTA = "View details";

export const getSelectedOfferDescription = (
  offer: TravelWalletOffer,
  boldAmount?: boolean
) => {
  return boldAmount
    ? `<span class="bold-text">${offer.title}</span>`
    : offer.title;
};

export const getSelectedCreditDescription = (
  credit?: TravelWalletCredit,
  creditAmountToApply?: number,
  boldAmount?: boolean
) => {
  if (credit) {
    return boldAmount
      ? `<strong>${CurrencyFormatters.get(credit.amount.currency).format(
          Math.abs(credit.amount.amount || 0)
        )}</strong>`
      : `Apply ${CurrencyFormatters.get(credit.amount.currency).format(
          Math.abs(creditAmountToApply || credit.amount.amount || 0)
        )} in travel credits`;
  }
  return "";
};

export const getWalletModalTitle = (
  offers: TravelWalletOffer[],
  credit: TravelWalletCredit | undefined,
  isMobile: boolean
) => {
  if (credit) {
    return `You have <strong>travel credits</strong> and <strong>(${offers.length}) travel offers</strong> that are eligible for this booking.`;
  }
  return isMobile
    ? `You have
    <span class="bold-text"> (${offers.length}) travel offers </span>
    available.`
    : `You have
    <span class="bold-text"> (${offers.length}) travel offers </span>
    eligible for this booking.`;
};

export const CONTINUE = "Continue";

export const LOADING_OFFERS = "Loading travel offers";
export const LOADING_WALLET = "Loading travel offers and credits";
