import {
  OffersForProductsRequest,
  WalletOffersResponse,
  WalletSummaryResponse,
} from "redmond";
import { put, select } from "redux-saga/effects";
import { fetchTravelWalletOffers } from "../../../../api/v1/book/travel-wallet/fetchTravelWalletOffers";
import { fetchTravelWalletSummary } from "../../../../api/v1/travel-wallet/fetchTravelWalletSummary";
import { IStoreState } from "../../../../reducers/types";
import {
  setBestOfferOverall,
  setFetchApplicableTravelWalletItemsCallStateFailure,
  setFetchApplicableTravelWalletItemsCallStateSuccess,
  setTravelWalletCredit,
  setTravelWalletOffers,
} from "../../actions/actions";
import { getOffersRequest } from "../../reducer/selectors";

export function* fetchApplicableTravelWalletItemsSaga() {
  try {
    const state: IStoreState = yield select();
    const request: OffersForProductsRequest | null = getOffersRequest(state);
    if (!request) throw new Error("Must have a selected vehicle");

    const offersResponse: WalletOffersResponse = yield fetchTravelWalletOffers(
      request
    );
    const summaryResponse: WalletSummaryResponse =
      yield fetchTravelWalletSummary();

    yield put(setTravelWalletOffers(offersResponse.rankedApplicableOffers));
    yield put(setBestOfferOverall(offersResponse.rankedApplicableOffers[0]));
    yield put(setTravelWalletCredit(summaryResponse.credit));
    yield put(setFetchApplicableTravelWalletItemsCallStateSuccess());
  } catch (e) {
    yield put(setFetchApplicableTravelWalletItemsCallStateFailure());
  }
}
