import { CustomerAccountRole } from "redmond";

export const INELIGIBLE_REWARDS_REDEMPTION_NOTICE = (
  role?: CustomerAccountRole
) => {
  switch (role) {
    case CustomerAccountRole.AuthorizedUser:
      return "Authorized users can't redeem rewards";
    case CustomerAccountRole.AccountManager:
      return "Account managers can't redeem rewards";
    default:
      return "Can't redeem rewards";
  }
};
