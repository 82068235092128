export const VIEW_OFFER_DETAILS = "View Offer Details";

export const NO_OFFERS_AVAIL_TITLE = "You don’t have any travel offers.";
export const NO_OFFER_AVAIL_BODY =
  "We’ll notify you when travel offers become available.";

export const TRAVEL_SALES_EVENT_LEADUP_SUBTITLE =
  "Our first-ever travel sale is about to land.";
export const TRAVEL_SALES_EVENT_LEADUP_CTA = "Explore upcoming sale";

export const TRAVEL_SALES_EVENT_ACTIVE_SUBTITLE =
  "Save up to $200 on select hotels with our first-ever travel sale.";
export const TRAVEL_SALES_EVENT_ACTIVE_CTA = "Explore destinations on sale";

export const HSP_OFFER_TITLE_LABEL =
  "Congrats, you have two hotel offers to choose from:";
