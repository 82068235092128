import {
  CarsAutocompleteResponse,
  CarsAutocompleteResult,
  CarsTripCategory,
  DateTime,
} from "redmond";
import { actions, actionTypes } from "../actions";

export enum MobileCarSearchStep {
  LocationSearch,
  CalendarPicker,
  TimePickerAndDriverAge,
  Complete,
}

export interface ICarSearchState {
  dropOffLocation: CarsAutocompleteResult | null;
  dropOffLocationResults: CarsAutocompleteResponse[];
  dropOffLocationResultsLoading: boolean | null;
  dropOffDate: Date | null;
  dropOffTime: DateTime | null;
  pickUpLocation: CarsAutocompleteResult | null;
  pickUpLocationResults: CarsAutocompleteResponse[];
  pickUpLocationResultsLoading: boolean | null;
  pickUpDate: Date | null;
  pickUpTime: DateTime | null;
  driverAge: number | null;

  // The datesPicker popup needs to be controllable through different components
  openDatesModal: boolean;
  mobileSearchStep: MobileCarSearchStep;
  tripCategory: CarsTripCategory;
  hasPickUpAutocompleteError: boolean;
  hasDropOffAutocompleteError: boolean;
}

export const DEFAULT_DRIVER_AGE = 25;
// TODO: confirm and possibly change default pickUp/dropOff times
export const DEFAULT_PICK_UP_TIME = "10:00 AM";
export const DEFAULT_DROP_OFF_TIME = "10:00 AM";

const initialState: ICarSearchState = {
  pickUpLocation: null,
  dropOffLocation: null,
  dropOffLocationResults: [],
  dropOffLocationResultsLoading: false,
  pickUpLocationResults: [],
  pickUpLocationResultsLoading: false,
  pickUpDate: null,
  dropOffDate: null,
  pickUpTime: DEFAULT_PICK_UP_TIME,
  dropOffTime: DEFAULT_DROP_OFF_TIME,
  driverAge: DEFAULT_DRIVER_AGE,
  openDatesModal: false,
  mobileSearchStep: MobileCarSearchStep.LocationSearch,
  tripCategory: CarsTripCategory.SAME_AS_DROP_OFF,
  hasPickUpAutocompleteError: false,
  hasDropOffAutocompleteError: false,
};

export function reducer(
  state: ICarSearchState = initialState,
  action: actions.CarSearchActions
): ICarSearchState {
  switch (action.type) {
    case actionTypes.FETCH_DROP_OFF_LOCATION_RESULTS:
      return {
        ...state,
        dropOffLocationResults: [],
        dropOffLocationResultsLoading: true,
      };
    case actionTypes.SET_DROP_OFF_LOCATION:
      const { location: dropOffLocation } = action;
      return {
        ...state,
        dropOffLocation,
        dropOffLocationResultsLoading: false,
      };
    case actionTypes.SET_DROP_OFF_LOCATION_RESULTS:
      const { results: dropOffLocationResults } = action;
      return {
        ...state,
        dropOffLocationResults,
        dropOffLocationResultsLoading: false,
        hasDropOffAutocompleteError: false,
      };
    case actionTypes.SET_DROP_OFF_DATE:
      const { date: dropOffDate } = action;
      return { ...state, dropOffDate };
    case actionTypes.SET_DROP_OFF_TIME:
      const { time: dropOffTime } = action;
      return { ...state, dropOffTime };

    case actionTypes.FETCH_PICK_UP_LOCATION_RESULTS:
      return {
        ...state,
        pickUpLocationResults: [],
        pickUpLocationResultsLoading: true,
      };
    case actionTypes.SET_PICK_UP_LOCATION:
      const { location: pickUpLocation } = action;
      return {
        ...state,
        pickUpLocation,
        pickUpLocationResultsLoading: false,
        hasPickUpAutocompleteError: false,
      };
    case actionTypes.SET_PICK_UP_LOCATION_RESULTS:
      const { results: pickUpLocationResults } = action;
      return {
        ...state,
        pickUpLocationResults,
        pickUpLocationResultsLoading: false,
      };
    case actionTypes.SET_PICK_UP_DATE:
      const { date: pickUpDate } = action;
      return { ...state, pickUpDate };
    case actionTypes.SET_PICK_UP_TIME:
      const { time: pickUpTime } = action;
      return { ...state, pickUpTime };

    case actionTypes.SET_DRIVER_AGE:
      const { driverAge } = action;
      return { ...state, driverAge };

    case actionTypes.SET_OPEN_DATES_MODAL:
      const { openDatesModal } = action;
      return { ...state, openDatesModal };

    case actionTypes.SET_MOBILE_SEARCH_PROGRESS:
      return {
        ...state,
        mobileSearchStep: action.mobileCarSearchStep,
      };
    case actionTypes.SET_TRIP_CATEGORY:
      return {
        ...state,
        tripCategory: action.tripCategory,
      };
    case actionTypes.SET_PICK_UP_AUTOCOMPLETE_ERROR: {
      return {
        ...state,
        hasPickUpAutocompleteError: action.error,
        pickUpLocationResultsLoading: false,
      };
    }
    case actionTypes.SET_DROP_OFF_AUTOCOMPLETE_ERROR: {
      return {
        ...state,
        hasDropOffAutocompleteError: action.error,
        dropOffLocationResultsLoading: false,
      };
    }
    default:
      return state;
  }
}

export * from "./selectors";
