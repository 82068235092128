import { Box } from "@material-ui/core";
import {
  createGenerateClassName,
  StylesProvider,
  ThemeProvider,
} from "@material-ui/core/styles";
import { AgentBanner, TravelerWorkflowDialog, useDeviceTypes } from "halifax";
import React, { useCallback, useContext, useEffect, useState } from "react";
import { IntlProvider } from "react-intl";
import { Provider, useDispatch, useSelector } from "react-redux";
import { Route, Router, Switch } from "react-router-dom";
import {
  CarsClientAssetProps,
  CarsModuleProps,
  CorpSessionInfo,
  CustomEvents,
  EditTravelerModalState,
  installColorConfig,
  IPerson,
  SavedTravelersProperties,
  SelfServeEvents,
  SessionInfo,
  VIEWED_TRAVELER_DETAILS,
  ViewedSavedTravelersProperties,
  ViewedTravelerDetailsProperties,
} from "redmond";
import {
  ExperimentsHookProvider,
  useExperimentIsVariant,
} from "@capone/experiments";

import { trackEvent } from "./api/v1/analytics/trackEvent";
import fetchUserInfo from "./api/v1/user/fetchUserInfo";
import AxiosInterceptors from "./components/AxiosInterceptors";
import ExperimentsProvider, {
  CARS_V1_PURCHASE_FLOW,
  getExperimentVariant,
  useExperiments,
} from "./context/experiments";
import UserSourceProvider from "./context/userSource";
import useWindowEventListener from "./hooks/useWindowEventListener";
import * as englishTranslations from "./lang/en.json";
import * as frenchTranslations from "./lang/fr.json";
import { CarsAvailability } from "./modules/availability";
import { CarBook } from "./modules/book";
import {
  deleteUserPassenger,
  fetchCorpUserPassengers,
  fetchUserPassengers,
  setCarBookExperiments,
  updateUserPassenger,
} from "./modules/book/actions/actions";
import { PriceMatchBanner } from "./modules/book/components/PriceMatchBanner";
import { getUserPassengers } from "./modules/book/reducer";
import {
  setAgentEmail,
  setIsAutoApprovalEnabled,
  setIsFirstLaunch,
  setSelectedAccountReferenceId,
} from "./modules/rewards/actions/actions";
import { RewardsBanner } from "./modules/rewards/components";
import { CarsSearch } from "./modules/search";
import { CarsShop } from "./modules/shop";
import { store } from "./store";
import { eventsToListen } from "./utils/events";
import {
  PATH_AVAILABILITY,
  PATH_BOOK,
  PATH_HOME,
  PATH_HOME_SEARCH,
  PATH_SHOP,
} from "./utils/paths";
import {
  isCorpTenant,
  useIsAutoApprovalEnabled,
} from "../../../packages/common/src";
import { config } from "./api/config";
import { OnboardingModal } from "./components/OnboardingModal";
import { useMedalliaFeedback } from "b2b-base/src/components/MedalliaFeedback";
import { fetchUserPolicies } from "b2b-base/src/api/v1/policies/fetchPolicies";
import { getRewardsAccounts } from "./modules/rewards/reducer";

function loadLocaleData(locale: string): any {
  switch (locale) {
    case "fr":
      return frenchTranslations;
    default:
      return englishTranslations;
  }
}
const generateClassName = createGenerateClassName({
  productionPrefix: "ptCarsModule",
  seed: "ptCarsModule",
});

interface CarsClientContextType extends CarsClientAssetProps {
  isAgentPortal: boolean;
  isAutoApprovalEnabled: boolean;
}

export const ClientContext = React.createContext<
  Partial<CarsClientContextType>
>({});

const App = (props: CarsModuleProps) => {
  const {
    baseHistory,
    clientAssets,
    colors,
    experiments,
    isAgentPortal,
    language,
    theme,
  } = props;

  const [activeTheme, setActiveTheme] = useState(theme);
  const [locale, setLocale] = useState(language);
  const [messages, setMessages] = useState(loadLocaleData(locale).default);
  const [sessionInfo, setSessionInfo] = useState(clientAssets.sessionInfo);
  const [policies, setPolicies] = useState(clientAssets.policies);

  useEffect(() => {
    handleFetchUserInfo();
  }, []);

  useEffect(() => {
    if (!policies && isCorpTenant(config.TENANT)) {
      handleFetchPolicies();
    }
  }, [policies]);

  const handleFetchUserInfo = async () => {
    try {
      const userInfoResponse = await fetchUserInfo();
      setSessionInfo(userInfoResponse);
    } catch (error) {
      console.error(error);
    }
  };

  const handleFetchPolicies = async () => {
    const policiesResponse = await fetchUserPolicies();
    setPolicies(policiesResponse.policyDetail);
  };

  installColorConfig(colors);

  const handleThemeChanged = (e: CustomEvent) => {
    setActiveTheme(e.detail);
    console.log("THEME RECEIVED:", e.detail.palette.type);
  };
  const handleLocaleChanged = (e: CustomEvent) => {
    setLocale(e.detail);
    const localMessages = loadLocaleData(e.detail);
    setMessages(localMessages.default);
  };
  useWindowEventListener(eventsToListen.HOST_THEME_CHANGED, handleThemeChanged);
  useWindowEventListener(
    eventsToListen.HOST_LOCALE_CHANGED,
    handleLocaleChanged
  );
  const { firstName, lastName } = sessionInfo?.userInfo || {
    firstName: "",
    lastName: "",
  };

  const isAutoApprovalEnabled = useIsAutoApprovalEnabled(
    clientAssets.policies || policies
  );

  return (
    <Provider store={store}>
      <ExperimentsProvider initState={experiments}>
        <ExperimentsHookProvider isLoggedIn={Boolean(sessionInfo?.csrfToken)}>
          <UserSourceProvider>
            <Router history={baseHistory}>
              <AxiosInterceptors isAgentPortal={isAgentPortal} />
              <ClientContext.Provider
                value={{
                  ...clientAssets,
                  sessionInfo,
                  policies,
                  isAgentPortal,
                  isAutoApprovalEnabled,
                }}
              >
                <div className="App">
                  <StylesProvider
                    generateClassName={generateClassName}
                    injectFirst
                  >
                    <ThemeProvider theme={activeTheme}>
                      {messages != null ? (
                        <IntlProvider
                          locale={locale}
                          defaultLocale="en"
                          messages={messages}
                        >
                          <Switch>
                            {isAgentPortal ? (
                              <Route path="*">
                                <AgentBanner
                                  firstName={firstName}
                                  lastName={lastName}
                                />
                              </Route>
                            ) : (
                              <>
                                <Route path={PATH_BOOK}>
                                  <PriceMatchBanner />
                                </Route>
                                <Route path="*">
                                  <RewardsBanner />
                                </Route>
                              </>
                            )}
                          </Switch>
                          <Body
                            sessionInfo={sessionInfo}
                            clientAssets={{ ...clientAssets, policies }}
                          />
                        </IntlProvider>
                      ) : (
                        <div>Loading</div>
                      )}
                    </ThemeProvider>
                  </StylesProvider>
                </div>
              </ClientContext.Provider>
            </Router>
          </UserSourceProvider>
        </ExperimentsHookProvider>
      </ExperimentsProvider>
    </Provider>
  );
};

export const Body = (props: {
  sessionInfo?: SessionInfo | CorpSessionInfo;
  clientAssets: CarsClientAssetProps;
}) => {
  const { sessionInfo, clientAssets } = props;
  const isApprovalsV2Enabled = useExperimentIsVariant(
    "corp-approvals-v2",
    "m2"
  );
  const dispatch = useDispatch();
  const { matchesMobile } = useDeviceTypes();
  const expState = useExperiments();
  const travelers = useSelector(getUserPassengers);
  const [editTravelersOpen, setEditTravelersOpen] = useState(
    EditTravelerModalState.closed
  );

  const rewardsAccounts = useSelector(getRewardsAccounts, (a, b) => {
    if (a.length !== b.length) {
      return false;
    }
    return true;
  });

  const userRoles =
    sessionInfo && "corporateInfo" in sessionInfo
      ? sessionInfo.corporateInfo.role
      : [];

  const defaultTravelerId =
    sessionInfo && "corporateInfo" in sessionInfo
      ? sessionInfo.corporateInfo.defaultTravelerId
      : "";

  const closeEditTravelersModal = useCallback(() => {
    setEditTravelersOpen(EditTravelerModalState.closed);
  }, []);

  const onEditTravelersStateChange = useCallback((ev: any) => {
    const newEditModalState =
      (ev as CustomEvent).detail ?? EditTravelerModalState.closed;

    setEditTravelersOpen(newEditModalState);
  }, []);

  useEffect(() => {
    dispatch(
      setCarBookExperiments({
        [CARS_V1_PURCHASE_FLOW]: getExperimentVariant(
          expState.experiments,
          CARS_V1_PURCHASE_FLOW
        ),
      })
    );
  }, [expState]);

  useEffect(() => {
    if (editTravelersOpen === EditTravelerModalState.ground) {
      if (isCorpTenant(config.TENANT)) {
        trackEvent({
          eventName: VIEWED_TRAVELER_DETAILS,
          properties: {
            num_traveler_profiles: travelers.length,
          } satisfies ViewedTravelerDetailsProperties,
        });
      } else {
        trackEvent({
          eventName: SelfServeEvents.ViewedSavedTravelers,
          properties: {
            url: window.location.pathname,
            num_traveler_profiles: travelers.length,
          } satisfies ViewedSavedTravelersProperties,
        });
      }
    }
  }, [editTravelersOpen, travelers.length]);

  const handleAddPass = useCallback(() => {
    !isCorpTenant(config.TENANT) &&
      trackEvent({
        eventName: SelfServeEvents.ClickSavedTravelersAddNew,
        properties: {
          url: window.location.pathname,
        },
      });
  }, []);

  useEffect(() => {
    if (!isCorpTenant(config.TENANT)) {
      dispatch(fetchUserPassengers());
    }

    document.addEventListener(
      CustomEvents.editTravelersStateChange,
      onEditTravelersStateChange
    );

    return () => {
      document.removeEventListener(
        CustomEvents.editTravelersStateChange,
        onEditTravelersStateChange
      );
    };
  }, []);

  useEffect(() => {
    if (isCorpTenant(config.TENANT) && rewardsAccounts.length > 0) {
      dispatch(fetchCorpUserPassengers());
    }
  }, [rewardsAccounts]);

  const handleAccountSelected = (event: CustomEvent) => {
    dispatch(setSelectedAccountReferenceId(event.detail));
  };
  useWindowEventListener(
    "mclean_rewards_account_selected",
    handleAccountSelected
  );

  useEffect(() => {
    if (sessionInfo) {
      dispatch(setIsFirstLaunch(sessionInfo.isFirstSession));
      if (sessionInfo.isDelegatedSession) {
        dispatch(setAgentEmail(sessionInfo.isDelegatedSession));
      }
    }
  }, [dispatch, sessionInfo]);

  const { isAutoApprovalEnabled } = useContext(ClientContext);

  useEffect(() => {
    dispatch(
      setIsAutoApprovalEnabled(
        Boolean(isAutoApprovalEnabled) || isApprovalsV2Enabled
      )
    );
  }, [dispatch, isAutoApprovalEnabled, isApprovalsV2Enabled]);

  useMedalliaFeedback();

  return (
    <>
      <Box className="main-section">
        <Route path={[PATH_HOME, PATH_HOME_SEARCH]} exact>
          <CarsSearch />
        </Route>
        <Route path={PATH_AVAILABILITY}>
          <CarsAvailability />
        </Route>
        <Route path={PATH_SHOP}>
          <CarsShop />
        </Route>
        <Route path={PATH_BOOK}>
          <CarBook />
        </Route>
      </Box>
      <TravelerWorkflowDialog
        tenant={config.TENANT}
        showHotelLoyaltySection
        disableDriversLicenseValidation
        disallowSelect
        displayModalSubtitle
        onAddPass={handleAddPass}
        showAdditionalInfoSection
        showDriverLicenseSection
        showFrequentFlyerSection
        showGenderField
        showNationalityField
        buttonClassName="b2b"
        dialogProps={{
          className: "cars-edit-traveler",
          onBackdropClick: closeEditTravelersModal,
          onClose: closeEditTravelersModal,
          open: editTravelersOpen === EditTravelerModalState.ground,
        }}
        errorMessage="Add or choose one traveler to continue."
        fullScreenWithBanner={matchesMobile}
        handleDeletePassenger={(personId: string) => {
          dispatch(deleteUserPassenger({ personId }));
        }}
        handleUpdatePassenger={(
          person: IPerson,
          hideProfileAction,
          entryPoint
        ) => {
          const frequentFlyerProgramsTotal = Reflect.ownKeys(
            person.frequentFlyer
          ).length;

          !isCorpTenant(config.TENANT) &&
            trackEvent({
              eventName: SelfServeEvents.ClickSavedTravelersSaveTraveler,
              properties: {
                url: window.location.pathname,
                frequent_flyer_program_added: frequentFlyerProgramsTotal > 0,
                num_frequent_flyer_programs: frequentFlyerProgramsTotal,
              } satisfies SavedTravelersProperties,
            });
          dispatch(
            updateUserPassenger(
              { person, hideProfileAction },
              defaultTravelerId,
              entryPoint
            )
          );
        }}
        isMobile={matchesMobile}
        travelers={travelers}
        titles={{}}
        dialogContentProps={{}}
        userRoles={userRoles}
        trackEvent={trackEvent}
        defaultTravelerId={defaultTravelerId}
      />
      {clientAssets.policies && sessionInfo && (
        <OnboardingModal
          policies={clientAssets.policies}
          trackEvent={trackEvent}
        />
      )}
    </>
  );
};

export default App;
