import React, { useEffect, useMemo } from "react";
import {
  Select,
  Input,
  FormControl,
  InputLabel,
  MenuItem,
} from "@material-ui/core";
import { Icon } from "halifax";
import clsx from "clsx";

import "./styles.scss";

export interface ITimeSelector {
  className?: string;
  time: any;
  setTime: (time: any) => void;
  label: string;
  increment: number;
  minTime?: string;
  hideLabel?: boolean;
  hideIcon?: boolean;
}

const START_HOUR = 0;
const END_HOUR = 23;

const getTimeOptions = (minTime?: string, increment: number = 30) => {
  const timeOptions = [];
  let time = new Date();

  const endTime = new Date();
  endTime.setHours(END_HOUR, 59);

  const toMinutesString = (min: number) => (min > 9 ? min : `0${min}`);
  const [h, m, a] = minTime?.split(/:|\s/) || [START_HOUR, "0", "AM"];
  const hour = Number(h);
  time.setHours(a === "PM" && hour < 12 ? hour + 12 : hour, Number(m), 0);

  while (time.getTime() < endTime.getTime()) {
    const currentHours = time.getHours();
    const timeAtHour = `${
      currentHours > 12
        ? currentHours - 12
        : currentHours === 0
        ? 12
        : currentHours
    }:${toMinutesString(time.getMinutes())} ${
      currentHours >= 12 ? "PM" : "AM"
    }`;
    timeOptions.push({ label: timeAtHour, value: timeAtHour });
    time.setTime(time.getTime() + increment * 60000);
  }

  return timeOptions;
};

export const TimeSelector = ({
  className,
  hideIcon,
  hideLabel,
  label,
  setTime,
  time,
  minTime,
  increment,
}: ITimeSelector) => {
  const timeOptions = useMemo(() => {
    return getTimeOptions(minTime, increment);
  }, [minTime]);

  const defaultTime = useMemo(() => {
    if (
      timeOptions?.length &&
      (!time || !timeOptions.find((opt) => opt.value === time))
    ) {
      return timeOptions[0].value;
    } else {
      return time;
    }
  }, [time, timeOptions]);

  useEffect(() => {
    // If a change in time options have changed the selected time
    if (defaultTime !== time) {
      setTime(defaultTime);
    }
  }, [time, defaultTime, setTime]);

  return (
    <FormControl className={clsx("time-input-container", className)}>
      {!hideLabel && <InputLabel id="time-select-label">{label}</InputLabel>}
      <Select
        labelId="time-select-label"
        id="time-select"
        className={"time-selection-menu"}
        classes={{ root: "time-select-root", icon: "time-select-arrow-icon" }}
        label={label}
        onChange={(event) => setTime(event.target.value)}
        value={defaultTime}
        MenuProps={{
          classes: { paper: "time-selector-menu" },
        }}
        input={
          <Input
            className="input-root"
            disableUnderline={true}
            startAdornment={
              !hideIcon && <Icon className="clock-icon" name="clock-icon" />
            }
          />
        }
      >
        {getTimeOptions(minTime, increment).map((option) => (
          <MenuItem key={option.value} value={option.value}>
            {option.label}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
};
