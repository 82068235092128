import { connect } from "react-redux";
import { CarsAutocompleteResult, CarsTripCategory } from "redmond";
import { IStoreState } from "../../../../../../reducers/types";
import {
  getDropOffLocation,
  getDropOffLocationResults,
  getDropOffLocationResultsLoading,
  getPickUpLocation,
  getPickUpLocationResults,
  getPickUpLocationResultsLoading,
  getTripCategory,
} from "../../../../reducer";
import { actions } from "../../../../actions";
import { B2BSearchAutocomplete, IB2BSearchAutocompleteProps } from "halifax";
import { SAME_AS_PICKUP } from "../../constants";

export const dropOffMapDispatchToProps = {
  setValue: actions.setDropOffLocation,
  fetchValueCategories: actions.fetchDropOffLocationResults,
};

export const dropOffMapStateToProps = (state: IStoreState) => {
  const loading = !!getDropOffLocationResultsLoading(state);

  let overrideInputValue = undefined;
  if (getTripCategory(state) === CarsTripCategory.SAME_AS_DROP_OFF) {
    overrideInputValue = SAME_AS_PICKUP;
  }

  return {
    value: getDropOffLocation(state),
    valueCategories: getDropOffLocationResults(state),
    loading,
    overrideInputValue,
  };
};

export const ConnectedDropOffLocationAutocomplete = connect(
  dropOffMapStateToProps,
  dropOffMapDispatchToProps
)(
  B2BSearchAutocomplete as (
    props: IB2BSearchAutocompleteProps<CarsAutocompleteResult | null>
  ) => JSX.Element
);

export const pickUpMapDispatchToProps = {
  setValue: actions.setPickUpLocation,
  fetchValueCategories: actions.fetchPickUpLocationResults,
};

export const pickUpMapStateToProps = (state: IStoreState) => {
  const loading = !!getPickUpLocationResultsLoading(state);

  return {
    value: getPickUpLocation(state),
    valueCategories: getPickUpLocationResults(state),
    loading,
  };
};

export const ConnectedPickUpLocationAutocomplete = connect(
  pickUpMapStateToProps,
  pickUpMapDispatchToProps
)(
  B2BSearchAutocomplete as (
    props: IB2BSearchAutocompleteProps<CarsAutocompleteResult | null>
  ) => JSX.Element
);
