export const FETCH_CAR_EXTRA_INFO = "carShop/FETCH_CAR_EXTRA_INFO";
export type FETCH_CAR_EXTRA_INFO = typeof FETCH_CAR_EXTRA_INFO;

export const SET_CAR_EXTRA_INFO_RESULTS = "carShop/SET_CAR_EXTRA_INFO_RESULTS";
export type SET_CAR_EXTRA_INFO_RESULTS = typeof SET_CAR_EXTRA_INFO_RESULTS;

export const SET_CAR_EXTRA_INFO_CALL_STATE_FAILED =
  "carShop/SET_CAR_EXTRA_INFO_CALL_STATE_FAILED";
export type SET_CAR_EXTRA_INFO_CALL_STATE_FAILED = typeof SET_CAR_EXTRA_INFO_CALL_STATE_FAILED;

export const ACKNOWLEDGE_CAR_EXTRA_INFO_FAILURE =
  "carShop/ACKNOWLEDGE_CAR_EXTRA_INFO_FAILURE";
export type ACKNOWLEDGE_CAR_EXTRA_INFO_FAILURE = typeof ACKNOWLEDGE_CAR_EXTRA_INFO_FAILURE;

export const SELECT_SELECTED_VEHICLE_ID = "carShop/SELECT_SELECTED_VEHICLE_ID";
export type SELECT_SELECTED_VEHICLE_ID = typeof SELECT_SELECTED_VEHICLE_ID;

export const SET_SELECTED_VEHICLE = "carShop/SET_SELECTED_VEHICLE";
export type SET_SELECTED_VEHICLE = typeof SET_SELECTED_VEHICLE;
