import { delay, put, putResolve, select } from "redux-saga/effects";
import { pollPriceQuote } from "../../../../api/v1/book/book-flow/pollPriceQuote";
import { IStoreState } from "../../../../reducers/types";
import {
  getPaymentRequestV2,
  getPriceQuoteCarProperties,
  getPricingEstimateTotal,
  getSession,
  getUseV1PurchaseFlow,
} from "../../reducer";
import {
  GetPaymentPending,
  GetPaymentFailed,
  GetPaymentResultEnum,
  PaymentQuoteValidationDataSucceeded,
  PaymentQuoteResult,
  CarPriceQuoteData,
  PRICE_QUOTE_CAR,
  PaymentError as LegacyPaymentError,
} from "redmond";
import { DO_NOT_APPLY_REWARDS_KEY, isCorpTenant } from "@capone/common";
import {
  IPollPriceQuote,
  setPollPriceQuoteCallStateFailure,
  setPollPriceQuoteCallStateSuccess,
  setPriceQuote,
} from "../../actions/actions";
import { actions } from "../../actions";
import { trackEvent } from "../../../../api/v1/analytics/trackEvent";
import dayjs from "dayjs";
import { config } from "../../../../api/config";
import { pollPriceQuoteV1 } from "../../../../api/v2/book/book-flow/pollPriceQuote";
import {
  ErrorCode,
  PaymentError,
  ProductError,
  Product,
  PurchaseError,
  PurchaseErrorEnum,
  QuoteFailure,
  QuoteResponseEnum,
  QuoteSuccess,
  QuoteResponse,
} from "@b2bportal/purchase-api";

const toLegacyErrorString = (error: LegacyPaymentError): string => {
  return error.code || error.message || error.msg || error.PaymentError;
};

const toErrorString = (error: PurchaseError): string => {
  switch (error.Error) {
    case PurchaseErrorEnum.ErrorCode:
      return (error as ErrorCode).code;
    case PurchaseErrorEnum.PaymentError:
      const paymentError = error as PaymentError;
      return toLegacyErrorString(
        paymentError.value.value as LegacyPaymentError
      );
    case PurchaseErrorEnum.ProductError:
      const purchaseError = error as ProductError;
      return toLegacyErrorString(
        purchaseError.value.value as LegacyPaymentError
      );
    default:
      return PurchaseErrorEnum[error.Error];
  }
};

const defaultRewardsAccountRefId = isCorpTenant(config.TENANT)
  ? DO_NOT_APPLY_REWARDS_KEY
  : null;

export function* pollPriceQuoteSaga({
  agentFee,
  pollQuoteOnly,
}: IPollPriceQuote) {
  try {
    const state: IStoreState = yield select();
    const sessionToken = getSession(state);
    const version = getUseV1PurchaseFlow(state) ? "v1" : "v0";

    const pollQuoteCarProperties = getPriceQuoteCarProperties(state);

    if (!sessionToken) {
      throw new Error("Session token is not present.");
    }

    const delayTimes = [1000];
    let pollFailed = false;
    let index = 0;
    const startTime = dayjs();
    while (!pollFailed) {
      yield delay(delayTimes[index]);
      if (version === "v0") {
        const priceQuoteCheckedResponse: PaymentQuoteResult | QuoteResponse =
          yield pollPriceQuote(sessionToken);

        switch (
          (priceQuoteCheckedResponse as PaymentQuoteResult).result ||
          (priceQuoteCheckedResponse as QuoteResponse).QuoteResponse
        ) {
          case GetPaymentResultEnum.Failed:
            pollFailed = true;
            const failedResponse =
              priceQuoteCheckedResponse as GetPaymentFailed;
            if (failedResponse.errors.length > 0) {
              yield putResolve(
                setPollPriceQuoteCallStateFailure(failedResponse.errors)
              );
              trackEvent({
                eventName: PRICE_QUOTE_CAR,
                properties: {
                  ...pollQuoteCarProperties.properties,
                  failure_reason:
                    failedResponse.errors.length > 0
                      ? failedResponse.errors
                          .map(
                            (error) =>
                              error?.code ||
                              error?.message ||
                              error?.msg ||
                              error?.PaymentError
                          )
                          .join(" - ")
                      : "Poll quote checked response returned an error and the given error code is not handleable.",
                },
                encryptedProperties: [
                  ...pollQuoteCarProperties.encryptedProperties,
                ],
              });
              return;
            } else {
              trackEvent({
                eventName: PRICE_QUOTE_CAR,
                properties: {
                  ...pollQuoteCarProperties.properties,
                  failure_reason:
                    "Poll quote checked response returned an error and the given error code is not handleable.",
                },
                encryptedProperties: [
                  ...pollQuoteCarProperties.encryptedProperties,
                ],
              });
              yield putResolve(setPollPriceQuoteCallStateFailure([]));
              throw new Error(
                "Price quote checked response returned an error and the given error code is not handleable."
              );
            }
          case GetPaymentResultEnum.Pending:
            const priceQuoteResponsePoll =
              priceQuoteCheckedResponse as GetPaymentPending;
            switch (priceQuoteResponsePoll.result) {
              case GetPaymentResultEnum.Failed:
                trackEvent({
                  eventName: PRICE_QUOTE_CAR,
                  properties: {
                    ...pollQuoteCarProperties.properties,
                    success: false,
                    failure_reason: "Price Quote failed",
                  },
                  encryptedProperties: [
                    ...pollQuoteCarProperties.encryptedProperties,
                  ],
                });
                pollFailed = true;
                yield putResolve(setPollPriceQuoteCallStateFailure([]));
                break;
              default:
                continue;
            }
            break;
          case GetPaymentResultEnum.Succeeded:
            const priceQuoteResponse =
              priceQuoteCheckedResponse as PaymentQuoteValidationDataSucceeded;
            switch (priceQuoteResponse.result) {
              case GetPaymentResultEnum.Succeeded:
                const quote = (priceQuoteResponse.quote as CarPriceQuoteData)
                  .groundQuoteData;
                const estimate = getPricingEstimateTotal(state)?.fiat.value;
                const payNowAmount =
                  quote.vehAvail.rentalRate.payNow.fiat.value;
                yield putResolve(setPriceQuote(quote));
                yield putResolve(setPollPriceQuoteCallStateSuccess());

                trackEvent({
                  eventName: PRICE_QUOTE_CAR,
                  properties: {
                    ...pollQuoteCarProperties.properties,
                    success: true,
                    load_time: dayjs().diff(startTime, "seconds", true),
                  },
                  encryptedProperties: [
                    ...pollQuoteCarProperties.encryptedProperties,
                  ],
                });
                if (pollQuoteOnly) {
                  return;
                }

                if (payNowAmount === estimate) {
                  const paymentRequest = getPaymentRequestV2(state);
                  if (paymentRequest) {
                    yield put(actions.scheduleBook(agentFee));
                  } else {
                    yield putResolve(setPollPriceQuoteCallStateFailure([]));
                    throw new Error("Payment amount is undefined");
                  }
                } else {
                  yield put(
                    actions.setSelectedPaymentMethodId({
                      paymentMethodId: "",
                      accountId: undefined,
                    })
                  );
                  yield put(
                    actions.setSelectedRewardsAccountReferenceId(
                      defaultRewardsAccountRefId
                    )
                  );
                }
                return;
              default:
                trackEvent({
                  eventName: PRICE_QUOTE_CAR,
                  properties: {
                    ...pollQuoteCarProperties.properties,
                    success: false,
                    failure_reason: "Price Quote failed",
                  },
                  encryptedProperties: [
                    ...pollQuoteCarProperties.encryptedProperties,
                  ],
                });
                pollFailed = true;
                yield putResolve(setPollPriceQuoteCallStateFailure([]));
                throw new Error("Price Quote Failed");
            }
        }
        if (index !== delayTimes.length - 1) {
          index++;
        }
      } else {
        const priceQuoteCheckedResponse: QuoteResponse = yield pollPriceQuoteV1(
          { req: sessionToken }
        );
        switch (priceQuoteCheckedResponse.QuoteResponse) {
          case QuoteResponseEnum.Failure:
            pollFailed = true;

            const failedResponse = priceQuoteCheckedResponse as QuoteFailure;

            if (failedResponse.errors.length > 0) {
              yield putResolve(
                setPollPriceQuoteCallStateFailure(failedResponse.errors)
              );
              trackEvent({
                eventName: PRICE_QUOTE_CAR,
                properties: {
                  ...pollQuoteCarProperties.properties,

                  failure_reason:
                    failedResponse.errors.length > 0
                      ? failedResponse.errors
                          .map((error) => toErrorString(error))
                          .join(" - ")
                      : "Poll quote checked response returned an error and the given error code is not handleable.",
                },
                encryptedProperties: [
                  ...pollQuoteCarProperties.encryptedProperties,
                ],
              });
              return;
            } else {
              trackEvent({
                eventName: PRICE_QUOTE_CAR,
                properties: {
                  ...pollQuoteCarProperties.properties,
                  failure_reason:
                    "Poll quote checked response returned an error and the given error code is not handleable.",
                },
                encryptedProperties: [
                  ...pollQuoteCarProperties.encryptedProperties,
                ],
              });
              yield putResolve(setPollPriceQuoteCallStateFailure([]));
              throw new Error(
                "Price quote checked response returned an error and the given error code is not handleable."
              );
            }
          case QuoteResponseEnum.Pending:
            break;
          case QuoteResponseEnum.Success:
            const product = (
              priceQuoteCheckedResponse as QuoteSuccess
            ).quoteBreakdown.products.find(
              (product) => product.product.type === Product.Ground
            );
            const estimate = getPricingEstimateTotal(state)?.fiat.value;
            const payNowAmount =
              product?.product.value?.vehAvail.rentalRate.payNow.fiat.value;

            yield putResolve(setPriceQuote(product?.product.value));
            yield putResolve(setPollPriceQuoteCallStateSuccess());
            trackEvent({
              eventName: PRICE_QUOTE_CAR,
              properties: {
                ...pollQuoteCarProperties.properties,
                success: true,
                load_time: dayjs().diff(startTime, "seconds", true),
              },
              encryptedProperties: [
                ...pollQuoteCarProperties.encryptedProperties,
              ],
            });
            if (pollQuoteOnly) {
              return;
            }

            if (payNowAmount === estimate) {
              const paymentRequest = getPaymentRequestV2(state);
              if (paymentRequest) {
                yield put(actions.scheduleBook(agentFee));
              } else {
                yield putResolve(setPollPriceQuoteCallStateFailure([]));
                throw new Error("Payment amount is undefined");
              }
            } else {
              yield put(
                actions.setSelectedPaymentMethodId({
                  paymentMethodId: "",
                  accountId: undefined,
                })
              );
              yield put(
                actions.setSelectedRewardsAccountReferenceId(
                  defaultRewardsAccountRefId
                )
              );
            }
            return;
          default:
            trackEvent({
              eventName: PRICE_QUOTE_CAR,
              properties: {
                ...pollQuoteCarProperties.properties,
                success: false,
                failure_reason: "Price Quote failed",
              },
              encryptedProperties: [
                ...pollQuoteCarProperties.encryptedProperties,
              ],
            });
            pollFailed = true;
            yield putResolve(setPollPriceQuoteCallStateFailure([]));
            throw new Error("Price Quote Failed");
        }
      }
      if (index !== delayTimes.length - 1) {
        index++;
      }
    }
  } catch (e) {
    const state: IStoreState = yield select();
    const pollQuoteCarProperties = getPriceQuoteCarProperties(state);
    trackEvent({
      eventName: PRICE_QUOTE_CAR,
      properties: {
        ...pollQuoteCarProperties.properties,
        failure_reason:
          "Poll quote checked response returned an error and the given error code is not handleable.",
      },
      encryptedProperties: [...pollQuoteCarProperties.encryptedProperties],
    });
    yield putResolve(setPollPriceQuoteCallStateFailure([]));
  }
}
