import * as H from "history";
import {
  CarsAvailabilityRequestEnum,
  CarsAvailabilityResponse,
  IPriceRange,
  CarsAutocompleteResult,
  DateTime,
  APPLIED_CARS_FILTER,
  AppliedCarsFilterProperties,
  CarEntryTypeEnum,
  PickUpType,
  CarsAvailabilityRequest,
  Payment,
} from "redmond";
import { trackEvent } from "../../../api/v1/analytics/trackEvent";

import {
  GeneralCarType,
  ICarCompaniesFilter,
  ISpecifications,
  ICancellationPolicy,
  CarFilterTypeV5,
  PassengerFilter,
} from "../reducer";
import * as actionTypes from "./constants";

export interface IFetchCarAvailability {
  type: actionTypes.FETCH_CAR_AVAILABILITY;
  requestType: CarsAvailabilityRequestEnum;
  history: H.History;
  includeLocationLabels?: boolean;
}

export const fetchInitialCarsAvailability = (
  history: H.History,
  includeLocationLabels?: boolean
): IFetchCarAvailability => ({
  type: actionTypes.FETCH_CAR_AVAILABILITY,
  requestType: CarsAvailabilityRequestEnum.InitialSearch,
  history,
  includeLocationLabels,
});

export const fetchMoreCarsAvailability = (
  history: H.History
): IFetchCarAvailability => ({
  type: actionTypes.FETCH_CAR_AVAILABILITY,
  requestType: CarsAvailabilityRequestEnum.FollowUpSearch,
  history,
});

export interface ISetCarAvailabilityResults {
  type: actionTypes.SET_CAR_AVAILABILITY_RESULTS;
  payload: ISetCarAvailabilityResultsPayload;
}

export type ISetCarAvailabilityResultsPayload = CarsAvailabilityResponse;

export const setCarAvailabilityResults = (args: {
  payload: ISetCarAvailabilityResultsPayload;
}): ISetCarAvailabilityResults => ({
  type: actionTypes.SET_CAR_AVAILABILITY_RESULTS,
  ...args,
});

export interface ISetCarAvailabilityCallStateFailed {
  type: actionTypes.SET_CAR_AVAILABILITY_CALL_STATE_FAILED;
}

export const setCarAvailabilityCallStateFailed =
  (): ISetCarAvailabilityCallStateFailed => ({
    type: actionTypes.SET_CAR_AVAILABILITY_CALL_STATE_FAILED,
  });

export interface IPopulateCarAvailabilityQueryParams {
  type: actionTypes.POPULATE_CAR_AVAILABILITY_QUERY_PARAMS;
  history: H.History;
}

export const populateCarAvailabilityQueryParams = (
  history: H.History
): IPopulateCarAvailabilityQueryParams => ({
  type: actionTypes.POPULATE_CAR_AVAILABILITY_QUERY_PARAMS,
  history,
});

export interface ISetCarTypeFilter {
  type: actionTypes.SET_CAR_TYPE_FILTER;
  carTypes: GeneralCarType[] | CarFilterTypeV5[];
}

export const setCarTypeFilter = (
  carTypes: GeneralCarType[] | CarFilterTypeV5[]
): ISetCarTypeFilter => {
  const properties: AppliedCarsFilterProperties = {
    filter_type: "car_type",
    car_type: carTypes.toString(),
  };
  trackEvent({
    eventName: APPLIED_CARS_FILTER,
    properties,
  });
  return {
    type: actionTypes.SET_CAR_TYPE_FILTER,
    carTypes,
  };
};

export interface ISetPassengersFilter {
  type: actionTypes.SET_PASSENGERS_FILTER;
  passengersSelected: PassengerFilter[];
}

export const setPassengersFilter = (
  passengersSelected: PassengerFilter[]
): ISetPassengersFilter => {
  const properties: AppliedCarsFilterProperties = {
    filter_type: "passengers",
    passengers: passengersSelected.toString(),
  };
  trackEvent({
    eventName: APPLIED_CARS_FILTER,
    properties,
  });
  return {
    type: actionTypes.SET_PASSENGERS_FILTER,
    passengersSelected,
  };
};

export interface ISetPriceRangeFilter {
  type: actionTypes.SET_PRICE_RANGE_FILTER;
  priceRange: IPriceRange | null;
}

export const setPriceRangeFilter = (
  priceRange: IPriceRange | null
): ISetPriceRangeFilter => {
  const properties: AppliedCarsFilterProperties = {
    filter_type: "price",
    price: priceRange?.max,
  };
  trackEvent({
    eventName: APPLIED_CARS_FILTER,
    properties,
  });
  return {
    type: actionTypes.SET_PRICE_RANGE_FILTER,
    priceRange,
  };
};

export interface ISetMaxTotalPriceFilter {
  type: actionTypes.SET_MAX_TOTAL_PRICE_FILTER;
  maxTotalPrice: number;
}

export const setMaxTotalPriceFilter = (
  maxTotalPrice: number
): ISetMaxTotalPriceFilter => {
  const properties: AppliedCarsFilterProperties = {
    filter_type: "price",
    price: maxTotalPrice,
  };
  trackEvent({
    eventName: APPLIED_CARS_FILTER,
    properties,
  });
  return {
    type: actionTypes.SET_MAX_TOTAL_PRICE_FILTER,
    maxTotalPrice,
  };
};

export interface ISetCarCompaniesFilter {
  type: actionTypes.SET_CAR_COMPANIES_FILTER;
  carCompanies: ICarCompaniesFilter;
}

export const setCarCompaniesFilter = (
  carCompanies: ICarCompaniesFilter
): ISetCarCompaniesFilter => {
  const properties: AppliedCarsFilterProperties = {
    filter_type: "rental_company",
    rental_company: carCompanies.toString(),
  };
  trackEvent({
    eventName: APPLIED_CARS_FILTER,
    properties,
  });
  return {
    type: actionTypes.SET_CAR_COMPANIES_FILTER,
    carCompanies,
  };
};

export interface ISetSpecificationsFilter {
  type: actionTypes.SET_SPECIFICATIONS_FILTER;
  specifications: ISpecifications;
}

export const setSpecificationsFilter = (
  specifications: ISpecifications
): ISetSpecificationsFilter => {
  const properties: AppliedCarsFilterProperties = {
    filter_type: "specifications",
  };
  trackEvent({
    eventName: APPLIED_CARS_FILTER,
    properties,
  });
  return {
    type: actionTypes.SET_SPECIFICATIONS_FILTER,
    specifications,
  };
};

export interface ISetCancellationPolicyFilter {
  type: actionTypes.SET_CANCELLATION_POLICY_FILTER;
  cancellation: ICancellationPolicy;
}

export const setCancellationPolicyFilter = (
  cancellation: ICancellationPolicy
): ISetCancellationPolicyFilter => {
  const properties: AppliedCarsFilterProperties = {
    filter_type: "cancellation_policy",
    cancellation: cancellation.toString(),
  };
  trackEvent({
    eventName: APPLIED_CARS_FILTER,
    properties,
  });
  return {
    type: actionTypes.SET_CANCELLATION_POLICY_FILTER,
    cancellation,
  };
};

export interface IResetFilters {
  type: actionTypes.RESET_CAR_AVAILABILITY_FILTERS;
}

export const resetFilters = (): IResetFilters => {
  const properties: AppliedCarsFilterProperties = {
    filter_type: "reset",
  };
  trackEvent({
    eventName: APPLIED_CARS_FILTER,
    properties,
  });
  return {
    type: actionTypes.RESET_CAR_AVAILABILITY_FILTERS,
  };
};

export interface IResetCallStates {
  type: actionTypes.RESET_CAR_AVAILABILITY_CALL_STATES;
}

export const resetCallStates = (): IResetCallStates => ({
  type: actionTypes.RESET_CAR_AVAILABILITY_CALL_STATES,
});

export interface ISetLocationSearched {
  type:
    | actionTypes.SET_DROP_OFF_LOCATION_SEARCHED
    | actionTypes.SET_PICK_UP_LOCATION_SEARCHED;
  location: CarsAutocompleteResult | null;
}

export const setPickUpLocationSearched = (
  location: CarsAutocompleteResult | null
): ISetLocationSearched => ({
  type: actionTypes.SET_PICK_UP_LOCATION_SEARCHED,
  location,
});

export const setDropOffLocationSearched = (
  location: CarsAutocompleteResult | null
): ISetLocationSearched => ({
  type: actionTypes.SET_DROP_OFF_LOCATION_SEARCHED,
  location,
});

export interface ISetDateSearched {
  type:
    | actionTypes.SET_DROP_OFF_DATE_SEARCHED
    | actionTypes.SET_PICK_UP_DATE_SEARCHED;
  date: Date | null;
}

export const setDropOffDateSearched = (
  date: Date | null
): ISetDateSearched => ({
  type: actionTypes.SET_DROP_OFF_DATE_SEARCHED,
  date,
});

export const setPickUpDateSearched = (date: Date | null): ISetDateSearched => ({
  type: actionTypes.SET_PICK_UP_DATE_SEARCHED,
  date,
});

export interface ISetTimeSearched {
  type:
    | actionTypes.SET_DROP_OFF_TIME_SEARCHED
    | actionTypes.SET_PICK_UP_TIME_SEARCHED;
  time: DateTime | null;
}

export const setDropOffTimeSearched = (
  time: DateTime | null
): ISetTimeSearched => ({
  type: actionTypes.SET_DROP_OFF_TIME_SEARCHED,
  time,
});

export const setPickUpTimeSearched = (
  time: DateTime | null
): ISetTimeSearched => ({
  type: actionTypes.SET_PICK_UP_TIME_SEARCHED,
  time,
});

export interface ISetOpenDatesModal {
  type: actionTypes.SET_OPEN_DATES_MODAL;
  openDatesModal: boolean;
}

export const setOpenDatesModal = (
  openDatesModal: boolean
): ISetOpenDatesModal => ({
  type: actionTypes.SET_OPEN_DATES_MODAL,
  openDatesModal,
});

export interface ISetCarAvailabilityEntryPoint {
  type: actionTypes.SET_CAR_AVAILABILITY_ENTRY_POINT;
  entryPoint?: CarEntryTypeEnum;
}

export const setCarAvailabilityEntryPoint = (
  entryPoint?: CarEntryTypeEnum
): ISetCarAvailabilityEntryPoint => ({
  type: actionTypes.SET_CAR_AVAILABILITY_ENTRY_POINT,
  entryPoint,
});

export interface ISetIsCarsCXV1Experiment {
  type: actionTypes.SET_IS_CARS_CX_V1_EXPERIMENTS;
  isEnabled?: boolean;
}

export const setIsCarsCXV1Experiment = (
  isEnabled?: boolean
): ISetIsCarsCXV1Experiment => ({
  type: actionTypes.SET_IS_CARS_CX_V1_EXPERIMENTS,
  isEnabled,
});
export interface ISetPickUpFilter {
  type: actionTypes.SET_PICKUP_FILTER;
  pickUpTypes: PickUpType[];
}

export const setPickupFilter = (
  pickUpTypes: PickUpType[]
): ISetPickUpFilter => {
  return {
    type: actionTypes.SET_PICKUP_FILTER,
    pickUpTypes,
  };
};

export interface ISetBagsFilter {
  type: actionTypes.SET_BAGS_FILTER;
  bags: number[];
}

export const setBagsFilter = (bags: number[]): ISetBagsFilter => {
  return {
    type: actionTypes.SET_BAGS_FILTER,
    bags,
  };
};

export const listPaymentMethods = (): IListPaymentMethods => ({
  type: actionTypes.LIST_PAYMENT_METHODS,
});

export interface IListPaymentMethods {
  type: actionTypes.LIST_PAYMENT_METHODS;
}

export const setPaymentMethods = (args: {
  paymentMethods: Payment[];
}): ISetPaymentMethods => ({
  type: actionTypes.SET_PAYMENT_METHODS,
  ...args,
});

export interface ISetPaymentMethods {
  type: actionTypes.SET_PAYMENT_METHODS;
  paymentMethods: Payment[];
}

export const setPaymentMethodsCallStateFailed =
  (): ISetPaymentMethodsCallStateFailed => ({
    type: actionTypes.SET_PAYMENT_METHODS_CALL_STATE_FAILED,
  });

export interface ISetPaymentMethodsCallStateFailed {
  type: actionTypes.SET_PAYMENT_METHODS_CALL_STATE_FAILED;
}

// Corporate
export interface ISetCarPolicyFilter {
  type: actionTypes.SET_POLICY_FILTER;
  isInPolicy: boolean;
}

export const setCarPolicyFilter = (isInPolicy: boolean) => {
  const properties: AppliedCarsFilterProperties = {
    filter_type: "policy_status",
  };

  trackEvent({
    eventName: APPLIED_CARS_FILTER,
    properties,
  });
  return {
    type: actionTypes.SET_POLICY_FILTER,
    isInPolicy,
  };
};

// Corporate Travel
export interface ISetCarAvailabilityRequest {
  type: actionTypes.SET_CAR_AVAILABILITY_REQUEST;
  payload: CarsAvailabilityRequest;
}

export const setCarAvailabilityRequest = (
  request: CarsAvailabilityRequest
) => ({ type: actionTypes.SET_CAR_AVAILABILITY_REQUEST, payload: request });

export type CarAvailabilityActions =
  | IFetchCarAvailability
  | ISetCarAvailabilityResults
  | ISetCarAvailabilityCallStateFailed
  | IPopulateCarAvailabilityQueryParams
  | ISetCarTypeFilter
  | ISetPassengersFilter
  | ISetPriceRangeFilter
  | ISetMaxTotalPriceFilter
  | ISetCarCompaniesFilter
  | ISetSpecificationsFilter
  | ISetCancellationPolicyFilter
  | IResetFilters
  | IResetCallStates
  | ISetLocationSearched
  | ISetDateSearched
  | ISetTimeSearched
  | ISetOpenDatesModal
  | ISetCarAvailabilityEntryPoint
  | ISetIsCarsCXV1Experiment
  | ISetBagsFilter
  | ISetCarPolicyFilter
  | ISetPickUpFilter
  | ISetCarAvailabilityRequest
  | IListPaymentMethods
  | ISetPaymentMethods
  | ISetPaymentMethodsCallStateFailed;
