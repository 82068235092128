import { select, put, call, putResolve } from "redux-saga/effects";
import {
  CarsAvailabilityRequest,
  CarsAvailabilityRequestEnum,
  CarsAvailabilityResponse,
  LocationQueryEnum,
  ILocationQueryLabel,
  CarsTripCategory,
  CarsAutocompleteResult,
  IdEnum,
  GroundPlace as Place,
  CARS_ENTRY,
  CarEntryTypeEnum,
  CarsEntryProperties,
  ITrackingProperties,
  Category,
  IIdFlight,
  RegionType,
} from "redmond";
import { getDateTimeWithFormat } from "halifax";
import queryStringParser from "query-string";
import * as H from "history";
import dayjs from "dayjs";

import {
  getExistingCarsAvailabilityRequestParameters,
  DEFAULT_DRIVER_AGE,
} from "../../search/reducer";
import {
  getCarAvailabilityNextPageToken,
  getCarsEntryProperties,
} from "../../availability/reducer";
import { IStoreState } from "../../../reducers/types";
import { fetchCarsAvailability } from "../../../api/v1/availability/fetchCarsAvailability";
import {
  fetchCarsLocationAutocomplete,
  ICarsAutocompleteResponseData,
} from "../../../api/v1/search/fetchCarsLocationAutocomplete";
import Logger from "../../../utils/logger";
import { ICarAvailabilityParsedQuery } from "../utils/queryStringHelpers";
import { PATH_HOME } from "../../../utils/paths";
import { actions as searchActions } from "../../search/actions";
import { actions as bookActions } from "../../book/actions";
import { actions } from "../actions";
import { isEqual } from "lodash-es";
import { trackEvent } from "../../../api/v1/analytics/trackEvent";

export interface CarRequestData {
  dropOffDate: Date;
  dropOffTime: string;
  dropOffLocation: any;
  pickUpDate: Date;
  pickUpTime: string;
  pickUpLocation: any;
  driverAge: number;
  pickUpLocationLabel?: string;
  dropOffLocationLabel?: string;
}

export const getTimeStamp = (date: Date, time: string) => {
  const timeDayjsObj = getDateTimeWithFormat(time, "h:mm A");

  return dayjs(date)
    .hour(timeDayjsObj.hour())
    .minute(timeDayjsObj.minute())
    .format("YYYY-MM-DDTHH:mm:ss");
};

export function* fetchCarsAvailabilitySaga(
  action: actions.IFetchCarAvailability
) {
  try {
    let requestBody: CarsAvailabilityRequest;
    const state: IStoreState = yield select();
    yield put(bookActions.redoSearch());

    switch (action.requestType) {
      case CarsAvailabilityRequestEnum.InitialSearch: {
        const requestData: CarRequestData = yield call(
          getCarsAvailabilityRequestParameters,
          action
        );
        const {
          dropOffDate,
          dropOffTime,
          dropOffLocation,
          pickUpDate,
          pickUpTime,
          pickUpLocation,
          driverAge,
        } = requestData;

        // If we received empty request data, we are redirecting and should not make the request.
        if (
          JSON.stringify(requestData) === "{}" ||
          isAnyFieldFalsy({
            pickUpDate,
            pickUpTime,
            dropOffDate,
            dropOffTime,
            driverAge,
            pickUpLocation,
            dropOffLocation,
          })
        ) {
          return;
        }

        requestBody = {
          AvailabilityRequest: CarsAvailabilityRequestEnum.InitialSearch,
          progressiveConfig: {},
          pickupSelection: pickUpLocation.id,
          dropOffSelection: dropOffLocation.id,
          pickupTime: getTimeStamp(pickUpDate, pickUpTime),
          dropOffTime: getTimeStamp(dropOffDate, dropOffTime),
          driverAge: driverAge,
          pickUpLocationLabel:
            action.includeLocationLabels && !!pickUpLocation?.label
              ? pickUpLocation?.label
              : undefined,
          dropOffLocationLabel:
            action.includeLocationLabels && !!dropOffLocation?.label
              ? dropOffLocation?.label
              : undefined,
        };
        const properties: ITrackingProperties<CarsEntryProperties> =
          yield select(getCarsEntryProperties);
        trackEvent({
          eventName: CARS_ENTRY,
          properties: {
            ...properties.properties,
          },
        });
        break;
      }
      case CarsAvailabilityRequestEnum.FollowUpSearch: {
        const nextPageToken = getCarAvailabilityNextPageToken(state);

        if (!nextPageToken) return;

        requestBody = {
          moreToken: nextPageToken,
          progressiveConfig: {},
          AvailabilityRequest: CarsAvailabilityRequestEnum.FollowUpSearch,
        };
        break;
      }
    }

    yield put(actions.setCarAvailabilityRequest(requestBody));

    const availabilityResponse: CarsAvailabilityResponse =
      yield fetchCarsAvailability(requestBody);

    yield put(
      actions.setCarAvailabilityResults({
        payload: availabilityResponse,
      })
    );
  } catch (e) {
    yield put(actions.setCarAvailabilityCallStateFailed());
    Logger.debug(e);
  }
}

export const isAnyFieldFalsy = ({
  dropOffDate,
  dropOffTime,
  dropOffLocation,
  pickUpDate,
  pickUpTime,
  pickUpLocation,
  driverAge,
}: any) =>
  !pickUpDate ||
  !pickUpTime ||
  !dropOffDate ||
  !dropOffTime ||
  !driverAge ||
  !pickUpLocation ||
  !dropOffLocation;

const parseQueryString = (history: H.History): ICarAvailabilityParsedQuery => {
  const queryString = history?.location?.search || "";

  const {
    dropOffDate,
    dropOffTime,
    dropOffLocation,
    pickUpDate,
    pickUpTime,
    pickUpLocation,
    driverAge,
    entryPoint,
  } = queryStringParser.parse(queryString);

  return {
    dropOffDate: dayjs(dropOffDate as string).toDate(),
    dropOffTime: dropOffTime as string,
    dropOffLocation: dropOffLocation as string,
    pickUpDate: dayjs(pickUpDate as string).toDate(),
    pickUpTime: pickUpTime as string,
    pickUpLocation: pickUpLocation as string,
    driverAge: parseInt(driverAge as string),
    entryPoint: entryPoint as CarEntryTypeEnum,
  };
};

function* getCarsAvailabilityRequestParameters(
  fetchCarAvailability: actions.IFetchCarAvailability
) {
  const state: IStoreState = yield select();

  let {
    dropOffDate,
    dropOffTime,
    dropOffLocation,
    pickUpDate,
    pickUpTime,
    pickUpLocation,
    driverAge,
  } = getExistingCarsAvailabilityRequestParameters(state);
  const history = fetchCarAvailability.history;
  const parsedQueryString = parseQueryString(history);
  let pickUpLocationToSearch: CarsAutocompleteResult | null = pickUpLocation;
  let dropOffLocationToSearch: CarsAutocompleteResult | null = dropOffLocation;

  if (
    !pickUpLocation ||
    !dropOffLocation ||
    (pickUpLocation.id.Id === IdEnum.Flight
      ? parsedQueryString.pickUpLocation !== pickUpLocation.id.code.code
      : parsedQueryString.pickUpLocation !==
        (pickUpLocation.id.groundSelection as Place).searchTerm) ||
    (dropOffLocation.id.Id === IdEnum.Flight
      ? parsedQueryString.dropOffLocation !== dropOffLocation.id.code.code
      : parsedQueryString.dropOffLocation !==
        (dropOffLocation.id.groundSelection as Place).searchTerm)
  ) {
    const { correspondingLocation: correspondingDropOff } = yield call(
      fetchLocation,
      parsedQueryString.dropOffLocation
    );
    dropOffLocationToSearch = correspondingDropOff;

    const { correspondingLocation: correspondingPickUp } = yield call(
      fetchLocation,
      parsedQueryString.pickUpLocation
    );
    pickUpLocationToSearch = correspondingPickUp;
  }

  [
    dropOffDate,
    dropOffTime,
    dropOffLocation,
    pickUpDate,
    pickUpTime,
    pickUpLocation,
    driverAge,
  ] = [
    parsedQueryString.dropOffDate,
    parsedQueryString.dropOffTime,
    dropOffLocationToSearch,
    parsedQueryString.pickUpDate,
    parsedQueryString.pickUpTime,
    pickUpLocationToSearch,
    parsedQueryString.driverAge || DEFAULT_DRIVER_AGE,
  ];

  // If we know we are missing the data we should redirect the user home rather than make a bad request.
  // TODO: this should probably be monitored in the future. If we get a lot of these something is likely wrong.
  if (
    isAnyFieldFalsy({
      pickUpDate,
      pickUpTime,
      dropOffDate,
      dropOffTime,
      driverAge,
      pickUpLocation,
      dropOffLocation,
    })
  ) {
    fetchCarAvailability.history.push(PATH_HOME);
    return {};
  }

  yield putResolve(searchActions.setPickUpDate(pickUpDate));
  yield putResolve(searchActions.setPickUpTime(pickUpTime));
  yield putResolve(searchActions.setDropOffDate(dropOffDate));
  yield putResolve(searchActions.setDropOffTime(dropOffTime));
  yield putResolve(searchActions.setDriverAge(driverAge));
  yield putResolve(searchActions.setPickUpLocation(pickUpLocation));
  yield putResolve(searchActions.setDropOffLocation(dropOffLocation));
  if (parsedQueryString.entryPoint) {
    yield putResolve(
      actions.setCarAvailabilityEntryPoint(parsedQueryString.entryPoint)
    );
  }
  yield put(
    searchActions.setTripCategory(
      isEqual(pickUpLocation, dropOffLocation)
        ? CarsTripCategory.SAME_AS_DROP_OFF
        : CarsTripCategory.DIFFERENT_DROP_OFF
    )
  );

  yield put(actions.setPickUpDateSearched(pickUpDate));
  yield put(actions.setPickUpTimeSearched(pickUpTime));
  yield put(actions.setDropOffDateSearched(dropOffDate));
  yield put(actions.setDropOffTimeSearched(dropOffTime));
  yield put(actions.setPickUpLocationSearched(pickUpLocation));
  yield put(actions.setDropOffLocationSearched(dropOffLocation));

  return {
    dropOffDate,
    dropOffTime,
    dropOffLocation,
    pickUpDate,
    pickUpTime,
    pickUpLocation,
    driverAge,
  };
}

function* fetchLocation(locationName: string) {
  if (!locationName) {
    return { correspondingLocation: undefined };
  }

  const locationRequestBody: ILocationQueryLabel = {
    LocationQuery: LocationQueryEnum.Label,
    l: locationName,
  };

  const responseData: ICarsAutocompleteResponseData = yield call(
    fetchCarsLocationAutocomplete,
    locationRequestBody
  );
  const correspondingLocation = responseData.categories.flatMap((category) =>
    category.results.find((result) => {
      if (
        category.label === Category.Airports &&
        (result.id as IIdFlight).code.regionType === RegionType.Airport
      ) {
        return (result.id as IIdFlight).code.code === locationName; // This can return undefined like if we were to search `Madrid, Spain`. We get back airports + places so we will get undefined when mapping through the airports category
      } else {
        return result.label
          .toLowerCase()
          .includes(locationName.split(",")[0].toLowerCase());
      }
    })
  );

  return {
    correspondingLocation: correspondingLocation.filter(
      // This filters out the undefined returned in the flatMap
      (location) => !!location
    )[0],
  };
}
