import React, { useContext, useEffect, useState } from "react";
import { Box, Typography } from "@material-ui/core";
import dayjs from "dayjs";
import {
  CarDetailsCard,
  Header,
  ActionButton,
  Icon,
  IconName,
  OutOfPolicyModal,
} from "halifax";
import { RouteComponentProps } from "react-router-dom";
import { ModalScreens, SelectedTravelOfferScreen } from "redmond";
import {
  getClickCancelOOPModalEvent,
  getClickContinueOOPModalEvent,
  getShowOOPModalEvent,
  isCorpTenant,
} from "@capone/common";

import { CarShopSmallMap } from "../CarShopSmallMap/component";
import { CarPolicyCard } from "../CarPolicyCard/component";
import { CarPaymentSection } from "../CarPaymentSection/component";
import { RESERVE } from "../../components/MobileShop/constants";
import { CarShopDetailsConnectorProps } from "./container";
import { RewardsAccountSelection } from "../../../rewards/components";
import {
  BASE_PATH_HOME,
  PATH_AVAILABILITY,
  PATH_BOOK,
  PATH_HOME,
} from "../../../../utils/paths";
import {
  transformToStringifiedAvailabilityQuery,
  transformToStringifiedShopQuery,
} from "../../../availability/utils/queryStringHelpers";
import * as constants from "./constants";
import "./styles.scss";
import { DEFAULT_DRIVER_AGE } from "../../../search/reducer";
import {
  TravelWalletDetailsBanner,
  TravelWalletDrawer,
} from "../../../travel-wallet/components";
import {
  useExperiments,
  getExperimentVariant,
  TRAVEL_WALLET_OFFER_EXPERIMENT,
  AVAILABLE,
  TRAVEL_WALLET_CREDITS_EXPERIMENT,
  CARS_CX_V1,
} from "../../../../context/experiments";
import clsx from "clsx";
import { getCancellationPolicyInfo } from "../../utils/getCancellationPolicyInfo";
import { config } from "../../../../api/config";
import { trackEvent } from "../../../../api/v1/analytics/trackEvent";
import { ClientContext } from "../../../../App";
import { ICorpCarAvailabilityLineItem } from "../../../availability/reducer/utils/carAvailabilityHelperFunctions";
import { useExperimentIsVariant } from "@capone/experiments";

export interface ICarShopDetailsProps
  extends CarShopDetailsConnectorProps,
    RouteComponentProps {
  hideBanner?: boolean;
  hidePaymentSection?: boolean;
  isMobile?: boolean;
}

export const CarShopDetails = (props: ICarShopDetailsProps) => {
  const {
    dropOffDate,
    dropOffTime,
    dropOffLocation,
    hideBanner,
    hidePaymentSection,
    pickUpDate,
    pickUpTime,
    pickUpLocation,
    driverAge,
    history,
    carShopError,
    carShopCallInProgress,
    selectedVehicle,
    carShopQueryParams,
    extraInfo,
    resetBookState,
    largestValueAccount,
    credit,
    canEarnRewards,
  } = props;

  const expState = useExperiments();

  const { policies, isAutoApprovalEnabled } = useContext(ClientContext);
  const [showPolicyModal, setShowPolicyModal] = useState(false);

  const maxPricePerDay =
    policies?.cars.policies[0]?.maxPricePerDay ?? undefined;

  const travelWalletOffer = getExperimentVariant(
    expState.experiments,
    TRAVEL_WALLET_OFFER_EXPERIMENT
  );
  const isTravelWalletOfferExperiment = React.useMemo(
    () => travelWalletOffer === AVAILABLE,
    [travelWalletOffer]
  );

  const travelWalletCreditsExperiment = getExperimentVariant(
    expState.experiments,
    TRAVEL_WALLET_CREDITS_EXPERIMENT
  );
  const isTravelWalletCreditsExperiment = React.useMemo(
    () => travelWalletCreditsExperiment === AVAILABLE,
    [travelWalletCreditsExperiment]
  );

  const isApprovalsV2Enabled = useExperimentIsVariant(
    "corp-approvals-v2",
    "m2"
  );

  const outOfPolicyCopy =
    isAutoApprovalEnabled || isApprovalsV2Enabled
      ? "If you wish to proceed with your selection, admins will be notified upon booking that this car was out of policy."
      : "If you wish to proceed with your selection, approval will be required to reserve this car.";

  const isCorporate = isCorpTenant(config.TENANT);

  const carsCXV1Experiment = getExperimentVariant(
    expState.experiments,
    CARS_CX_V1
  );
  const isCarsCXV1Experiment = React.useMemo(
    () => carsCXV1Experiment === AVAILABLE,
    [carsCXV1Experiment]
  );

  const showEarnEnhancement =
    !!largestValueAccount &&
    !!largestValueAccount.earn.carsMultiplier &&
    canEarnRewards;

  const returnToAvailability = () => {
    if (!selectedVehicle) {
      return history.push(PATH_HOME);
    }

    // TODO: This needs to include state to be accurate, we don't have it.
    const search = transformToStringifiedAvailabilityQuery(
      dropOffDate!,
      dropOffTime!,
      dropOffLocation!,
      pickUpDate!,
      pickUpTime!,
      pickUpLocation!,
      driverAge!
    );

    return history.push(`${PATH_AVAILABILITY}${search}`);
  };

  const renderSkeleton = () => (
    <Box className="desktop-car-shop-skeleton">
      <Box className="car-details-card-wrapper">
        <CarDetailsCard type="skeleton" />
      </Box>
    </Box>
  );

  const [showOffer, setShowOffer] = useState(false);
  useEffect(() => {
    setShowOffer(
      (isTravelWalletOfferExperiment &&
        selectedVehicle?.offer &&
        !carShopCallInProgress &&
        (credit &&
        Math.abs(credit.amount.amount) ===
          Math.abs(selectedVehicle?.offer.amount.amount)
          ? true
          : !!selectedVehicle?.offer.shopPageBanner)) ||
        false
    );
  }, [selectedVehicle, credit]);

  const corporateTravelData = (selectedVehicle as ICorpCarAvailabilityLineItem)
    ?.corporateTravel;

  const onReserveCar = () => {
    if (
      isCorporate &&
      !(corporateTravelData?.policyCompliance.isInPolicy ?? true) &&
      !showPolicyModal
    ) {
      setShowPolicyModal(true);
      trackEvent(
        getShowOOPModalEvent(ModalScreens.CARS_AVAILABILITY, "cars", modalType)
      );
      return;
    }

    resetBookState();
    if (selectedVehicle) {
      const id = selectedVehicle.id!;
      const params = transformToStringifiedShopQuery({
        vehicleId: id,
        ...carShopQueryParams,
      });

      history.push(`${PATH_BOOK}${params}`);
    } else {
      history.push(`${BASE_PATH_HOME}`);
    }
  };

  const modalType = isAutoApprovalEnabled
    ? "out_of_policy_auto"
    : isApprovalsV2Enabled
    ? "out_of_policy_24hr_review"
    : "out_of_policy";

  const onContinueClick = () => {
    setShowPolicyModal(false);
    onReserveCar();
    trackEvent(
      getClickContinueOOPModalEvent(
        ModalScreens.CARS_AVAILABILITY,
        "cars",
        modalType
      )
    );
  };

  const renderShop = () => (
    <Box
      className={clsx("desktop-car-shop-container", config.TENANT, {
        "has-offer": showOffer,
        "cars-cx-v1": isCarsCXV1Experiment,
      })}
    >
      <Box className="left-content">
        <Box className="car-details-card-wrapper">
          {selectedVehicle && (
            <CarDetailsCard
              {...selectedVehicle}
              type="content"
              isMobile={false}
              className="car-shop-car-details"
              earnTagContent={
                showEarnEnhancement ? (
                  <>
                    <Icon name={IconName.StarIcon} />
                    <Typography
                      className="earn-tag-text"
                      dangerouslySetInnerHTML={{
                        __html: constants.getEarnTagText(
                          largestValueAccount.earn.carsMultiplier,
                          largestValueAccount.rewardsBalance
                            .currencyDescription ??
                            largestValueAccount.rewardsBalance.currency
                        ),
                      }}
                    />
                  </>
                ) : undefined
              }
              earnTagClassName={showEarnEnhancement ? "b2b" : undefined}
              isCarsCXV1Experiment={isCarsCXV1Experiment}
            />
          )}
        </Box>
        <Box className="car-shop-map-container">
          {selectedVehicle && (
            <CarShopSmallMap
              vendorMessages={extraInfo?.vendorMessages}
              selectedVehicle={selectedVehicle}
            />
          )}
        </Box>
        <CarPolicyCard
          title={constants.CANCELLATION_POLICY}
          description={getCancellationPolicyInfo(selectedVehicle)}
        />
        {/* [CMKT-183] Remove Collision Damage Insurance since it is coming back incorrect from Priceline */}
        <CarPolicyCard
          title={constants.IMPORTANT_INFORMATION}
          details={extraInfo?.vendorMessages?.importantInfo?.details.filter(
            (d) => d.title && !d.title?.includes("Collision Damage Insurance")
          )}
        />
        <CarPolicyCard
          title={constants.BOOKING_CONDITIONS}
          details={extraInfo?.vendorMessages?.bookingConditions?.details}
        />
      </Box>
      {!hidePaymentSection && (
        <Box className="right-content">
          <CarPaymentSection
            message={RESERVE(selectedVehicle)}
            driverAge={driverAge || DEFAULT_DRIVER_AGE}
            onClick={onReserveCar}
            corporateTravel={corporateTravelData}
            limit={maxPricePerDay}
          />
        </Box>
      )}
      {isCorporate && (
        <OutOfPolicyModal
          subtitle={outOfPolicyCopy}
          isMobile={false}
          isOpen={showPolicyModal}
          onClose={() => {
            setShowPolicyModal(false);
            trackEvent(
              getClickCancelOOPModalEvent(
                ModalScreens.CARS_AVAILABILITY,
                "cars",
                modalType
              )
            );
          }}
          onContinue={onContinueClick}
          isApprovalRequired={
            policies?.settings && policies.settings.isApprovalRequired
          }
        />
      )}
    </Box>
  );

  return !carShopError ? (
    <Box className="desktop-car-shop-wrapper">
      {!hideBanner && (
        <Header
          className="desktop-car-shop-rewards-header-container"
          left={
            <Box className="desktop-car-shop-basic-info-contents">
              <ActionButton
                className="back-to-results"
                message={constants.BACK_TO_RESULTS}
                onClick={() => {
                  returnToAvailability();
                }}
                defaultStyle="h4r-secondary"
              />
              <Box className="desktop-car-shop-header-details">
                <Typography className="locations-section" variant="body1">
                  {constants.REVIEW_CAR_RENTAL}
                </Typography>
                <Typography className="dates-section" variant="body2">
                  {pickUpDate &&
                    dropOffDate &&
                    `${dayjs(pickUpDate).format("ddd, MMM DD")} - ${dayjs(
                      dropOffDate
                    ).format("ddd, MMM DD")}`}
                </Typography>
              </Box>
            </Box>
          }
          right={
            <Box className="desktop-car-shop-rewards-account-contents">
              <RewardsAccountSelection
                className={clsx("b2b", {
                  "hide-balance-border": isTravelWalletCreditsExperiment,
                })}
                popoverClassName="b2b"
              />
              {isTravelWalletCreditsExperiment && <TravelWalletDrawer />}
            </Box>
          }
        />
      )}
      {showOffer && (
        <TravelWalletDetailsBanner
          onDismiss={() => setShowOffer(false)}
          offer={selectedVehicle?.offer!}
          variant="full-width"
          showButton
          screen={SelectedTravelOfferScreen.CARS_REVIEW}
          bannerTextType="shop"
        />
      )}
      {!selectedVehicle || carShopCallInProgress
        ? renderSkeleton()
        : renderShop()}
    </Box>
  ) : (
    <p>error</p>
    // <ShopErrorModal />
  );
};
