import { connect, ConnectedProps } from "react-redux";
import { withRouter } from "react-router";
import { setHertzLoyaltyRewardsNumber } from "../../actions/actions";
import { IStoreState } from "../../../../reducers/types";
import { HertzLoyaltyForm } from "./component";
import {
  getConfirmationDetailsError,
  getHertzLoyaltyRewardsNumber,
  getPriceQuoteErrors,
  getUseV1PurchaseFlow,
} from "../../reducer";
import { getCarShopSelectedVehicle } from "../../../shop/reducer";

const mapStateToProps = (state: IStoreState) => ({
  hertzLoyaltyRewardsNumber: getHertzLoyaltyRewardsNumber(state),
  priceQuoteErrors: getPriceQuoteErrors(state),
  confirmationDetailsErrors: getConfirmationDetailsError(state),
  selectedVehicle: getCarShopSelectedVehicle(state),
  useV1PurchaseFlow: getUseV1PurchaseFlow(state),
});

const mapDispatchToProps = {
  setHertzLoyaltyRewardsNumber,
};

const connector = connect(mapStateToProps, mapDispatchToProps);

export type HertzLoyaltyFormConnectorProps = ConnectedProps<typeof connector>;

export const ConnectedHertzLoyaltyForm = withRouter(
  connector(HertzLoyaltyForm)
);
