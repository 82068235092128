import { delay, putResolve, select } from "redux-saga/effects";
import { pollFinalized } from "../../../../api/v1/book/book-flow/pollFinalized";
import { IStoreState } from "../../../../reducers/types";
import {
  getCompleteBuyCarProperties,
  getSession,
  getUseV1PurchaseFlow,
  getv1FulfillSession,
} from "../../reducer";
import {
  GetPaymentFailed,
  GetPaymentPending,
  GetPaymentResultEnum,
  CarPaymentFinalizeResult,
  CarPaymentFinalizeResultSucceeded,
  COMPLETE_BUY_CARS,
  CompleteBuyCarProperties,
  ITrackingProperties,
  PaymentError as LegacyPaymentError,
} from "redmond";
import {
  setPollConfirmationDetailsCallStateFailure,
  setPollConfirmationDetailsCallStateSuccess,
  setConfirmationDetails,
  IPollConfirmationDetails,
} from "../../actions/actions";
import { trackEvent } from "../../../../api/v1/analytics/trackEvent";
import {
  ErrorCode,
  PaymentError,
  ProductError,
  Product,
  PurchaseError,
  PurchaseErrorEnum,
  FulfillResponse,
  FulfillResponseEnum,
  FulfillFailure,
  FulfillSuccess,
} from "@b2bportal/purchase-api";
import { pollFinalizedV1 } from "../../../../api/v2/book/book-flow/pollFinalized";

const toLegacyErrorString = (error: LegacyPaymentError): string => {
  return (
    error.code ||
    error.message ||
    error.msg ||
    error.PaymentError ||
    JSON.stringify(error)
  );
};

const toErrorString = (error: PurchaseError): string => {
  switch (error.Error) {
    case PurchaseErrorEnum.ErrorCode:
      const e = error as ErrorCode;
      return `${e.code}${e.message ? ` - ${e.message}` : ""}`;
    case PurchaseErrorEnum.PaymentError:
      const paymentError = error as PaymentError;
      return toLegacyErrorString(
        paymentError.value.value as LegacyPaymentError
      );
    case PurchaseErrorEnum.ProductError:
      const purchaseError = error as ProductError;
      return toLegacyErrorString(
        purchaseError.value.value as LegacyPaymentError
      );
    default:
      return PurchaseErrorEnum[error.Error];
  }
};

export function* pollConfirmationDetailsSaga({
  agentFee,
}: IPollConfirmationDetails) {
  try {
    const state: IStoreState = yield select();
    const sessionToken = getSession(state);
    const v1FulfillSessionToken = getv1FulfillSession(state);
    const version = getUseV1PurchaseFlow(state) ? "v1" : "v0";

    const completeBuyCarProperties = getCompleteBuyCarProperties(state);

    if (!sessionToken || (version === "v1" && !v1FulfillSessionToken)) {
      throw new Error("Session token is not present.");
    }

    const delayTimes = [1000];
    let pollFailed = false;
    let index = 0;

    while (!pollFailed) {
      yield delay(delayTimes[index]);
      if (version === "v0") {
        const finalizedCheckedResponse: GetPaymentPending = yield pollFinalized(
          sessionToken
        ).catch((e) => {
          // [CMKT-1150] do not error out on network errors, instead retry
          if (e.response.status !== 200) {
            return Promise.resolve({
              data: { result: GetPaymentResultEnum.Pending },
              status: 200,
              statusText: "Gateway timeout",
              headers: [],
              config: {},
            });
          }
          return Promise.reject(e);
        });

        switch (finalizedCheckedResponse.result) {
          case GetPaymentResultEnum.Failed:
            pollFailed = true;
            const failedResponse = finalizedCheckedResponse as GetPaymentFailed;
            trackEvent({
              eventName: COMPLETE_BUY_CARS,
              properties: {
                ...completeBuyCarProperties.properties,
                success: false,
                agent_booking_fee_amount_usd: agentFee,
                failure_reason:
                  failedResponse.errors.length > 0
                    ? failedResponse.errors
                        .map((error) => {
                          return toLegacyErrorString(error);
                        })
                        .join(" - ")
                    : "Poll Confirmation Details response returned an error and the given error code is not handleable.",
              },
              encryptedProperties: [
                ...completeBuyCarProperties.encryptedProperties,
              ],
            });
            if (failedResponse.errors.length > 0) {
              yield putResolve(
                setPollConfirmationDetailsCallStateFailure(
                  failedResponse.errors
                )
              );
              return;
            } else {
              yield putResolve(setPollConfirmationDetailsCallStateFailure([]));
              throw new Error(
                "Price quote checked response returned an error and the given error code is not handleable."
              );
            }
          case GetPaymentResultEnum.Pending:
            const priceQuoteResponsePoll =
              finalizedCheckedResponse as GetPaymentPending;
            switch (priceQuoteResponsePoll.result) {
              case GetPaymentResultEnum.Failed:
                pollFailed = true;
                yield putResolve(
                  setPollConfirmationDetailsCallStateFailure([])
                );
                break;
              default:
                continue;
            }
            break;
          case GetPaymentResultEnum.Succeeded:
            const finalizedResponse =
              finalizedCheckedResponse as CarPaymentFinalizeResultSucceeded;
            switch (finalizedResponse.result) {
              case GetPaymentResultEnum.Succeeded:
                yield putResolve(
                  setConfirmationDetails(
                    (finalizedResponse as CarPaymentFinalizeResult).booking
                  )
                );
                yield putResolve(setPollConfirmationDetailsCallStateSuccess());
                trackEvent({
                  eventName: COMPLETE_BUY_CARS,
                  properties: {
                    ...completeBuyCarProperties.properties,
                    success: true,
                    agent_booking_fee_amount_usd: agentFee,
                    ground_booking_id: (
                      finalizedResponse as CarPaymentFinalizeResult
                    ).booking.groundBookingId,
                    ...(finalizedResponse as CarPaymentFinalizeResult).booking
                      .trackingPropertiesV2?.properties,
                  },
                  encryptedProperties: [
                    ...completeBuyCarProperties.encryptedProperties,
                    (finalizedResponse as CarPaymentFinalizeResult).booking
                      .trackingPropertiesV2?.encryptedProperties ?? "",
                  ],
                });
                return;
              default:
                pollFailed = true;
                yield putResolve(
                  setPollConfirmationDetailsCallStateFailure([])
                );
                trackEvent({
                  eventName: COMPLETE_BUY_CARS,
                  properties: {
                    ...completeBuyCarProperties,
                    success: false,
                    agent_booking_fee_amount_usd: agentFee,
                    failure_reason:
                      "Poll Confirmation Details Success Response Failed",
                  },
                });
                throw new Error("Poll Finalized Failed");
            }
        }
      } else {
        if (!v1FulfillSessionToken) {
          throw new Error("Session token is not present.");
        }

        const finalizedCheckedResponse: FulfillResponse = yield pollFinalizedV1(
          { req: v1FulfillSessionToken }
        ).catch((e) => {
          // [CMKT-1150] do not error out on network errors, instead retry
          if (e.response.status !== 200) {
            return Promise.resolve({
              data: { result: FulfillResponseEnum.Pending },
              status: 200,
              statusText: "Gateway timeout",
              headers: [],
              config: {},
            });
          }
          return Promise.reject(e);
        });
        switch (finalizedCheckedResponse.FulfillResponse) {
          case FulfillResponseEnum.Failure:
            pollFailed = true;
            const failedResponse = finalizedCheckedResponse as FulfillFailure;
            trackEvent({
              eventName: COMPLETE_BUY_CARS,
              properties: {
                ...completeBuyCarProperties.properties,
                success: false,
                agent_booking_fee_amount_usd: agentFee,
                failure_reason:
                  failedResponse.errors.length > 0
                    ? failedResponse.errors
                        .map((error) => toErrorString(error))
                        .join(" - ")
                    : "Poll Confirmation Details response returned an error and the given error code is not handleable.",
              },
              encryptedProperties: [
                ...completeBuyCarProperties.encryptedProperties,
              ],
            });
            if (failedResponse.errors.length > 0) {
              yield putResolve(
                setPollConfirmationDetailsCallStateFailure(
                  failedResponse.errors
                )
              );
              return;
            } else {
              yield putResolve(setPollConfirmationDetailsCallStateFailure([]));
              throw new Error(
                "Price quote checked response returned an error and the given error code is not handleable."
              );
            }
          case FulfillResponseEnum.Pending:
            break;
          case FulfillResponseEnum.Success:
            const finalizedResponse =
              finalizedCheckedResponse as FulfillSuccess;
            const product = finalizedResponse.products.find(
              (product) => product.type === Product.Ground
            );
            yield putResolve(setConfirmationDetails(product?.value));
            yield putResolve(setPollConfirmationDetailsCallStateSuccess());
            trackEvent({
              eventName: COMPLETE_BUY_CARS,
              properties: {
                ...completeBuyCarProperties.properties,
                success: true,
                agent_booking_fee_amount_usd: agentFee,
                ground_booking_id: (product?.value).groundBookingId,
                ...product?.value.trackingPropertiesV2?.properties,
              },
              encryptedProperties: [
                ...completeBuyCarProperties.encryptedProperties,
                product?.value.trackingPropertiesV2?.encryptedProperties ?? "",
              ],
            });
            return;
          default:
            pollFailed = true;
            yield putResolve(setPollConfirmationDetailsCallStateFailure([]));
            trackEvent({
              eventName: COMPLETE_BUY_CARS,
              properties: {
                ...completeBuyCarProperties,
                success: false,
                agent_booking_fee_amount_usd: agentFee,
                failure_reason:
                  "Poll Confirmation Details Success Response Failed",
              },
            });
            throw new Error("Poll Finalized Failed");
        }
      }
      // if we want to give up on polling we should instead direct the user to my trips
      if (index >= delayTimes.length) {
        window.location.pathname = "/trips";
      }

      if (index !== delayTimes.length - 1) {
        index++;
      }
    }
  } catch (e) {
    const completeBuyCarProperties: ITrackingProperties<CompleteBuyCarProperties> =
      yield select(getCompleteBuyCarProperties);

    trackEvent({
      eventName: COMPLETE_BUY_CARS,
      properties: {
        ...completeBuyCarProperties.properties,
        success: true,
        agent_booking_fee_amount_usd: agentFee,
        failure_reason: "Poll Confirmation Details Network Call Failed",
      },
      encryptedProperties: [...completeBuyCarProperties.encryptedProperties],
    });
    yield putResolve(setPollConfirmationDetailsCallStateFailure([]));
  }
}
