import { LocationQueryEnum, ILocationQueryLabel } from "redmond";
import { delay, put } from "redux-saga/effects";
import { fetchCarsLocationAutocomplete } from "../../../api/v1/search/fetchCarsLocationAutocomplete";
import Logger from "../../../utils/logger";
import { actions, actionTypes } from "../actions";

export function* fetchCarsLocationAutocompleteSaga(
  action: actions.IFetchLocationResults
) {
  const { type } = action;
  yield delay(300);
  try {
    const requestBody: ILocationQueryLabel = {
      LocationQuery: LocationQueryEnum.Label,
      l: action.queryString,
    };

    const { categories: results } = yield fetchCarsLocationAutocomplete(
      requestBody
    );
    if (type === actionTypes.FETCH_DROP_OFF_LOCATION_RESULTS) {
      yield put(actions.setDropOffLocationResults(results));
    }
    if (type === actionTypes.FETCH_PICK_UP_LOCATION_RESULTS) {
      yield put(actions.setPickUpLocationResults(results));
    }
  } catch (e) {
    Logger.debug(e);
    if (type === actionTypes.FETCH_DROP_OFF_LOCATION_RESULTS) {
      yield put(actions.setDropOffLocationResults([]));
      yield put(actions.setDropOffAutocompleteError(true));
    }
    if (type === actionTypes.FETCH_PICK_UP_LOCATION_RESULTS) {
      yield put(actions.setPickUpLocationResults([]));
      yield put(actions.setPickUpAutocompleteError(true));
    }
  }
}
