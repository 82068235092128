import { CarShopResponse } from "redmond";
import {
  ICarAvailabilityLineItem,
  ICorpCarAvailabilityLineItem,
} from "../../availability/reducer/utils/carAvailabilityHelperFunctions";
import { actions } from "../actions";
import {
  FETCH_CAR_EXTRA_INFO,
  SET_CAR_EXTRA_INFO_RESULTS,
  SELECT_SELECTED_VEHICLE_ID,
  ACKNOWLEDGE_CAR_EXTRA_INFO_FAILURE,
  SET_CAR_EXTRA_INFO_CALL_STATE_FAILED,
  SET_SELECTED_VEHICLE,
} from "../actions/constants";

export interface ICarShopState {
  selectedVehicleId: string | null;
  carExtraInfoCallState: CarExtraInfoCallState;
  carExtraInfoCallError: CarExtraInfoCallError | null;
  selectedVehicle:
    | ICarAvailabilityLineItem
    | ICorpCarAvailabilityLineItem
    | null;
  extraInfo: CarShopResponse | null;
}

export enum CarExtraInfoCallState {
  NotCalled = "NotCalled",
  InProcess = "InProcess",
  Success = "Success",
  Failed = "Failed",
  FailureAcknowledged = "FailureAcknowledged",
}

export enum CarExtraInfoCallError {
  NoAvailability = "NoAvailability",
  Unknown = "Unknown",
}

const initialState: ICarShopState = {
  selectedVehicleId: null,
  extraInfo: null,
  carExtraInfoCallState: CarExtraInfoCallState.NotCalled,
  carExtraInfoCallError: null,
  selectedVehicle: null,
};

export const reducer = (
  state: ICarShopState = initialState,
  action: actions.CarShopActions
): ICarShopState => {
  switch (action.type) {
    case FETCH_CAR_EXTRA_INFO:
      return {
        ...state,
        carExtraInfoCallState: CarExtraInfoCallState.InProcess,
        carExtraInfoCallError: null,
      };

    case SET_CAR_EXTRA_INFO_RESULTS:
      return {
        ...state,
        carExtraInfoCallState: action.carExtraInfoCallState,
        carExtraInfoCallError: action.carExtraInfoCallError,
        extraInfo: action.extraInfo || null,
      };

    case SET_CAR_EXTRA_INFO_CALL_STATE_FAILED:
      return {
        ...state,
        carExtraInfoCallState: CarExtraInfoCallState.Failed,
        carExtraInfoCallError: action.carExtraInfoCallError,
      };

    case SELECT_SELECTED_VEHICLE_ID:
      return {
        ...state,
        selectedVehicleId: action.id,
      };

    case SET_SELECTED_VEHICLE:
      return {
        ...state,
        selectedVehicle: action.payload,
      };

    case ACKNOWLEDGE_CAR_EXTRA_INFO_FAILURE:
      return {
        ...state,
        carExtraInfoCallState: CarExtraInfoCallState.FailureAcknowledged,
      };

    default:
      return { ...state };
  }
};

export * from "./selectors";
