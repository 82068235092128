import axios from "axios";
import { config } from "../../../config";

import { carBookApiPrefix } from "../../paths";
import {
  PaymentSplitRequest,
  ConnectionResult,
  PaymentSplitRequestV2,
} from "redmond";

const END_POINT: string = `${carBookApiPrefix}/payment/schedule`;

export const schedulePayment = (
  req: PaymentSplitRequest | PaymentSplitRequestV2
): Promise<ConnectionResult> =>
  new Promise(async (resolve, reject) => {
    try {
      const res = await axios.post(END_POINT, req, {
        baseURL: config.baseURL,
      });

      const responseBody: ConnectionResult = res.data;
      resolve(responseBody);
    } catch (e) {
      reject(e);
    }
  });
