import { connect, ConnectedProps } from "react-redux";
import { IStoreState } from "../../../../reducers/types";
import { PriceBreakdown } from "./component";
import {
  getPollPriceQuoteCallState,
  getCarPricingLineItems,
  getCarRewardsAndTotalLineItems,
  getCarType,
} from "../../reducer";

const mapStateToProps = (state: IStoreState) => {
  return {
    carType: getCarType(state),
    pollPriceQuoteCallState: getPollPriceQuoteCallState(state),
    pricingLineItems: getCarPricingLineItems(state),
    rewardsAndTotalLineItems: getCarRewardsAndTotalLineItems(state),
  };
};

const mapDispatchToProps = {};

const connector = connect(mapStateToProps, mapDispatchToProps);

export type PriceBreakdownConnectorProps = ConnectedProps<typeof connector>;

export const ConnectedPriceBreakdown = connector(PriceBreakdown);
