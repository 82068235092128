import { Specification } from "../../../../reducer";

const AIR_CONDITIONING_TEXT = "Air Conditioning";
const AUTOMATIC_TRANSMISSION_TEXT = "Automatic Transmission";
const UNLIMITED_MILEAGE_TEXT = "Unlimited Mileage";

export const getSpecificationTitle = (specification: Specification) => {
  switch (specification) {
    case Specification.AirConditioning:
      return AIR_CONDITIONING_TEXT;
    case Specification.AutomaticTransmission:
      return AUTOMATIC_TRANSMISSION_TEXT;
    case Specification.UnlimitedMileage:
      return UNLIMITED_MILEAGE_TEXT;
    default:
      return "";
  }
};
