import React from "react";
import { IconName, LandingBenefit } from "halifax";

export const TITLE = (
  <>
    Thousands of <strong>rental cars</strong> to choose from.
  </>
);
export const SUBTITLE =
  "Book with flexibility and enjoy elevated travel benefits.";

export const EARN_ENHANCEMENT_TITLE = (
  earnRate: string | number,
  productDisplayName: string,
  currency: string
) => {
  const multiplier = currency.includes("cash") ? "%" : "X";
  return (
    <span className="font-regular">
      Earn{" "}
      <strong>
        {earnRate}
        {multiplier} {currency}{" "}
      </strong>{" "}
      on cars with your {productDisplayName} account.
    </span>
  );
};

export const CASH_CARD_BENEFITS_TITLE = (productDisplayName: string) => (
  <>
    <strong>{productDisplayName}</strong> Benefits when you book on Capital One
    Travel
  </>
);

export const CASH_CARD_BENEFITS: { [key: string]: LandingBenefit[] } = {
  VARIANT_A: [
    {
      icon: IconName.Gift,
      text: (
        <>
          Earn <strong>5% cash back</strong> on hotels and rental cars
        </>
      ),
    },
    {
      icon: IconName.PricePredictionIcon,
      text: (
        <>
          <strong>Save an average of 15%</strong> on flights with price
          prediction
        </>
      ),
    },
    {
      icon: IconName.HotelDND,
      text: (
        <>
          We <strong>match hotel prices</strong> before and after you book
        </>
      ),
    },
  ],
  VARIANT_B: [
    {
      icon: IconName.Gift,
      text: (
        <>
          Earn <strong>5% cash back</strong> on hotels and rental cars
        </>
      ),
    },
    {
      icon: IconName.PricePredictionIcon,
      text: (
        <>
          <strong>Save an average of 15%</strong> on flights with price
          prediction
        </>
      ),
    },
    {
      icon: IconName.PriceDropProtectionIcon,
      text: (
        <>
          Book your flight when we recommend and get{" "}
          <strong>price drop protection</strong>
        </>
      ),
    },
  ],
  VARIANT_C: [
    {
      icon: IconName.Gift,
      text: (
        <>
          Earn <strong>5% cash back</strong> on hotels and rental cars
        </>
      ),
    },
    {
      icon: IconName.PricePredictionIcon,
      text: (
        <>
          <strong>Save an average of 15%</strong> on flights with price
          prediction
        </>
      ),
    },
    {
      icon: IconName.BadgeRibbon,
      text: (
        <>
          If you find a better price within 24 hours,{" "}
          <strong>we’ll match it</strong>
        </>
      ),
    },
  ],
};

export const PARADISE_TITLE = "Your next journey starts here.";
