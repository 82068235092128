import { connect, ConnectedProps } from "react-redux";
import { actions } from "../../actions";
import {
  getIsMissingLicense,
  getUserPassengerCallState,
  getUserPassengers,
  getUserSelectedTravelerId,
} from "../../reducer";
import { IStoreState } from "../../../../reducers/types";
import { CarBookPassengerSelection } from "./component";

const mapStateToProps = (state: IStoreState) => {
  return {
    travelers: getUserPassengers(state),
    selectedTravelerId: getUserSelectedTravelerId(state),
    userPassengerCallState: getUserPassengerCallState(state),
    isMissingLicense: getIsMissingLicense(state),
  };
};

const mapDispatchToProps = {
  setUserSelectedPassengerIds: actions.setUserSelectedPassengerIds,
  addUserPassenger: actions.updateUserPassenger,
  removeUserPassenger: actions.deleteUserPassenger,
  fetchUserPassengers: actions.fetchUserPassengers,
  fetchCorpUserPassengers: actions.fetchCorpUserPassengers,
};

const connector = connect(mapStateToProps, mapDispatchToProps);

export type CarBookPassengerSelectionConnectorProps = ConnectedProps<
  typeof connector
>;

export const ConnectedCarBookPassengerSelection = connector(
  CarBookPassengerSelection
);
