import React from "react";
import { DatePickerButton } from "halifax";
import clsx from "clsx";

import { ConnectedCalendarPickerButtonProps } from "./container";
import { DesktopCalendarPicker } from "../DesktopCalendarPicker";
import "./styles.scss";
import * as textConstants from "./textConstants";

export interface CalendarPickerButtonProps
  extends ConnectedCalendarPickerButtonProps {
  className?: string;
  pickUpDateInputClassName?: string;
  dropOffDateInputClassName?: string;
  saveDatesOnClose?: boolean;
  hasMissingSearchInfoError?: boolean;
}

export const CalendarPickerButton = ({
  className,
  fromDate,
  untilDate,
  openDatesModal,
  setOpenDatesModal,
  saveDatesOnClose,
  hasMissingSearchInfoError,
}: CalendarPickerButtonProps) => {
  const onClick = () => {
    setOpenDatesModal(true);
  };

  return (
    <DatePickerButton
      startDate={fromDate}
      endDate={untilDate}
      endLabel={textConstants.DROP_OFF}
      startLabel={textConstants.PICK_UP}
      classes={[clsx("inline-juncture-input", className)]}
      renderCalendarPopup={(props) => (
        <DesktopCalendarPicker
          {...props}
          open={openDatesModal && props?.open}
          closePopup={() => {
            setOpenDatesModal(false);
            props?.closePopup?.();
          }}
          saveDatesOnClose={saveDatesOnClose}
        />
      )}
      onClick={onClick}
      dateFormat={"ddd MMM D"}
      hasMissingSearchInfoError={hasMissingSearchInfoError}
    />
  );
};
