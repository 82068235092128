import React from "react";
import { Badge, Box, Typography } from "@material-ui/core";
import {
  ActionButton,
  ActionLink,
  MobilePopoverCard,
  CloseButtonIcon,
  AccordionCollection,
  MobileFloatingButton,
  Icon,
  IconName,
  ButtonWrap,
} from "halifax";
import { ALL_FILTERS_MODAL_CARS_EVENT, IPriceRange, PickUpType } from "redmond";
import { isEqual } from "lodash-es";
import clsx from "clsx";

import { AvailabilityFiltersConnectorProps } from "../../container";
import {
  CarTypeSelection,
  PriceRangeSelection,
  CarCompanySelection,
  SpecificationSelection,
  CancellationPolicySelection,
  PassengersSelection,
  PickUpSelection,
  BagsSelection,
} from "..";
import {
  GeneralCarType,
  ICarCompaniesFilter,
  ISpecifications,
  ICancellationPolicy,
  CarFilterTypeV5,
  initialFilterState,
  PassengerFilter,
} from "../../../../reducer";
import {
  FILTERS_TEXT,
  CAR_TYPE_TEXT,
  PRICE_RANGE_TEXT,
  RENTAL_CAR_COMPANY_TEXT,
  SPECIFICATIONS_TEXT,
  CANCELLATION_POLICY_TEXT,
  APPLY_FILTERS_TEXT,
  RESET_CTA_TEXT,
  PASSENGERS,
  PICKUP_TYPE_TEXT,
  BAGS_TEXT,
} from "../../textConstants";
import "./styles.scss";
import { FilterModalContent } from "../FilterModalContent";
import { trackEvent } from "../../../../../../api/v1/analytics/trackEvent";

export interface IMobileAvailabilityFiltersProps
  extends AvailabilityFiltersConnectorProps {
  isCarsCXV1Experiment?: boolean;
  modalOpen: boolean;
  setModalOpen: (open: boolean) => void;
  isGlobalMobileNavExperiment?: boolean;
}

export const MobileAvailabilityFilters = (
  props: IMobileAvailabilityFiltersProps
) => {
  const {
    carTypes,
    setCarTypeFilter,
    priceRange,
    minMaxPriceRange,
    totalMinMaxPriceRange,
    maxTotalPrice,
    setMaxTotalPriceFilter,
    currencySymbol,
    setPriceRangeFilter,
    carCompaniesList,
    carCompanies,
    setCarCompaniesFilter,
    specifications,
    setSpecificationsFilter,
    cancellation,
    setCancellationPolicyFilter,
    isCarsCXV1Experiment,
    setPassengersFilter,
    passengers,
    pickUps,
    setPickupFilter,
    bags,
    setBagsFilter,
    isInPolicy,
    setCarPolicyFilter,
    modalOpen,
    setModalOpen,
    isGlobalMobileNavExperiment,
  } = props;

  const [localCarTypes, setLocalCarTypes] = React.useState<
    GeneralCarType[] | CarFilterTypeV5[]
  >(carTypes);
  const [localPriceRange, setLocalPriceRange] =
    React.useState<IPriceRange>(priceRange);
  const [localMaxTotalPrice, setLocalMaxTotalPrice] =
    React.useState<number>(maxTotalPrice);
  const [localCarCompanies, setLocalCarCompanies] =
    React.useState<ICarCompaniesFilter>(carCompanies);
  const [localSpecifications, setLocalSpecifications] =
    React.useState<ISpecifications>(specifications);
  const [localCancellation, setLocalCancellation] =
    React.useState<ICancellationPolicy>(cancellation);
  const [localPassengers, setLocalPassengers] =
    React.useState<PassengerFilter[]>(passengers);
  const [localPickUps, setLocalPickUps] = React.useState<PickUpType[]>(pickUps);
  const [localBags, setLocalBags] = React.useState<number[]>(bags);
  const [localPolicy, setLocalPolicy] = React.useState<boolean>(isInPolicy);

  const filtersToApplyCount = React.useMemo(
    () =>
      [
        !isEqual(localCarTypes, carTypes),
        !isEqual(localPriceRange, priceRange),
        localMaxTotalPrice !== maxTotalPrice,
        !isEqual(localCancellation, cancellation),
        !Object.keys(localCarCompanies).every(
          (key) => !!localCarCompanies[key] === !!carCompanies[key]
        ),
        !isEqual(localSpecifications, specifications),
        !isEqual(passengers, localPassengers),
        !isEqual(pickUps, localPickUps),
        !isEqual(bags, localBags),
        !isEqual(localPolicy, isInPolicy),
      ].filter((notEqual) => notEqual).length,
    [
      localCarTypes,
      carTypes,
      localPriceRange,
      priceRange,
      localMaxTotalPrice,
      maxTotalPrice,
      localCancellation,
      cancellation,
      localCarCompanies,
      carCompanies,
      localSpecifications,
      specifications,
      passengers,
      localPassengers,
      pickUps,
      localPickUps,
      bags,
      localBags,
      isInPolicy,
      localPolicy,
    ]
  );

  const hasFiltersToApply = filtersToApplyCount > 0;

  const filtersDifferFromInitialState = React.useMemo(
    () =>
      [
        !isEqual(localCarTypes, initialFilterState.carTypes),
        !isEqual(localPriceRange, minMaxPriceRange),
        localMaxTotalPrice !== initialFilterState.maxTotalPrice,
        !isEqual(localCancellation, initialFilterState.cancellation),
        Object.values(localCarCompanies).length
          ? Object.values(localCarCompanies).some((isSelected) => isSelected)
          : false,
        !isEqual(localSpecifications, initialFilterState.specifications),
        !isEqual(localPassengers, initialFilterState.passengers),
        !isEqual(localPickUps, initialFilterState.pickUp),
        !isEqual(localBags, initialFilterState.bags),
        !isEqual(localPolicy, initialFilterState.isInPolicy),
      ].some((notEqual) => notEqual),
    [
      localCarTypes,
      localPriceRange,
      localMaxTotalPrice,
      minMaxPriceRange,
      localCancellation,
      localCarCompanies,
      localSpecifications,
      localPassengers,
      localPickUps,
      localBags,
      localPolicy,
    ]
  );

  const handleApply = React.useCallback(() => {
    trackEvent({
      eventName: ALL_FILTERS_MODAL_CARS_EVENT,
      properties: {
        car_type: localCarTypes.toString(),
        passengers: localPassengers.toString(),
        bags: localBags.toString(),
        pickup_location: localPickUps.toString(),
        rental_company: localCarCompanies.toString(),
        price: localMaxTotalPrice,
        cancellation: localCancellation.toString(),
      },
    });
    setCarTypeFilter(localCarTypes);
    setPriceRangeFilter(localPriceRange);
    setMaxTotalPriceFilter(localMaxTotalPrice);
    setCarCompaniesFilter(localCarCompanies);
    setSpecificationsFilter(localSpecifications);
    setCancellationPolicyFilter(localCancellation);
    setPassengersFilter(localPassengers);
    setPickupFilter(localPickUps);
    setBagsFilter(localBags);
    setCarPolicyFilter(localPolicy);
  }, [
    setCarTypeFilter,
    localCarTypes,
    setPriceRangeFilter,
    localPriceRange,
    localMaxTotalPrice,
    setCarCompaniesFilter,
    localCarCompanies,
    setSpecificationsFilter,
    localSpecifications,
    setCancellationPolicyFilter,
    localCancellation,
    setPassengersFilter,
    localPassengers,
    setPickupFilter,
    localPickUps,
    setBagsFilter,
    localBags,
    setCarPolicyFilter,
    localPolicy,
  ]);

  const handleReset = () => {
    trackEvent({
      eventName: ALL_FILTERS_MODAL_CARS_EVENT,
      properties: {
        car_type: initialFilterState.carTypes.toString(),
        passengers: initialFilterState.passengers.toString(),
        bags: initialFilterState.bags.toString(),
        pickup_location: initialFilterState.pickUp.toString(),
        rental_company: initialFilterState.carCompanies.toString(),
        price: initialFilterState.maxTotalPrice,
        cancellation: initialFilterState.cancellation.toString(),
      },
    });
    setLocalCarTypes(initialFilterState.carTypes as CarFilterTypeV5[]);
    setLocalPriceRange(minMaxPriceRange);
    setLocalMaxTotalPrice(initialFilterState.maxTotalPrice);
    setLocalCarCompanies(initialFilterState.carCompanies);
    setLocalSpecifications(initialFilterState.specifications);
    setLocalCancellation(initialFilterState.cancellation);
    setLocalPassengers(initialFilterState.passengers);
    setLocalPickUps(initialFilterState.pickUp);
    setLocalBags(initialFilterState.bags);
    setCarPolicyFilter(initialFilterState.isInPolicy);
  };

  const renderFilterButtons = React.useCallback(() => {
    return (
      <Box className="car-availability-filter-bottom-buttons-container">
        {hasFiltersToApply && (
          <MobileFloatingButton
            className="apply-filters-floating-button"
            wrapperClassName="apply-filters-floating-button-container"
            disabled={false}
            onClick={() => {
              handleApply();
              onClose();
            }}
          >
            <Typography variant="h3" className="apply-filters-text">
              {APPLY_FILTERS_TEXT(
                isCarsCXV1Experiment ? filtersToApplyCount : 0
              )}
            </Typography>
          </MobileFloatingButton>
        )}
      </Box>
    );
  }, [hasFiltersToApply, handleApply]);

  const onClose = () => setModalOpen(false);

  React.useEffect(() => {
    setLocalCarTypes(carTypes);
  }, [carTypes, modalOpen]);

  React.useEffect(() => {
    setLocalPriceRange(priceRange);
  }, [priceRange, modalOpen]);

  // note: while there is no result returned, searching again on car availability screen can cause the following condition to happen
  React.useEffect(() => {
    if (
      priceRange.max > minMaxPriceRange.max ||
      priceRange.min < minMaxPriceRange.min
    ) {
      setPriceRangeFilter(minMaxPriceRange);
    }
  }, [setPriceRangeFilter, priceRange, minMaxPriceRange, modalOpen]);

  React.useEffect(() => {
    setLocalMaxTotalPrice(maxTotalPrice);
  }, [maxTotalPrice, modalOpen]);

  React.useEffect(() => {
    setLocalCarCompanies(carCompanies);
  }, [carCompanies, modalOpen]);

  React.useEffect(() => {
    setLocalSpecifications(specifications);
  }, [specifications, modalOpen]);

  React.useEffect(() => {
    setLocalCancellation(cancellation);
  }, [cancellation, modalOpen]);

  React.useEffect(() => {
    setLocalPassengers(passengers);
  }, [passengers, modalOpen]);

  React.useEffect(() => {
    setLocalPickUps(pickUps);
  }, [pickUps, modalOpen]);

  React.useEffect(() => {
    setLocalBags(bags);
  }, [bags, modalOpen]);

  React.useEffect(() => {
    setLocalPolicy(isInPolicy);
  }, [isInPolicy, modalOpen]);

  return (
    <>
      {!isGlobalMobileNavExperiment && (
        <>
          {isCarsCXV1Experiment ? (
            <ButtonWrap
              className="mobile-availability-filter-button"
              onClick={() => setModalOpen(true)}
            >
              <Badge
                className="filter-count"
                badgeContent={filtersDifferFromInitialState ? "" : undefined}
              >
                <Icon name={IconName.Settings} />
              </Badge>
            </ButtonWrap>
          ) : (
            <ActionButton
              className="mobile-availability-filter-button"
              defaultStyle="h4r-secondary"
              message={FILTERS_TEXT}
              onClick={() => setModalOpen(true)}
            />
          )}
        </>
      )}

      <MobilePopoverCard
        open={modalOpen}
        onClose={onClose}
        className={"mobile-car-availability-filter-root"}
        contentClassName={"mobile-car-availability-filter-content-wrapper"}
        bottomButton={renderFilterButtons()}
        centered={true}
        topLeftButton={
          isCarsCXV1Experiment && filtersDifferFromInitialState ? (
            <ActionLink content={RESET_CTA_TEXT} onClick={handleReset} />
          ) : undefined
        }
        topRightButton={
          <ActionLink
            className="filter-close-button"
            content={<CloseButtonIcon />}
            label="Close"
            onClick={onClose}
          />
        }
      >
        <Box className="mobile-car-availability-filter-content-container">
          {isCarsCXV1Experiment ? (
            <FilterModalContent
              carTypes={localCarTypes as CarFilterTypeV5[]}
              setCarTypes={setLocalCarTypes}
              maxTotalPriceFilter={localMaxTotalPrice}
              setMaxTotalPriceFilter={setLocalMaxTotalPrice}
              minMaxPriceRange={totalMinMaxPriceRange}
              carCompanies={localCarCompanies}
              allCarCompanies={carCompaniesList}
              setCarCompanies={setLocalCarCompanies}
              specifications={localSpecifications}
              setSpecifications={setLocalSpecifications}
              cancellationPolicy={localCancellation}
              setCancellationPolicy={setLocalCancellation}
              currencySymbol={currencySymbol}
              passengers={localPassengers}
              setPassengers={setLocalPassengers}
              pickUps={localPickUps}
              setPickUp={setLocalPickUps}
              bags={localBags}
              setBags={setLocalBags}
              isMobile
              isInPolicy={localPolicy}
              setIsInPolicy={setLocalPolicy}
            />
          ) : (
            <AccordionCollection
              className="car-availability-filter-accordions-container"
              accordionContents={[
                {
                  title: (
                    <Typography variant="h4" className="header-container">
                      {CAR_TYPE_TEXT}
                    </Typography>
                  ),
                  body: (
                    <CarTypeSelection
                      className={clsx(
                        "mobile-filter-option-selection",
                        "car-type"
                      )}
                      carTypes={localCarTypes}
                      setCarTypes={setLocalCarTypes}
                      isCarsCXV1Experiment={isCarsCXV1Experiment}
                    />
                  ),
                },
                {
                  title: (
                    <Typography variant="h4" className="header-container">
                      {PRICE_RANGE_TEXT}
                    </Typography>
                  ),
                  body: (
                    <PriceRangeSelection
                      className={clsx(
                        "mobile-filter-option-selection",
                        "price-range"
                      )}
                      setPriceRange={setLocalPriceRange}
                      priceRange={localPriceRange}
                      minPrice={minMaxPriceRange.min}
                      maxPrice={minMaxPriceRange.max}
                      currencySymbol={currencySymbol}
                    />
                  ),
                },
                {
                  title: (
                    <Typography variant="h4" className="header-container">
                      {RENTAL_CAR_COMPANY_TEXT}
                    </Typography>
                  ),
                  body: (
                    <CarCompanySelection
                      className={clsx(
                        "mobile-filter-option-selection",
                        "car-company"
                      )}
                      options={carCompaniesList}
                      carCompanies={localCarCompanies}
                      setCarCompanies={setLocalCarCompanies}
                    />
                  ),
                },
                {
                  title: (
                    <Typography variant="h4" className="header-container">
                      {SPECIFICATIONS_TEXT}
                    </Typography>
                  ),
                  body: (
                    <SpecificationSelection
                      className={clsx(
                        "mobile-filter-option-selection",
                        "specification"
                      )}
                      specifications={localSpecifications}
                      setSpecifications={setLocalSpecifications}
                    />
                  ),
                },
                {
                  title: (
                    <Typography variant="h4" className="header-container">
                      {CANCELLATION_POLICY_TEXT}
                    </Typography>
                  ),
                  body: (
                    <CancellationPolicySelection
                      className={clsx(
                        "mobile-filter-option-selection",
                        "cancellation"
                      )}
                      cancellation={localCancellation}
                      setCancellationPolicy={setLocalCancellation}
                    />
                  ),
                },
                isCarsCXV1Experiment
                  ? {
                      title: (
                        <Typography variant="h4" className="header-container">
                          {PASSENGERS}
                        </Typography>
                      ),
                      body: (
                        <PassengersSelection
                          className={clsx(
                            "mobile-filter-option-selection",
                            "passengers"
                          )}
                          passengersSelected={localPassengers}
                          setPassengersFilter={setLocalPassengers}
                          isCarsCXV1Experiment
                        />
                      ),
                    }
                  : null,
                isCarsCXV1Experiment
                  ? {
                      title: (
                        <Typography variant="h4" className="header-container">
                          {PICKUP_TYPE_TEXT}
                        </Typography>
                      ),
                      body: (
                        <PickUpSelection
                          className={clsx(
                            "mobile-filter-option-selection",
                            "pick-up-type"
                          )}
                          pickUpSelected={localPickUps}
                          setPickUpFilter={setLocalPickUps}
                          isCarsCXV1Experiment
                        />
                      ),
                    }
                  : null,
                isCarsCXV1Experiment
                  ? {
                      title: (
                        <Typography variant="h4" className="header-container">
                          {BAGS_TEXT}
                        </Typography>
                      ),
                      body: (
                        <BagsSelection
                          className={clsx(
                            "mobile-filter-option-selection",
                            "bags"
                          )}
                          isCarsCXV1Experiment
                          bagsSelected={localBags}
                          setBagsFilter={setLocalBags}
                        />
                      ),
                    }
                  : null,
              ]}
            />
          )}
        </Box>
      </MobilePopoverCard>
    </>
  );
};
