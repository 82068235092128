import { connect, ConnectedProps } from "react-redux";
import { withRouter } from "react-router";

import { IStoreState } from "../../reducers/types";
import { CarBook } from "./component";
import { getCarShopSelectedVehicle } from "../shop/reducer/selectors";
import {
  getIsBookingValid,
  getReviewedCarDetailsCheckoutProperties,
} from "./reducer";
import { setPriceQuote } from "./actions/actions";

const mapStateToProps = (state: IStoreState) => {
  return {
    selectedVehicle: getCarShopSelectedVehicle(state),
    isBookingValid: getIsBookingValid(state),
    reviewedCarDetailsCheckoutProperties:
      getReviewedCarDetailsCheckoutProperties(state),
  };
};

const mapDispatchToProps = {
  setPriceQuote,
};

const connector = connect(mapStateToProps, mapDispatchToProps);

export type CarBookConnectorProps = ConnectedProps<typeof connector>;

export const ConnectedCarBook = withRouter(connector(CarBook));
