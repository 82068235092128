import { IStoreState } from "../../../../reducers/types";
import { CarsAutocompleteResult, DateTime, CarsTripCategory } from "redmond";
import { createSelector } from "reselect";

export const getDropOffLocation = (state: IStoreState) =>
  state.carSearch.dropOffLocation;

export const getPickUpLocation = (state: IStoreState) =>
  state.carSearch.pickUpLocation;

export const getDropOffLocationResults = (state: IStoreState) =>
  state.carSearch.dropOffLocationResults;

export const getPickUpLocationResults = (state: IStoreState) =>
  state.carSearch.pickUpLocationResults;

export const getDropOffLocationResultsLoading = (state: IStoreState) =>
  state.carSearch.dropOffLocationResultsLoading;

export const getPickUpLocationResultsLoading = (state: IStoreState) =>
  state.carSearch.pickUpLocationResultsLoading;

export const getDropOffDate = (state: IStoreState) =>
  state.carSearch.dropOffDate;

export const getPickUpDate = (state: IStoreState) => state.carSearch.pickUpDate;

export const getDropOffTime = (state: IStoreState) =>
  state.carSearch.dropOffTime;

export const getPickUpTime = (state: IStoreState) => state.carSearch.pickUpTime;

export const getDriverAge = (state: IStoreState) => state.carSearch.driverAge;

export const getOpenDatesModal = (state: IStoreState) =>
  state.carSearch.openDatesModal;

export const getTripCategory = (state: IStoreState) =>
  state.carSearch.tripCategory;

export const getMobileSearchStep = (state: IStoreState) =>
  state.carSearch.mobileSearchStep;

export const getExistingCarsAvailabilityRequestParameters = createSelector(
  getDropOffDate,
  getDropOffTime,
  getDropOffLocation,
  getPickUpDate,
  getPickUpTime,
  getPickUpLocation,
  getDriverAge,
  getTripCategory,
  (
    dropOffDate,
    dropOffTime,
    dropOffLocation,
    pickUpDate,
    pickUpTime,
    pickUpLocation,
    driverAge,
    tripCategory
  ): {
    dropOffDate: Date | null;
    dropOffTime: DateTime | null;
    dropOffLocation: CarsAutocompleteResult | null;
    pickUpDate: Date | null;
    pickUpTime: DateTime | null;
    pickUpLocation: CarsAutocompleteResult | null;
    driverAge: number | null;
  } => ({
    dropOffDate: dropOffDate,
    dropOffTime: dropOffTime,
    dropOffLocation:
      tripCategory === CarsTripCategory.DIFFERENT_DROP_OFF
        ? dropOffLocation
        : pickUpLocation,
    pickUpDate: pickUpDate,
    pickUpTime: pickUpTime,
    pickUpLocation: pickUpLocation,
    driverAge: driverAge,
  })
);

export const getHasPickUpAutocompleteError = (state: IStoreState) =>
  state.carSearch.hasPickUpAutocompleteError;

export const getHasDropOffAutocompleteError = (state: IStoreState) =>
  state.carSearch.hasDropOffAutocompleteError;
