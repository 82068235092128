import { connect, ConnectedProps } from "react-redux";
import { withRouter } from "react-router";

import { IStoreState } from "../../../../reducers/types";
import {
  getPickUpLocationSearched,
  getPickUpDateSearched,
  getDropOffDateSearched,
  isCarAvailabilityInEmptyState,
  getCarAvailabilityCallState,
  getAppliedFiltersCount,
} from "../../reducer";
import { setMobileCarSearchStep } from "../../../search/actions/actions";
import { MobileCarsAvailability } from "./component";
import { getTravelWalletCredit } from "../../../travel-wallet/reducer";

const mapStateToProps = (state: IStoreState) => {
  return {
    pickUpLocation: getPickUpLocationSearched(state),
    pickUpDate: getPickUpDateSearched(state),
    dropOffDate: getDropOffDateSearched(state),
    isCarAvailabilityInEmptyState: isCarAvailabilityInEmptyState(state),
    credit: getTravelWalletCredit(state),
    availabilityCallState: getCarAvailabilityCallState(state),
    appliedFilterCount: getAppliedFiltersCount(state),
  };
};

const mapDispatchToProps = {
  setMobileCarSearchStep,
};

const connector = connect(mapStateToProps, mapDispatchToProps);

export type MobileCarAvailabilityConnectorProps = ConnectedProps<
  typeof connector
>;

export const ConnectedMobileCarsAvailability = withRouter(
  connector(MobileCarsAvailability)
);
