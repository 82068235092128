import { connect, ConnectedProps } from "react-redux";
import { Dispatch } from "@reduxjs/toolkit";

import { IStoreState } from "../../../../reducers/types";
import {
  hasChangedCarAvailabilityCarTypeFilter,
  hasChangedCarAvailabilityPriceRangeFilter,
  hasChangedCarAvailabilityCarCompaniesFilter,
  hasChangedCarAvailabilitySpecificationsFilter,
  hasChangedCarAvailabilityCancellationPolicyFilter,
  hasChangedCarAvailabilityPassengersFilter,
  hasChangedCarAvailabilityPickUpFilter,
  hasChangedCarAvailabilityBagsFilter,
  hasChangedCarAvailabilityPolicyFilter,
} from "../../reducer/selectors";
import { initialFilterState } from "../../reducer";
import { actions } from "../../actions";
import { AvailabilityFilterTags } from "./component";

export enum FilterKey {
  CarType = "CarType",
  PriceRange = "PriceRange",
  CarCompany = "CarCompany",
  Specification = "Specification",
  Cancellation = "Cancellation",
  Passengers = "Passengers",
  PickUpType = "PickUpType",
  Bags = "Bags",
  IsInPolicy = "IsInPolicy",
}

interface IStateProps {
  filters: { [key in FilterKey]: boolean };
}

const mapStateToProps = (state: IStoreState): IStateProps => {
  return {
    filters: {
      CarType: hasChangedCarAvailabilityCarTypeFilter(state),
      PriceRange: hasChangedCarAvailabilityPriceRangeFilter(state),
      CarCompany: hasChangedCarAvailabilityCarCompaniesFilter(state),
      Specification: hasChangedCarAvailabilitySpecificationsFilter(state),
      Cancellation: hasChangedCarAvailabilityCancellationPolicyFilter(state),
      Passengers: hasChangedCarAvailabilityPassengersFilter(state),
      PickUpType: hasChangedCarAvailabilityPickUpFilter(state),
      Bags: hasChangedCarAvailabilityBagsFilter(state),
      IsInPolicy: hasChangedCarAvailabilityPolicyFilter(state),
    },
  };
};

type ResetFunctions = {
  [key in FilterKey]: () => void;
};

interface IDispatchProps {
  reset: ResetFunctions;
  resetAll: () => void;
}

const mapDispatchToProps = (dispatch: Dispatch): IDispatchProps => {
  return {
    reset: {
      CarType: () =>
        dispatch(actions.setCarTypeFilter(initialFilterState.carTypes)),
      PriceRange: () =>
        dispatch(actions.setPriceRangeFilter(initialFilterState.pricePerDay)),
      CarCompany: () =>
        dispatch(
          actions.setCarCompaniesFilter(initialFilterState.carCompanies)
        ),
      Specification: () =>
        dispatch(
          actions.setSpecificationsFilter(initialFilterState.specifications)
        ),
      Cancellation: () =>
        dispatch(
          actions.setCancellationPolicyFilter(initialFilterState.cancellation)
        ),
      Passengers: () =>
        dispatch(actions.setPassengersFilter(initialFilterState.passengers)),
      PickUpType: () =>
        dispatch(actions.setPickupFilter(initialFilterState.pickUp)),
      Bags: () => dispatch(actions.setBagsFilter(initialFilterState.bags)),
      IsInPolicy: () =>
        dispatch(actions.setCarPolicyFilter(initialFilterState.isInPolicy)),
    },
    resetAll: () => {
      dispatch(actions.resetFilters());
    },
  };
};

const mergeProps = (stateProps: IStateProps, dispatchProps: IDispatchProps) => {
  return {
    ...stateProps,
    resets: {
      ...dispatchProps,
    },
  };
};

export type AvailabilityFilterTagsProps = ConnectedProps<typeof connector>;

const connector = connect(mapStateToProps, mapDispatchToProps, mergeProps);
export const ConnectedAvailabilityFilterTags = connector(
  AvailabilityFilterTags
);
