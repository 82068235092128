import React, { useState, useRef } from "react";
import {
  Box,
  Switch,
  TextField,
  Typography,
  withStyles,
} from "@material-ui/core";
import {
  MobileAutocompleteOptions,
  removeStyleSheetByMetaName,
  ActionButton,
  Icon,
  IconName,
  InfoIcon,
  RecentlySearchedList,
} from "halifax";
import { CarsTripCategory, RecentCarSearch } from "redmond";
import clsx from "clsx";

import { LocationSearchConnectorProps } from "./container";
import * as textConstants from "./textConstants";
import { colors } from "../../../../../../utils/colors";
import { MobileCarSearchStep } from "../../../../reducer";
import "./styles.scss";
import * as H from "history";
import { PATH_HOME } from "../../../../../../utils/paths";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimesCircle } from "@fortawesome/free-solid-svg-icons";

export interface ILocationSearchProps extends LocationSearchConnectorProps {
  onComplete?: () => void;
  history: H.History;
  recentSearches?: RecentCarSearch[];
  onRecentSearchClick?: (search: RecentCarSearch) => void;
  onSelectLocation?: (value: any) => void;
}

// TODO: refactor DropOffOptionSwitch so that it's stored in its own .tsx file;
const StyledSwitch = withStyles({
  switchBase: {
    // PrivateSwitchBase - default padding size
    padding: "9px",
    "&$checked + $track": {
      backgroundColor: colors["blue-9"],
    },
  },
  track: {
    borderRadius: "16px",
    opacity: 1,
    backgroundColor: colors["grey-8"],
  },
  thumb: {},
})(Switch);

export const LocationSearch = (props: ILocationSearchProps) => {
  const {
    pickUpLocation,
    dropOffLocation,
    loading,
    tripCategory,
    pickUpLocationResults,
    dropOffLocationResults,
    fetchDropOffLocationResults,
    fetchPickUpLocationResults,
    setDropOffLocation,
    setPickUpLocation,
    setTripCategory,
    setMobileCarSearchStep,
    onComplete,
    readyToContinue,
    hasPickUpAutocompleteError,
    hasDropOffAutocompleteError,
    setPickUpAutocompleteError,
    setDropOffAutocompleteError,
    history,
    recentSearches,
    onRecentSearchClick,
    onSelectLocation,
  } = props;
  const [pickUpFocused, setPickUpFocused] = useState<boolean>(false);
  const [dropOffFocused, setDropOffFocused] = useState<boolean>(false);
  const [pickUpSearchString, setPickUpSearchString] = useState<string>(
    pickUpLocation?.label || ""
  );

  const [dropOffSearchString, setDropOffSearchString] = useState<string>(
    dropOffLocation?.label || ""
  );

  const onOriginSearch = (
    event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>
  ) => {
    hasPickUpAutocompleteError && setPickUpAutocompleteError(false);
    setPickUpLocation(null);
    setPickUpSearchString(event.target.value);
    fetchPickUpLocationResults(event.target.value);
  };

  const onDestinationSearch = (
    event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>
  ) => {
    hasDropOffAutocompleteError && setDropOffAutocompleteError(false);
    setDropOffLocation(null);
    setDropOffSearchString(event.target.value);
    fetchDropOffLocationResults(event.target.value);
  };

  const onPickUpSelected = (value: any) => {
    setPickUpFocused(false);
    setPickUpSearchString(value.label);
    setPickUpLocation(value);
    onSelectLocation?.(value);
    if (tripCategory === CarsTripCategory.SAME_AS_DROP_OFF) {
      setDropOffSearchString(value.label);
      setDropOffLocation(value);
    }
    if (dropOffLocation || tripCategory === CarsTripCategory.SAME_AS_DROP_OFF) {
      onContinue();
      if (onComplete) onComplete();
    }
  };

  const element = useRef<HTMLInputElement | null>(null);
  const onDropOffSelected = (value: any) => {
    setDropOffFocused(false);
    setDropOffSearchString(value.label);
    setDropOffLocation(value);
    onSelectLocation?.(value);
    if (pickUpLocation) {
      onContinue();
      if (onComplete) onComplete();
    }
    if (!pickUpLocation) {
      setPickUpFocused(true);
      if (element.current) {
        element.current.focus();
      }
    }
  };

  const onClearPickUpField = () => {
    setPickUpLocation(null);
    setPickUpSearchString("");
    fetchPickUpLocationResults("");
  };

  const onClearDropOffField = () => {
    setDropOffLocation(null);
    setDropOffSearchString("");
    fetchDropOffLocationResults("");
  };

  const onContinue = () => {
    setMobileCarSearchStep(MobileCarSearchStep.CalendarPicker);
  };

  const handleTripCategoryChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    if (event.target.checked) {
      // SAME_AS_DROP_OFF
      setTripCategory(CarsTripCategory.SAME_AS_DROP_OFF);
      if (pickUpLocation) {
        setDropOffLocation(pickUpLocation);
        setDropOffSearchString(pickUpLocation.label);
      }
    } else {
      // DIFFERENT_DROP_OFF
      setTripCategory(CarsTripCategory.DIFFERENT_DROP_OFF);
      if (dropOffLocation) {
        setDropOffLocation(null);
        setDropOffSearchString("");
      }
    }
  };

  React.useEffect(() => {
    removeStyleSheetByMetaName("PrivateSwitchBase");
  }, []);

  return (
    <Box className="mobile-car-search-location redesign-v3">
      <Box className="mobile-location-pickers">
        <Box className="mobile-cars-search-location-input-field pickup">
          <Icon name={IconName.B2BMapPin} />
          <TextField
            className={clsx("location-auto-complete", "mobile")}
            onChange={(value) => onOriginSearch(value)}
            value={pickUpSearchString}
            label={textConstants.PICK_UP_LOCATION}
            InputProps={{
              disableUnderline: true,
            }}
            onFocus={() => {
              setPickUpFocused(true);
              setDropOffFocused(false);
            }}
          />
          {/* Note: Instead of conditionally rendering this icon component, a className of 'hidden' is conditionally used so it can take the style "visibility:hidden"
            This style will allocate space for the icon even when it's not shown - this prevents the sibling elements from changing when it's shown or hidden */}
          <FontAwesomeIcon
            className={clsx("icon", { hidden: !pickUpSearchString })}
            icon={faTimesCircle}
            onClick={onClearPickUpField}
          />
        </Box>

        {tripCategory === CarsTripCategory.DIFFERENT_DROP_OFF && (
          <Box className="mobile-cars-search-location-input-field dropoff">
            <Icon name={IconName.B2BMapPin} />
            <TextField
              className={clsx("location-auto-complete", "mobile")}
              label={textConstants.DROP_OFF_LOCATION}
              InputProps={{
                disableUnderline: true,
              }}
              onFocus={() => {
                setDropOffFocused(true);
                setPickUpFocused(false);
              }}
              onChange={(value) => onDestinationSearch(value)}
              value={dropOffSearchString}
            />
            <FontAwesomeIcon
              className={clsx("icon", { hidden: !dropOffSearchString })}
              icon={faTimesCircle}
              onClick={onClearDropOffField}
            />
          </Box>
        )}
        <Box className="trip-category-switch-container">
          <Typography className="trip-category-label">
            {textConstants.TRIP_CATEGORY_LABEL}
          </Typography>
          <StyledSwitch
            checked={tripCategory === CarsTripCategory.SAME_AS_DROP_OFF}
            onChange={handleTripCategoryChange}
            focusVisibleClassName={"trip-category-switch-focus-visible"}
            disableRipple
            className={"cars-trip-category-switch"}
            classes={{
              thumb: "trip-category-switch-thumb",
              track: "trip-category-switch-track",
              switchBase: "trip-category-switch-base",
            }}
          />
        </Box>
        {(hasPickUpAutocompleteError || hasDropOffAutocompleteError) && (
          <Box className="autocomplete-error-container">
            <InfoIcon />
            <span className="autocomplete-error-text">
              Error fetching results.{" "}
            </span>
            <span
              onClick={() => {
                history.push(PATH_HOME);
                hasPickUpAutocompleteError && setPickUpAutocompleteError(false);
                hasDropOffAutocompleteError &&
                  setDropOffAutocompleteError(false);
              }}
              className="try-again-text"
            >
              Try again.
            </span>
          </Box>
        )}
      </Box>
      {pickUpFocused && (
        <MobileAutocompleteOptions
          value={null}
          valueCategories={pickUpLocationResults}
          setValue={(value: any) => onPickUpSelected(value)}
          loading={loading}
          loadingText={"Loading"}
        />
      )}
      {!loading &&
        pickUpSearchString.length === 0 &&
        (pickUpLocationResults.length === 0 ||
          pickUpLocationResults[0]?.results.length === 0) &&
        !!recentSearches &&
        recentSearches.length > 0 &&
        !!onRecentSearchClick && (
          <div
            className={clsx("mobile-recently-searched-cars-container", {
              "has-results": pickUpLocationResults.length > 0,
            })}
          >
            <RecentlySearchedList
              recentSearches={recentSearches}
              onRecentSearchClick={(recentSearch) =>
                onRecentSearchClick(recentSearch as RecentCarSearch)
              }
            />
          </div>
        )}

      {tripCategory === CarsTripCategory.DIFFERENT_DROP_OFF &&
        dropOffFocused && (
          <MobileAutocompleteOptions
            value={null}
            valueCategories={dropOffLocationResults}
            setValue={(value: any) => onDropOffSelected(value)}
            loading={loading}
            loadingText={"Loading"}
          />
        )}
      {readyToContinue && (
        <ActionButton
          className={clsx("mobile-autocomplete-continue-button", "b2b")}
          message={textConstants.CONTINUE}
          onClick={onContinue}
        />
      )}
    </Box>
  );
};
