import { put } from "redux-saga/effects";
import { actions } from "../actions";
import { listPaymentMethods } from "../../../api/v1/book/payment-methods/listPaymentMethods";
import { Payment } from "redmond";

export function* listPaymentMethodsSaga(_: actions.IListPaymentMethods) {
  try {
    const paymentMethods: Payment[] = yield listPaymentMethods();

    yield put(
      actions.setPaymentMethods({
        paymentMethods,
      })
    );
  } catch (e) {
    yield put(actions.setPaymentMethodsCallStateFailed());
  }
}
