import axios from "axios";
import { config } from "../../config";

import {
  CarsAvailabilityRequest,
  CarsAvailabilityResponse,
  VIEWED_CAR_AVAILABILITY_LOADING,
} from "redmond";
import { carsAvailabilityApiPrefix } from "../paths";

import dayjs from "dayjs";
import { trackEvent } from "../analytics/trackEvent";

export const fetchCarsAvailability = (
  body: CarsAvailabilityRequest
): Promise<CarsAvailabilityResponse> =>
  new Promise(async (resolve, reject) => {
    try {
      const startTime = dayjs();
      const res = await axios.post(carsAvailabilityApiPrefix, body, {
        baseURL: config.baseURL,
      });
      trackEvent({
        eventName: VIEWED_CAR_AVAILABILITY_LOADING,
        properties: {
          runTime: dayjs().diff(startTime, "seconds", true),
        },
      });
      resolve(res.data);
    } catch (e) {
      reject(e);
    }
  });
