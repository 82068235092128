import dayjs from "dayjs";

export const VIEWING_CARS_TEXT = (locationName: string) =>
  `Viewing Car Rentals at ${locationName}`;
export const DATES_TEXT = (fromDate: Date, untilDate: Date) =>
  `${dayjs(fromDate).format("ddd, MMM DD")} - ${dayjs(untilDate).format(
    "ddd, MMM DD"
  )}`;

export const PICK_UP_LOCATION_AUTOCOMPLETE_PLACEHOLDER = "Pick-up location";
export const DROP_OFF_LOCATION_AUTOCOMPLETE_PLACEHOLDER = "Drop-off location";
export const TRIP_CATEGORY_LABEL = "Drop-off at the same location";

export const SEARCH_TEXT = "Search";
