import { connect, ConnectedProps } from "react-redux";

import {
  getSelectedAccountReferenceId,
  getSortedRewardsAccounts,
} from "../../reducer";
import {
  fetchRewardsAccounts,
  setSelectedAccountReferenceId,
} from "../../actions/actions";
import { IStoreState } from "../../../../reducers/types";
import { RewardsAccountSelection } from "./component";

const mapStateToProps = (state: IStoreState) => {
  return {
    selectedAccountReferenceId: getSelectedAccountReferenceId(state),
    rewardsAccounts: getSortedRewardsAccounts(state),
  };
};

const mapDispatchToProps = {
  fetchRewardsAccounts,
  setSelectedAccountReferenceId,
};

const connector = connect(mapStateToProps, mapDispatchToProps);

export type RewardsAccountSelectionConnectorProps = ConnectedProps<
  typeof connector
>;

export const ConnectedRewardsAccountSelection = connector(
  RewardsAccountSelection
);
