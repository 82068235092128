export const FETCH_CAR_AVAILABILITY = "carAvailability/FETCH_CAR_AVAILABILITY";
export type FETCH_CAR_AVAILABILITY = typeof FETCH_CAR_AVAILABILITY;

export const SET_CAR_AVAILABILITY_RESULTS =
  "carAvailability/SET_CAR_AVAILABILITY_RESULTS";
export type SET_CAR_AVAILABILITY_RESULTS = typeof SET_CAR_AVAILABILITY_RESULTS;

export const SET_CAR_AVAILABILITY_CALL_STATE_FAILED =
  "carAvailability/SET_CAR_AVAILABILITY_CALL_STATE_FAILED";
export type SET_CAR_AVAILABILITY_CALL_STATE_FAILED =
  typeof SET_CAR_AVAILABILITY_CALL_STATE_FAILED;

export const POPULATE_CAR_AVAILABILITY_QUERY_PARAMS =
  "carAvailability/POPULATE_CAR_AVAILABILITY_QUERY_PARAMS";
export type POPULATE_CAR_AVAILABILITY_QUERY_PARAMS =
  typeof POPULATE_CAR_AVAILABILITY_QUERY_PARAMS;

export const SET_CAR_TYPE_FILTER = "carAvailability/SET_CAR_TYPE_FILTER";
export type SET_CAR_TYPE_FILTER = typeof SET_CAR_TYPE_FILTER;

export const SET_PASSENGERS_FILTER = "carAvailability/SET_PASSENGERS_FILTER";
export type SET_PASSENGERS_FILTER = typeof SET_PASSENGERS_FILTER;

export const SET_PRICE_RANGE_FILTER = "carAvailability/SET_PRICE_RANGE_FILTER";
export type SET_PRICE_RANGE_FILTER = typeof SET_PRICE_RANGE_FILTER;

export const SET_MAX_TOTAL_PRICE_FILTER =
  "carAvailability/SET_MAX_TOTAL_PRICE_FILTER";
export type SET_MAX_TOTAL_PRICE_FILTER = typeof SET_MAX_TOTAL_PRICE_FILTER;

export const SET_CAR_COMPANIES_FILTER =
  "carAvailability/SET_CAR_COMPANIES_FILTER";
export type SET_CAR_COMPANIES_FILTER = typeof SET_CAR_COMPANIES_FILTER;

export const SET_SPECIFICATIONS_FILTER =
  "carAvailability/SET_SPECIFICATIONS_FILTER";
export type SET_SPECIFICATIONS_FILTER = typeof SET_SPECIFICATIONS_FILTER;

export const SET_CANCELLATION_POLICY_FILTER =
  "carAvailability/SET_CANCELLATION_POLICY_FILTER";
export type SET_CANCELLATION_POLICY_FILTER =
  typeof SET_CANCELLATION_POLICY_FILTER;

export const RESET_CAR_AVAILABILITY_FILTERS =
  "carAvailability/RESET_CAR_AVAILABILITY_FILTERS";
export type RESET_CAR_AVAILABILITY_FILTERS =
  typeof RESET_CAR_AVAILABILITY_FILTERS;

export const RESET_CAR_AVAILABILITY_CALL_STATES =
  "carAvailability/RESET_CAR_AVAILABILITY_CALL_STATES";
export type RESET_CAR_AVAILABILITY_CALL_STATES =
  typeof RESET_CAR_AVAILABILITY_CALL_STATES;

export const SET_DROP_OFF_LOCATION_SEARCHED =
  "carAvailability/SET_DROP_OFF_LOCATION_SEARCHED";
export type SET_DROP_OFF_LOCATION_SEARCHED =
  typeof SET_DROP_OFF_LOCATION_SEARCHED;

export const SET_PICK_UP_LOCATION_SEARCHED =
  "carAvailability/SET_PICK_UP_LOCATION_SEARCHED";
export type SET_PICK_UP_LOCATION_SEARCHED =
  typeof SET_PICK_UP_LOCATION_SEARCHED;

export const SET_PICK_UP_DATE_SEARCHED =
  "carAvailability/SET_PICK_UP_DATE_SEARCHED";
export type SET_PICK_UP_DATE_SEARCHED = typeof SET_PICK_UP_DATE_SEARCHED;

export const SET_DROP_OFF_DATE_SEARCHED =
  "carAvailability/SET_DROP_OFF_DATE_SEARCHED";
export type SET_DROP_OFF_DATE_SEARCHED = typeof SET_DROP_OFF_DATE_SEARCHED;

export const SET_PICK_UP_TIME_SEARCHED =
  "carAvailability/SET_PICK_UP_TIME_SEARCHED";
export type SET_PICK_UP_TIME_SEARCHED = typeof SET_PICK_UP_TIME_SEARCHED;

export const SET_DROP_OFF_TIME_SEARCHED =
  "carAvailability/SET_DROP_OFF_TIME_SEARCHED";
export type SET_DROP_OFF_TIME_SEARCHED = typeof SET_DROP_OFF_TIME_SEARCHED;

export const SET_OPEN_DATES_MODAL = "carAvailability/SET_OPEN_DATES_MODAL";
export type SET_OPEN_DATES_MODAL = typeof SET_OPEN_DATES_MODAL;

export const SET_CAR_AVAILABILITY_ENTRY_POINT =
  "carAvailability/SET_CAR_AVAILABILITY_ENTRY_POINT";
export type SET_CAR_AVAILABILITY_ENTRY_POINT =
  typeof SET_CAR_AVAILABILITY_ENTRY_POINT;

export const SET_IS_CARS_CX_V1_EXPERIMENTS =
  "carAvailability/SET_IS_CARS_CX_V1_EXPERIMENTS";
export type SET_IS_CARS_CX_V1_EXPERIMENTS =
  typeof SET_IS_CARS_CX_V1_EXPERIMENTS;

export const SET_PICKUP_FILTER = "carAvailability/SET_PICKUP_FILTER";
export type SET_PICKUP_FILTER = typeof SET_PICKUP_FILTER;

export const SET_BAGS_FILTER = "carAvailability/SET_BAGS_FILTER";
export type SET_BAGS_FILTER = typeof SET_BAGS_FILTER;

export const LIST_PAYMENT_METHODS = "carAvailability/LIST_PAYMENT_METHODS";
export type LIST_PAYMENT_METHODS = typeof LIST_PAYMENT_METHODS;

export const SET_PAYMENT_METHODS = "carAvailability/SET_PAYMENT_METHODS";
export type SET_PAYMENT_METHODS = typeof SET_PAYMENT_METHODS;

export const SET_PAYMENT_METHODS_CALL_STATE_FAILED =
  "carAvailability/SET_PAYMENT_METHODS_CALL_STATE_FAILED";
export type SET_PAYMENT_METHODS_CALL_STATE_FAILED =
  typeof SET_PAYMENT_METHODS_CALL_STATE_FAILED;

// Corporate
export const SET_POLICY_FILTER = "carAvailability/SET_POLICY_FILTER";
export type SET_POLICY_FILTER = typeof SET_POLICY_FILTER;

export const SET_CAR_AVAILABILITY_REQUEST =
  "carAvailability/SET_CAR_AVAILABILITY_REQUEST";
export type SET_CAR_AVAILABILITY_REQUEST = typeof SET_CAR_AVAILABILITY_REQUEST;
