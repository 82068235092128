import React, { useMemo } from "react";
import { Box, Typography, useScrollTrigger } from "@material-ui/core";
import dayjs from "dayjs";
import clsx from "clsx";
import {
  ActionLink,
  MobilePopoverCard,
  CloseButtonIcon,
  MobileFloatingButton,
  Icon,
  IconName,
} from "halifax";
import { RouteComponentProps } from "react-router";
import { faChevronLeft } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { MobileShopHeaderConnectorProps } from "./container";
import {
  PATH_AVAILABILITY,
  PATH_HOME,
  PATH_HOME_SEARCH,
} from "../../../../utils/paths";
import { transformToStringifiedAvailabilityQuery } from "../../../availability/utils/queryStringHelpers";
import { MobileCarSearchStep } from "../../../search/reducer";

import "./styles.scss";
import {
  MonthAndDatePicker,
  MonthAndDatePickerType,
} from "../../../search/components/DesktopCarSearchControl/components/MonthAndDatePicker";
import {
  useExperiments,
  getExperimentVariant,
  RECENTLY_VIEWED_V2_CARS,
  AVAILABLE,
  GLOBAL_MOBILE_NAV_EXPERIMENT,
} from "../../../../context/experiments";

export interface IMobileShopHeaderProps
  extends RouteComponentProps,
    MobileShopHeaderConnectorProps {}

export const MobileShopHeader = (props: IMobileShopHeaderProps) => {
  const {
    selectedVehicle,
    dropOffDate,
    dropOffTime,
    dropOffLocation,
    pickUpDate,
    pickUpTime,
    pickUpLocation,
    driverAge,
    history,
    fetchCarExtraInfo,
    setPickUpDate,
    setDropOffDate,
    setMobileCarSearchStep,
  } = props;
  const [openModal, setOpenModal] = React.useState<boolean>(false);
  const [hasChangedDates, setHasChangedDates] = React.useState(false);
  const [pickUp, setPickUp] = React.useState<Date | null>(pickUpDate);
  const [dropOff, setDropOff] = React.useState<Date | null>(dropOffDate);

  const expState = useExperiments();

  const recentlyViewedV2Cars = getExperimentVariant(
    expState.experiments,
    RECENTLY_VIEWED_V2_CARS
  );

  const isRecentlyViewedCarsV2Experiment = useMemo(() => {
    return recentlyViewedV2Cars === AVAILABLE;
  }, [recentlyViewedV2Cars]);

  const isGlobalMobileNavExperiment =
    getExperimentVariant(expState.experiments, GLOBAL_MOBILE_NAV_EXPERIMENT) ===
    AVAILABLE;

  React.useEffect(() => {
    setPickUp(pickUpDate);
  }, [pickUpDate]);

  React.useEffect(() => {
    setDropOff(dropOffDate);
  }, [dropOffDate]);

  //   const focusedMonthIndex = pickUpDate
  //     ? dayjs(pickUpDate).diff(dayjs(), "month")
  //     : 0;

  const onClose = () => setOpenModal(false);

  const returnToAvailability = () => {
    if (!selectedVehicle) {
      return history.push(PATH_HOME);
    }

    // TODO: This needs to include state to be accurate, we don't have it.
    const search = transformToStringifiedAvailabilityQuery(
      dropOffDate,
      dropOffTime,
      dropOffLocation,
      pickUpDate,
      pickUpTime,
      pickUpLocation,
      driverAge
    );

    history.push(`${PATH_AVAILABILITY}${search}`);
  };

  const returnToSearch = () => {
    const search = transformToStringifiedAvailabilityQuery(
      dropOffDate,
      dropOffTime,
      dropOffLocation,
      pickUpDate,
      pickUpTime,
      pickUpLocation,
      driverAge
    );
    setMobileCarSearchStep(MobileCarSearchStep.CalendarPicker);
    history.push(`${PATH_HOME_SEARCH}${search}`);
  };

  React.useEffect(() => {
    const differentDates = pickUp !== pickUpDate || dropOff !== dropOffDate;
    const setBothDates = pickUpDate && dropOffDate;

    if (differentDates && setBothDates) {
      setHasChangedDates(true);
    } else {
      setHasChangedDates(false);
    }
  }, [pickUpDate, dropOffDate, pickUp, dropOff]);

  const scrollTrigger = useScrollTrigger({ disableHysteresis: true });

  return (
    <>
      <Box
        className={clsx("car-shop-progress-header-root", {
          "global-mobile-nav": isGlobalMobileNavExperiment,
          scrolled: scrollTrigger,
        })}
      >
        <Box className="car-shop-progress-header-container">
          <Box className="go-back-button-section">
            <ActionLink
              className={clsx("go-back-button")}
              onClick={returnToAvailability}
              content={<FontAwesomeIcon icon={faChevronLeft} />}
            />
          </Box>
          <Box className="locations-and-dates-section">
            <Typography className="locations-section" variant="body1">
              {selectedVehicle?.carModelDetails.carSize}
            </Typography>
            <Typography className="dates-section" variant="body2">
              {pickUpDate &&
                dropOffDate &&
                `${dayjs(pickUpDate).format("ddd, MMM DD")} - ${dayjs(
                  dropOffDate
                ).format("ddd, MMM DD")}`}
            </Typography>
          </Box>
          <Box className="edit-location-and-date-button-section">
            <ActionLink
              className="edit-location-and-date-button"
              onClick={() => {
                returnToSearch();
              }}
              content={
                <Icon name={IconName.B2BEditPencil} className="edit-icon" />
              }
            />
          </Box>
        </Box>
      </Box>
      <MobilePopoverCard
        open={openModal}
        onClose={onClose}
        fullScreen={true}
        className="mobile-shop-header-root"
        contentClassName="mobile-shop-header-content-wrapper"
        topRightButton={
          <ActionLink
            className="shop-header-close-button"
            content={<CloseButtonIcon />}
            label="Close"
            onClick={onClose}
          />
        }
      >
        <Box className="mobile-shop-header-content-container">
          <Box className="car-mobile-calendar-picker-root">
            <MonthAndDatePicker
              viewType={MonthAndDatePickerType.Column}
              from={pickUp}
              until={dropOff}
              setFromDate={setPickUp}
              setUntilDate={setDropOff}
            />
            {hasChangedDates && (
              <MobileFloatingButton
                className="mobile-calendar-picker-search-button"
                disabled={!(!!pickUp && !!dropOff)}
                onClick={() => {
                  setPickUpDate(pickUp);
                  setDropOffDate(dropOff);
                  fetchCarExtraInfo(history, {
                    includeLocationLabelsInAvailCall:
                      isRecentlyViewedCarsV2Experiment,
                  });
                  onClose();
                }}
              >
                Search dates
              </MobileFloatingButton>
            )}
          </Box>
        </Box>
      </MobilePopoverCard>
    </>
  );
};
