import React from "react";
import {
  CarFilterTypeV5,
  ICarCompaniesFilter,
  ISpecifications,
  PassengerFilter,
  Specification,
} from "../../../../reducer";
import { PickUpType } from "redmond";
import { getCarTypeTitles } from "../CarTypeSelection/textConstants";
import { getSpecificationTitle } from "../SpecificationSelection/textConstants";

export interface FilterLabelValues {
  carTypeLabel: string;
  priceLabel: string;
  rentalCompaniesLabel: string;
  specificationsLabel: string;
  cancellationLabel: string;
  passengersLabel: string;
  pickUpLabel: string;
  bagsLabel: string;
  policyLabel: string;
}

export const useFilterLabelValues = ({
  carTypes,
  maxTotalPrice,
  carCompanies,
  allCarCompanies,
  specifications,
  cancellation,
  passengers,
  pickUps,
  bags,
  isInPolicy,
}: {
  carTypes: CarFilterTypeV5[];
  maxTotalPrice: number;
  carCompanies: ICarCompaniesFilter;
  allCarCompanies: {
    key: string;
    name: string;
  }[];
  specifications: ISpecifications;
  cancellation: boolean;
  passengers: PassengerFilter[];
  pickUps: PickUpType[];
  bags: number[];
  isInPolicy: boolean;
}): FilterLabelValues => {
  const [orderedCarTypes, setOrderedCarTypes] = React.useState<
    CarFilterTypeV5[]
  >([]);
  const [orderedRentalCompanies, setOrderedRentalCompanies] = React.useState<
    string[]
  >([]);
  const [orderedSpecifications, setOrderedSpecifications] = React.useState<
    Specification[]
  >([]);

  const carTypeLabel = React.useMemo(() => {
    if (carTypes.length) {
      const newOrderedCarTypesFilter = [
        ...orderedCarTypes.filter((carType) => carTypes.includes(carType)),
        ...carTypes.filter((carType) => !orderedCarTypes.includes(carType)),
      ];
      setOrderedCarTypes(newOrderedCarTypesFilter);

      const mainLabel = getCarTypeTitles(newOrderedCarTypesFilter[0]).title;
      const additionalCount =
        newOrderedCarTypesFilter.length > 1
          ? ` +${newOrderedCarTypesFilter.length - 1}`
          : "";

      return `${mainLabel}${additionalCount}`;
    }
    return "";
  }, [carTypes]);

  const priceLabel = React.useMemo(() => {
    return maxTotalPrice > 0 ? `$${maxTotalPrice}` : "";
  }, [maxTotalPrice]);

  const rentalCompaniesLabel = React.useMemo(() => {
    const appliedRentalCompanies = Object.keys(carCompanies).filter(
      (key) => carCompanies[key]
    );

    if (appliedRentalCompanies.length) {
      const newOrderedRentalCompaniesFilter = [
        ...orderedRentalCompanies.filter((rentalCompany) =>
          appliedRentalCompanies.includes(rentalCompany)
        ),
        ...appliedRentalCompanies.filter(
          (rentalCompany) => !orderedRentalCompanies.includes(rentalCompany)
        ),
      ];
      setOrderedRentalCompanies(newOrderedRentalCompaniesFilter);

      const mainLabel = allCarCompanies.find(
        ({ key }) => key === newOrderedRentalCompaniesFilter[0]
      )?.name;
      const additionalCount =
        newOrderedRentalCompaniesFilter.length > 1
          ? ` +${newOrderedRentalCompaniesFilter.length - 1}`
          : "";

      return `${mainLabel}${additionalCount}`;
    }
    return "";
  }, [carCompanies, allCarCompanies]);

  const specificationsLabel = React.useMemo(() => {
    const appliedSpecifications = Object.keys(specifications).filter(
      (key) => specifications[key]
    ) as Specification[];

    if (appliedSpecifications.length) {
      const newOrderedSpecificationsFilter = [
        ...orderedSpecifications.filter((specification) =>
          appliedSpecifications.includes(specification)
        ),
        ...appliedSpecifications.filter(
          (specification) => !orderedSpecifications.includes(specification)
        ),
      ];
      setOrderedSpecifications(newOrderedSpecificationsFilter);

      const mainLabel = getSpecificationTitle(
        newOrderedSpecificationsFilter[0]
      );
      const additionalCount =
        newOrderedSpecificationsFilter.length > 1
          ? ` +${newOrderedSpecificationsFilter.length - 1}`
          : "";

      return `${mainLabel}${additionalCount}`;
    }
    return "";
  }, [specifications]);

  const cancellationLabel = React.useMemo(
    () => (cancellation ? "Free" : ""),
    [cancellation]
  );

  const passengersLabel = React.useMemo(() => {
    if (passengers.includes(PassengerFilter.SixOrMore)) {
      return "6+";
    }
    if (passengers.includes(PassengerFilter.UpToFive)) {
      return "2-5";
    }
    return "";
  }, [passengers]);

  const pickUpLabel = React.useMemo(() => {
    if (pickUps.length > 0) {
      return `${pickUps[0]}${
        pickUps.length - 1 > 0 ? " +" + (pickUps.length - 1) : ""
      }`;
    }
    return "";
  }, [pickUps]);

  const bagsLabel = React.useMemo(() => {
    if (bags.length) {
      if (bags.length > 1) {
        return `${Math.min(...bags)} - ${Math.max(...bags)}`;
      }
      return `${bags[0]}`;
    }
    return "";
  }, [bags]);

  const policyLabel = isInPolicy ? "In policy only" : "";

  return {
    carTypeLabel,
    priceLabel,
    rentalCompaniesLabel,
    specificationsLabel,
    cancellationLabel,
    passengersLabel,
    pickUpLabel,
    bagsLabel,
    policyLabel,
  };
};
