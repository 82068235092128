import { connect } from "react-redux";
import { IStoreState } from "../../../../../../reducers/types";
import { getDropOffTime, getPickUpTime } from "../../../../reducer";
import { actions } from "../../../../actions";
import { TimeSelector, ITimeSelector } from "./component";
import { DROP_OFF_TIME, PICK_UP_TIME } from "./constants";

export const dropOffMapDispatchToProps = {
  setTime: actions.setDropOffTime,
};

export const dropOffMapStateToProps = (state: IStoreState) => ({
  time: getDropOffTime(state),
  label: DROP_OFF_TIME,
});

export const ConnectedDropOffTimeSelector = connect(
  dropOffMapStateToProps,
  dropOffMapDispatchToProps
)(TimeSelector as (props: ITimeSelector) => JSX.Element);

export const pickUpMapDispatchToProps = {
  setTime: actions.setPickUpTime,
};

export const pickUpMapStateToProps = (state: IStoreState) => ({
  time: getPickUpTime(state),
  label: PICK_UP_TIME,
});

export const ConnectedPickUpTimeSelector = connect(
  pickUpMapStateToProps,
  pickUpMapDispatchToProps
)(TimeSelector as (props: ITimeSelector) => JSX.Element);
