import axios from "axios";

import {
  purchaseApi,
  Product,
  ProductOpaqueValue,
} from "@b2bportal/purchase-api";
import { CarPriceQuoteScheduleRequest } from "redmond";

export const schedulePriceQuoteV1 = ({
  req,
  type = Product.Ground,
}: {
  req: CarPriceQuoteScheduleRequest;
  type?: Product;
}): Promise<String> =>
  new Promise((resolve, reject) => {
    try {
      const productOpaqueValue: ProductOpaqueValue = {
        type,
        value: req as object,
      };

      const res = purchaseApi(axios).apiV1PurchaseQuoteSchedulePost({
        emailAddress: req.emailAddress,
        products: [productOpaqueValue],
      });

      res.then((body) => resolve(body.data.value)).catch((e) => reject(e));
    } catch (e) {
      reject(e);
    }
  });
