import { ICorpPerson } from "redmond";

export const useCorpPersonData = (
  person: ICorpPerson | null
): [boolean, string] => {
  if (!person) return [false, ""];
  const isPersonMissingProfile = person.isMissingInfo;
  const isDEUser = !person.isGhostUser;
  const shouldAssignTraveler = isDEUser && isPersonMissingProfile;
  const deUserId = person.linkedDEUserId;

  return [shouldAssignTraveler ?? false, deUserId ?? ""];
};
