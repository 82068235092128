import { connect, ConnectedProps } from "react-redux";
import { withRouter } from "react-router";
import { BookingSubmitApprovalModal } from "./component";
import { getSubmitForApprovalCallState } from "../../../reducer";
import { IStoreState } from "../../../../../reducers/types";
import { CallState } from "redmond";

const mapStateToProps = (state: IStoreState) => ({
  isOpen: getSubmitForApprovalCallState(state) === CallState.Success,
});

const mapDispatchToProps = {};

const connector = connect(mapStateToProps, mapDispatchToProps);

export type BookingSubmitApprovalModalConnectorProps = ConnectedProps<
  typeof connector
>;

export const ConnectedBookingSubmitApprovalModal = withRouter(
  connector(BookingSubmitApprovalModal)
);
