import { PassengerFilter } from "../../../../reducer";

export const getPassengersLabel = (passengerFilterValue: PassengerFilter) => {
  switch (passengerFilterValue) {
    case PassengerFilter.UpToFive:
      return "2 - 5 passengers";
    case PassengerFilter.SixOrMore:
      return "6 passengers or more";
  }
};
