import { connect, ConnectedProps } from "react-redux";
import { withRouter } from "react-router";
import { IStoreState } from "../../../../../../reducers/types";
import { setPickUpDate, setDropOffDate } from "../../../../actions/actions";
import {
  getPickUpDate,
  getDropOffDate,
  getDropOffLocation,
  getDriverAge,
  getDropOffTime,
  getPickUpLocation,
  getPickUpTime,
} from "../../../../reducer";
import { MobileCalendarPickerModal } from "./component";

const mapDispatchToProps = {
  setPickUpDate,
  setDropOffDate,
};
const mapStateToProps = (state: IStoreState) => {
  const pickUpLocation = getPickUpLocation(state);
  const dropOffLocation = getDropOffLocation(state);
  const pickUpTime = getPickUpTime(state);
  const dropOffTime = getDropOffTime(state);
  const driverAge = getDriverAge(state);
  const pickUpDate = getPickUpDate(state);
  const dropOffDate = getDropOffDate(state);

  return {
    pickUpLocation,
    dropOffLocation,
    pickUpTime,
    dropOffTime,
    driverAge,
    pickUpDate,
    dropOffDate,
  };
};

const connector = connect(mapStateToProps, mapDispatchToProps);

export type MobileCalendarPickerModalConnectorProps = ConnectedProps<
  typeof connector
>;

export const ConnectedMobileCalendarPickerModal = withRouter(
  connector(MobileCalendarPickerModal)
);
