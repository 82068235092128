import React from "react";
import { Badge, Box, Typography } from "@material-ui/core";
import {
  ActionLink,
  BackButton,
  Icon,
  IconName,
  Header,
  ButtonWrap,
} from "halifax";
import { RouteComponentProps } from "react-router";
import { CarsAutocompleteResult } from "redmond";
import { MobileLocationSelectionConnectorProps } from "./container";
import { PATH_AVAILABILITY, PATH_HOME } from "../../../../utils/paths";
import "./styles.scss";
import { transformToStringifiedAvailabilityQuery } from "../../utils/queryStringHelpers";
import { MobileLocationSearchModal } from "../../../search/components/MobileCarSearchControlV2/components/MobileLocationSearchModal";
import * as textConstants from "./textConstants";
import clsx from "clsx";
import dayjs from "dayjs";
import { MobileCalendarPickerModal } from "../../../search/components/MobileCarSearchControlV2/components/MobileCalendarPickerModal";
import { AvailabilityFilters } from "../AvailabilityFilters";
import {
  AVAILABLE,
  GLOBAL_MOBILE_NAV_EXPERIMENT,
  getExperimentVariant,
  useExperiments,
} from "../../../../context/experiments";
import { TravelWalletDrawer } from "../../../travel-wallet/components";

interface IMobileLocationSelectionProps
  extends RouteComponentProps,
    MobileLocationSelectionConnectorProps {
  isCarsCXV1Experiment?: boolean;
  filterModalOpen: boolean;
  setFilterModalOpen: (open: boolean) => void;
}

export const MobileLocationSelection = (
  props: IMobileLocationSelectionProps
) => {
  const {
    history,
    pickUpLocation,
    dropOffLocation,
    pickUpTime,
    dropOffTime,
    driverAge,
    pickUpDate,
    dropOffDate,
    isCarsCXV1Experiment,
    filterModalOpen,
    setFilterModalOpen,
    walletItemCount,
  } = props;
  const [openLocationModal, setOpenLocationModal] =
    React.useState<boolean>(false);
  const [openDatesModal, setOpenDatesModal] = React.useState<boolean>(false);

  const [localPickUpLocation, setLocalPickUpLocation] =
    React.useState<CarsAutocompleteResult | null>(null);
  const [localDropOffLocation, setLocalDropOffLocation] =
    React.useState<CarsAutocompleteResult | null>(null);
  const [travelWalletDrawerOpen, setTravelWalletDrawerOpen] =
    React.useState<boolean>(false);

  const { experiments } = useExperiments();

  const isGlobalMobileNavExperiment =
    getExperimentVariant(experiments, GLOBAL_MOBILE_NAV_EXPERIMENT) ===
    AVAILABLE;

  React.useEffect(() => {
    setLocalPickUpLocation(pickUpLocation);
  }, [pickUpLocation]);
  React.useEffect(() => {
    setLocalDropOffLocation(dropOffLocation);
  }, [dropOffLocation]);

  const isReadyToSearch =
    dropOffDate &&
    dropOffTime &&
    localPickUpLocation &&
    pickUpDate &&
    pickUpTime &&
    localDropOffLocation &&
    driverAge;

  const onSearchAgain = () => {
    if (isReadyToSearch && localPickUpLocation && localDropOffLocation) {
      history.push(
        `${PATH_AVAILABILITY}${transformToStringifiedAvailabilityQuery(
          dropOffDate!,
          dropOffTime!,
          localDropOffLocation,
          pickUpDate!,
          pickUpTime!,
          localPickUpLocation,
          driverAge!
        )}`
      );
    }
  };
  return (
    <>
      <Header
        className={clsx("mobile-location-selection-header", {
          "cars-cx-v1": isCarsCXV1Experiment,
        })}
        left={
          <BackButton
            className="mobile-location-selection-go-back"
            onClick={() => history.push(PATH_HOME)}
          />
        }
        center={
          isCarsCXV1Experiment ? (
            <Box className="mobile-location-dates">
              <ButtonWrap
                className="mobile-header-button location"
                onClick={() => setOpenLocationModal(true)}
              >
                {pickUpLocation?.label}
              </ButtonWrap>
              <ButtonWrap
                className="mobile-header-button dates"
                onClick={() => setOpenDatesModal(true)}
              >
                {dayjs(pickUpDate).format("MMM D")} -{" "}
                {dayjs(dropOffDate).format("MMM D")}
              </ButtonWrap>
            </Box>
          ) : (
            <Box className="mobile-location-text">
              <Typography variant="body1" className="location-text">
                {pickUpLocation?.label}
              </Typography>
            </Box>
          )
        }
        right={
          isGlobalMobileNavExperiment ? (
            <>
              <ActionLink
                className="mobile-cars-availability-wallet-entry"
                onClick={() => setTravelWalletDrawerOpen(true)}
                content={
                  <Badge
                    className="wallet-count"
                    badgeContent={walletItemCount ? "" : undefined}
                  >
                    <Icon name={IconName.TravelWalletIcon} />
                  </Badge>
                }
              />
              <AvailabilityFilters
                modalOpen={filterModalOpen}
                setModalOpen={setFilterModalOpen}
              />
            </>
          ) : isCarsCXV1Experiment ? (
            <AvailabilityFilters
              modalOpen={filterModalOpen}
              setModalOpen={setFilterModalOpen}
            />
          ) : (
            <ActionLink
              className="mobile-location-button"
              onClick={() => setOpenLocationModal(true)}
              content={
                <Icon name={IconName.B2BEditPencil} className="edit-icon" />
              }
            />
          )
        }
      />
      <MobileLocationSearchModal
        openModal={openLocationModal}
        setOpenModal={setOpenLocationModal}
        history={history}
        onContinue={
          isReadyToSearch
            ? () => {
                onSearchAgain();
              }
            : undefined
        }
        setLocalPickUpLocation={setLocalPickUpLocation}
        setLocalDropOffLocation={setLocalDropOffLocation}
        searchOnContinue
        headerText={textConstants.CHANGE_LOCATION}
      />
      <MobileCalendarPickerModal
        openModal={openDatesModal}
        setOpenModal={setOpenDatesModal}
        searchOnContinue
        headerText={textConstants.CHANGE_DATES}
      />
      {isGlobalMobileNavExperiment && (
        <TravelWalletDrawer
          drawerOpen={travelWalletDrawerOpen}
          setDrawerOpen={setTravelWalletDrawerOpen}
        />
      )}
    </>
  );
};
