import { connect, ConnectedProps } from "react-redux";

import { DesktopCarSearchControl } from "./component";
import { IStoreState } from "../../../../reducers/types";
import {
  getDropOffDate,
  getDropOffLocation,
  getDropOffTime,
  getPickUpDate,
  getPickUpLocation,
  getPickUpTime,
  getDriverAge,
  getTripCategory,
} from "../../reducer/index";
import { actions } from "../../actions";
import { resetFilters } from "../../../availability/actions/actions";

const mapStateToProps = (state: IStoreState) => {
  return {
    dropOffDate: getDropOffDate(state),
    dropOffLocation: getDropOffLocation(state),
    dropOffTime: getDropOffTime(state),
    pickUpDate: getPickUpDate(state),
    pickUpLocation: getPickUpLocation(state),
    pickUpTime: getPickUpTime(state),
    driverAge: getDriverAge(state),
    tripCategory: getTripCategory(state),
  };
};

const mapDispatchToProps = {
  setDropOffLocation: actions.setDropOffLocation,
  setTripCategory: actions.setTripCategory,
  resetFilters: resetFilters,
};

const connector = connect(mapStateToProps, mapDispatchToProps);

export type DesktopCarSearchControlConnectorProps = ConnectedProps<
  typeof connector
>;

export const ConnectedDesktopCarSearchControl = connector(
  DesktopCarSearchControl
);
