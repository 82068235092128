import React from "react";
import { Box, Typography } from "@material-ui/core";

import { PriceBreakdownConnectorProps } from "./container";
import { LoadingIndicator, CarsPriceBreakdown, B2BSpinner } from "halifax";
import { CallState } from "redmond";
import "./styles.scss";

export interface IPriceBreakdownProps extends PriceBreakdownConnectorProps {
  showSummaryItems?: boolean;
}

export const PriceBreakdown = ({
  pollPriceQuoteCallState,
  pricingLineItems,
  carType,
  rewardsAndTotalLineItems,
  showSummaryItems = true,
}: IPriceBreakdownProps) => {
  const showLoadingIndicator =
    pollPriceQuoteCallState === CallState.InProcess ||
    pricingLineItems.length === 0;

  return (
    <Box className={"car-price-breakdown"}>
      {showLoadingIndicator ? (
        <LoadingIndicator
          indicatorSize={"small"}
          indicator={B2BSpinner}
          message={"Fetching latest price"}
        ></LoadingIndicator>
      ) : (
        <>
          <Typography className="title">{carType}</Typography>
          <CarsPriceBreakdown
            className="pricing-line-items-breakdown"
            pricingItems={pricingLineItems[0]}
            totalItems={pricingLineItems[1]}
            summaryItems={
              showSummaryItems ? rewardsAndTotalLineItems : undefined
            }
            currencySymbol={""}
          />
        </>
      )}
    </Box>
  );
};
