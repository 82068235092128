import React from "react";
import { Box, Typography } from "@material-ui/core";
import { DateRangePicker } from "halifax";
import dayjs from "dayjs";

import { MonthAndDatePickerConnectorProps } from "./container";
import * as textConstants from "./constants";
import "./styles.scss";

export interface IMonthAndDatePickerProps
  extends MonthAndDatePickerConnectorProps {
  viewType: MonthAndDatePickerType;
  header?: JSX.Element | string;
  // focusedMonthIndex must be given in order to make columnView focus on an arbitrary month
  focusedMonthIndex?: number;
  // setFocusedMonthIndex must be given for working with the groupView + columnView combination
  setFocusedMonthIndex?: (index: number) => void;
  setFromDate: (date: Date | null) => void;
  setUntilDate: (date: Date | null) => void;
  from: Date | null;
  until: Date | null;
  className?: string;
}

export const MonthAndDatePicker = (props: IMonthAndDatePickerProps) => {
  const {
    pickerType,
    from,
    until,
    setFromDate,
    setUntilDate,
    header,
    viewType,
    className,
  } = props;

  return (
    <>
      {header && (
        <Box className="month-and-date-picker-header-container">
          {typeof header === "string" ? (
            <Typography variant="body2">{header}</Typography>
          ) : (
            header
          )}
        </Box>
      )}
      <DateRangePicker
        className={className}
        minAllowedDate={dayjs().toDate()}
        maxAllowedDate={dayjs().add(1, "year").toDate()}
        currency={""}
        startDate={from}
        endDate={until}
        setStartDate={setFromDate}
        setEndDate={setUntilDate}
        months={[]}
        priceTags={[]}
        pickerType={pickerType}
        columnView={viewType === MonthAndDatePickerType.Column}
        hideSelectedDateIcon={true}
        renderSelectedStartDateLabel={(date) =>
          date
            ? `${textConstants.PICK_UP}: ${dayjs(date).format("MMM D")}`
            : textConstants.PICK_UP
        }
        renderSelectedEndDateLabel={(date) =>
          date
            ? `${textConstants.DROP_OFF} ${dayjs(date).format("MMM D")}`
            : textConstants.DROP_OFF
        }
      />
    </>
  );
};

export enum MonthAndDatePickerType {
  Horizontal,
  Column,
  Group,
}
