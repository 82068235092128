import axios from "axios";
import { config } from "../../../config";

import { carBookApiPrefix } from "../../paths";

import { HotelBookPollResult, PaymentSessionToken } from "redmond";

const END_POINT: string = `${carBookApiPrefix}/priceQuote/pollQuote`;

export const pollPriceQuote = (
  req: PaymentSessionToken
): Promise<HotelBookPollResult> =>
  new Promise(async (resolve, reject) => {
    try {
      const res = await axios.post(END_POINT, req, {
        baseURL: config.baseURL,
      });

      const responseBody = res.data;
      resolve(responseBody);
    } catch (e) {
      reject(e);
    }
  });
