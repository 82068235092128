import { connect, ConnectedProps } from "react-redux";
import { withRouter } from "react-router";
import { isCorpTenant } from "@capone/common";

import { IStoreState } from "../../../../reducers/types";
import {
  getCarAvailabilityLineItems,
  getCarAvailabilityCallState,
  getCarShopQueryParams,
  CarAvailabilityCallState,
  hasChangedCarAvailabilityCarTypeFilter,
  hasChangedCarAvailabilityPriceRangeFilter,
  hasChangedCarAvailabilityCarCompaniesFilter,
  hasChangedCarAvailabilitySpecificationsFilter,
  hasChangedCarAvailabilityCancellationPolicyFilter,
  getOpenDatesModal,
  hasChangedCarAvailabilityPassengersFilter,
  hasChangedCarAvailabilityPolicyFilter,
} from "../../reducer";
import {
  fetchInitialCarsAvailability,
  fetchMoreCarsAvailability,
  resetFilters,
  setOpenDatesModal,
} from "../../actions/actions";
import {
  setSelectedVehicleId,
  setSelectedVehicle,
} from "../../../shop/actions/actions";
import { CarAvailabilityList } from "./component";
import { getSelectedCarFromListProperties } from "../../../shop/reducer";
import { getExistingCarsAvailabilityRequestParameters } from "../../../search/reducer";
import { getTravelWalletCredit } from "../../../travel-wallet/reducer";
import {
  getRewardsAccountWithLargestEarnForBanner,
  getUserIsPrimaryCardHolder,
} from "../../../rewards/reducer";
import { config } from "../../../../api/config";

export enum FilterKey {
  CarType = "CarType",
  PriceRange = "PriceRange",
  CarCompany = "CarCompany",
  Specification = "Specification",
  Cancellation = "Cancellation",
  Passengers = "Passengers",
}

const mapStateToProps = (state: IStoreState) => {
  const carAvailabilityCallState = getCarAvailabilityCallState(state);
  const hasCompletedRequest =
    carAvailabilityCallState === CarAvailabilityCallState.Complete;
  const isLoadingFirstBatch =
    carAvailabilityCallState ===
    CarAvailabilityCallState.InitialSearchCallInProcess;
  const isReadyToFetchMoreCars =
    carAvailabilityCallState ===
      CarAvailabilityCallState.FollowUpSearchCallSuccess ||
    carAvailabilityCallState ===
      CarAvailabilityCallState.InitialSearchCallSuccess;

  const carShopQueryParams = getCarShopQueryParams(state);
  return {
    carAvailabilityLineItems: getCarAvailabilityLineItems(state),
    hasCompletedRequest,
    isLoadingFirstBatch,
    carShopQueryParams,
    isReadyToFetchMoreCars,
    selectedCarFromListProperties: getSelectedCarFromListProperties(state),
    filters: {
      CarType: hasChangedCarAvailabilityCarTypeFilter(state),
      PriceRange: hasChangedCarAvailabilityPriceRangeFilter(state),
      CarCompany: hasChangedCarAvailabilityCarCompaniesFilter(state),
      Specification: hasChangedCarAvailabilitySpecificationsFilter(state),
      Cancellation: hasChangedCarAvailabilityCancellationPolicyFilter(state),
      Passengers: hasChangedCarAvailabilityPassengersFilter(state),
      IsInPolicy: hasChangedCarAvailabilityPolicyFilter(state),
    },
    openDatesModal: getOpenDatesModal(state),
    availabilityRequestParameters:
      getExistingCarsAvailabilityRequestParameters(state),
    credit: getTravelWalletCredit(state),
    largestValueAccount: getRewardsAccountWithLargestEarnForBanner(state),
    canEarnRewards:
      !isCorpTenant(config.TENANT) || getUserIsPrimaryCardHolder(state),
  };
};

const mapDispatchToProps = {
  fetchInitialCarsAvailability,
  fetchMoreCarsAvailability,
  resetFilters,
  setSelectedVehicleId,
  setSelectedVehicle,
  setOpenDatesModal,
};

const connector = connect(mapStateToProps, mapDispatchToProps);

export type DesktopCarAvailabilityConnectorProps = ConnectedProps<
  typeof connector
>;

export const ConnectedCarAvailabilityList = withRouter(
  connector(CarAvailabilityList)
);
