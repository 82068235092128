import React, { useState } from "react";
import * as textConstants from "./textConstants";
import {
  MobileSearchFieldButton,
  Icon,
  IconName,
  DatePickerButton,
  NotificationBanner,
  BannerSeverity,
} from "halifax";
import { Box } from "@material-ui/core";
import { MobileCarSearchControlV2ConnectorProps } from "./container";
import { RouteComponentProps } from "react-router";
import "./styles.scss";
import { CarsTripCategory, RecentCarSearch } from "redmond";
import { DriverAgeInput } from "../DesktopCarSearchControl/components/DriverAgeInput";
import { CarsSearchButton } from "../DesktopCarSearchControl/components/SearchButton";
import clsx from "clsx";
import { PATH_AVAILABILITY } from "../../../../utils/paths";
import { transformToStringifiedAvailabilityQuery } from "../../../availability/utils/queryStringHelpers";
import H from "history";
import { MobileCalendarPickerModal } from "./components/MobileCalendarPickerModal";
import { MobileLocationSearchModal } from "./components/MobileLocationSearchModal";
import { MobileTimePickerModal } from "./components/MobileTimePickerModal";

interface IMobileCarSearchControlV2Props
  extends MobileCarSearchControlV2ConnectorProps,
    RouteComponentProps {
  recentSearches?: RecentCarSearch[];
  onRecentSearchClick?: (search: RecentCarSearch) => void;
  onSelectLocation?: (value: any) => void;
  onSelectDates?: (pickUp: Date | null, dropOff: Date | null) => void;
}

export const MobileCarSearchControlV2 = (
  props: IMobileCarSearchControlV2Props
) => {
  const {
    pickUpDate,
    dropOffDate,
    tripCategory,
    pickUpLocation,
    dropOffLocation,
    dropOffTime,
    pickUpTime,
    driverAge,
    history,
    recentSearches,
    onRecentSearchClick,
    onSelectLocation,
    onSelectDates,
  } = props;
  const [openPickUpLocationModal, setOpenPickUpLocationModal] = useState(false);
  const [openDropOffLocationModal, setOpenDropOffLocationModal] =
    useState(false);
  const [openCalendarModal, setOpenCalendarModal] = useState(false);
  const [openTimeModal, setOpenTimeModal] = useState(false);
  const [hasMissingSearchInfoError, setHasMissingSearchInfoError] =
    React.useState(false);

  const isReadyToSearch =
    !!dropOffDate &&
    !!dropOffTime &&
    !!dropOffLocation &&
    !!pickUpDate &&
    !!pickUpTime &&
    !!pickUpLocation &&
    !!driverAge;
  React.useEffect(() => {
    if (isReadyToSearch) {
      setHasMissingSearchInfoError(false);
    }
  }, [
    dropOffDate,
    dropOffTime,
    dropOffLocation,
    pickUpDate,
    pickUpTime,
    pickUpLocation,
    driverAge,
  ]);

  const handleSearch = (history: H.History) => {
    history.push(
      `${PATH_AVAILABILITY}${transformToStringifiedAvailabilityQuery(
        dropOffDate!,
        dropOffTime!,
        dropOffLocation!,
        pickUpDate!,
        pickUpTime!,
        pickUpLocation!,
        driverAge!
      )}`
    );
  };
  const handleSearchClick = (history: H.History) => {
    isReadyToSearch
      ? handleSearch(history)
      : setHasMissingSearchInfoError(true);
  };

  return (
    <Box className="mobile-car-search-v2">
      <MobileSearchFieldButton
        value={pickUpLocation?.label}
        label={textConstants.PICK_UP_LOCATION}
        icon={<Icon name={IconName.B2BMapPin} />}
        onClick={() => setOpenPickUpLocationModal(true)}
        isMissingSearchInfo={hasMissingSearchInfoError && !pickUpLocation}
        renderPopup={() => (
          <MobileLocationSearchModal
            openModal={openPickUpLocationModal}
            setOpenModal={setOpenPickUpLocationModal}
            history={history}
            recentSearches={recentSearches}
            onRecentSearchClick={onRecentSearchClick}
            onSelectLocation={onSelectLocation}
          />
        )}
      />
      {tripCategory === CarsTripCategory.DIFFERENT_DROP_OFF ? (
        <MobileSearchFieldButton
          value={dropOffLocation?.label}
          label={textConstants.DROP_OFF_LOCATION}
          icon={<Icon name={IconName.B2BMapPin} />}
          onClick={() => setOpenDropOffLocationModal(true)}
          isMissingSearchInfo={hasMissingSearchInfoError && !dropOffLocation}
          renderPopup={
            openDropOffLocationModal
              ? () => (
                  <MobileLocationSearchModal
                    openModal={openDropOffLocationModal}
                    setOpenModal={setOpenDropOffLocationModal}
                    history={history}
                    focusDropOff={openDropOffLocationModal}
                    onSelectLocation={onSelectLocation}
                  />
                )
              : undefined
          }
        />
      ) : null}

      {!pickUpDate || !dropOffDate ? (
        <MobileSearchFieldButton
          label={textConstants.CHOOSE_DATES}
          icon={<Icon name={IconName.Calendar} />}
          onClick={() => setOpenCalendarModal(true)}
          isMissingSearchInfo={
            hasMissingSearchInfoError && (!pickUpDate || !dropOffDate)
          }
          renderPopup={() => (
            <MobileCalendarPickerModal
              openModal={openCalendarModal}
              setOpenModal={setOpenCalendarModal}
              onSelectDates={onSelectDates}
            />
          )}
        />
      ) : (
        <DatePickerButton
          startDate={pickUpDate}
          endDate={dropOffDate}
          startLabel={textConstants.PICK_UP}
          endLabel={textConstants.DROP_OFF}
          classes={["car-inline-juncture-input"]}
          onClick={() => setOpenCalendarModal(true)}
          dateFormat={"ddd, MMM D"}
          renderCalendarPopup={() => (
            <MobileCalendarPickerModal
              openModal={openCalendarModal}
              setOpenModal={setOpenCalendarModal}
              onSelectDates={onSelectDates}
            />
          )}
        />
      )}
      <Box className="time-selection-buttons-container">
        <MobileSearchFieldButton
          classes={["pick-up-time-button"]}
          value={pickUpTime || ""}
          label={textConstants.PICK_UP_TIME}
          icon={<Icon name={IconName.ClockIcon} />}
          onClick={() => setOpenTimeModal(true)}
          endIcon={<Icon name={IconName.Dropdown} />}
          renderPopup={() => (
            <MobileTimePickerModal
              openModal={openTimeModal}
              setOpenModal={setOpenTimeModal}
            />
          )}
        />
        <MobileSearchFieldButton
          classes={["drop-off-time-button"]}
          value={dropOffTime || ""}
          label={textConstants.DROP_OFF_TIME}
          icon={<Icon name={IconName.ClockIcon} />}
          onClick={() => setOpenTimeModal(true)}
          endIcon={<Icon name={IconName.Dropdown} />}
          renderPopup={() => (
            <MobileTimePickerModal
              openModal={openTimeModal}
              setOpenModal={setOpenTimeModal}
            />
          )}
        />
      </Box>
      <DriverAgeInput
        className="driver-age-input"
        hasMissingSearchInfoError={!driverAge}
        useModal
      />

      {hasMissingSearchInfoError && (
        <Box className="missing-info-search-error-container">
          <NotificationBanner
            className={clsx("missing-info-search-error-banner")}
            label={textConstants.MISSING_INFO_SEARCH_ERROR}
            severity={BannerSeverity.ERROR}
            icon={<Icon name={IconName.WarningAlert} />}
          />
        </Box>
      )}
      <CarsSearchButton
        className={clsx("car-search-control-button", "b2b")}
        message={textConstants.SEARCH_CARS}
        onClick={(history) => {
          handleSearchClick(history);
        }}
        enabled={true}
      />
    </Box>
  );
};
