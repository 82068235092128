import { connect, ConnectedProps } from "react-redux";
import { withRouter } from "react-router";

import { IStoreState } from "../../../../reducers/types";
import {
  getTripCategory,
  getExistingCarsAvailabilityRequestParameters,
} from "../../../search/reducer";
import {
  getPickUpLocationSearched,
  getPickUpDateSearched,
  getDropOffDateSearched,
  areRequestParametersChanged,
  isCarAvailabilityInEmptyState,
} from "../../reducer";
import {
  fetchInitialCarsAvailability,
  resetFilters,
} from "../../actions/actions";
import { setTripCategory } from "../../../search/actions/actions";
import { DesktopCarsAvailability } from "./component";
import { getTravelWalletCredit } from "../../../travel-wallet/reducer";

const mapStateToProps = (state: IStoreState) => {
  return {
    pickUplocation: getPickUpLocationSearched(state),
    fromDate: getPickUpDateSearched(state),
    untilDate: getDropOffDateSearched(state),
    tripCategory: getTripCategory(state),
    availabilityRequestParameters:
      getExistingCarsAvailabilityRequestParameters(state),
    areRequestParametersChanged: areRequestParametersChanged(state),
    isCarAvailabilityInEmptyState: isCarAvailabilityInEmptyState(state),
    credit: getTravelWalletCredit(state),
  };
};

const mapDispatchToProps = {
  setTripCategory,
  fetchInitialCarsAvailability,
  resetFilters,
};

const connector = connect(mapStateToProps, mapDispatchToProps);

export type DesktopCarAvailabilityConnectorProps = ConnectedProps<
  typeof connector
>;

export const ConnectedDesktopCarsAvailability = withRouter(
  connector(DesktopCarsAvailability)
);
