import axios from "axios";
import { config } from "../../../config";
import { IPerson } from "redmond";

import { passengersApiPrefix } from "../../paths";

const END_POINT: string = `${passengersApiPrefix}/list`;

export const fetchUserPassengers = (
  body: IListUserPassengersRequest
): Promise<IListUserPassengersResponse> =>
  new Promise(async (resolve, reject) => {
    try {
      const res = await axios.put(END_POINT, body, {
        baseURL: config.baseURL,
      });

      const responseBody: IPerson[] = res.data.value;
      resolve(responseBody);
    } catch (e) {
      reject(e);
    }
  });

export interface IListUserPassengersRequest {}

export interface IListUserPassengersResponse extends Array<IPerson> {}
