import React, { useContext, useState } from "react";
import {
  GenericInfoPopup,
  Icon,
  IconName,
  getAgentErrorSubtitle,
  getAgentErrorTitle,
  useDeviceTypes,
} from "halifax";
import { RouteComponentProps } from "react-router";
import {
  ErrorType,
  ModalAlertProperties,
  ModalButtonType,
  ModalCategoryType,
  ModalScreens,
  MODAL_ALERT,
} from "redmond";

import { ClientContext } from "../../../../App";
import { PATH_HOME } from "../../../../utils/paths";
import { AvailabilityErrorModalConnectorProps } from "./container";
import * as textConstants from "./textConstants";

import "./styles.scss";
import { trackEvent } from "../../../../api/v1/analytics/trackEvent";

export interface IAvailabilityErrorModalProps
  extends AvailabilityErrorModalConnectorProps,
    RouteComponentProps {}

export const AvailabilityErrorModal = (props: IAvailabilityErrorModalProps) => {
  const { hasFailed, history, fetchInitialCarsAvailability, resetCallStates } =
    props;
  const { isAgentPortal } = useContext(ClientContext);
  const { matchesMobile } = useDeviceTypes();
  const [isOpen, setIsOpen] = useState<boolean>(false);

  const modalEventProperties: ModalAlertProperties = {
    type: "cars_availability_error",
    primary_button: textConstants.RETRY_BUTTON,
    secondary_button: textConstants.SEARCH_AGAIN_BUTTON,
    screen: ModalScreens.CARS_AVAILABILITY,
    category: ModalCategoryType.TROUBLE,
    modal_subtitle: textConstants.AVAILABILITY_ERROR_SUBTITLE,
    modal_title: textConstants.AVAILABILITY_ERROR_TITLE,
    agent_subtitle: getAgentErrorSubtitle(ErrorType.NoAvailability),
    agent_title: getAgentErrorTitle(ErrorType.NoAvailability),
    funnel: "cars",
    step: "availability",
  };
  React.useEffect(() => {
    if (hasFailed) {
      setIsOpen(true);
      trackEvent({
        eventName: MODAL_ALERT,
        properties: { ...modalEventProperties },
      });
    }
  }, [hasFailed]);

  const buttons = [
    {
      buttonText: textConstants.RETRY_BUTTON,
      buttonWrapperClassName: "availability-error-modal-button",
      defaultStyle: "h4r-primary",
      onClick: () => {
        setIsOpen(false);
        trackEvent({
          eventName: MODAL_ALERT,
          properties: {
            ...modalEventProperties,
            button_choice: ModalButtonType.PRIMARY,
          },
        });
        fetchInitialCarsAvailability(history);
      },
    },
    {
      buttonText: textConstants.SEARCH_AGAIN_BUTTON,
      buttonWrapperClassName: "availability-error-modal-button",
      defaultStyle: "h4r-secondary",
      onClick: () => {
        setIsOpen(false);
        trackEvent({
          eventName: MODAL_ALERT,
          properties: {
            ...modalEventProperties,
            button_choice: ModalButtonType.SECONDARY,
          },
        });
        resetCallStates();
        history.push(PATH_HOME);
      },
    },
  ];

  return (
    <GenericInfoPopup
      agentSubtitle={getAgentErrorSubtitle(ErrorType.NoAvailability)}
      agentTitle={getAgentErrorTitle(ErrorType.NoAvailability)}
      buttons={buttons}
      className="car-availability-error-modal-root"
      image={<Icon className="error-icon" name={IconName.ErrorState} />}
      isAgent={isAgentPortal}
      isMobile={matchesMobile}
      open={isOpen}
      subtitle={textConstants.AVAILABILITY_ERROR_SUBTITLE}
      title={textConstants.AVAILABILITY_ERROR_TITLE}
    />
  );
};
