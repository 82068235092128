import * as React from "react";
import { Box, Typography } from "@material-ui/core";
import { IPriceRange } from "redmond";
import clsx from "clsx";
import { GenericSlider, useDeviceTypes } from "halifax";
import "./styles.scss";
import { debounce } from "lodash-es";
import { PRICE_FILTER_SUBTITLE, PRICE_FILTER_TITLE } from "../../textConstants";
import { initialFilterState } from "../../../../reducer";

interface IMaxPriceSelectionProps {
  minMaxPriceRange: IPriceRange;
  maxPrice: number;
  setMaxPrice: (newMaxPrice: number) => void;
  showTitles?: boolean;
}

export const MaxPriceSelection = (props: IMaxPriceSelectionProps) => {
  const { minMaxPriceRange, maxPrice, setMaxPrice, showTitles } = props;

  const [localValue, setLocalValue] = React.useState(maxPrice);

  const { matchesMobile } = useDeviceTypes();

  const onMaxPriceChange = (newMaxPrice: number) => {
    if (newMaxPrice === minMaxPriceRange.max) {
      setMaxPrice(0); // reset
    } else {
      setMaxPrice(newMaxPrice);
    }
  };

  const handleMaxPriceChangeDebounced = React.useMemo(
    () => debounce(onMaxPriceChange, 150),
    []
  );

  const handleMaxPriceChange = (_: number, newMaxPrice: number) => {
    setLocalValue(newMaxPrice);
    handleMaxPriceChangeDebounced(newMaxPrice);
  };

  React.useEffect(() => {
    setLocalValue(maxPrice);
  }, [maxPrice]);

  const valueToShow = localValue || minMaxPriceRange.max;

  const roundedMin = Math.floor(minMaxPriceRange.min + 1);

  const isMinValue = React.useMemo(() => {
    if (showTitles) {
      if (localValue === initialFilterState.maxTotalPrice) {
        return false;
      } else {
        const sliderValuePercentage =
          (localValue - roundedMin) / (minMaxPriceRange.max - roundedMin);

        return sliderValuePercentage <= 0.05;
      }
    }
    return false;
  }, [showTitles, localValue, roundedMin, minMaxPriceRange]);

  return (
    <Box
      className={clsx("max-price-selection-root", {
        mobile: matchesMobile,
        "min-value": isMinValue,
      })}
    >
      <Box className="max-price-selection-container">
        {showTitles && (
          <>
            <Typography variant="body1" className="max-price-title">
              {PRICE_FILTER_TITLE}
            </Typography>
            <Typography variant="body2" className="max-price-subtitle">
              {PRICE_FILTER_SUBTITLE}
            </Typography>
          </>
        )}

        <Box className="max-price-slider-container">
          <GenericSlider
            className={clsx("max-price-slider-root", {
              "min-value": isMinValue,
            })}
            sliderType="singleThumb"
            onChange={handleMaxPriceChange}
            step={1}
            chosenMin={roundedMin}
            chosenMax={valueToShow}
            sliderMin={roundedMin}
            sliderMax={minMaxPriceRange.max}
            getLabel={(value: number) => `$${value}`}
            singleThumbType="max"
            alwaysShowTooltip
            showResetButton={!!maxPrice}
            reset={() => handleMaxPriceChange(0, 0)}
            marksToDisplay={[
              {
                value: roundedMin,
                label: `$${roundedMin}`,
              },
            ]}
          />
        </Box>
      </Box>
    </Box>
  );
};
