import React from "react";
import {
  Box,
  Checkbox,
  FormGroup,
  FormControl,
  FormControlLabel,
  Typography,
} from "@material-ui/core";
import { useDeviceTypes } from "halifax";
import clsx from "clsx";

import "./styles.scss";
import { getBagsLabel } from "./textConstants";

export interface IBagsSelectionProps {
  className?: string;
  bagsSelected: number[];
  setBagsFilter: (bagsFilter: number[]) => void;
  isCarsCXV1Experiment?: boolean;
}

export const BagsSelection = (props: IBagsSelectionProps) => {
  const { className, bagsSelected, setBagsFilter, isCarsCXV1Experiment } =
    props;
  const { matchesMobile } = useDeviceTypes();

  const isChecked = (bagCount: number): boolean => {
    return bagsSelected.some((type) => type === bagCount);
  };

  const handleChange = (bagCount: number) => {
    if (isChecked(bagCount)) {
      const selectedAfterRemove = bagsSelected.filter(
        (selectedFilter: number) => selectedFilter !== bagCount
      );
      setBagsFilter(selectedAfterRemove);
    } else {
      const newSelected = [...bagsSelected, bagCount] as number[];
      setBagsFilter(newSelected);
    }
  };

  const renderOptions = (options: number[]) => {
    return options.map((bagCount: number) => {
      const checked = isChecked(bagCount);
      return (
        <FormControlLabel
          key={`${bagCount}-bags`}
          onKeyDown={(e) => {
            if (e && e.key === "Enter") {
              handleChange(bagCount);
            }
          }}
          control={
            <Checkbox
              inputProps={{ "aria-labelledby": `${bagCount}-bags` }}
              checked={checked}
              onChange={() => handleChange(bagCount)}
              value={bagCount}
              className="bags-selection-checkbox"
            />
          }
          labelPlacement={
            isCarsCXV1Experiment && !matchesMobile ? "end" : "start"
          }
          label={
            <Box id={`${bagCount}-bags`} className="bags-label-wrapper">
              <Typography variant="body1" className="bags-label">
                {getBagsLabel(bagCount)}
              </Typography>
            </Box>
          }
          className="bags-selection-form-control-label"
        />
      );
    });
  };

  return (
    <Box
      className={clsx(
        "bags-selection-root",
        { mobile: matchesMobile, "cars-cx-v1": isCarsCXV1Experiment },
        className
      )}
    >
      <Box className="bags-selection-container">
        <FormControl
          className="bags-selection-form-control"
          component="fieldset"
        >
          <FormGroup>{renderOptions([2, 3, 4, 5])}</FormGroup>
        </FormControl>
      </Box>
    </Box>
  );
};
