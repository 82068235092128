import { PickUpType } from "redmond";

export const getPickUpLabel = (pickUpFilterValue: PickUpType) => {
  switch (pickUpFilterValue) {
    case PickUpType.Airport:
      return "Pick-up at airport";
    case PickUpType.Shuttle:
      return "Free airport shuttle to pick-up location";
    case PickUpType.MeetAndGreet:
      return "Meet and Greet";
  }
};
