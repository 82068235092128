import { connect, ConnectedProps } from "react-redux";
import { withRouter } from "react-router";
import { IStoreState } from "../../../../reducers/types";
import { setMobileCarSearchStep } from "./../../../search/actions/actions";
import { getCarShopSelectedVehicle } from "../../reducer/selectors";
import { MobileShopHeader } from "./component";
import {
  getPickUpDate,
  getDropOffDate,
  getDropOffLocation,
  getPickUpLocation,
  getPickUpTime,
  getDropOffTime,
  getDriverAge,
} from "../../../search/reducer";
import { setPickUpDate, setDropOffDate } from "../../../search/actions/actions";
import { fetchCarExtraInfo } from "../../actions/actions";

const mapStateToProps = (state: IStoreState) => {
  return {
    selectedVehicle: getCarShopSelectedVehicle(state),
    dropOffDate: getDropOffDate(state),
    dropOffTime: getDropOffTime(state),
    dropOffLocation: getDropOffLocation(state),
    pickUpDate: getPickUpDate(state),
    pickUpTime: getPickUpTime(state),
    pickUpLocation: getPickUpLocation(state),
    driverAge: getDriverAge(state),
  };
};

export const mapDispatchToProps = {
  fetchCarExtraInfo,
  setPickUpDate,
  setDropOffDate,
  setMobileCarSearchStep,
};

const connector = connect(mapStateToProps, mapDispatchToProps);

export type MobileShopHeaderConnectorProps = ConnectedProps<typeof connector>;

export const ConnectedMobileShopHeader = withRouter(
  connector(MobileShopHeader)
);
