import axios from "axios";
import { config } from "../../config";
import { fetchPolicies } from "../../paths";
import { PolicyTier } from "redmond";

type PolicyResponse = {
  policyDetail: PolicyTier;
};

export const fetchUserPolicies = (): Promise<PolicyResponse> =>
  new Promise(async (resolve, reject) => {
    try {
      const res = await axios.post(fetchPolicies, {}, {
        baseURL: config.baseURL,
      });

      resolve(res.data);
    } catch (e) {
      reject(e);
    }
  });

export default fetchUserPolicies;
