export const TRAVELER_INFO_TITLE = "Step 1: Driver Information";
export const TRAVELER_INFO_TITLE_UPDATED = "Select or Add Driver";
export const TRAVELER_INFO_SUBTITLE =
  "Make sure the information you add matches the driver’s license. You can’t change this information after booking.";
export const TRAVELER_INFO_FORM_SUBTITLE =
  "Make sure the information here matches your travel ID. You won’t be able to change Driver Information after you confirm your booking.";
// TODO: remove unused text constant or replace with empty string as componet needs
export const FREQUENT_FLYER_TITLE = "Frequent Flyer";
export const ADDITIONAL_INFO_TITLE = "Additional Info";
export const ADULT_TITLE = "Adult";
export const CHILD_TITLE = "Child";
export const INFANT_SEAT_TITLE = "Infant";
export const INFANT_LAP_TITLE = "Infant";
export const UPDATE_TEXT = "Update Driver";
export const VALIDATE_TEXT = "Validate";
export const SELECT_DRIVER_TEXT = "Select Driver";
export const ADD_TRAVELERS_TEXT = "Add Driver";
export const ADD_TRAVELERS_TEXT_UPDATED = "Select or Add Driver";
export const ADD_TRAVELERS_TEXT_NEW = "Add New Driver";
export const ADD_TRAVELER_INFO_TEXT = "Add Driver Information";
export const TRAVELER_INFO_TEXT = "Driver Information";
export const ADD_TRAVELER_ERROR_MESSAGE =
  "Add or choose one driver to continue.";

// corporate
export const COMPLETE_PROFILE_TITLE = "Complete traveler profile";
export const COMPLETE_PROFILE_SUBTITLE =
  "Please make sure all the information added matches the government issued ID for this traveler as this will be applied to travel bookings moving forward. Traveler information will be accessible to all Admins.";
