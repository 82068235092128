import React from "react";
import {
  MobilePopoverCard,
  ActionLink,
  CloseButtonIcon,
  DesktopPopupModal,
} from "halifax";
import { Box, Typography } from "@material-ui/core";
import { VendorMessages } from "redmond";
import { RENTAL_LOCATION_DETAILS } from "../CarShopSmallMap/constants";
import "./styles.scss";

export interface ICarLocationDetailsModalProps {
  isMobile: boolean | undefined;
  onClose: () => void;
  vendorMessages?: VendorMessages | undefined;
}

const defaultProps: Partial<ICarLocationDetailsModalProps> = {
  vendorMessages: {},
};

const CarLocationDetailsModal = ({
  isMobile,
  onClose,
  vendorMessages,
}: {
  isMobile: boolean | undefined;
  onClose: () => void;
  vendorMessages?: VendorMessages | undefined;
}) => {
  const renderContent = (displayTitle?: boolean) => {
    const { displayText, text } =
      vendorMessages?.pickUpDirections?.details[0] ?? {};

    return (
      <>
        {displayTitle && (
          <Typography className="title" variant="h2">
            {RENTAL_LOCATION_DETAILS}
          </Typography>
        )}
        <Typography
          className="content"
          variant="body2"
          dangerouslySetInnerHTML={{
            __html: displayText || text || "",
          }}
        />
      </>
    );
  };

  if (isMobile) {
    return (
      <MobilePopoverCard
        open
        onClose={() => onClose()}
        centered
        contentClassName="location-detail-mobile-popover-content"
        headerElement={
          <Box className="car-location-details-modal-header">
            <Typography className="header-title">
              {RENTAL_LOCATION_DETAILS}
            </Typography>
            <ActionLink
              className="modal-close-button"
              content={<CloseButtonIcon />}
              label="Close"
              onClick={() => onClose()}
            />
          </Box>
        }
        className="car-location-details-modal"
      >
        <Box className="car-location-details-modal-content">
          {renderContent(false)}
        </Box>
      </MobilePopoverCard>
    );
  }
  return (
    <DesktopPopupModal
      className="location-detail-popover-content"
      open
      onClose={() => onClose()}
      invisibleBackdrop={false}
    >
      <Box className="car-location-details-modal-content">
        {renderContent(true)}
      </Box>
    </DesktopPopupModal>
  );
};

CarLocationDetailsModal.defaultProps = defaultProps;

export default CarLocationDetailsModal;
