import React, { useEffect, useState } from "react";
import { Box, useScrollTrigger } from "@material-ui/core";
import { RouterProps } from "react-router";
import { MobileCarAvailabilityConnectorProps } from "./container";
import {
  CarAvailabilityList,
  AvailabilityFilters,
  AvailabilityFilterTags,
  AvailabilityNoResults,
} from "../../components";
import { RewardsAccountSelection } from "../../../rewards/components";
import { MobileCarSearchStep } from "../../../search/reducer";
import { PATH_HOME_SEARCH } from "../../../../utils/paths";
import "./styles.scss";
import {
  AVAILABLE,
  CARS_CX_V1,
  getExperimentVariant,
  GLOBAL_MOBILE_NAV_EXPERIMENT,
  TRAVEL_WALLET_CREDITS_EXPERIMENT,
  useExperiments,
} from "../../../../context/experiments";
import { SelectedTravelOfferScreen } from "redmond";
import { BackToTopButton, FloatingMenuPill, IconName } from "halifax";
import {
  TravelWalletDetailsBanner,
  TravelWalletDrawer,
} from "../../../travel-wallet/components";
import { MobileLocationSelection } from "../MobileLocationSelection";
import { MobileDatesSelection } from "../MobileDatesSelection";
import clsx from "clsx";

export interface IMobileCarsAvailability
  extends MobileCarAvailabilityConnectorProps,
    RouterProps {}

export const MobileCarsAvailability = (props: IMobileCarsAvailability) => {
  const {
    pickUpDate,
    dropOffDate,
    isCarAvailabilityInEmptyState,
    setMobileCarSearchStep,
    history,
    credit,
    availabilityCallState,
    appliedFilterCount,
  } = props;

  const expState = useExperiments();

  const travelWalletCreditsExperiment = getExperimentVariant(
    expState.experiments,
    TRAVEL_WALLET_CREDITS_EXPERIMENT
  );
  const isTravelWalletCreditsExperiment = React.useMemo(
    () => travelWalletCreditsExperiment === AVAILABLE,
    [travelWalletCreditsExperiment]
  );

  const carsCXV1Experiment = getExperimentVariant(
    expState.experiments,
    CARS_CX_V1
  );
  const isCarsCXV1Experiment = React.useMemo(
    () => carsCXV1Experiment === AVAILABLE,
    [carsCXV1Experiment]
  );

  const globalMobileNavExperimentVariant = getExperimentVariant(
    expState.experiments,
    GLOBAL_MOBILE_NAV_EXPERIMENT
  );
  const isGlobalMobileNavExperiment = React.useMemo(
    () => globalMobileNavExperimentVariant === AVAILABLE,
    [globalMobileNavExperimentVariant]
  );

  const [showTravelWalletBanner, setShowTravelWalletBanner] = useState(false);
  const [filterModalOpen, setFilterModalOpen] = useState(false);

  useEffect(() => {
    setShowTravelWalletBanner((credit && credit.amount.amount < 0) || false);
  }, [credit]);

  useEffect(() => {
    const bannerHeight =
      document.querySelector(".b2b-portal-banner-root")?.getBoundingClientRect()
        .height || 0;

    const mobileRoot = document.querySelector(".car-availability-root");

    if (mobileRoot) {
      (mobileRoot as unknown as any).style["margin-top"] = `${bannerHeight}px`;
    }
  }, [availabilityCallState]);

  const handleGoBack = (step: MobileCarSearchStep) => {
    setMobileCarSearchStep(step);
    history.push(PATH_HOME_SEARCH);
  };

  const scrollTrigger = useScrollTrigger({ disableHysteresis: true });

  return (
    <Box
      className={clsx("mobile-car-availability-root", {
        "global-mobile-nav": isGlobalMobileNavExperiment,
      })}
    >
      <Box className="mobile-car-availability-container">
        <Box
          className={clsx("mobile-edit-location-section", {
            "global-mobile-nav": isGlobalMobileNavExperiment,
            scrolled: scrollTrigger,
          })}
        >
          <MobileLocationSelection
            isCarsCXV1Experiment={isCarsCXV1Experiment}
            filterModalOpen={filterModalOpen}
            setFilterModalOpen={setFilterModalOpen}
          />
        </Box>
        <Box className="mobile-rewards-account-contents">
          <RewardsAccountSelection className="b2b" popoverClassName="b2b" />
        </Box>
        {!isCarsCXV1Experiment && (
          <Box className="dates-and-filters-section">
            <Box className="dates-and-filters-wrapper">
              {pickUpDate && dropOffDate && <MobileDatesSelection />}
              <AvailabilityFilters
                modalOpen={filterModalOpen}
                setModalOpen={setFilterModalOpen}
              />
            </Box>
            {!isCarsCXV1Experiment && (
              <Box className="filter-tags-section">
                <AvailabilityFilterTags />
              </Box>
            )}
          </Box>
        )}
        {showTravelWalletBanner && (
          <TravelWalletDetailsBanner
            variant="default"
            screen={SelectedTravelOfferScreen.CARS_SHOP}
            bannerTextType="availability"
          />
        )}
        {isCarAvailabilityInEmptyState ? (
          <Box className="car-availability-no-results-wrapper">
            <AvailabilityNoResults />
          </Box>
        ) : (
          <Box className="car-availability-list-wrapper">
            <CarAvailabilityList
              onAdjustDateClick={() =>
                handleGoBack(MobileCarSearchStep.CalendarPicker)
              }
            />
          </Box>
        )}
        {isGlobalMobileNavExperiment && (
          <FloatingMenuPill
            items={[
              {
                label: "Filter",
                onClick: () => setFilterModalOpen(true),
                icon: IconName.Settings,
                badge: appliedFilterCount
                  ? String(appliedFilterCount)
                  : undefined,
              },
            ]}
          />
        )}
        {isGlobalMobileNavExperiment ? <BackToTopButton /> : undefined}
        {!isGlobalMobileNavExperiment && isTravelWalletCreditsExperiment ? (
          <TravelWalletDrawer />
        ) : undefined}
      </Box>
    </Box>
  );
};
