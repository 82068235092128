import React from "react";
import {
  Box,
  Checkbox,
  FormGroup,
  FormControl,
  FormControlLabel,
  Typography,
} from "@material-ui/core";
import { useDeviceTypes } from "halifax";
import clsx from "clsx";

import { ISpecifications, Specification } from "../../../../reducer";
import { getSpecificationTitle } from "./textConstants";
import "./styles.scss";
import { randomIdWithName } from "../../../../utils/randomIdWithName";

export interface ISpecificationSelectionProps {
  className?: string;
  specifications: ISpecifications;
  setSpecifications: (specifications: ISpecifications) => void;
  isCarsCXV1Experiment?: boolean;
}

export const SpecificationSelection = (props: ISpecificationSelectionProps) => {
  const { className, specifications, setSpecifications, isCarsCXV1Experiment } =
    props;
  const { matchesMobile } = useDeviceTypes();
  const isChecked = (specification: string): boolean => {
    return specifications[specification];
  };

  const handleChange = (specification: string) => {
    let newSelected = { ...specifications };
    newSelected[specification] = !newSelected[specification];
    setSpecifications(newSelected);
  };

  const renderOptions = (options: Specification[]) => {
    return options.map((specification) => {
      const title = getSpecificationTitle(specification);
      const checked = isChecked(specification);
      const id = randomIdWithName(specification);
      return (
        <FormControlLabel
          key={specification}
          className="specifications-selection-form-control-label"
          onKeyDown={(e) => {
            if (e && e.key === "Enter") {
              handleChange(specification);
            }
          }}
          control={
            <Checkbox
              inputProps={{ "aria-labelledby": id }}
              checked={checked}
              onChange={() => handleChange(specification)}
              value={specification}
              className="specifications-selection-checkbox"
            />
          }
          labelPlacement={
            isCarsCXV1Experiment && !matchesMobile ? "end" : "start"
          }
          label={
            <Box className="specification-label-wrapper">
              <Typography
                id={id}
                variant="body2"
                className="specification-label"
              >
                {title}
              </Typography>
            </Box>
          }
        />
      );
    });
  };

  return (
    <Box
      className={clsx(
        "specification-selection-root",
        { mobile: matchesMobile, "cars-cx-v1": isCarsCXV1Experiment },
        className
      )}
    >
      <Box className="specification-selection-container">
        <FormControl
          className="specifications-selection-form-control"
          component="fieldset"
        >
          <FormGroup>
            {renderOptions([
              Specification.AirConditioning,
              Specification.AutomaticTransmission,
              Specification.UnlimitedMileage,
            ])}
          </FormGroup>
        </FormControl>
      </Box>
    </Box>
  );
};
