import axios from "axios";
import { fetchUsersList } from "../../paths";
import { config } from "../../config";

export interface CorpUsersListResponse {
  users: CorpUsersListPayload[];
}
export interface CorpUsersListPayload {
  id: string;
  firstName: string;
  lastName: string;
  defaultTravelerId: string;
}

export const fetchCorpUsersList = (): Promise<CorpUsersListResponse> =>
  new Promise(async (resolve, reject) => {
    try {
      const res = await axios.put(fetchUsersList, {
        baseURL: config.baseURL,
      });

      resolve(res.data);
    } catch (e) {
      reject(e);
    }
  });

export default fetchCorpUsersList;
