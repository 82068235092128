export const BASE_PATH_HOME = "/";

export const PATH_HOME = "/cars/";
export const PATH_HOME_SEARCH = PATH_HOME + "search/";
export const PATH_AVAILABILITY = PATH_HOME + "availability/";
export const PATH_SHOP = PATH_HOME + "shop/";
export const PATH_BOOK = PATH_HOME + "book/";
export const PATH_BOOK_CONFIRMATION = PATH_BOOK + "confirm/";
export const PATH_TERMS = `${BASE_PATH_HOME}terms/`;
export const PATH_BEST_PRICE_GUARANTEE = `${PATH_TERMS}best-price-guarantee`;
export const PATH_TRAVEL_SALE = `/travelsale/`;

// Corporate
export const PATH_PRICE_MATCH_GUARANTEE = `${PATH_TERMS}price-match-guarantee`;
