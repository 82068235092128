import { connect, ConnectedProps } from "react-redux";
import { withRouter } from "react-router";

import { MobileLocationSelection } from "./component";
import { IStoreState } from "../../../../reducers/types";
import { actions } from "../../../search/actions";
import {
  getDriverAge,
  getDropOffDate,
  getDropOffLocation,
  getDropOffTime,
  getPickUpDate,
  getPickUpLocation,
  getPickUpTime,
} from "../../../search/reducer";
import {
  getTravelWalletCredit,
  getTravelWalletOffers,
} from "../../../travel-wallet/reducer";

const mapStateToProps = (state: IStoreState) => {
  const pickUpLocation = getPickUpLocation(state);
  const dropOffLocation = getDropOffLocation(state);
  const pickUpTime = getPickUpTime(state);
  const dropOffTime = getDropOffTime(state);
  const driverAge = getDriverAge(state);
  const pickUpDate = getPickUpDate(state);
  const dropOffDate = getDropOffDate(state);

  return {
    pickUpLocation,
    dropOffLocation,
    pickUpTime,
    dropOffTime,
    driverAge,
    pickUpDate,
    dropOffDate,
    walletItemCount: getTravelWalletCredit(state)
      ? getTravelWalletOffers(state).length + 1
      : getTravelWalletOffers(state).length,
  };
};

const mapDispatchToProps = {
  setPickUpLocation: actions.setPickUpLocation,
  setDropOffLocation: actions.setDropOffLocation,
  fetchPickUpLocationResults: actions.fetchPickUpLocationResults,
  fetchDropOffLocationResults: actions.fetchDropOffLocationResults,
};

const connector = connect(mapStateToProps, mapDispatchToProps);

export type MobileLocationSelectionConnectorProps = ConnectedProps<
  typeof connector
>;

export const ConnectedMobileLocationSelection = withRouter(
  connector(MobileLocationSelection)
);
