import { CarFilterTypeV5, GeneralCarType } from "../../../../reducer";

export const getCarTypeTitles = (
  carType: GeneralCarType | CarFilterTypeV5
): { title: string; subtitle: string } => {
  switch (carType) {
    case GeneralCarType.Large:
      return { title: "Large", subtitle: "Typically seats 5+ passengers" };
    case GeneralCarType.Medium:
      return { title: "Medium", subtitle: "Typically seats 4-5 passengers" };
    case GeneralCarType.Small:
      return { title: "Small", subtitle: "Typically seats 2-4 passengers" };
    case CarFilterTypeV5.MidSize:
      return { title: "Midsize", subtitle: "" };
    case CarFilterTypeV5.FullSize:
      return { title: "Full-size", subtitle: "" };
    default:
      return { title: carType, subtitle: "" };
  }
};
