import { connect, ConnectedProps } from "react-redux";
import { withRouter } from "react-router";

import { AvailabilityNoResults } from "./component";
import { hasChangedCarAvailabilityFilter } from "../../reducer";
import {
  resetFilters,
  fetchInitialCarsAvailability,
} from "../../actions/actions";
import { IStoreState } from "../../../../reducers/types";

const mapStateToProps = (state: IStoreState) => {
  return {
    hasChangedFilter: hasChangedCarAvailabilityFilter(state),
  };
};

const mapDispatchToProps = {
  resetFilters,
  fetchInitialCarsAvailability,
};

const connector = connect(mapStateToProps, mapDispatchToProps);

export type AvailabilityNoResultsConnectorProps = ConnectedProps<
  typeof connector
>;

export const ConnectedAvailabilityNoResults = withRouter(
  connector(AvailabilityNoResults)
);
