import React from "react";
import {
  Box,
  Checkbox,
  FormGroup,
  FormControl,
  FormControlLabel,
  Typography,
} from "@material-ui/core";
import { useDeviceTypes } from "halifax";
import clsx from "clsx";

import { ICarCompaniesFilter } from "../../../../reducer";
import { randomIdWithName } from "../../../../utils/randomIdWithName";
import "./styles.scss";

export interface ICarCompanySelectionProps {
  className?: string;
  options: { key: string; name: string }[];
  carCompanies: ICarCompaniesFilter;
  setCarCompanies: (carCompanies: ICarCompaniesFilter) => void;
  isCarsCXV1Experiment?: boolean;
}

export const CarCompanySelection = (props: ICarCompanySelectionProps) => {
  const {
    className,
    options,
    carCompanies,
    setCarCompanies,
    isCarsCXV1Experiment,
  } = props;
  const { matchesMobile } = useDeviceTypes();

  const isChecked = (carCompanyCode: string): boolean => {
    return !!carCompanies[carCompanyCode];
  };

  const handleChange = (carCompanyCode: string) => {
    let newSelected = { ...carCompanies };
    newSelected[carCompanyCode] = isChecked(carCompanyCode) ? false : true;
    setCarCompanies(newSelected);
  };

  const renderOptions = () => {
    return options.map(({ key, name }) => {
      const checked = isChecked(key);
      const id = randomIdWithName(name);
      return (
        <FormControlLabel
          key={key}
          className="car-company-selection-form-control-label"
          onKeyDown={(e) => {
            if (e && e.key === "Enter") {
              handleChange(key);
            }
          }}
          control={
            <Checkbox
              inputProps={{ "aria-labelledby": id }}
              checked={checked}
              onChange={() => handleChange(key)}
              value={name}
              className="car-company-selection-checkbox"
            />
          }
          labelPlacement={
            isCarsCXV1Experiment && !matchesMobile ? "end" : "start"
          }
          label={
            <Box className="car-company-label-wrapper">
              <Typography id={id} variant="body2" className="car-company-name">
                {name}
              </Typography>
            </Box>
          }
        />
      );
    });
  };

  return (
    <Box
      className={clsx(
        "car-company-selection-root",
        { mobile: matchesMobile, "cars-cx-v1": isCarsCXV1Experiment },
        className
      )}
    >
      <Box className="car-company-selection-container">
        <FormControl
          className="car-companies-selection-form-control"
          component="fieldset"
        >
          <FormGroup>{renderOptions()}</FormGroup>
        </FormControl>
      </Box>
    </Box>
  );
};
