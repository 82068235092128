import * as actionTypes from "./constants";
import * as H from "history";
import { CarExtraInfoCallState, CarExtraInfoCallError } from "../reducer";
import { ICarAvailabilityLineItem } from "../../availability/reducer/utils/carAvailabilityHelperFunctions";
import { CarShopResponse } from "redmond";

export interface IFetchCarExtraInfo {
  type: actionTypes.FETCH_CAR_EXTRA_INFO;
  history: H.History;
  options?: {
    overrideStateByQueryParams?: boolean;
    forceCallCarAvailability?: boolean;
    includeLocationLabelsInAvailCall?: boolean;
  };
}

export const fetchCarExtraInfo = (
  history: H.History,
  options?: {
    overrideStateByQueryParams?: boolean;
    forceCallCarAvailability?: boolean;
    includeLocationLabelsInAvailCall?: boolean;
  }
): IFetchCarExtraInfo => ({
  type: actionTypes.FETCH_CAR_EXTRA_INFO,
  history,
  options,
});

export interface ISetCarShopResults {
  type: actionTypes.SET_CAR_EXTRA_INFO_RESULTS;
  carExtraInfoCallState: CarExtraInfoCallState;
  carExtraInfoCallError: CarExtraInfoCallError | null;
  extraInfo?: CarShopResponse;
}

export const setCarShopResults = (args: {
  carExtraInfoCallState: CarExtraInfoCallState;
  carExtraInfoCallError?: CarExtraInfoCallError;
  extraInfo?: CarShopResponse;
}): ISetCarShopResults => ({
  type: actionTypes.SET_CAR_EXTRA_INFO_RESULTS,
  ...{ carExtraInfoCallError: null, ...args },
});

interface IISetCarShopCallStateFailedPayload {
  carExtraInfoCallError: CarExtraInfoCallError;
}
export interface ISetCarShopCallStateFailed
  extends IISetCarShopCallStateFailedPayload {
  type: actionTypes.SET_CAR_EXTRA_INFO_CALL_STATE_FAILED;
}

export const setCarShopCallStateFailed = ({
  carExtraInfoCallError,
}: IISetCarShopCallStateFailedPayload): ISetCarShopCallStateFailed => ({
  type: actionTypes.SET_CAR_EXTRA_INFO_CALL_STATE_FAILED,
  carExtraInfoCallError,
});

export interface ISetSelectedVehicleId {
  type: actionTypes.SELECT_SELECTED_VEHICLE_ID;
  id: string;
}

export const setSelectedVehicleId = (id: string): ISetSelectedVehicleId => ({
  type: actionTypes.SELECT_SELECTED_VEHICLE_ID,
  id,
});

export interface ISetSelectedVehicle {
  type: actionTypes.SET_SELECTED_VEHICLE;
  payload: ICarAvailabilityLineItem;
}

export const setSelectedVehicle = (
  payload: ICarAvailabilityLineItem
): ISetSelectedVehicle => ({
  type: actionTypes.SET_SELECTED_VEHICLE,
  payload,
});

export interface IAcknowledgeCarShopFailure {
  type: actionTypes.ACKNOWLEDGE_CAR_EXTRA_INFO_FAILURE;
}

export const acknowledgeCarShopFailure = (): IAcknowledgeCarShopFailure => ({
  type: actionTypes.ACKNOWLEDGE_CAR_EXTRA_INFO_FAILURE,
});

export type CarShopActions =
  | IFetchCarExtraInfo
  | ISetCarShopResults
  | ISetCarShopCallStateFailed
  | ISetSelectedVehicleId
  | ISetSelectedVehicle
  | IAcknowledgeCarShopFailure;
