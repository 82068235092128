import { connect, ConnectedProps } from "react-redux";
import { CarSummaryPanel, ICarSummaryPanelProps } from "halifax";
import { IStoreState } from "../../../../reducers/types";
import { getCarShopSelectedVehicle } from "../../../shop/reducer";
import { getDropOffDate, getPickUpDate } from "../../../search/reducer";

const mapStateToProps = (state: IStoreState) => {
  const vehicle = getCarShopSelectedVehicle(state);
  if (!vehicle) {
    return;
  } else {
    return {
      days: vehicle.days,
      totalFiatPrice: vehicle.totalFiatPrice,
      totalRewards: vehicle.totalRewards,
      carDetailsText: vehicle.carDetailsText,
      carImgAssets: vehicle.carImgAssets,
      carModelDetails: vehicle.carModelDetails,
      carInfoIconDetails: vehicle.carInfoIconDetails,
      carAvailabilityTagDetails: vehicle.carAvailabilityTagDetails,
      dropOff: getDropOffDate(state),
      pickUp: getPickUpDate(state),
    };
  }
};

const mapDispatchToProps = {};

const connector = connect(mapStateToProps, mapDispatchToProps);

export type CarBookSummaryPanelConnectorProps = ConnectedProps<
  typeof connector
>;

export const ConnectedCarBookSummaryPanel = connector(
  CarSummaryPanel as (props: ICarSummaryPanelProps) => JSX.Element
);
