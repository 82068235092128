import React, { useContext, useEffect } from "react";
import {
  GenericBanner,
  TravelSaleTakeoverBanner,
  useDeviceTypes,
} from "halifax";
import { RouterProps } from "react-router-dom";
import { B2B_PORTAL_BANNER_CONTAINER_ID, CustomerAccountRole } from "redmond";
import { ClientContext } from "../../../../App";

import { RewardsBannerConnectorProps } from "./container";
import {
  PATH_HOME,
  PATH_AVAILABILITY,
  PATH_SHOP,
  PATH_TRAVEL_SALE,
} from "../../../../utils/paths";
import {
  CONTROL,
  TRAVEL_SALE,
  TRAVEL_SALE_VARIANTS,
  getExperimentVariantCustomVariants,
  useExperiments,
} from "../../../../context/experiments";
import { REWARDS_BANNER_TEXT } from "@capone/common";
import { config } from "../../../../api/config";

export interface IRewardsBannerProps
  extends RewardsBannerConnectorProps,
    RouterProps {}

export const RewardsBanner = (props: IRewardsBannerProps) => {
  const { history, largestValueAccount: account, fetchRewardsAccounts } = props;
  useEffect(() => {
    fetchRewardsAccounts();
  }, []);
  const { matchesMobile } = useDeviceTypes();
  const firstName =
    useContext(ClientContext).sessionInfo?.userInfo?.firstName || "";

  const expState = useExperiments();

  const travelSaleVariant = getExperimentVariantCustomVariants(
    expState.experiments,
    TRAVEL_SALE,
    TRAVEL_SALE_VARIANTS
  );

  if (travelSaleVariant !== CONTROL)
    return (
      <TravelSaleTakeoverBanner
        variant={travelSaleVariant}
        onClickCTA={() => {
          const path = `${PATH_TRAVEL_SALE}?entryType=global_earn_banner`;
          matchesMobile ? history.push(path) : window.open(path, "_blank");
        }}
      />
    );

  let contentText = "";

  const {
    earn,
    lastFour = "",
    productDisplayName: productId = "",
    rewardsBalance,
  } = account || {};
  const { currency, currencyDescription } = rewardsBalance || {};
  const {
    carsMultiplier: earnRate = "",
    flightsMultiplier: flightEarnRate = "",
  } = earn || {};

  //if no earn multiplier do not display a banner or if its less than 0
  if (
    ((account && !account.earn?.carsMultiplier) || earnRate <= 0) &&
    !productId.toLowerCase().includes("paradise")
  ) {
    return null;
  }

  // TODO: handle texts properly once the backend is ready
  switch (history.location.pathname) {
    case PATH_HOME.slice(0, -1):
    case PATH_HOME:
      if (!matchesMobile) {
        contentText = REWARDS_BANNER_TEXT({
          firstName,
          earnRate,
          productId,
          lastFour,
          flightEarnRate,
          currency: currencyDescription || currency,
          isPrimary:
            account.customerAccountRole === CustomerAccountRole.Primary,
          tenant: config.TENANT,
        });
      }
      // hide the banner on mobile search workflow
      else {
        contentText = "";
      }
      break;
    case PATH_AVAILABILITY.slice(0, -1):
    case PATH_AVAILABILITY:
      contentText = REWARDS_BANNER_TEXT({
        firstName,
        earnRate,
        productId,
        lastFour,
        flightEarnRate,
        currency: currencyDescription || currency,
        isPrimary: account.customerAccountRole === CustomerAccountRole.Primary,
        tenant: config.TENANT,
      });
      break;
    case PATH_SHOP.slice(0, -1):
    case PATH_SHOP:
      contentText = REWARDS_BANNER_TEXT({
        firstName,
        earnRate,
        productId,
        lastFour,
        flightEarnRate,
        currency: currencyDescription || currency,
        isPrimary: account.customerAccountRole === CustomerAccountRole.Primary,
        tenant: config.TENANT,
      });
      break;
  }

  if (!contentText) {
    return null;
  }

  return (
    <GenericBanner
      className="rewards-banner-root"
      content={contentText}
      containerId={B2B_PORTAL_BANNER_CONTAINER_ID}
      isMobile={matchesMobile}
    />
  );
};
