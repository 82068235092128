import React, { useState } from "react";
import { Box, Typography } from "@material-ui/core";
import { VehicleAvailability, TimeAndPlace, VendorMessages } from "redmond";
import { ActionLink, Icon, IconName } from "halifax";
import { GoogleMap, OverlayView } from "@react-google-maps/api";
import { CarLocationDetailsModal } from "../CarLocationDetailsModal";
import dayjs from "dayjs";
import clsx from "clsx";

import * as constants from "./constants";
import "./styles.scss";
import {
  AVAILABLE,
  GLOBAL_MOBILE_NAV_EXPERIMENT,
  getExperimentVariant,
  useExperiments,
} from "../../../../context/experiments";

export interface ICarShopSmallMapProps {
  selectedVehicle: VehicleAvailability | false;
  vendorMessages?: VendorMessages | undefined;
  isMobile?: boolean;
}

export const CarShopSmallMap = (props: ICarShopSmallMapProps) => {
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const selectedVehicle = props.selectedVehicle as VehicleAvailability;
  const { isMobile, vendorMessages } = props;

  const expState = useExperiments();

  const globalMobileNavExperimentVariant = getExperimentVariant(
    expState.experiments,
    GLOBAL_MOBILE_NAV_EXPERIMENT
  );
  const isGlobalMobileNavExperiment = React.useMemo(
    () => globalMobileNavExperimentVariant === AVAILABLE,
    [globalMobileNavExperimentVariant]
  );

  const isPickUpSameAsDropOff =
    selectedVehicle?.pickUp.coordinates.lat ===
      selectedVehicle?.dropOff.coordinates.lat &&
    selectedVehicle?.pickUp.coordinates.lon ===
      selectedVehicle?.dropOff.coordinates.lon;

  const pickUpCenter = {
    lat: selectedVehicle?.pickUp?.coordinates.lat,
    lng: selectedVehicle?.pickUp?.coordinates.lon,
  };
  const dropOffCenter = {
    lat: selectedVehicle?.dropOff.coordinates.lat,
    lng: selectedVehicle?.dropOff?.coordinates.lon,
  };

  return (
    <Box
      className={clsx("car-shop-small-car-map-container", { mobile: isMobile })}
    >
      <Box className="map-container">
        <Box
          className={clsx(
            isPickUpSameAsDropOff ? "main-content" : "left-content"
          )}
        >
          <Typography className="car-shop-map-label">
            {isPickUpSameAsDropOff
              ? constants.getTitle(constants.PICK_UP_AND_DROP_OFF)
              : constants.getTitle(constants.PICK_UP)}
          </Typography>
          <GoogleMap
            id="small-car-map"
            mapContainerStyle={{ width: "100%" }}
            options={{
              clickableIcons: false,
              zoomControl: true,
              streetViewControl: false,
              mapTypeControl: false,
              draggable: true,
              fullscreenControl: false,
              draggableCursor: "pointer",
              gestureHandling: isGlobalMobileNavExperiment
                ? "greedy"
                : undefined,
            }}
            center={pickUpCenter}
            zoom={16}
          >
            <SmallHotelMapContent center={pickUpCenter} />
          </GoogleMap>

          {isPickUpSameAsDropOff ? (
            <Box
              className={clsx(
                "time-place-container",
                "pick-up-same-as-drop-off"
              )}
            >
              <TimeAndPlaceDetails
                label={constants.PICK_UP_TIME}
                timeAndPlace={selectedVehicle.pickUp}
              />
              <TimeAndPlaceDetails
                label={constants.DROP_OFF_TIME}
                timeAndPlace={selectedVehicle.dropOff}
              />
            </Box>
          ) : (
            <Box className="time-place-container">
              <TimeAndPlaceDetails
                label={constants.PICK_UP_TIME}
                timeAndPlace={selectedVehicle.pickUp}
              />
            </Box>
          )}
          {!!vendorMessages?.pickUpDirections && (
            <Box className="rental-location-details-container">
              <ActionLink
                content={
                  <Typography variant="h6">
                    {constants.RENTAL_LOCATION_DETAILS}
                  </Typography>
                }
                onClick={() => setModalIsOpen(true)}
                showTappableArea={false}
                className="b2b"
              />
            </Box>
          )}
        </Box>
        {!isPickUpSameAsDropOff && (
          <Box className="right-content">
            <Typography className="car-shop-map-label">
              {constants.getTitle(constants.DROP_OFF)}
            </Typography>
            <GoogleMap
              id="small-car-map"
              mapContainerStyle={{ width: "100%" }}
              options={{
                clickableIcons: false,
                zoomControl: true,
                streetViewControl: false,
                mapTypeControl: false,
                draggable: true,
                fullscreenControl: false,
                draggableCursor: "pointer",
                gestureHandling: isGlobalMobileNavExperiment
                  ? "greedy"
                  : undefined,
              }}
              center={dropOffCenter}
              zoom={16}
            >
              <SmallHotelMapContent center={dropOffCenter} />
            </GoogleMap>
            <Box className="time-place-container">
              <TimeAndPlaceDetails
                label={constants.DROP_OFF_TIME}
                timeAndPlace={selectedVehicle.dropOff}
              />
            </Box>
          </Box>
        )}
      </Box>
      {modalIsOpen && (
        <CarLocationDetailsModal
          onClose={() => setModalIsOpen(false)}
          isMobile={isMobile}
          vendorMessages={vendorMessages}
        />
      )}
    </Box>
  );
};

const SmallHotelMapContent = ({
  center,
}: {
  center: { lat: number; lng: number };
}) => (
  <>
    <OverlayView
      mapPaneName={OverlayView.OVERLAY_MOUSE_TARGET}
      position={center}
    >
      <div className="location-pin">
        <Icon name={IconName.MapPin}></Icon>
      </div>
    </OverlayView>
  </>
);

const TimeAndPlaceDetails = ({
  label,
  timeAndPlace,
}: {
  label: string;
  timeAndPlace: TimeAndPlace | false;
}) => {
  if (!timeAndPlace) return null;

  const formattedDate = dayjs(timeAndPlace.time).format("ddd, MMM D");
  const formattedTime = dayjs(timeAndPlace.time).format("h:mm A");

  return (
    <Box>
      <Typography className="time-place-label-and-date" variant="body1">
        <span className="time-place-label">{label}:</span> {formattedDate} at{" "}
        {formattedTime}
      </Typography>

      <Typography className="time-place-address" variant="body2">
        {timeAndPlace.address}
      </Typography>
    </Box>
  );
};
