import React, { useMemo } from "react";
import { RouteComponentProps } from "react-router-dom";
import { Box, Typography } from "@material-ui/core";
import { ActionButton, useDeviceTypes } from "halifax";
import dayjs from "dayjs";

import { DriverAgeInput } from "../../../DesktopCarSearchControl/components/DriverAgeInput";
import {
  PickUpTimeSelector,
  DropOffTimeSelector,
} from "../../../DesktopCarSearchControl/components/TimeSelector";
import { transformToStringifiedAvailabilityQuery } from "../../../../../availability/utils/queryStringHelpers";
import { PATH_AVAILABILITY } from "../../../../../../utils/paths";
import { TimeAndAgePickerConnectorProps } from "./container";
import * as textConstants from "./constants";
import "./styles.scss";
import { calcMinimumDropOffTime } from "../../../DesktopCarSearchControl/components/TimeSelector/util";
import {
  useExperiments,
  getExperimentVariant,
  CARS_OFF_HOUR_PICKUP,
  AVAILABLE,
} from "../../../../../../context/experiments";

interface ITimeAndAgePicker
  extends TimeAndAgePickerConnectorProps,
    RouteComponentProps {}

export const TimeAndAgePicker = (props: ITimeAndAgePicker) => {
  const {
    history,
    readyToSearch,
    dropOffDate,
    dropOffTime,
    dropOffLocation,
    pickUpDate,
    pickUpTime,
    pickUpLocation,
    driverAge,
  } = props;

  const { matchesMobile } = useDeviceTypes();

  const expState = useExperiments();

  const isCarsOffHourPickup = useMemo(
    () =>
      getExperimentVariant(expState.experiments, CARS_OFF_HOUR_PICKUP) ===
      AVAILABLE,
    [expState.experiments]
  );

  const minTime: string | undefined = useMemo(() => {
    if (pickUpDate && dropOffDate && pickUpTime) {
      return calcMinimumDropOffTime(pickUpDate, dropOffDate, pickUpTime);
    }
    return;
  }, [pickUpDate, dropOffDate, pickUpTime]);

  return (
    <Box className="time-age-picker-container">
      <Box className="time-picker-container">
        <Box className="pick-up-container">
          <Typography className="pick-up-label">
            {textConstants.PICK_UP_TIME}
          </Typography>
          <Typography className="time-picker-date">
            {dayjs(pickUpDate!).format("ddd, MMMM D")}
          </Typography>
          <PickUpTimeSelector
            hideLabel
            hideIcon
            increment={isCarsOffHourPickup ? 60 : 30}
          />
        </Box>
        <Box className="drop-off-container">
          <Typography className="pick-up-label">
            {textConstants.DROP_OFF_TIME}
          </Typography>
          <Typography className="time-picker-date">
            {dayjs(dropOffDate!).format("ddd, MMMM D")}
          </Typography>
          <DropOffTimeSelector
            minTime={minTime}
            hideLabel
            hideIcon
            increment={isCarsOffHourPickup ? 60 : 30}
          />
        </Box>
      </Box>
      <Box className="driver-age-picker">
        <DriverAgeInput isMobile={matchesMobile} />
      </Box>
      {readyToSearch && (
        <ActionButton
          className="cars-search-button"
          message={textConstants.SEARCH}
          onClick={() => {
            history.push(
              `${PATH_AVAILABILITY}${transformToStringifiedAvailabilityQuery(
                dropOffDate!,
                dropOffTime!,
                dropOffLocation!,
                pickUpDate!,
                pickUpTime!,
                pickUpLocation!,
                driverAge!
              )}`
            );
          }}
        />
      )}
    </Box>
  );
};
