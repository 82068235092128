import { connect } from "react-redux";
import { IStoreState } from "../../../../../../reducers/types";
import {
  getDropOffLocation,
  getPickUpLocation,
  getDropOffDate,
  getPickUpDate,
} from "../../../../reducer/selectors";
import { withRouter } from "react-router";
import { ActionButtonWithRoute } from "halifax";

const mapStateToProps = (
  state: IStoreState,
  ownProps: { from?: Date; until?: Date; enabled?: boolean }
) => {
  const dropOffLocation = getDropOffLocation(state);
  const pickUpLocation = getPickUpLocation(state);
  const dropOffDate = getDropOffDate(state) || ownProps.from;
  const pickUpDate = getPickUpDate(state) || ownProps.until;

  const hasSetLocation = dropOffLocation !== null && pickUpLocation !== null;
  const areJuncturesSet = !!dropOffDate && !!pickUpDate;

  return {
    disabled: ownProps.enabled
      ? !ownProps.enabled
      : !(hasSetLocation && areJuncturesSet),
  };
};

const mapDispatchToProps = {};

export const ConnectedCarsSearchButton = connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(ActionButtonWithRoute));
