import React, { useMemo } from "react";
import { RouteComponentProps } from "react-router-dom";
import { Box, Typography } from "@material-ui/core";
import {
  ActionButton,
  ActionLink,
  CloseButtonIcon,
  MobilePopoverCard,
} from "halifax";
import {
  PickUpTimeSelector,
  DropOffTimeSelector,
} from "../../../DesktopCarSearchControl/components/TimeSelector";
import { MobileTimePickerModalConnectorProps } from "./container";
import * as textConstants from "./constants";
import "./styles.scss";
import { calcMinimumDropOffTime } from "../../../DesktopCarSearchControl/components/TimeSelector/util";
import {
  AVAILABLE,
  CARS_OFF_HOUR_PICKUP,
  getExperimentVariant,
  useExperiments,
} from "../../../../../../context/experiments";

interface IMobileTimePickerModalProps
  extends MobileTimePickerModalConnectorProps,
    RouteComponentProps {
  openModal: boolean;
  setOpenModal: (val: boolean) => void;
}

export const MobileTimePickerModal = (props: IMobileTimePickerModalProps) => {
  const { dropOffDate, pickUpDate, pickUpTime, openModal, setOpenModal } =
    props;

  const expState = useExperiments();

  const isCarsOffHourPickup = useMemo(
    () =>
      getExperimentVariant(expState.experiments, CARS_OFF_HOUR_PICKUP) ===
      AVAILABLE,
    [expState.experiments]
  );

  const minTime: string | undefined = useMemo(() => {
    if (pickUpDate && dropOffDate && pickUpTime) {
      return calcMinimumDropOffTime(pickUpDate, dropOffDate, pickUpTime);
    }
    return;
  }, [pickUpDate, dropOffDate, pickUpTime]);

  const handleContinue = () => {
    setOpenModal(false);
  };

  const MobileSearchPopoverHeader = () => {
    return (
      <Box className="modal-header-container">
        <Typography className="header-title">
          {textConstants.CHOOSE_TIMES}
        </Typography>
        <ActionLink
          className="modal-close-button"
          onClick={handleContinue}
          content={<CloseButtonIcon className="close-button-icon" />}
          label="Close"
          showTappableArea
        />
      </Box>
    );
  };
  return (
    <MobilePopoverCard
      open={openModal}
      className={"mobile-cars-search-time-picker-popup"}
      onClose={handleContinue}
      headerElement={<MobileSearchPopoverHeader />}
    >
      <Box className="time-picker-modal-content-container">
        <Box className="time-picker-container">
          <PickUpTimeSelector increment={isCarsOffHourPickup ? 60 : 30} />
          <DropOffTimeSelector
            minTime={minTime}
            increment={isCarsOffHourPickup ? 60 : 30}
          />
        </Box>

        <ActionButton
          className="cars-time-picker-continue-button b2b"
          message={textConstants.CONTINUE}
          onClick={handleContinue}
        />
      </Box>
    </MobilePopoverCard>
  );
};
