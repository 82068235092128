import { connect, ConnectedProps } from "react-redux";
import { withRouter } from "react-router";

import { IStoreState } from "../../../../reducers/types";
import {
  setDropOffAutocompleteError,
  setMobileCarSearchStep,
  setPickUpAutocompleteError,
} from "../../actions/actions";
import {
  getDropOffDate,
  getHasDropOffAutocompleteError,
  getHasPickUpAutocompleteError,
  getMobileSearchStep,
  getPickUpDate,
  getTripCategory,
} from "../../reducer";
import { MobileCarSearchControlV3 } from "./component";

const mapDispatchToProps = {
  setCurrentStep: setMobileCarSearchStep,
  setPickUpAutocompleteError,
  setDropOffAutocompleteError,
};
const mapStateToProps = (state: IStoreState) => ({
  tripCategory: getTripCategory(state),
  currentStep: getMobileSearchStep(state),
  pickUpDate: getPickUpDate(state),
  dropOffDate: getDropOffDate(state),
  hasPickUpAutocompleteError: getHasPickUpAutocompleteError(state),
  hasDropOffAutocompleteError: getHasDropOffAutocompleteError(state),
});

const connector = connect(mapStateToProps, mapDispatchToProps);

export type MobileCarSearchControlV3ConnectorProps = ConnectedProps<
  typeof connector
>;

export const ConnectedMobileCarSearchControlV3 = withRouter(
  connector(MobileCarSearchControlV3)
);
