import axios from "axios";

import { PaymentSessionToken } from "redmond";
import { purchaseApi, PaymentOpaqueValue } from "@b2bportal/purchase-api";

export const schedulePaymentV1 = ({
  token,
  payments,
  corporateOutOfPolicyReason,
}: {
  token: PaymentSessionToken;
  payments: Array<PaymentOpaqueValue>;
  corporateOutOfPolicyReason?: string;
}): Promise<String> =>
  new Promise((resolve, reject) => {
    try {
      const res = purchaseApi(axios).apiV1PurchaseFulfillSchedulePost({
        quoteToken: {
          value: token.value,
        },
        payments: payments,
        corporateOutOfPolicyReason: corporateOutOfPolicyReason,
      });

      res.then((body) => resolve(body.data.value)).catch((e) => reject(e));
    } catch (e) {
      reject(e);
    }
  });
