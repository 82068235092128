import { connect, ConnectedProps } from "react-redux";
import { withRouter } from "react-router";

import {
  getTripCategory,
  getMobileSearchStep,
  getPickUpDate,
  getDropOffDate,
  getHasPickUpAutocompleteError,
  getHasDropOffAutocompleteError,
} from "../../reducer";
import {
  setDropOffAutocompleteError,
  setMobileCarSearchStep,
  setPickUpAutocompleteError,
} from "../../actions/actions";
import { IStoreState } from "../../../../reducers/types";
import { MobileCarSearchControl } from "./component";

const mapDispatchToProps = {
  setCurrentStep: setMobileCarSearchStep,
  setPickUpAutocompleteError,
  setDropOffAutocompleteError,
};
const mapStateToProps = (state: IStoreState) => ({
  tripCategory: getTripCategory(state),
  currentStep: getMobileSearchStep(state),
  pickUpDate: getPickUpDate(state),
  dropOffDate: getDropOffDate(state),
  hasPickUpAutocompleteError: getHasPickUpAutocompleteError(state),
  hasDropOffAutocompleteError: getHasDropOffAutocompleteError(state),
});

const connector = connect(mapStateToProps, mapDispatchToProps);

export type MobileCarSearchConnectorProps = ConnectedProps<typeof connector>;

export const ConnectedMobileCarSearchControl = withRouter(
  connector(MobileCarSearchControl)
);
