import { actions, actionTypes } from "../actions";
import { RewardsAccount } from "redmond";

export interface ICarRewardsState {
  rewardsAccounts: RewardsAccount[];
  selectedAccountReferenceId: string | null;
  fetchRewardsAccountsCallState: CallState;
  isFirstLaunch: boolean;
  agentEmail: string;
}

// Corporate Travel
export interface ICorpCarRewardsState extends ICarRewardsState {
  isAutoApprovalEnabled: boolean;
}

export enum CallState {
  NotCalled,
  InProcess,
  Success,
  Failed,
}

const initialState: ICarRewardsState | ICorpCarRewardsState = {
  rewardsAccounts: [],
  selectedAccountReferenceId: null,
  fetchRewardsAccountsCallState: CallState.NotCalled,
  isFirstLaunch: false,
  agentEmail: "",
  isAutoApprovalEnabled: false,
};

export function reducer(
  state: ICarRewardsState = initialState,
  action: actions.CarRewardsActions
): ICarRewardsState | ICorpCarRewardsState {
  switch (action.type) {
    case actionTypes.SET_IS_FIRST_LAUNCH:
      return {
        ...state,
        isFirstLaunch: action.isFirstLaunch,
      };

    case actionTypes.SET_AGENT_EMAIL:
      return {
        ...state,
        agentEmail: action.agentEmail,
      };

    case actionTypes.FETCH_REWARDS_ACCOUNTS:
      return {
        ...state,
        fetchRewardsAccountsCallState: CallState.InProcess,
      };

    case actionTypes.SET_FETCH_REWARDS_ACCOUNTS_CALL_STATE_FAILED:
      return {
        ...state,
        fetchRewardsAccountsCallState: CallState.Failed,
      };

    case actionTypes.SET_REWARDS_ACCOUNTS:
      return {
        ...state,
        rewardsAccounts: action.rewardsAccounts,
        fetchRewardsAccountsCallState: CallState.Success,
      };

    case actionTypes.SET_SELECTED_ACCOUNT_REFERENCE_ID:
      return {
        ...state,
        selectedAccountReferenceId: action.selectedAccountReferenceId,
      };

    case actionTypes.SET_IS_AUTO_APPROVAL_ENABLED:
      return {
        ...state,
        isAutoApprovalEnabled: action.isAutoApprovalEnabled,
      };

    default:
      return state;
  }
}

export * from "./selectors";
