import {
  AvailabilityFilterEnum,
  CarsAutocompleteResult,
  CarsAvailabilityRequestEnum,
  CarsAvailabilityResponse,
  CarShopRequest,
  CarShopResponse,
  CarsInitialSearch,
  CarsTripCategory,
  IdEnum,
  ILocationQueryLabel,
  LocationQueryEnum,
} from "redmond";
import { fetchCarExtraInfo } from "./../../../api/v1/shop/fetchCarExtraInfo";
import { select, call, putResolve, put } from "redux-saga/effects";
import { IStoreState } from "../../../reducers/types";
import queryStringParser from "query-string";
import { actions } from "../actions";
import { actions as availActions } from "../../availability/actions";
import { actions as searchActions } from "../../search/actions";
import {
  CarExtraInfoCallError,
  CarExtraInfoCallState,
  getCarShopSelectedVehicle,
} from "../reducer";
import Logger from "../../../utils/logger";
import {
  ICarShopQueryParsedQuery,
  transformToStringifiedAvailabilityQuery,
} from "../../availability/utils/queryStringHelpers";
import dayjs from "dayjs";
import {
  ICarsAutocompleteResponseData,
  fetchCarsLocationAutocomplete,
} from "../../../api/v1/search/fetchCarsLocationAutocomplete";
import { PATH_AVAILABILITY, PATH_HOME } from "../../../utils/paths";
import {
  DEFAULT_DRIVER_AGE,
  getDriverAge,
  getDropOffDate,
  getDropOffLocation,
  getDropOffTime,
  getPickUpDate,
  getPickUpLocation,
  getPickUpTime,
} from "../../search/reducer";
import {
  getTimeStamp,
  isAnyFieldFalsy,
} from "../../availability/sagas/fetchCarsAvailabilitySaga";
import {
  getCarAvailabilityLineItem,
  ICarAvailabilityLineItem,
} from "../../availability/reducer/utils/carAvailabilityHelperFunctions";
import { fetchCarsAvailability } from "../../../api/v1/availability/fetchCarsAvailability";

export function* fetchCarShopSaga(
  fetchCarShopAction: actions.IFetchCarExtraInfo
) {
  try {
    const vehicle: ICarAvailabilityLineItem = yield call(
      setUpCarShopParameters,
      fetchCarShopAction
    );
    const body: CarShopRequest = {
      opaqueShopRequestVehicle: vehicle.opaqueShopRequestVehicle,
      opaqueShopRequestContext: vehicle.opaqueShopRequestContext,
    };
    const extraInfo: CarShopResponse = yield call(fetchCarExtraInfo, body);
    yield putResolve(
      actions.setCarShopResults({
        carExtraInfoCallState: CarExtraInfoCallState.Success,
        extraInfo,
      })
    );
  } catch (e) {
    Logger.debug(e);
    yield put(
      actions.setCarShopCallStateFailed({
        carExtraInfoCallError: CarExtraInfoCallError.Unknown,
      })
    );
  }
}
function* setUpCarShopParameters(
  fetchCarExtraInfoAction: actions.IFetchCarExtraInfo
) {
  const state: IStoreState = yield select();
  let selectedVehicle: ICarAvailabilityLineItem | null =
    yield getCarShopSelectedVehicle(state);
  let dropOff: Date = yield getDropOffDate(state);
  let pickUp: Date = yield getPickUpDate(state);
  let pickUpTime: number = yield getPickUpTime(state);
  let dropOffTime: number = yield getDropOffTime(state);
  let dropOffLocation: CarsAutocompleteResult = yield getDropOffLocation(state);
  let pickUpLocation: CarsAutocompleteResult = yield getPickUpLocation(state);
  let driverAge: number = yield getDriverAge(state);
  const history = fetchCarExtraInfoAction.history;
  let parsedQueryString = parseQueryString(fetchCarExtraInfoAction);
  if (!fetchCarExtraInfoAction.options?.overrideStateByQueryParams) {
    if (
      selectedVehicle &&
      dropOff &&
      pickUp &&
      (parsedQueryString.vehicleId !== selectedVehicle.id ||
        parsedQueryString.dropOffDate !== dropOff ||
        parsedQueryString.pickUpDate !== pickUp)
    ) {
      history.replace({
        ...history.location,
        search: queryStringParser.stringify({
          vehicleId: selectedVehicle.id,
          dropOffDate: dayjs(dropOff).format("YYYY-MM-DD"),
          pickUpDate: dayjs(pickUp).format("YYYY-MM-DD"),
          dropOffTime,
          pickUpTime,
          dropOffLocation: dropOffLocation?.label,
          pickUpLocation: pickUpLocation?.label,
          driverAge,
        }),
      });
    }
  }
  if (
    !selectedVehicle ||
    !dropOff ||
    !pickUp ||
    fetchCarExtraInfoAction.options?.overrideStateByQueryParams ||
    fetchCarExtraInfoAction.options?.forceCallCarAvailability
  ) {
    const { correspondingLocation: dropOffLocationFromQuery } = yield call(
      fetchLocation,
      parsedQueryString.dropOffLocation
    );
    const { correspondingLocation: pickUpLocationFromQuery } = yield call(
      fetchLocation,
      parsedQueryString.pickUpLocation
    );
    let [
      vehicleId,
      dropOffDate,
      dropOffTime,
      dropOffLocation,
      pickUpDate,
      pickUpTime,
      pickUpLocation,
      driverAge,
    ] = [
      parsedQueryString.vehicleId,
      parsedQueryString.dropOffDate,
      parsedQueryString.dropOffTime,
      dropOffLocationFromQuery,
      parsedQueryString.pickUpDate,
      parsedQueryString.pickUpTime,
      pickUpLocationFromQuery,
      parsedQueryString.driverAge || DEFAULT_DRIVER_AGE,
    ];
    // If we know we are missing the data we should redirect the user home rather than make a bad request.
    // TODO: this should probably be monitored in the future. If we get a lot of these something is likely wrong.
    if (
      isAnyFieldFalsy({
        pickUpDate,
        pickUpTime,
        dropOffDate,
        dropOffTime,
        driverAge,
        pickUpLocation,
        dropOffLocation,
      })
    ) {
      history.push(PATH_HOME);
      return {};
    }
    yield putResolve(searchActions.setPickUpDate(pickUpDate));
    yield putResolve(searchActions.setPickUpTime(pickUpTime));
    yield putResolve(searchActions.setDropOffDate(dropOffDate));
    yield putResolve(searchActions.setDropOffTime(dropOffTime));
    yield putResolve(searchActions.setDriverAge(driverAge));
    yield putResolve(searchActions.setPickUpLocation(pickUpLocation));
    yield putResolve(searchActions.setDropOffLocation(dropOffLocation));
    yield put(
      searchActions.setTripCategory(
        pickUpLocation?.id === dropOffLocation?.id
          ? CarsTripCategory.SAME_AS_DROP_OFF
          : CarsTripCategory.DIFFERENT_DROP_OFF
      )
    );
    yield put(availActions.setPickUpDateSearched(pickUpDate));
    yield put(availActions.setPickUpTimeSearched(pickUpTime));
    yield put(availActions.setDropOffDateSearched(dropOffDate));
    yield put(availActions.setDropOffTimeSearched(dropOffTime));
    yield put(availActions.setPickUpLocationSearched(pickUpLocation));
    yield put(availActions.setDropOffLocationSearched(dropOffLocation));
    const requestBody: CarsInitialSearch = {
      filter: {
        AvailabilityFilter: AvailabilityFilterEnum.VehicleIdFilter,
        vehicleAvailabilityIds: [vehicleId!],
      },
      AvailabilityRequest: CarsAvailabilityRequestEnum.InitialSearch,
      pickupSelection: pickUpLocation!.id,
      dropOffSelection: dropOffLocation!.id,
      pickupTime: getTimeStamp(pickUpDate!, pickUpTime!),
      dropOffTime: getTimeStamp(dropOffDate!, dropOffTime!),
      driverAge: driverAge || DEFAULT_DRIVER_AGE,
      pickUpLocationLabel:
        fetchCarExtraInfoAction.options?.includeLocationLabelsInAvailCall &&
        !!pickUpLocation?.label
          ? pickUpLocation.label
          : undefined,
      dropOffLocationLabel:
        fetchCarExtraInfoAction.options?.includeLocationLabelsInAvailCall &&
        !!dropOffLocation?.label
          ? dropOffLocation.label
          : undefined,
    };
    const availabilityResponse: CarsAvailabilityResponse =
      yield fetchCarsAvailability(requestBody);
    yield put(
      availActions.setCarAvailabilityResults({
        payload: availabilityResponse,
      })
    );
    if (availabilityResponse.vehicles.length > 0) {
      const selectedVehicle = getCarAvailabilityLineItem(
        availabilityResponse.vehicles[0],
        availabilityResponse.context,
        null,
        pickUpLocation?.id.Id === IdEnum.Flight
          ? `to ${pickUpLocation.label}`
          : `to pick-up location`,
        0
      );
      yield put(actions.setSelectedVehicle(selectedVehicle));
      return selectedVehicle;
    } else {
      history.push(
        `${PATH_AVAILABILITY}${transformToStringifiedAvailabilityQuery(
          dropOffDate!,
          dropOffTime!,
          dropOffLocation!,
          pickUpDate!,
          pickUpTime!,
          pickUpLocation!,
          driverAge!
        )}`
      );
      throw Error("Shop results empty");
    }
  }
  return selectedVehicle;
}
function* fetchLocation(locationName: string) {
  if (!locationName) {
    return { correspondingLocation: undefined };
  }
  const locationRequestBody: ILocationQueryLabel = {
    LocationQuery: LocationQueryEnum.Label,
    l: locationName,
  };
  const responseData: ICarsAutocompleteResponseData = yield call(
    fetchCarsLocationAutocomplete,
    locationRequestBody
  );
  const correspondingLocation = responseData.categories.flatMap((category) =>
    category.results.find((result) =>
      result.label
        .toLowerCase()
        .includes(locationName.split(",")[0].toLowerCase())
    )
  );
  return { correspondingLocation: correspondingLocation[0] };
}
function parseQueryString(
  fetchCarExtraInfoAction: actions.IFetchCarExtraInfo
): ICarShopQueryParsedQuery {
  const queryString = fetchCarExtraInfoAction.history.location.search;
  let parsedQueryStringPrimitive = queryStringParser.parse(queryString);
  let parsedQueryString: ICarShopQueryParsedQuery = {
    vehicleId: parsedQueryStringPrimitive.vehicleId as string,
    pickUpDate: dayjs(parsedQueryStringPrimitive.pickUpDate as string).toDate(),
    pickUpTime: parsedQueryStringPrimitive.pickUpTime as string,
    dropOffDate: dayjs(
      parsedQueryStringPrimitive.dropOffDate as string
    ).toDate(),
    dropOffTime: parsedQueryStringPrimitive.dropOffTime as string,
    dropOffLocation: parsedQueryStringPrimitive.dropOffLocation as string,
    pickUpLocation: parsedQueryStringPrimitive.pickUpLocation as string,
    driverAge: Number(parsedQueryStringPrimitive.driverAge),
  };
  return parsedQueryString;
}
