import { REWARDS_PROGRAM_NAME } from "../../reducer/selectors/textConstants";

export const HERTZ_REWARDS_WARNING = (supplierRef?: string) =>
`Adding your ${REWARDS_PROGRAM_NAME(supplierRef)} number may result in a price change if you have pre-accepted charges.`;
export const HERTZ_REWARDS_TITLE = (supplierRef?: string) =>
  `${REWARDS_PROGRAM_NAME(supplierRef)} (Optional)`;
export const HERTZ_REWARDS_SUBTITLE = (supplierRef?: string) =>
  `Enter your ${REWARDS_PROGRAM_NAME(
    supplierRef
  )} number, and ensure the name of the driver matches the name on the loyalty account.`;
