import { all, call, put, select } from "redux-saga/effects";
import isEqual from "lodash-es/isEqual";
import { actions } from "../../actions";
import { updateUserPassenger } from "../../../../api/v1/book/user-passengers/updateUserPassenger";
import {
  fetchUserPassengers,
  IListUserPassengersResponse,
} from "../../../../api/v1/book/user-passengers/fetchUserPassengers";
import {
  ADD_TRAVELER,
  CallState,
  CorpSessionInfo,
  ICorpPerson,
  IPerson,
  SessionInfo,
} from "redmond";
import { getUserPassengers } from "../../reducer";
import { trackEvent } from "../../../../api/v1/analytics/trackEvent";
import updateUserDefaultTraveler from "b2b-base/src/api/v1/user/updateUserDefaultTraveler";
import { isCaponeTenant } from "@capone/common";
import { config } from "../../../../api/config";
import {
  CorpUsersListResponse,
  fetchCorpUsersList,
} from "b2b-base/src/api/v1/user/fetchCorpUsersList";
import { buildCorpTravelers } from "b2b-base/src/utils/capone-corporate/travelersMappingUtils";
import { ToggleActions } from "halifax";
import updateUserShowProfile from "b2b-base/src/api/v1/user/updateUserShowProfile";
import updateUserHideProfile from "b2b-base/src/api/v1/user/updateUserHideProfile";
import fetchUserInfo from "../../../../api/v1/user/fetchUserInfo";
import { useCorpPersonData } from "@capone/common/src/hooks/useCorpPersonData";

export function* updateUserPassengerSaga(action: actions.IUpdateUserPassenger) {
  try {
    // Check if passport field is valid or filter it
    const { person, source, hideProfileAction } = action.payload;
    const entryPoint = action.entryPoint;
    const { passport, ...restOfPayload } = person;
    const personToUpdate: IPerson = { ...restOfPayload };
    if (passport?.countryOfIssue?.length === 2) {
      personToUpdate.passport = passport;
    }

    const currentTravelers: IPerson[] | ICorpPerson[] = yield select(
      getUserPassengers
    );
    const existingTraveler = currentTravelers.find(
      (traveler) => traveler.id === personToUpdate.id
    );
    const isNewTraveler: boolean = !existingTraveler;
    const [shouldAssignTraveler, deUserId] = useCorpPersonData(
      "isGhostUser" in person ? (person as ICorpPerson) : null
    );
    // remove undefined keys for comparison
    Object.keys(personToUpdate).forEach(
      (key) => personToUpdate[key] === undefined && delete personToUpdate[key]
    );
    const sameTravelerInfo: boolean = isEqual(existingTraveler, personToUpdate);

    if (!sameTravelerInfo) {
      yield updateUserPassenger({
        person: personToUpdate,
        isNewTraveler,
        source,
        hideProfileAction,
        entryPoint,
      });
    }

    if (shouldAssignTraveler) {
      yield updateUserDefaultTraveler({
        travelerId: personToUpdate.id,
        userId: deUserId,
      });
    }

    if (hideProfileAction === ToggleActions.OFF) {
      yield updateUserShowProfile({
        travelerId: personToUpdate.id,
      });
    } else if (hideProfileAction === ToggleActions.ON) {
      yield updateUserHideProfile({
        travelerId: personToUpdate.id,
      });
    }

    const sessionInfo: SessionInfo = yield call(fetchUserInfo);
    const canViewUsers =
      "corporateInfo" in sessionInfo
        ? (sessionInfo as CorpSessionInfo).corporateInfo.permissions
            .canViewUsers
        : false;

    const travelerDefaultTravelerId =
      ("corporateInfo" in sessionInfo &&
        (sessionInfo as CorpSessionInfo).corporateInfo.defaultTravelerId) ??
      "";

    let userPassengers: IPerson[] | ICorpPerson[];
    if (isCaponeTenant(config.TENANT)) {
      userPassengers = yield fetchUserPassengers({});
    } else {
      const [corpUsersList, caponeUserPassengers]: [
        CorpUsersListResponse,
        IListUserPassengersResponse
      ] = yield all([
        canViewUsers
          ? call(fetchCorpUsersList)
          : {
              users: [
                {
                  id: sessionInfo.userId,
                  firstName: sessionInfo.userInfo.firstName,
                  lastName: sessionInfo.userInfo.lastName,
                  defaultTravelerId: travelerDefaultTravelerId,
                },
              ],
            },
        call(fetchUserPassengers, {}),
      ]);

      const hiddenTravelerIds =
        "corporateInfo" in sessionInfo
          ? (sessionInfo as CorpSessionInfo).corporateInfo.hiddenTravelerIds
          : [];

      const loggedInUserId = sessionInfo.userId;

      userPassengers = buildCorpTravelers(
        caponeUserPassengers,
        corpUsersList.users,
        hiddenTravelerIds,
        loggedInUserId
      );
    }

    if (currentTravelers.length < userPassengers.length) {
      trackEvent({
        eventName: ADD_TRAVELER,
        properties: {},
      });
    }

    yield put(
      actions.setUserPassengers({
        userPassengerCallState: CallState.Success,
        userPassengers: userPassengers,
      })
    );
    yield put(
      actions.setUserSelectedPassengerIds({
        userSelectedPassengerIds: [person?.id],
      })
    );
  } catch (e) {
    yield put(actions.setUserPassengersCallStateFailed());
  }
}
