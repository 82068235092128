import { getPortalName, getSupportNumber } from "@capone/common";
import { Tenant } from "redmond";

export const PRICE_MATCH_IMAGE_LOCATION = "Peyto Lake, Alberta";
export const PRICE_MATCH_TITLE = "Our price match guarantee";
export const PRICE_MATCH_SUBTITLE =
  "We take extra care to get you the best price, and we’ll stand by our recommendations. If you find a better price on another website within 24 hours of booking, we’ll refund you the difference.";
export const PRICE_MATCH_CREDITS_SUBTITLE =
  "We take extra care to get you the best price, and we’ll stand by our recommendations. If you find a better price on another website within 24 hours of booking, we’ll give you a travel credit for the difference.";
export const PRICE_MATCH_HEADER = `If you find a better price, <strong> we'll match it.</strong>`;

export const PRICE_MATCH_CTA_TEXT = "Read terms and conditions";
export const PRICE_MATCH_DISCLAIMER_TEXT = (tenant: Tenant) =>
  `<strong>How to submit a claim:</strong> You must submit a price match guarantee claim through an agent by calling ${getPortalName()} at ${getSupportNumber(
    tenant
  )}. Please provide the trip ID for the original booking and the website where you found the lower price so that our agents can verify the lower price.`;
