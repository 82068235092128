import axios from "axios";
import { userUpdateShowTravelerPath } from "../../paths";
import { config } from "../../config";

export const updateUserHideProfile = (
  body: IUserHideTravelerPayload
): Promise<IUserHideTravelerResponse> =>
  new Promise(async (resolve, reject) => {
    try {
      const res = await axios.put(userUpdateShowTravelerPath, body, {
        baseURL: config.baseURL,
      });

      const responseBody: IUserHideTravelerResponse = res.data;
      resolve(responseBody);
    } catch (e) {
      reject(e);
    }
  });

export interface IUserHideTravelerPayload {
  travelerId: string;
}

export interface IUserHideTravelerResponse {}

export default updateUserHideProfile;
