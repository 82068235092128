import React from "react";
import {
  TravelerSelectWorkflow,
  TravelerSelectStep,
  useDeviceTypes,
  B2BSpinner,
  LoadingIndicator,
} from "halifax";
import {
  CallState,
  IPerson,
  PersonId,
  SELECT_TRAVELERS,
  ITravelerStepErrors,
  ICorpPerson,
} from "redmond";
import clsx from "clsx";

import "./styles.scss";
import { CarBookPassengerSelectionConnectorProps } from "./container";
import * as constants from "./textConstants";
import { trackEvent } from "../../../../api/v1/analytics/trackEvent";
import { config } from "../../../../api/config";
import { isCorpTenant } from "@capone/common";

export interface ICarBookPassengerSelectionProps
  extends CarBookPassengerSelectionConnectorProps {
  progress: TravelerSelectStep;
  setProgress: (progress: TravelerSelectStep) => void;
  onProgressChange?: (step: TravelerSelectStep) => void;
  onContinue?: (travelersChanged?: boolean) => void;
  onGoBack?: (travelersChanged?: boolean) => void;
  className?: string;
  selectionScreenHeaderElement?: JSX.Element;
  onTravelersStep?: boolean;
  isMobile?: boolean;
  disabled?: boolean;
  combinedStep?: boolean;
  showErrors?: ITravelerStepErrors;
  setShowErrors?: (showErrors: ITravelerStepErrors) => void;
  saveButtonClicked?: boolean;
  useLocalId?: boolean;
  hiddenTravelerIds?: string[];
}

export const CarBookPassengerSelection = (
  props: ICarBookPassengerSelectionProps
) => {
  const {
    userPassengerCallState,
    className,
    travelers,
    progress,
    selectionScreenHeaderElement,
    setProgress,
    onProgressChange,
    onContinue,
    onGoBack,
    selectedTravelerId,
    setUserSelectedPassengerIds,
    addUserPassenger,
    removeUserPassenger,
    fetchUserPassengers,
    isMissingLicense,
    onTravelersStep,
    isMobile,
    disabled,
    combinedStep,
    showErrors,
    setShowErrors,
    saveButtonClicked,
    useLocalId,
    fetchCorpUserPassengers,
  } = props;

  const [localTravelerId, setLocalTravelerId] = React.useState<string[]>([]);
  const travelersChanged = selectedTravelerId !== localTravelerId[0];
  const currentTravelerId = useLocalId
    ? localTravelerId[0]
    : selectedTravelerId;

  const { matchesMobile } = useDeviceTypes();

  React.useEffect(() => {
    isCorpTenant(config.TENANT)
      ? fetchCorpUserPassengers()
      : fetchUserPassengers();
  }, []);

  React.useEffect(() => {
    if (
      travelers.length === 1 &&
      (!(travelers[0] as ICorpPerson).isMissingInfo ?? true)
    ) {
      setUserSelectedPassengerIds({
        userSelectedPassengerIds: [travelers[0].id],
      });
    }
  }, [travelers]);

  React.useEffect(() => {
    if (selectedTravelerId && isMissingLicense) {
      setProgress(TravelerSelectStep.TravelerInfoForm);
    }
  }, [selectedTravelerId, isMissingLicense]);

  const handleContinue = () => {
    if (useLocalId && travelersChanged) {
      setUserSelectedPassengerIds({
        userSelectedPassengerIds: localTravelerId,
      });
    }
    onContinue && onContinue(travelersChanged);
  };

  const handleGoBack = () => {
    if (useLocalId && travelersChanged) {
      setUserSelectedPassengerIds({
        userSelectedPassengerIds: localTravelerId,
      });
    }
    onGoBack && onGoBack(travelersChanged);
  };
  return (
    <>
      {userPassengerCallState === CallState.InProcess ? (
        <LoadingIndicator
          indicatorSize="small"
          indicator={B2BSpinner}
          message={
            userPassengerCallState === CallState.InProcess
              ? constants.UPDATE_TEXT
              : constants.VALIDATE_TEXT
          }
        />
      ) : (
        <TravelerSelectWorkflow
          showDriverLicenseSection
          showGenderField
          showNationalityField
          singleTravelerWorkflow
          buttonClassName="b2b"
          buttonMessage={
            travelers?.length > 0
              ? constants.ADD_TRAVELERS_TEXT_NEW
              : constants.ADD_TRAVELERS_TEXT
          }
          className={clsx("car-book-passenger-selection-root", className, {
            "combined-step": combinedStep,
          })}
          disabled={disabled}
          editPassenger={
            selectedTravelerId
              ? travelers.find((t) => t.id === selectedTravelerId)
              : undefined
          }
          errorMessage={constants.ADD_TRAVELER_ERROR_MESSAGE}
          handleDeletePassenger={(travelerId: PersonId) => {
            if (useLocalId) {
              if (localTravelerId[0] === travelerId) {
                setLocalTravelerId([]);
              }
            }
            removeUserPassenger({ personId: travelerId });
          }}
          handleUpdatePassenger={(
            traveler: IPerson,
            hideProfileAction,
            entryPoint
          ) => {
            if (useLocalId) {
              setLocalTravelerId([traveler.id]);
            }

            addUserPassenger(
              {
                person: traveler,
                hideProfileAction: hideProfileAction,
              },
              undefined,
              entryPoint
            );
          }}
          isMobile={matchesMobile}
          onContinue={handleContinue}
          onGoBack={handleGoBack}
          onProgressChange={onProgressChange}
          progress={progress}
          saveButtonClicked={saveButtonClicked}
          setProgress={setProgress}
          selectedTravelerIds={currentTravelerId ? [currentTravelerId] : []}
          selectionScreenHeaderElement={selectionScreenHeaderElement}
          setSelectedTravelerIds={(travelerIds: string[]) => {
            trackEvent({
              eventName: SELECT_TRAVELERS,
              properties: {},
            });
            if (useLocalId) {
              setLocalTravelerId(travelerIds);
            } else {
              setUserSelectedPassengerIds({
                userSelectedPassengerIds: travelerIds,
              });
            }
          }}
          setShowErrors={setShowErrors}
          showErrors={showErrors}
          titles={{
            travelerInfoTitle: matchesMobile
              ? onTravelersStep
                ? constants.SELECT_DRIVER_TEXT
                : constants.TRAVELER_INFO_TEXT
              : constants.TRAVELER_INFO_TITLE_UPDATED,
            travelerInfoSubtitle: constants.TRAVELER_INFO_SUBTITLE,
            travelerInfoFormSubtitle: constants.TRAVELER_INFO_FORM_SUBTITLE,
            frequentFlyerTitle: constants.FREQUENT_FLYER_TITLE,
            additionalInfoTitle: constants.ADDITIONAL_INFO_TITLE,
            adultTitle: constants.ADULT_TITLE,
            childTitle: constants.CHILD_TITLE,
            infantSeatTitle: constants.INFANT_SEAT_TITLE,
            infantLapTitle: constants.INFANT_LAP_TITLE,
            addTravelers: matchesMobile
              ? onTravelersStep
                ? constants.ADD_TRAVELER_INFO_TEXT
                : constants.TRAVELER_INFO_TEXT
              : constants.ADD_TRAVELERS_TEXT_UPDATED,
            completeTravelerProfileTitle: constants.COMPLETE_PROFILE_TITLE,
            completeTravelerProfileSubtitle:
              constants.COMPLETE_PROFILE_SUBTITLE,
          }}
          travelers={travelers}
          wrapButton={isMobile && onTravelersStep}
          tenant={config.TENANT}
          trackEvent={trackEvent}
        />
      )}
    </>
  );
};
