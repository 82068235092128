import { GroundSelectionEnum, CarsAutocompleteResult, IdEnum } from "redmond";
import { isEqual } from "lodash-es";

export const getSelectedOption = (
  option: CarsAutocompleteResult | null,
  value: CarsAutocompleteResult | null
) => {
  const valueId = value?.id;
  const optionId = option?.id;

  if (!valueId || !optionId) {
    return false;
  }

  if (valueId.Id === IdEnum.Grounds && optionId.Id === IdEnum.Grounds) {
    const valueGroundSelection = valueId.groundSelection;
    const optionGroundSelection = optionId.groundSelection;
    if (
      valueGroundSelection.GroundSelection === GroundSelectionEnum.Location &&
      optionGroundSelection.GroundSelection === GroundSelectionEnum.Location
    ) {
      return isEqual(valueGroundSelection.center, optionGroundSelection.center);
    }
    if (
      valueGroundSelection.GroundSelection === GroundSelectionEnum.Place &&
      optionGroundSelection.GroundSelection === GroundSelectionEnum.Place
    ) {
      return valueGroundSelection.placeId === optionGroundSelection.placeId;
    }
  } else if (valueId.Id === IdEnum.Flight && optionId.Id === IdEnum.Flight) {
    return isEqual(valueId.code, optionId.code);
  }

  return false;
};
