import { CancellationPolicy } from "../../../../reducer";

const FREE_CANCELLATION_TEXT = "Free Cancellation";

export const getCancellationPolicyTitle = (
  cancellation: CancellationPolicy
) => {
  switch (cancellation) {
    case CancellationPolicy.FreeCancellation:
      return FREE_CANCELLATION_TEXT;
    default:
      return "";
  }
};

export const VIEW_CARS_WITH_FREE_CANCEL =
  "View rental cars that include free cancellation policies.";
