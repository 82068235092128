import React from "react";
import { Box, Typography } from "@material-ui/core";
import { ActionButton, Icon, IconName } from "halifax";

import {
  MonthAndDatePicker,
  MonthAndDatePickerType,
} from "../../../DesktopCarSearchControl/components/MonthAndDatePicker";
import { MobileCalendarPickerConnectorProps } from "./container";
import * as textConstants from "./textConstants";
import { MobileCarSearchStep } from "../../../../reducer";
import "./styles.scss";
import clsx from "clsx";
import dayjs from "dayjs";

interface IMobileCalendarPickerProps
  extends MobileCalendarPickerConnectorProps {
  pickUpDate: Date | null;
  dropOffDate: Date | null;
  focusedMonthIndex: number;
  onComplete?: () => void;
  hideTimePicker?: boolean;
  onSelectDates?: (pickUp: Date | null, dropOff: Date | null) => void;
}

export const MobileCalendarPicker = (props: IMobileCalendarPickerProps) => {
  const {
    readyToContinue,
    focusedMonthIndex,
    setPickUpDate,
    setDropOffDate,
    pickUpDate,
    dropOffDate,
    setMobileCarSearchStep,
    hideTimePicker,
    onSelectDates,
  } = props;

  const DatePickerModalHeader = ({
    label,
    value,
  }: {
    label: string;
    value?: string;
  }) => {
    return (
      <Box className="selected-date-wrapper">
        <Icon name={IconName.Calendar} />
        <Box className="selected-date-content">
          <Typography className="content-top-label">
            {value ? label : ""}
          </Typography>
          <Typography
            className={clsx("content-main-content", { label: !value })}
          >
            {value || label}
          </Typography>
        </Box>
      </Box>
    );
  };

  return (
    <Box className="mobile-calendar-picker-root redesign-v3">
      <MonthAndDatePicker
        className="b2b"
        viewType={MonthAndDatePickerType.Column}
        focusedMonthIndex={focusedMonthIndex}
        setFromDate={setPickUpDate}
        setUntilDate={setDropOffDate}
        from={pickUpDate}
        until={dropOffDate}
        header={
          <Box className="cars-mobile-calendar-header">
            <DatePickerModalHeader
              label={textConstants.PICK_UP}
              value={
                pickUpDate ? dayjs(pickUpDate).format("ddd D, MMM") : undefined
              }
            />
            <DatePickerModalHeader
              label={textConstants.DROP_OFF}
              value={
                dropOffDate
                  ? dayjs(dropOffDate).format("ddd D, MMM")
                  : undefined
              }
            />
          </Box>
        }
      />
      {!hideTimePicker && readyToContinue && (
        <ActionButton
          className={clsx("mobile-calendar-picker-continue-button", "b2b")}
          message={textConstants.CONTINUE}
          onClick={() => {
            setMobileCarSearchStep(MobileCarSearchStep.TimePickerAndDriverAge);
            onSelectDates?.(pickUpDate, dropOffDate);
          }}
        />
      )}
    </Box>
  );
};
