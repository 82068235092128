export const BOOK_BUTTON_TEXT = "Confirm and Book";
export const REQUIRED_DETAILS_TEXT = "Enter all required details";
export const CONTACT_INFO_TITLE = "Contact Information";
export const CONTACT_INFO_SUBTITLE =
  "This information is used to notify you about any updates or changes to your trip.";
export const CONTACT_INFO_SAVE = "Save Contact Information";
export const CONTINUE = "Continue";
export const REVIEW_MY_TRIP_TEXT = "Review my Trip";
export const PRICE_QUOTE_MESSAGE = "Saving driver and checking availability";
export const TITLE = "Trip Review";
export const SUBTITLE = "Review your trip before you Confirm and Book.";
export const UNDER_25_WARNING =
  "This rental car company may charge an underage driver fee at pick-up.";
export const TREES_MODAL_TITLE = "How it works";
export const TREES_MODAL_SUBTITLE =
  "For every <span class='font-bold'>flight, hotel</span> or <span class='font-bold'>car rental</span> you book, together with our partners, we <span class='font-bold'>plant 4 trees</span> to help with forest restoration programs.";
export const TREES_MODAL_HEADER =
  "When you book, <span class='font-regular'>you plant trees.</span>";
export const TREES_MODAL_LOCATION = "Banff, Alberta";
export const TREES_MODAL_CTA_TEXT = "will be planted for this booking! ";
export const TREES_BOLDED_MODAL_CTA_TEXT = "4 trees ";
export const UPDATE_DRIVER = "Update Driver Details";
