import { connect } from "react-redux";
import { IStoreState } from "../../reducers/types";
import {
  getUserPassengerCallState,
  getUserPassengers,
} from "../../modules/book/reducer";
import { updateUserPassenger } from "../../modules/book/actions/actions";
import { CallState } from "redmond";

import { OnboardingModal as OnboardingModalComponent } from "halifax";

const mapStateToProps = (state: IStoreState) => {
  return {
    travelers: getUserPassengers(state),
    isLoadingTravelers:
      getUserPassengerCallState(state) === CallState.InProcess ||
      getUserPassengerCallState(state) === CallState.NotCalled,
  };
};

const mapDispatchToProps = {
  upsertTraveler: updateUserPassenger,
};

const connector = connect(mapStateToProps, mapDispatchToProps);

export const OnboardingModal = connector(OnboardingModalComponent);
