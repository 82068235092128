import { takeLeading } from "redux-saga/effects";

import { actionTypes } from "../actions";
import { fetchTravelWalletCreditHistorySaga } from "./fetchTravelWalletCreditHistorySaga";
import { fetchTravelWalletDetailsSaga } from "./travel-wallet-details";

export function* watchFetchTravelWalletDetails() {
  yield takeLeading(
    actionTypes.FETCH_TRAVEL_WALLET_DETAILS,
    fetchTravelWalletDetailsSaga
  );
}

export function* watchFetchTravelWalletCreditHistorySaga() {
  yield takeLeading(
    actionTypes.FETCH_TRAVEL_WALLET_CREDIT_HISTORY,
    fetchTravelWalletCreditHistorySaga
  );
}
