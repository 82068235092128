import { combineReducers } from "@reduxjs/toolkit";
import { reducer as carSearch } from "../modules/search/reducer";
import { reducer as carAvailability } from "../modules/availability/reducer";
import { reducer as carRewards } from "../modules/rewards/reducer";
import { reducer as carShop } from "../modules/shop/reducer";
import { reducer as carBook } from "../modules/book/reducer";
import { reducer as carTravelWallet } from "../modules/travel-wallet/reducer";

export const rootReducer = combineReducers({
  carAvailability,
  carRewards,
  carSearch,
  carShop,
  carBook,
  carTravelWallet,
});
