import { connect, ConnectedProps } from "react-redux";
import { IStoreState } from "../../../../../../reducers/types";
import {
  getDropOffDate,
  getPickUpDate,
  getOpenDatesModal,
} from "../../../../reducer";
import { setOpenDatesModal } from "../../../../actions/actions";
import { CalendarPickerButton } from "./component";

export const mapStateToProps = (state: IStoreState) => {
  return {
    fromDate: getPickUpDate(state),
    untilDate: getDropOffDate(state),
    openDatesModal: getOpenDatesModal(state),
  };
};

const mapDispatchToProps = {
  setOpenDatesModal,
};

const connector = connect(mapStateToProps, mapDispatchToProps);

export type ConnectedCalendarPickerButtonProps = ConnectedProps<
  typeof connector
>;

export const ConnectedCalendarPickerButton = connector(CalendarPickerButton);
