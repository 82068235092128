import axios from "axios";

import { PaymentSessionToken } from "redmond";
import { config } from "../../../config";
import { carBookApiPrefix } from "../../paths";

const END_POINT: string = `${carBookApiPrefix}/session/close`;

export const closeSession = (token: PaymentSessionToken): Promise<any> =>
  new Promise(async (resolve, reject) => {
    try {
      const res = await axios.post(
        END_POINT,
        { ...token },
        {
          baseURL: config.baseURL,
        }
      );

      const responseBody = res.data;
      resolve(responseBody);
    } catch (e) {
      reject(e);
    }
  });
