import { takeLatest } from "redux-saga/effects";
import { actionTypes } from "../actions";
import { fetchCarsLocationAutocompleteSaga } from "./fetchCarsLocationAutocompleteSaga";

export function* watchFetchDropOffLocationAutocomplete() {
  yield takeLatest(
    actionTypes.FETCH_DROP_OFF_LOCATION_RESULTS,
    fetchCarsLocationAutocompleteSaga
  );
}

export function* watchFetchPickUpLocationAutocomplete() {
  yield takeLatest(
    actionTypes.FETCH_PICK_UP_LOCATION_RESULTS,
    fetchCarsLocationAutocompleteSaga
  );
}
