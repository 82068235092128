import * as React from "react";
import { Box, Typography } from "@material-ui/core";
import {
  AppliedCarsFilterProperties,
  APPLIED_CARS_FILTER,
  IPriceRange,
} from "redmond";
import clsx from "clsx";
import { GenericSlider, useDeviceTypes } from "halifax";

import { getPriceRangeText } from "./textConstants";
import "./styles.scss";
import { trackEvent } from "../../../../../../api/v1/analytics/trackEvent";

interface IPriceRangeSelectionProps {
  className?: string;
  title?: string;
  setPriceRange: (priceRange: IPriceRange) => void;
  priceRange: IPriceRange;
  minPrice: number;
  maxPrice: number;
  currencySymbol: string;
}

export const PriceRangeSelection = (props: IPriceRangeSelectionProps) => {
  const {
    className,
    title,
    setPriceRange,
    priceRange,
    minPrice,
    maxPrice,
    currencySymbol,
  } = props;

  const { matchesMobile } = useDeviceTypes();

  const onChangePriceRange = (min: number, max: number) => {
    const properties: AppliedCarsFilterProperties = {
      filter_type: "price",
    };
    trackEvent({
      eventName: APPLIED_CARS_FILTER,
      properties,
    });
    setPriceRange({ min, max });
  };

  // note: while there is no result returned, searching again on car availability screen can cause the following condition to happen
  React.useEffect(() => {
    if (priceRange.max > maxPrice || priceRange.min < minPrice) {
      setPriceRange({
        min: minPrice,
        max: maxPrice,
      });
    }
  }, [setPriceRange, priceRange, minPrice, maxPrice]);

  return (
    <Box
      className={clsx(
        "price-range-selection-root",
        { mobile: matchesMobile },
        className
      )}
    >
      <Box className="price-range-selection-container">
        {title && (
          <Typography variant="body1" className="header-text">
            {title}
          </Typography>
        )}
        <Typography variant="body2" className="price-range-text">
          {getPriceRangeText(priceRange, currencySymbol)}
        </Typography>
        <Box className="selection-container">
          <Box className="price-range-slider-container">
            <PriceRangeSlider
              priceRange={priceRange}
              onChange={onChangePriceRange}
              sliderMin={minPrice}
              sliderMax={maxPrice}
            />
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

interface IPriceRangeSliderProps {
  onChange: (min: number, max: number) => void;
  priceRange: IPriceRange;
  sliderMin: number;
  sliderMax: number;
}

const PriceRangeSlider = (props: IPriceRangeSliderProps) => {
  const { onChange, priceRange, sliderMin, sliderMax } = props;

  return (
    <GenericSlider
      className="price-range-slider-root"
      onChange={onChange}
      sliderType={"doubleThumb"}
      step={1}
      chosenMin={priceRange.min}
      chosenMax={priceRange.max}
      sliderMin={sliderMin}
      sliderMax={sliderMax}
      getLabel={(value: number) => `$${value}`}
    />
  );
};
