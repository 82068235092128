export const FIND_MORE_RESULTS_TITLE_TEXT = "Find more cars";
export const FIND_MORE_RESULTS_WITH_FILTERS_SUBTITLE_TEXT =
  "Adjust your dates or reset filters to see more results.";
export const FIND_MORE_RESULTS_SUBTITLE_TEXT =
  "Adjust your dates to see more results.";
export const SEARCH_AGAIN_TEXT = "Search Again";
export const RESET_FILTERS_TEXT = "Reset Filters";
export const SHOWING_TEXT = "Showing";
export const CAR_RENTALS_TEXT = "Car Rentals";
export const CAR_LIST_COUNT_TITLE = "rental cars";
export const ADJUST_DATES = "Adjust Dates";
export const SORTED_BY_BEST_PRICE_TEXT = "Sorted by best price first";
export const getEarnTagText = (earnRate: string | number, currency: string) => {
  const multiplier = currency.includes("cash") ? "%" : "X";
  return `<span class="font-bold">Earn ${earnRate}${multiplier}</span> ${currency} on cars`;
};
