import axios from "axios";
import { config } from "../../../config";
import { PersonId } from "redmond";

import { passengersApiPrefix } from "../../paths";

const END_POINT: string = `${passengersApiPrefix}/delete`;

export const deleteUserPassenger = (
  body: IDeleteUserPassengerRequest
): Promise<IDeleteUserPassengerResponse> =>
  new Promise(async (resolve, reject) => {
    try {
      const res = await axios.post(END_POINT, body, {
        baseURL: config.baseURL,
      });

      const responseBody: IDeleteUserPassengerResponse = res.data;
      resolve(responseBody);
    } catch (e) {
      reject(e);
    }
  });

export interface IDeleteUserPassengerRequest {
  personId: PersonId;
}

export interface IDeleteUserPassengerResponse {}
