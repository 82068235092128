import React from "react";
import {
  Box,
  Checkbox,
  FormGroup,
  FormControl,
  FormControlLabel,
  Typography,
} from "@material-ui/core";
import { useDeviceTypes } from "halifax";
import clsx from "clsx";

import { CarFilterTypeV5, GeneralCarType } from "../../../../reducer";
import { getCarTypeTitles } from "./textConstants";
import "./styles.scss";
import { randomIdWithName } from "../../../../utils/randomIdWithName";

export interface ICarTypeSelectionProps {
  className?: string;
  carTypes: GeneralCarType[] | CarFilterTypeV5[];
  setCarTypes: (carTypes: GeneralCarType[] | CarFilterTypeV5[]) => void;
  isCarsCXV1Experiment?: boolean;
}

export const CarTypeSelection = (props: ICarTypeSelectionProps) => {
  const { className, carTypes, setCarTypes, isCarsCXV1Experiment } = props;
  const { matchesMobile } = useDeviceTypes();

  const isChecked = (carType: GeneralCarType | CarFilterTypeV5): boolean => {
    return carTypes.some((type) => type === carType);
  };

  const handleChange = (carType: GeneralCarType | CarFilterTypeV5) => {
    if (isChecked(carType)) {
      const selectedAfterRemove =
        carType in CarFilterTypeV5
          ? (carTypes as CarFilterTypeV5[]).filter(
              (selectedCarType: CarFilterTypeV5) => selectedCarType !== carType
            )
          : (carTypes as GeneralCarType[]).filter(
              (selectedCarType: GeneralCarType) => selectedCarType !== carType
            );
      setCarTypes(selectedAfterRemove);
    } else {
      const newSelected = [...carTypes, carType] as
        | GeneralCarType[]
        | CarFilterTypeV5[];
      setCarTypes(newSelected);
    }
  };

  const renderOptions = (options: GeneralCarType[] | CarFilterTypeV5[]) => {
    return options.map((carType: GeneralCarType | CarFilterTypeV5) => {
      const { title, subtitle } = getCarTypeTitles(carType);
      const checked = isChecked(carType);
      const id = randomIdWithName(carType);
      return (
        <FormControlLabel
          key={carType}
          className="cars-types-selection-form-control-label"
          onKeyDown={(e) => {
            if (e && e.key === "Enter") {
              handleChange(carType);
            }
          }}
          control={
            <Checkbox
              inputProps={{ "aria-labelledby": id }}
              checked={checked}
              onChange={() => handleChange(carType)}
              value={carType}
              className="cars-types-selection-checkbox"
            />
          }
          labelPlacement={
            isCarsCXV1Experiment && !matchesMobile ? "end" : "start"
          }
          label={
            <Box id={id} className="car-type-label-wrapper">
              <Typography variant="body1" className="car-type-name">
                {title}
              </Typography>
              <Typography variant="body2" className="car-type-description">
                {subtitle}
              </Typography>
            </Box>
          }
        />
      );
    });
  };

  return (
    <Box
      className={clsx(
        "car-type-selection-root",
        { mobile: matchesMobile, "cars-cx-v1": isCarsCXV1Experiment },
        className
      )}
    >
      <Box className="car-type-selection-container">
        <FormControl
          className="car-types-selection-form-control"
          component="fieldset"
        >
          <FormGroup>
            {renderOptions(
              isCarsCXV1Experiment
                ? Object.values(CarFilterTypeV5)
                : [
                    GeneralCarType.Small,
                    GeneralCarType.Medium,
                    GeneralCarType.Large,
                  ]
            )}
          </FormGroup>
        </FormControl>
      </Box>
    </Box>
  );
};
