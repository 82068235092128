import { connect, ConnectedProps } from "react-redux";
import { withRouter } from "react-router";
import { BookingSubmitApprovalErrorModal } from "./component";
import { getSubmitForApprovalCallState } from "../../../reducer";
import { IStoreState } from "../../../../../reducers/types";
import { CallState } from "redmond";

const mapStateToProps = (state: IStoreState) => ({
  isOpen: getSubmitForApprovalCallState(state) === CallState.Failed,
});

const mapDispatchToProps = {};

const connector = connect(mapStateToProps, mapDispatchToProps);

export type BookingSubmitApprovalErrorModalConnectorProps = ConnectedProps<
  typeof connector
>;

export const ConnectedBookingSubmitApprovalErrorModal = withRouter(
  connector(BookingSubmitApprovalErrorModal)
);
