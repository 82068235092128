import { CarsCancellationPolicyEnum } from "redmond";
import { ICarAvailabilityLineItem } from "../../availability/reducer/utils/carAvailabilityHelperFunctions";

export const FREE_CANCELLATION = "Free Cancellation";
export const NON_REFUNDABLE = "Non Refundable";
export const CONDITIONAL = "Conditional";
export const CONDITIONAL_AFTER_BOOKING = "Conditional After Booking";

export const getCancellationPolicyInfo = (
  selVehicle: ICarAvailabilityLineItem | null
) => {
  const policy = selVehicle?.cancellationPolicy;
  if (policy?.description) {
    return policy.description;
  }

  switch (policy?.CancellationPolicy) {
    case CarsCancellationPolicyEnum.FreeCancellation:
      return FREE_CANCELLATION;
    case CarsCancellationPolicyEnum.PartiallyRefundable:
      return NON_REFUNDABLE; // Showing NonRefundable for now https://hopper-jira.atlassian.net/browse/BP-2209
    case CarsCancellationPolicyEnum.ConditionalAfterBooking:
      return CONDITIONAL_AFTER_BOOKING;
    case CarsCancellationPolicyEnum.Conditional:
      return CONDITIONAL;
    default:
      return NON_REFUNDABLE;
  }
};
