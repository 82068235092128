import { connect, ConnectedProps } from "react-redux";
import { withRouter } from "react-router-dom";
import { IStoreState } from "../../../../../../reducers/types";
import { setMobileCarSearchStep } from "../../../../actions/actions";
import {
  getDropOffDate,
  getPickUpDate,
  getDropOffLocation,
  getPickUpLocation,
  getPickUpTime,
  getDropOffTime,
  getDriverAge,
} from "../../../../reducer";
import { TimeAndAgePicker } from "./component";

const mapDispatchToProps = {
  setMobileCarSearchStep,
};
const mapStateToProps = (state: IStoreState) => {
  const dropOffDate = getDropOffDate(state);
  const pickUpDate = getPickUpDate(state);

  const dropOffLocation = getDropOffLocation(state);
  const pickUpLocation = getPickUpLocation(state);
  const pickUpTime = getPickUpTime(state);
  const dropOffTime = getDropOffTime(state);
  const driverAge = getDriverAge(state);

  const readyToSearch =
    !!dropOffLocation &&
    !!pickUpLocation &&
    !!pickUpTime &&
    !!dropOffTime &&
    typeof driverAge === "number" &&
    driverAge <= 99 &&
    driverAge >= 18;

  return {
    pickUpDate,
    dropOffDate,
    readyToSearch,
    dropOffTime,
    dropOffLocation,
    pickUpTime,
    pickUpLocation,
    driverAge,
  };
};

const connector = connect(mapStateToProps, mapDispatchToProps);

export type TimeAndAgePickerConnectorProps = ConnectedProps<typeof connector>;

export const ConnectedTimeAndAgePicker = withRouter(
  connector(TimeAndAgePicker)
);
