import { connect, ConnectedProps } from "react-redux";
import { withRouter } from "react-router";

import { actions } from "./actions";
import { IStoreState } from "../../reducers/types";
import {
  getCarAvailabilityCallState,
  CarAvailabilityCallState,
  getViewedCarsListProperties,
  getLowestTotalPrice,
} from "./reducer/index";
import { CarsAvailability } from "./component";
import {
  fetchTravelWalletCreditHistory,
  fetchTravelWalletDetails,
} from "../travel-wallet/actions/actions";
import { getRewardsAccountWithLargestEarnForBanner } from "../rewards/reducer";
import { listPaymentMethods, setIsCarsCXV1Experiment } from "./actions/actions";
import {
  getDropOffDate,
  getDropOffLocation,
  getPickUpDate,
  getPickUpLocation,
} from "../search/reducer";

const mapStateToProps = (state: IStoreState) => {
  const carAvailabilityCallState = getCarAvailabilityCallState(state);
  const isFetchingInitialAvailability =
    carAvailabilityCallState ===
    CarAvailabilityCallState.InitialSearchCallInProcess;

  return {
    isFetchingInitialAvailability,
    viewedCarsListProperties: getViewedCarsListProperties(state),
    carAvailabilityCallState: getCarAvailabilityCallState(state),
    largestValueAccount: getRewardsAccountWithLargestEarnForBanner(state),
    lowestTotalPrice: getLowestTotalPrice(state),
    pickUpLocation: getPickUpLocation(state),
    dropOffLocation: getDropOffLocation(state),
    pickUpDate: getPickUpDate(state),
    dropOffDate: getDropOffDate(state),
  };
};

const mapDispatchToProps = {
  fetchInitialCarsAvailability: actions.fetchInitialCarsAvailability,
  fetchTravelWalletDetails,
  fetchTravelWalletCreditHistory,
  setIsCarsCXV1Experiment,
  listPaymentMethods,
};

const connector = connect(mapStateToProps, mapDispatchToProps);

export type CarAvailabilityConnectorProps = ConnectedProps<typeof connector>;

export const ConnectedCarsAvailability = withRouter(
  connector(CarsAvailability)
);
