export const CAR_TYPE_TEXT = "Car type";
export const PRICE_PER_DAY_TEXT = "Price per day";
export const RENTAL_CAR_COMPANY_TEXT = "Rental Car Company";
export const SPECIFICATIONS_TEXT = "Specifications";
export const CANCELLATION_POLICY_TEXT = "Cancellation policy";
export const PASSENGERS = "Passengers";
export const RESET_TEXT = "Reset Filters";
export const PICKUP_TYPE_TEXT = "Pick-up Location";
export const BAGS_TEXT = "Number of bags";
export const IN_POLICY_TEXT = "Policy status";
