import { getTotalPriceText, twoDecimalFormatter } from "halifax";
import { ICarAvailabilityLineItem } from "../../../availability/reducer/utils/carAvailabilityHelperFunctions";

export const RESERVE = (selectedVehicle: ICarAvailabilityLineItem | null) =>
  selectedVehicle
    ? `Continue for <strong>${getTotalPriceText({
        price: selectedVehicle.totalCharge.fiat,
        priceFormatter: twoDecimalFormatter,
      })}</strong>`
    : `Continue`;
export const BACK_TO_RESULTS = "Back to results";
export const REVIEW_CAR_RENTAL = "Review Car Rental";
export const RENTAL_POLICY = "Rental Policy";
export const CANCELLATION_POLICY = "Cancellation Policy";
export const IMPORTANT_INFORMATION = "Important Information";
export const BOOKING_CONDITIONS = "Booking Conditions";
export const PICKUP_DIRECTIONS = "Pick Up Directions";
export const FREE_CANCELLATION = "Free Cancellation";
export const NON_REFUNDABLE = "Non Refundable";
export const getEarnTagText = (earnRate: string | number, currency: string) => {
  const multiplier = currency.includes("cash") ? "%" : "X";
  return `<span class="font-bold">Earn ${earnRate}${multiplier}</span> ${currency} on cars`;
};
