import axios from "axios";
import {
  CREATED_TRAVELER_PROFILE,
  IPerson,
  TravelerProfileProperties,
  UPDATED_TRAVELER_PROFILE,
} from "redmond";
import { config } from "../../../config";

import { passengersApiPrefix } from "../../paths";
import { trackEvent } from "../../analytics/trackEvent";
import { isCorpTenant } from "@capone/common";
import { ToggleActions } from "halifax";

const END_POINT: string = `${passengersApiPrefix}/update`;

export const updateUserPassenger = (
  body: IUpdateUserPassengerRequest
): Promise<IUpdateUserPassengerResponse> =>
  new Promise(async (resolve, reject) => {
    try {
      const { isNewTraveler, hideProfileAction, entryPoint, ...payload } = body;
      const res = await axios.put(END_POINT, payload, {
        baseURL: config.baseURL,
      });

      const responseBody: IUpdateUserPassengerResponse = res.data;
      resolve(responseBody);

      if (isCorpTenant(config.TENANT)) {
        const frequentFlyerProgramsAmount = Reflect.ownKeys(
          body.person.frequentFlyer
        ).length;
        const hotelLoyaltyProgramsAmount = Reflect.ownKeys(
          body.person.hotelLoyalty
        ).length;

        trackEvent({
          eventName: isNewTraveler
            ? CREATED_TRAVELER_PROFILE
            : UPDATED_TRAVELER_PROFILE,
          properties: {
            frequent_flyer_program_added: frequentFlyerProgramsAmount > 0,
            num_frequent_flyer_programs: frequentFlyerProgramsAmount,
            hotel_loyalty_program_added: hotelLoyaltyProgramsAmount > 0,
            num_hotel_loyalty_programs: hotelLoyaltyProgramsAmount,
            source: body.source,
            private: hideProfileAction === ToggleActions.ON,
            entry_point: entryPoint,
          } satisfies TravelerProfileProperties,
        });
      }
    } catch (e) {
      reject(e);
    }
  });

export interface IUpdateUserPassengerRequest {
  person: IPerson;
  isNewTraveler?: boolean;
  source?: string;
  hideProfileAction?: ToggleActions;
  entryPoint?: string;
}

export interface IUpdateUserPassengerResponse {}
