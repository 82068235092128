import { connect, ConnectedProps } from "react-redux";
import { withRouter } from "react-router";

import { IStoreState } from "../../../../reducers/types";
import { getPickUpDateSearched, getDropOffDateSearched } from "../../reducer";
import { MobileDatesSelection } from "./component";

const mapStateToProps = (state: IStoreState) => {
  return {
    pickUpDate: getPickUpDateSearched(state),
    dropOffDate: getDropOffDateSearched(state),
  };
};

const mapDispatchToProps = {};

const connector = connect(mapStateToProps, mapDispatchToProps);

export type MobileDatesSelectionConnectorProps = ConnectedProps<
  typeof connector
>;

export const ConnectedMobileDatesSelection = withRouter(
  connector(MobileDatesSelection)
);
