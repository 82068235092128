import React from "react";
import {
  Box,
  Checkbox,
  FormGroup,
  FormControl,
  FormControlLabel,
  Typography,
} from "@material-ui/core";
import { useDeviceTypes } from "halifax";
import clsx from "clsx";

import { PassengerFilter } from "../../../../reducer";
import { getPassengersLabel } from "./textConstants";
import "./styles.scss";

export interface IPassengersSelectionProps {
  className?: string;
  passengersSelected: PassengerFilter[];
  setPassengersFilter: (passengersFilter: PassengerFilter[]) => void;
  isCarsCXV1Experiment?: boolean;
}

export const PassengersSelection = (props: IPassengersSelectionProps) => {
  const {
    className,
    passengersSelected,
    setPassengersFilter,
    isCarsCXV1Experiment,
  } = props;
  const { matchesMobile } = useDeviceTypes();

  const isChecked = (passengers: PassengerFilter): boolean => {
    return passengersSelected.some((type) => type === passengers);
  };

  const handleChange = (passengerFilterValue: PassengerFilter) => {
    if (isChecked(passengerFilterValue)) {
      const selectedAfterRemove = (
        passengersSelected as PassengerFilter[]
      ).filter(
        (selectedFilter: PassengerFilter) =>
          selectedFilter !== passengerFilterValue
      );
      setPassengersFilter(selectedAfterRemove);
    } else {
      const newSelected = [
        ...passengersSelected,
        passengerFilterValue,
      ] as PassengerFilter[];
      setPassengersFilter(newSelected);
    }
  };

  const renderOptions = (options: PassengerFilter[]) => {
    return options.map((passengerFilterValue: PassengerFilter) => {
      const checked = isChecked(passengerFilterValue);
      return (
        <FormControlLabel
          key={passengerFilterValue}
          onKeyDown={(e) => {
            if (e && e.key === "Enter") {
              handleChange(passengerFilterValue);
            }
          }}
          control={
            <Checkbox
              inputProps={{ "aria-labelledby": passengerFilterValue }}
              checked={checked}
              onChange={() => handleChange(passengerFilterValue)}
              value={passengerFilterValue}
              className="passengers-selection-checkbox"
            />
          }
          labelPlacement={
            isCarsCXV1Experiment && !matchesMobile ? "end" : "start"
          }
          label={
            <Box id={passengerFilterValue} className="passengers-label-wrapper">
              <Typography variant="body1" className="passengers-label">
                {getPassengersLabel(passengerFilterValue)}
              </Typography>
            </Box>
          }
          className="passengers-selection-form-control-label"
        />
      );
    });
  };

  return (
    <Box
      className={clsx(
        "passengers-selection-root",
        { mobile: matchesMobile, "cars-cx-v1": isCarsCXV1Experiment },
        className
      )}
    >
      <Box className="passengers-selection-container">
        <FormControl
          className="passengers-selection-form-control"
          component="fieldset"
        >
          <FormGroup>
            {renderOptions([
              PassengerFilter.UpToFive,
              PassengerFilter.SixOrMore,
            ])}
          </FormGroup>
        </FormControl>
      </Box>
    </Box>
  );
};
