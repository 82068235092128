import axios from "axios";

import { PaymentSessionToken } from "redmond";
import { purchaseApi } from "@b2bportal/purchase-api";

export const closeSessionV1 = ({
  token,
}: {
  token: PaymentSessionToken;
}): Promise<any> =>
  new Promise((resolve, reject) => {
    try {
      const res = purchaseApi(axios).apiV1PurchaseQuoteDiscardPost({
        value: token.value,
      });

      res.then((body) => resolve(body.data)).catch((e) => reject(e));
    } catch (e) {
      reject(e);
    }
  });
