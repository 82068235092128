import { connect, ConnectedProps } from "react-redux";
import { withRouter } from "react-router";
import { BookingInProgressModal } from "./component";
import {
  getBookingInProgress,
  getConfirmationDetails,
  getPollPriceQuoteCallState,
  getSchedulePriceQuoteCallState,
  isContactStepComplete,
  isTravelerStepComplete,
} from "../../reducer";
import { IStoreState } from "../../../../reducers/types";
import { CallState } from "redmond";

const mapStateToProps = (state: IStoreState) => ({
  inProgress: getBookingInProgress(state),
  priceQuoteInProgress:
    getSchedulePriceQuoteCallState(state) === CallState.InProcess ||
    getPollPriceQuoteCallState(state) === CallState.InProcess,
  car: getConfirmationDetails(state),
  isTravelerStepComplete: isTravelerStepComplete(state),
  isContactStepComplete: isContactStepComplete(state),
});

const mapDispatchToProps = {};

const connector = connect(mapStateToProps, mapDispatchToProps);

export type BookingInProgressModalConnectorProps = ConnectedProps<
  typeof connector
>;

export const ConnectedBookingInProgressModal = withRouter(
  connector(BookingInProgressModal)
);
