import React, { useEffect, useMemo } from "react";
import { Box } from "@material-ui/core";
import { RouteComponentProps } from "react-router-dom";
import { useDeviceTypes } from "halifax";
import queryStringParser from "query-string";
import { VIEWED_CAR_DETAILS } from "redmond";

import { trackEvent } from "../../api/v1/analytics/trackEvent";
import { CarShopDetails } from "./components/CarShopDetails";
import { MobileShop } from "./components/MobileShop";
import { CarsShopConnectorProps } from "./container";
import { PORTAL_TITLE, REVIEW_CAR_TITLE } from "../../lang/textConstants";

import "./styles.scss";
import {
  useExperiments,
  getExperimentVariant,
  RECENTLY_VIEWED_V2_CARS,
  AVAILABLE,
} from "../../context/experiments";

interface CarsShopProps extends CarsShopConnectorProps, RouteComponentProps {}

export const CarsShop = (props: CarsShopProps) => {
  const {
    setSelectedAccount,
    fetchCarExtraInfo,
    history,
    rewardsAccounts,
    viewedCarFromListProperties,
  } = props;
  const { matchesMobile, matchesDesktop } = useDeviceTypes();
  const expState = useExperiments();

  const recentlyViewedV2Cars = getExperimentVariant(
    expState.experiments,
    RECENTLY_VIEWED_V2_CARS
  );

  const isRecentlyViewedCarsV2Experiment = useMemo(() => {
    return recentlyViewedV2Cars === AVAILABLE;
  }, [recentlyViewedV2Cars]);

  useEffect(() => {
    trackEvent({
      eventName: VIEWED_CAR_DETAILS,
      properties: {
        ...viewedCarFromListProperties.properties,
      },
      encryptedProperties: [...viewedCarFromListProperties.encryptedProperties],
    });
    fetchCarExtraInfo(history, {
      includeLocationLabelsInAvailCall: isRecentlyViewedCarsV2Experiment,
    });
  }, [isRecentlyViewedCarsV2Experiment]);

  useEffect(() => {
    document.title = REVIEW_CAR_TITLE;
    setTimeout(() => window.scrollTo(0, 0), 0);

    return () => {
      document.title = PORTAL_TITLE;
    };
  }, []);

  useEffect(() => {
    const { selectedAccountIndex } = queryStringParser.parse(
      history.location.search
    );

    if (selectedAccountIndex && rewardsAccounts.length > 0) {
      setSelectedAccount(
        rewardsAccounts[Number(selectedAccountIndex)].accountReferenceId
      );
    }
  }, [rewardsAccounts]);

  return (
    <Box className="car-shop-root">
      {matchesDesktop && <CarShopDetails />}
      {matchesMobile && <MobileShop />}
    </Box>
  );
};
