import React from "react";
import {
  Box,
  Checkbox,
  FormGroup,
  FormControl,
  FormControlLabel,
  Typography,
} from "@material-ui/core";
import { useDeviceTypes } from "halifax";
import clsx from "clsx";

import "./styles.scss";
import { PickUpType } from "redmond";
import { getPickUpLabel } from "./textConstants";

export interface IPickUpSelectionProps {
  className?: string;
  pickUpSelected: PickUpType[];
  setPickUpFilter: (pickUpTypes: PickUpType[]) => void;
  isCarsCXV1Experiment?: boolean;
}

export const PickUpSelection = (props: IPickUpSelectionProps) => {
  const { className, pickUpSelected, setPickUpFilter, isCarsCXV1Experiment } =
    props;
  const { matchesMobile } = useDeviceTypes();

  const isChecked = (pickUpType: PickUpType): boolean => {
    return pickUpSelected.some((type) => type === pickUpType);
  };

  const handleChange = (pickUpFilterValue: PickUpType) => {
    if (isChecked(pickUpFilterValue)) {
      const selectedAfterRemove = (pickUpSelected as PickUpType[]).filter(
        (selectedFilter: PickUpType) => selectedFilter !== pickUpFilterValue
      );
      setPickUpFilter(selectedAfterRemove);
    } else {
      const newSelected = [
        ...pickUpSelected,
        pickUpFilterValue,
      ] as PickUpType[];
      setPickUpFilter(newSelected);
    }
  };

  const renderOptions = (options: PickUpType[]) => {
    return options.map((pickUpFilterValue: PickUpType) => {
      const checked = isChecked(pickUpFilterValue);
      return (
        <FormControlLabel
          key={pickUpFilterValue}
          onKeyDown={(e) => {
            if (e && e.key === "Enter") {
              handleChange(pickUpFilterValue);
            }
          }}
          control={
            <Checkbox
              inputProps={{ "aria-labelledby": pickUpFilterValue }}
              checked={checked}
              onChange={() => handleChange(pickUpFilterValue)}
              value={pickUpFilterValue}
              className="pick-up-selection-checkbox"
            />
          }
          labelPlacement={
            isCarsCXV1Experiment && !matchesMobile ? "end" : "start"
          }
          label={
            <Box id={pickUpFilterValue} className="pick-up-label-wrapper">
              <Typography variant="body1" className="pick-up-label">
                {getPickUpLabel(pickUpFilterValue)}
              </Typography>
            </Box>
          }
          className="pick-up-selection-form-control-label"
        />
      );
    });
  };

  return (
    <Box
      className={clsx(
        "pick-up-selection-root",
        { mobile: matchesMobile, "cars-cx-v1": isCarsCXV1Experiment },
        className
      )}
    >
      <Box className="pick-up-selection-container">
        <FormControl
          className="pick-up-selection-form-control"
          component="fieldset"
        >
          <FormGroup>
            {renderOptions([PickUpType.Airport, PickUpType.Shuttle])}
          </FormGroup>
        </FormControl>
      </Box>
    </Box>
  );
};
