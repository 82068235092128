import React from "react";
import { Box, PopoverOrigin } from "@material-ui/core";
import {
  ActionButton,
  ActionLink,
  ButtonWrap,
  DesktopPopupModal,
  GenericDropdown,
  Icon,
  IconName,
  PolicyStatusSelection,
} from "halifax";
import clsx from "clsx";

import { AvailabilityFiltersConnectorProps } from "../../container";
import {
  CarTypeSelection,
  PriceRangeSelection,
  CarCompanySelection,
  SpecificationSelection,
  CancellationPolicySelection,
  PassengersSelection,
  BagsSelection,
} from "..";
import {
  CAR_TYPE_TEXT,
  PRICE_PER_DAY_TEXT,
  PRICE_RANGE_TEXT,
  RENTAL_CAR_COMPANY_TEXT,
  SPECIFICATIONS_TEXT,
  CANCELLATION_POLICY_TEXT,
  PRICE_DROPDOWN_LABEL,
  RENTAL_COMPANY_DROPDOWN_LABEL,
  SPECIFICATIONS_DROPDOWN_LABEL,
  CANCELLATION_DROPDOWN_LABEL,
  CAR_TYPE_DROPDOWN_LABEL,
  PASSENGERS_DROPDOWN_LABEL,
  PASSENGERS,
  IN_POLICY_TEXT,
  BAGS_TEXT,
  BAGS_DROPDOWN_LABEL,
  POLICY_DROPDOWN_LABEL,
} from "../../textConstants";
import "./styles.scss";
import {
  APPLY_FILTERS_MODAL_CTA_TEXT,
  CLEAR_FILTERS_MODAL_CTA_TEXT,
  FILTERS_MODAL_CTA_TEXT,
} from "./textConstants";
import { FilterModalContent } from "../FilterModalContent";
import {
  CarFilterTypeV5,
  ICancellationPolicy,
  ICarCompaniesFilter,
  ISpecifications,
  PassengerFilter,
  initialFilterState,
} from "../../../../reducer";
import { ALL_FILTERS_MODAL_CARS_EVENT, PickUpType } from "redmond";
import { isEqual } from "lodash-es";
import { useFilterLabelValues } from "./useFilterLabelValues";
import { MaxPriceSelection } from "../MaxPriceSelection";
import { trackEvent } from "../../../../../../api/v1/analytics/trackEvent";
import { config } from "../../../../../../api/config";
import { isCorpTenant } from "@capone/common";

export interface IDesktopAvailabilityFiltersProps
  extends AvailabilityFiltersConnectorProps {
  isCarsCXV1Experiment?: boolean;
  appliedFiltersCount: number;
}

export const DesktopAvailabilityFilters = (
  props: IDesktopAvailabilityFiltersProps
) => {
  const {
    carTypes,
    setCarTypeFilter,
    minMaxPriceRange,
    priceRange,
    setPriceRangeFilter,
    totalMinMaxPriceRange,
    maxTotalPrice,
    setMaxTotalPriceFilter,
    currencySymbol,
    setPassengersFilter,
    setPickupFilter,
    carCompanies,
    carCompaniesList,
    setCarCompaniesFilter,
    specifications,
    setSpecificationsFilter,
    cancellation,
    setCancellationPolicyFilter,
    isCarsCXV1Experiment,
    appliedFiltersCount,
    passengers,
    pickUps,
    bags,
    setBagsFilter,
    isInPolicy,
    setCarPolicyFilter,
  } = props;

  const [filterModalOpen, setFilterModalOpen] = React.useState<boolean>(false);

  const [localCarTypes, setLocalCarTypes] = React.useState<CarFilterTypeV5[]>(
    carTypes as CarFilterTypeV5[]
  );
  const [localMaxTotalPrice, setLocalMaxTotalPrice] =
    React.useState<number>(maxTotalPrice);
  const [localCancellationPolicy, setLocalCancellationPolicy] =
    React.useState<ICancellationPolicy>(cancellation);
  const [localCarCompanies, setLocalCarCompanies] =
    React.useState<ICarCompaniesFilter>(carCompanies);
  const [localSpecifications, setLocalSpecifications] =
    React.useState<ISpecifications>(specifications);
  const [localPassengers, setLocalPassengers] =
    React.useState<PassengerFilter[]>(passengers);
  const [localPickUps, setLocalPickUps] = React.useState<PickUpType[]>(pickUps);
  const [localBags, setLocalBags] = React.useState<number[]>(bags);
  const [localPolicy, setLocalPolicy] = React.useState<boolean>(isInPolicy);

  const filtersDifferFromInitialState = React.useMemo(
    () =>
      [
        !isEqual(localCarTypes, initialFilterState.carTypes),
        localMaxTotalPrice !== initialFilterState.maxTotalPrice,
        !isEqual(localCancellationPolicy, initialFilterState.cancellation),
        Object.values(localCarCompanies).length
          ? Object.values(localCarCompanies).some((isSelected) => isSelected)
          : false,
        !isEqual(localSpecifications, initialFilterState.specifications),
        !isEqual(localPassengers, initialFilterState.passengers),
        !isEqual(localPickUps, initialFilterState.pickUp),
        !isEqual(localBags, initialFilterState.bags),
        !isEqual(localPolicy, initialFilterState.isInPolicy),
      ].some((notEqual) => notEqual),
    [
      localCarTypes,
      localMaxTotalPrice,
      localCancellationPolicy,
      localCarCompanies,
      localSpecifications,
      localPassengers,
      localPickUps,
      localBags,
      localPolicy,
    ]
  );

  const filtersToApplyCount = React.useMemo(
    () =>
      [
        !isEqual(localCarTypes, carTypes),
        localMaxTotalPrice !== maxTotalPrice,
        !isEqual(localCancellationPolicy, cancellation),
        !Object.keys(localCarCompanies).every(
          (key) => !!localCarCompanies[key] === !!carCompanies[key]
        ),
        !isEqual(localSpecifications, specifications),
        !isEqual(localPassengers, passengers),
        !isEqual(localPickUps, pickUps),
        !isEqual(localBags, bags),
        !isEqual(localPolicy, isInPolicy),
      ].filter((notEqual) => notEqual).length,
    [
      localCarTypes,
      carTypes,
      localMaxTotalPrice,
      maxTotalPrice,
      localCancellationPolicy,
      cancellation,
      localCarCompanies,
      carCompanies,
      localSpecifications,
      specifications,
      localPassengers,
      passengers,
      localPickUps,
      pickUps,
      localBags,
      bags,
      localPolicy,
      isInPolicy,
    ]
  );

  const hasFiltersToApply = filtersToApplyCount > 0;

  const hasSetCarTypeFilter = !isEqual(carTypes, initialFilterState.carTypes);
  const hasSetPriceFilter = maxTotalPrice !== initialFilterState.maxTotalPrice;
  const hasSetCarCompanyFilter = Object.values(localCarCompanies).length
    ? Object.values(localCarCompanies).some((isSelected) => isSelected)
    : false;
  const hasSetSpecificationFilter = !isEqual(
    specifications,
    initialFilterState.specifications
  );
  const hasSetCancellationFilter = !isEqual(
    cancellation,
    initialFilterState.cancellation
  );

  const hasSetPassengersFilter = !isEqual(
    passengers,
    initialFilterState.passengers
  );

  const hasSetBagsFilter = !isEqual(bags, initialFilterState.bags);

  const hasSetPolicyFilter = !isEqual(
    isInPolicy,
    initialFilterState.isInPolicy
  );

  const {
    carTypeLabel,
    priceLabel,
    rentalCompaniesLabel,
    specificationsLabel,
    cancellationLabel,
    passengersLabel,
    bagsLabel,
    policyLabel,
  } = useFilterLabelValues({
    carTypes: carTypes as CarFilterTypeV5[],
    maxTotalPrice,
    carCompanies,
    allCarCompanies: carCompaniesList,
    specifications,
    cancellation: Object.values(cancellation).every((applied) => applied),
    passengers,
    pickUps,
    bags,
    isInPolicy,
  });

  React.useEffect(() => {
    setLocalCarTypes(carTypes as CarFilterTypeV5[]);
  }, [carTypes, filterModalOpen]);

  React.useEffect(() => {
    setLocalMaxTotalPrice(maxTotalPrice);
  }, [maxTotalPrice, filterModalOpen]);

  React.useEffect(() => {
    setLocalCancellationPolicy(cancellation);
  }, [cancellation, filterModalOpen]);

  React.useEffect(() => {
    setLocalCarCompanies(carCompanies);
  }, [carCompanies, filterModalOpen]);

  React.useEffect(() => {
    setLocalSpecifications(specifications);
  }, [specifications, filterModalOpen]);

  React.useEffect(() => {
    setLocalPassengers(passengers);
  }, [passengers, filterModalOpen]);

  React.useEffect(() => {
    setLocalPickUps(pickUps);
  }, [pickUps, filterModalOpen]);

  React.useEffect(() => {
    setLocalBags(bags);
  }, [bags, filterModalOpen]);

  React.useEffect(() => {
    setLocalPolicy(isInPolicy);
  }, [isInPolicy, filterModalOpen]);

  const handleClose = () => setFilterModalOpen(false);

  const handleApply = () => {
    trackEvent({
      eventName: ALL_FILTERS_MODAL_CARS_EVENT,
      properties: {
        car_type: localCarTypes.toString(),
        passengers: localPassengers.toString(),
        bags: localBags.toString(),
        pickup_location: localPickUps.toString(),
        rental_company: localCarCompanies.toString(),
        price: localMaxTotalPrice,
        cancellation: localCancellationPolicy.toString(),
      },
    });
    setCarTypeFilter(localCarTypes);
    setMaxTotalPriceFilter(localMaxTotalPrice);
    setCarCompaniesFilter(localCarCompanies);
    setSpecificationsFilter(localSpecifications);
    setCancellationPolicyFilter(localCancellationPolicy);
    setPassengersFilter(localPassengers);
    setPickupFilter(localPickUps);
    setBagsFilter(localBags);
    setCarPolicyFilter(localPolicy);
  };

  const handleReset = () => {
    trackEvent({
      eventName: ALL_FILTERS_MODAL_CARS_EVENT,
      properties: {
        car_type: initialFilterState.carTypes.toString(),
        passengers: initialFilterState.passengers.toString(),
        bags: initialFilterState.bags.toString(),
        pickup_location: initialFilterState.pickUp.toString(),
        rental_company: initialFilterState.carCompanies.toString(),
        price: initialFilterState.maxTotalPrice,
        cancellation: initialFilterState.cancellation.toString(),
      },
    });
    setLocalCarTypes(initialFilterState.carTypes as CarFilterTypeV5[]);
    setLocalMaxTotalPrice(initialFilterState.maxTotalPrice);
    setLocalCarCompanies(initialFilterState.carCompanies);
    setLocalSpecifications(initialFilterState.specifications);
    setLocalCancellationPolicy(initialFilterState.cancellation);
    setLocalPassengers(initialFilterState.passengers);
    setLocalPickUps(initialFilterState.pickUp);
    setLocalBags(initialFilterState.bags);
    setLocalPolicy(initialFilterState.isInPolicy);
  };

  const anchorOrigin: PopoverOrigin | undefined = isCarsCXV1Experiment
    ? {
        vertical: "bottom",
        horizontal: "left",
      }
    : undefined;
  const transformOrigin: PopoverOrigin | undefined = isCarsCXV1Experiment
    ? {
        vertical: "top",
        horizontal: "left",
      }
    : undefined;

  return (
    <Box
      className={clsx("desktop-availability-filter", {
        "cars-cx-v1": isCarsCXV1Experiment,
      })}
    >
      {isCarsCXV1Experiment && (
        <>
          <ActionButton
            className={clsx("filters-modal-action-button")}
            defaultStyle="h4r-secondary"
            message={FILTERS_MODAL_CTA_TEXT(appliedFiltersCount)}
            icon={<Icon name={IconName.Settings} />}
            onClick={() => setFilterModalOpen(true)}
          />

          <DesktopPopupModal
            open={filterModalOpen}
            className={"desktop-cars-availability-filter-root"}
            contentClassName={
              "desktop-cars-availability-filter-content-wrapper"
            }
            onClose={() => setFilterModalOpen(false)}
            bottomButton={
              <Box className="cars-availability-filter-bottom-buttons-container">
                {filtersDifferFromInitialState && (
                  <ActionLink
                    className={clsx("reset-filters-link", "filter-button")}
                    onClick={handleReset}
                    content={CLEAR_FILTERS_MODAL_CTA_TEXT}
                  />
                )}
                <ActionButton
                  className={clsx("apply-filters-button", "filter-button")}
                  onClick={() => {
                    handleApply();
                    handleClose();
                  }}
                  defaultStyle="h4r-primary"
                  disabled={!hasFiltersToApply}
                  message={APPLY_FILTERS_MODAL_CTA_TEXT(filtersToApplyCount)}
                />
              </Box>
            }
            invisibleBackdrop={false}
          >
            <Box className="desktop-cars-availability-filter-content-root">
              <Box className="desktop-cars-availability-filter-content-container">
                <FilterModalContent
                  carTypes={localCarTypes as CarFilterTypeV5[]}
                  setCarTypes={setLocalCarTypes}
                  minMaxPriceRange={totalMinMaxPriceRange}
                  maxTotalPriceFilter={localMaxTotalPrice}
                  setMaxTotalPriceFilter={setLocalMaxTotalPrice}
                  carCompanies={localCarCompanies}
                  allCarCompanies={carCompaniesList}
                  setCarCompanies={setLocalCarCompanies}
                  specifications={localSpecifications}
                  setSpecifications={setLocalSpecifications}
                  cancellationPolicy={localCancellationPolicy}
                  setCancellationPolicy={setLocalCancellationPolicy}
                  currencySymbol={currencySymbol}
                  passengers={localPassengers}
                  setPassengers={setLocalPassengers}
                  pickUps={localPickUps}
                  setPickUp={setLocalPickUps}
                  bags={localBags}
                  setBags={setLocalBags}
                  isInPolicy={localPolicy}
                  setIsInPolicy={setLocalPolicy}
                />
              </Box>
            </Box>
          </DesktopPopupModal>
        </>
      )}

      <GenericDropdown
        buttonClassName={clsx("car-availability-dropdown", "car-type", {
          "has-value": hasSetCarTypeFilter,
          "cars-cx-v1": isCarsCXV1Experiment,
        })}
        popoverClassName={clsx(
          "car-availability-dropdown-popover",
          "car-type",
          "b2b",
          { "cars-cx-v1": isCarsCXV1Experiment }
        )}
        ariaLabel={`Apply ${CAR_TYPE_TEXT} filter`}
        dropdownLabel={
          isCarsCXV1Experiment
            ? CAR_TYPE_DROPDOWN_LABEL(carTypeLabel)
            : CAR_TYPE_TEXT
        }
        dropdownContent={
          <CarTypeSelection
            carTypes={carTypes}
            setCarTypes={setCarTypeFilter}
            isCarsCXV1Experiment={isCarsCXV1Experiment}
          />
        }
        anchorOrigin={anchorOrigin}
        transformOrigin={transformOrigin}
        dropdownIcon={
          isCarsCXV1Experiment && hasSetCarTypeFilter ? (
            <ButtonWrap
              onClick={(e) => {
                e.stopPropagation();
                setCarTypeFilter(initialFilterState.carTypes);
              }}
            >
              <Icon name={IconName.XCircle} />
            </ButtonWrap>
          ) : undefined
        }
      />
      <GenericDropdown
        buttonClassName={clsx("car-availability-dropdown", "price-range", {
          "has-value": hasSetPriceFilter,
          "cars-cx-v1": isCarsCXV1Experiment,
        })}
        popoverClassName={clsx(
          "car-availability-dropdown-popover",
          "price-range",
          "b2b",
          { "cars-cx-v1": isCarsCXV1Experiment }
        )}
        ariaLabel={`Apply ${
          isCarsCXV1Experiment ? PRICE_DROPDOWN_LABEL() : PRICE_PER_DAY_TEXT
        } filter`}
        dropdownLabel={
          isCarsCXV1Experiment
            ? PRICE_DROPDOWN_LABEL(priceLabel)
            : PRICE_PER_DAY_TEXT
        }
        dropdownContent={
          isCarsCXV1Experiment ? (
            <MaxPriceSelection
              minMaxPriceRange={totalMinMaxPriceRange}
              maxPrice={maxTotalPrice}
              setMaxPrice={setMaxTotalPriceFilter}
              showTitles
            />
          ) : (
            <PriceRangeSelection
              title={PRICE_RANGE_TEXT}
              setPriceRange={setPriceRangeFilter}
              priceRange={priceRange}
              minPrice={minMaxPriceRange.min}
              maxPrice={minMaxPriceRange.max}
              currencySymbol={currencySymbol}
            />
          )
        }
        anchorOrigin={anchorOrigin}
        transformOrigin={transformOrigin}
        dropdownIcon={
          isCarsCXV1Experiment && hasSetPriceFilter ? (
            <ButtonWrap
              onClick={(e) => {
                e.stopPropagation();
                setMaxTotalPriceFilter(initialFilterState.maxTotalPrice);
              }}
            >
              <Icon name={IconName.XCircle} />
            </ButtonWrap>
          ) : undefined
        }
      />
      <GenericDropdown
        buttonClassName={clsx("car-availability-dropdown", "car-company", {
          "has-value": hasSetCarCompanyFilter,
          "cars-cx-v1": isCarsCXV1Experiment,
        })}
        popoverClassName={clsx(
          "car-availability-dropdown-popover",
          "car-company",
          "b2b",
          { "cars-cx-v1": isCarsCXV1Experiment }
        )}
        ariaLabel={`Apply ${
          isCarsCXV1Experiment
            ? RENTAL_COMPANY_DROPDOWN_LABEL()
            : RENTAL_CAR_COMPANY_TEXT
        } filter`}
        dropdownLabel={
          isCarsCXV1Experiment
            ? RENTAL_COMPANY_DROPDOWN_LABEL(rentalCompaniesLabel)
            : RENTAL_CAR_COMPANY_TEXT
        }
        dropdownContent={
          <CarCompanySelection
            options={carCompaniesList}
            carCompanies={carCompanies}
            setCarCompanies={setCarCompaniesFilter}
            isCarsCXV1Experiment={isCarsCXV1Experiment}
          />
        }
        anchorOrigin={anchorOrigin}
        transformOrigin={transformOrigin}
        dropdownIcon={
          isCarsCXV1Experiment && hasSetCarCompanyFilter ? (
            <ButtonWrap
              onClick={(e) => {
                e.stopPropagation();
                setCarCompaniesFilter(initialFilterState.carCompanies);
              }}
            >
              <Icon name={IconName.XCircle} />
            </ButtonWrap>
          ) : undefined
        }
      />
      {!isCarsCXV1Experiment && (
        <GenericDropdown
          buttonClassName={clsx("car-availability-dropdown", "specification", {
            "has-value": hasSetSpecificationFilter,
            "cars-cx-v1": isCarsCXV1Experiment,
          })}
          popoverClassName={clsx(
            "car-availability-dropdown-popover",
            "specification",
            "b2b",
            { "cars-cx-v1": isCarsCXV1Experiment }
          )}
          ariaLabel={`Apply ${SPECIFICATIONS_TEXT} filter`}
          dropdownLabel={
            isCarsCXV1Experiment
              ? SPECIFICATIONS_DROPDOWN_LABEL(specificationsLabel)
              : SPECIFICATIONS_TEXT
          }
          dropdownContent={
            <SpecificationSelection
              specifications={specifications}
              setSpecifications={setSpecificationsFilter}
              isCarsCXV1Experiment={isCarsCXV1Experiment}
            />
          }
          anchorOrigin={anchorOrigin}
          transformOrigin={transformOrigin}
          dropdownIcon={
            isCarsCXV1Experiment && hasSetSpecificationFilter ? (
              <ButtonWrap
                onClick={(e) => {
                  e.stopPropagation();
                  setSpecificationsFilter(initialFilterState.specifications);
                }}
              >
                <Icon name={IconName.XCircle} />
              </ButtonWrap>
            ) : undefined
          }
        />
      )}
      <GenericDropdown
        buttonClassName={clsx("car-availability-dropdown", "cancellation", {
          "has-value": hasSetCancellationFilter,
          "cars-cx-v1": isCarsCXV1Experiment,
        })}
        popoverClassName={clsx(
          "car-availability-dropdown-popover",
          "cancellation",
          "b2b",
          { "cars-cx-v1": isCarsCXV1Experiment }
        )}
        ariaLabel={`Apply ${CANCELLATION_POLICY_TEXT} filter`}
        dropdownLabel={
          isCarsCXV1Experiment
            ? CANCELLATION_DROPDOWN_LABEL(cancellationLabel)
            : CANCELLATION_POLICY_TEXT
        }
        dropdownContent={
          <CancellationPolicySelection
            cancellation={cancellation}
            setCancellationPolicy={setCancellationPolicyFilter}
            isCarsCXV1Experiment={isCarsCXV1Experiment}
          />
        }
        anchorOrigin={anchorOrigin}
        transformOrigin={transformOrigin}
        dropdownIcon={
          isCarsCXV1Experiment && hasSetCancellationFilter ? (
            <ButtonWrap
              onClick={(e) => {
                e.stopPropagation();
                setCancellationPolicyFilter(initialFilterState.cancellation);
              }}
            >
              <Icon name={IconName.XCircle} />
            </ButtonWrap>
          ) : undefined
        }
      />
      {isCarsCXV1Experiment && (
        <GenericDropdown
          buttonClassName={clsx("car-availability-dropdown", "passengers", {
            "has-value": hasSetPassengersFilter,
            "cars-cx-v1": isCarsCXV1Experiment,
          })}
          popoverClassName={clsx(
            "car-availability-dropdown-popover",
            "passengers",
            "b2b",
            { "cars-cx-v1": isCarsCXV1Experiment }
          )}
          ariaLabel={`Apply ${PASSENGERS} filter`}
          dropdownLabel={
            isCarsCXV1Experiment
              ? PASSENGERS_DROPDOWN_LABEL(passengersLabel)
              : PASSENGERS
          }
          dropdownContent={
            <PassengersSelection
              setPassengersFilter={setPassengersFilter}
              passengersSelected={passengers}
              isCarsCXV1Experiment
            />
          }
          anchorOrigin={anchorOrigin}
          transformOrigin={transformOrigin}
          dropdownIcon={
            hasSetPassengersFilter ? (
              <ButtonWrap
                onClick={(e) => {
                  e.stopPropagation();
                  setPassengersFilter(initialFilterState.passengers);
                }}
              >
                <Icon name={IconName.XCircle} />
              </ButtonWrap>
            ) : undefined
          }
        />
      )}
      {isCarsCXV1Experiment && (
        <GenericDropdown
          buttonClassName={clsx("car-availability-dropdown", "bags", {
            "has-value": hasSetBagsFilter,
            "cars-cx-v1": isCarsCXV1Experiment,
          })}
          popoverClassName={clsx(
            "car-availability-dropdown-popover",
            "bags",
            "b2b"
          )}
          ariaLabel={`Apply ${BAGS_TEXT} filter`}
          dropdownLabel={
            isCarsCXV1Experiment ? BAGS_DROPDOWN_LABEL(bagsLabel) : BAGS_TEXT
          }
          dropdownContent={
            <BagsSelection
              isCarsCXV1Experiment
              bagsSelected={bags}
              setBagsFilter={setBagsFilter}
            />
          }
          anchorOrigin={anchorOrigin}
          transformOrigin={transformOrigin}
          dropdownIcon={
            hasSetBagsFilter ? (
              <ButtonWrap
                onClick={(e) => {
                  e.stopPropagation();
                  setBagsFilter(initialFilterState.bags);
                }}
              >
                <Icon name={IconName.XCircle} />
              </ButtonWrap>
            ) : undefined
          }
        />
      )}
      {isCorpTenant(config.TENANT) && (
        <GenericDropdown
          buttonClassName={clsx("car-availability-dropdown", "policy", {
            "has-value": hasSetPolicyFilter,
          })}
          popoverClassName={clsx(
            "car-availability-dropdown-popover",
            "policy",
            "b2b"
          )}
          ariaLabel={`Apply ${IN_POLICY_TEXT} filter`}
          dropdownLabel={
            isCarsCXV1Experiment
              ? POLICY_DROPDOWN_LABEL(policyLabel)
              : IN_POLICY_TEXT
          }
          dropdownContent={
            <PolicyStatusSelection
              isInPolicy={isInPolicy}
              setIsInPolicy={setCarPolicyFilter}
            />
          }
          dropdownIcon={
            isCarsCXV1Experiment && hasSetPolicyFilter ? (
              <ButtonWrap
                onClick={(e) => {
                  e.stopPropagation();
                  setCarPolicyFilter(initialFilterState.isInPolicy);
                }}
              >
                <Icon name={IconName.XCircle} />
              </ButtonWrap>
            ) : undefined
          }
        />
      )}
    </Box>
  );
};
