import React from "react";
import {
  Box,
  Typography,
  Popover,
  Link,
  InputAdornment,
} from "@material-ui/core";
import {
  getTotalPriceText,
  getRewardText,
  twoDecimalFormatter,
  NotificationBanner,
  BannerSeverity,
  Icon,
  IconName,
  CarsPriceBreakdown,
} from "halifax";
import { faCaretDown } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import clsx from "clsx";
import { PriceBreakdownDropdownConnectorProps } from "./container";
import { TRIP_TOTAL_TEXT, UNDER_25_WARNING } from "./textConstants";
import "./styles.scss";

export interface IPriceBreakdownDropdownProps
  extends PriceBreakdownDropdownConnectorProps {
  popoverClassName?: string;
}

export const PriceBreakdownDropdown = ({
  pricingLineItems,
  rewardsAndTotalLineItems,
  tripTotal,
  driverAge,
  popoverClassName,
}: IPriceBreakdownDropdownProps) => {
  const [openPopover, setOpenPopover] = React.useState<boolean>(false);

  const togglePopover = () => {
    setOpenPopover((open) => !open);
  };

  const handleClose = () => {
    setOpenPopover(false);
  };

  if (!tripTotal) {
    return null;
  }
  const isUnderAge = (driverAge || 0) < 25;
  // TODO: refactor GenericDropdown component in Halifax so that it can display ReactNode for label, and then refactor this
  // to use the reworked GenericDropdown component.
  return (
    <>
      <Link
        className="price-breakdown-dropdown-root"
        onClick={togglePopover}
        component={"button"}
      >
        {/* note: this is dropdownLabel */}
        <Box className="total-price-label-container">
          <Typography
            className="trip-total"
            variant="subtitle2"
          >{`${TRIP_TOTAL_TEXT}:`}</Typography>
          <Typography className={clsx("value", "fiat")} variant="subtitle1">
            {`${tripTotal.fiat.currencyCode} ${getTotalPriceText({
              price: tripTotal.fiat,
              priceFormatter: twoDecimalFormatter,
            })}`}
          </Typography>
          {!!tripTotal.rewards && (
            <>
              <Typography className="separator" variant="subtitle2">
                /
              </Typography>
              <Typography
                className={clsx("value", "rewards")}
                variant="subtitle2"
              >
                {getRewardText({
                  reward: tripTotal.rewards,
                })}
              </Typography>
            </>
          )}
        </Box>
        <InputAdornment
          className="total-price-adornment-icon-container"
          position="end"
        >
          <Box>
            <FontAwesomeIcon
              className={clsx("caret-icon", { open: openPopover })}
              icon={faCaretDown}
            />
          </Box>
        </InputAdornment>
      </Link>
      <Popover
        className={clsx("price-breakdown-popover-root", popoverClassName)}
        open={openPopover}
        PaperProps={{
          className: "price-breakdown-popover-paper",
        }}
        BackdropProps={{
          className: "price-breakdown-popover-backdrop",
          invisible: false,
        }}
        hideBackdrop={false}
        onClose={handleClose}
        onClick={handleClose}
        anchorOrigin={{
          vertical: "top",
          horizontal: "center",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "center",
        }}
        disableEnforceFocus
      >
        {/* note: this is dropdownContent */}
        <Box className="hotel-price-breakdown-wrapper">
          <CarsPriceBreakdown
            className="pricing-line-items-breakdown"
            pricingItems={pricingLineItems[0]}
            totalItems={pricingLineItems[1]}
            summaryItems={rewardsAndTotalLineItems}
            currencySymbol={""}
          />
          <Box className="underage-banner-container">
            {isUnderAge && (
              <NotificationBanner
                className="underage-banner"
                label={UNDER_25_WARNING}
                severity={BannerSeverity.INFO}
                icon={
                  <Icon
                    className="banner-icon"
                    name={IconName.InfoCircleFill}
                  />
                }
              />
            )}
          </Box>
        </Box>
      </Popover>
    </>
  );
};
